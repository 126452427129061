import { ChangeEvent, MouseEvent as ReactMouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from '@mui/material';

export interface MenuItemsProps {
  logOut: () => void;
  openModal: () => void;
  onItemSelect: (
    event: ChangeEvent<any>,
  ) => void;
}

export const MainMenuItems = ({
  logOut,
  openModal,
  onItemSelect,
}: MenuItemsProps) => (
  <>
    <MenuItem
      onClick={(e: ReactMouseEvent<HTMLElement>) =>
        onItemSelect(e)
      }
      component={Link}
      to="/account/profile"
    >
      Profile
    </MenuItem>
    <MenuItem
      onClick={(e: ReactMouseEvent<HTMLElement>) =>
        onItemSelect(e)
      }
      component={Link}
      to="/account/company-profile"
    >
      Company Contact Details
    </MenuItem>

    <MenuItem
      onClick={(e: ReactMouseEvent<HTMLElement>) => {
        openModal();
        onItemSelect(e);
      }}
    >
      Auditor Confirmation
    </MenuItem>

    <MenuItem
      onClick={(e: ReactMouseEvent<HTMLElement>) =>
        onItemSelect(e)
      }
      component={Link}
      to="/faq"
    >
      FAQ
    </MenuItem>

    <MenuItem
      onClick={(e: ReactMouseEvent<HTMLElement>) => {
        logOut();
        onItemSelect(e);
      }}
      component={Link}
      to="/"
    >
      Log out
    </MenuItem>
  </>
);
