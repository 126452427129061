import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link, Typography } from '@mui/material';
import classes from './CookieConsentBar.module.css';

export const CookieConsentBar = () => (
  <CookieConsent
    cookieName="NTLoanPortalCookieConsent"
    style={{ background: '#13161c' }}
    buttonText="Accept"
    buttonStyle={{
      borderRadius: '4px',
      background: '#3f18aa',
      color: '#FFF',
        padding: '1rem'
    }}
    enableDeclineButton
    declineButtonText="Decline"
    declineButtonStyle={{
      borderRadius: '4px',
      background: '#f7f8f6',
      color: '#13161c',
        padding: '1rem',
    }}
    onDecline={() => window.location.replace('https://nordictrustee.com')}
  >
    <Typography variant="body1" className={classes.whiteText}>
      Nordic Trustee uses cookies to ensure that we give you the best experience
      on our website. More information
      <Link href="/terms-of-use" target="_blank" className={classes.link}>
        here
      </Link>
      .
    </Typography>
  </CookieConsent>
);
