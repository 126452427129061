import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoanDTO } from '../../api/api.types';
import { getLoan } from './LoansApi';

type LoanProviderProps = {
  isLoadingLoan: boolean;
  loan?: LoanDTO;
};
const LoansContext = createContext<LoanProviderProps>({ isLoadingLoan: false });

export const LoansProvider: FC = ({ children}) => {
  const { loanID} = useParams<{ loanID: string;}>();
  const [loan, setLoan] = useState<LoanDTO>();
  const [isLoadingLoan, setIsLoadingLoan] = useState<boolean>(false);
  useEffect(() => {
    if (!loanID || isLoadingLoan || +loanID === loan?.issueID) return;
    setIsLoadingLoan(true);
    getLoan(loanID)
      .then((data) => {
        setLoan(data);
        setIsLoadingLoan(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoadingLoan(false);
      });
  }, [loanID, isLoadingLoan, loan?.issueID]);

  return (
    <LoansContext.Provider value={{ isLoadingLoan, loan }}>
      {children}
    </LoansContext.Provider>
  );
};

export const useLoans = () => {
  const context = useContext(LoansContext);
  if (context === undefined) {
    throw new Error('useLoans must be used within a LoansProvider');
  }
  return context;
};
