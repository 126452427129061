import { CardContent } from '@mui/material';
import { LoanDTO } from 'api/api.types';
import { SubCard } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { LoanOverviewDataList } from '../atoms';

interface ComponentProps {
  loan: LoanDTO;
  loading: boolean;
}

export interface Data {
  [key: string]: string | undefined;
}

export const LoanOverviewBasic = ({ loan, loading }: ComponentProps) => {
  const data: Data = {
    ISIN: loan.isin,
    Ticker: loan.ticker,
    Exchange: loan.exchange,
    Type: loan.securitiesType,
    'Redemption Type': loan.redemptionType,
  };

  return (
    <SubCard title="Basic">
      <CardContent>
        <LoadingContainer loading={loading} type="singleList" rows={6} cols={2}>
          <LoanOverviewDataList data={data} />
        </LoadingContainer>
      </CardContent>
    </SubCard>
  );
};
