import React from 'react';
import { formatMoney } from 'utils/money';
import classes from './Money.module.css';

interface ComponentProps {
  value?: number | null;
  currency?: string;
  values?: number[];
}

export const Money = ({ value, values, currency }: ComponentProps) =>
  value == null ? null : (
    <span className={classes.root}>{formatMoney(value, currency, values)}</span>
  );
