import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { PageHeaderDrawer } from './PageHeaderDrawer';
import classes from './PageHeaderMobileMenu.module.css';

interface ComponentProps {
  logOut: () => void;
}

export const PageHeaderMobileMenu: React.FC<ComponentProps> = ({ logOut }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        color="inherit"
        variant="text"
        onClick={() => setOpen(!open)}
        className={classes.button}
      >
        <MenuIcon />
      </Button>
      <PageHeaderDrawer open={open} setOpen={setOpen} logOut={logOut} />
    </>
  );
};
