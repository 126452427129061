import { Box } from '@mui/material';
import { PublishedSecurityDTO } from '../../../../api/api.types';
import { SecurityDetailTypeUniverseEnum } from '../../../Global/enums';
import { getSecurityItem } from './getSecurityItem';
import { SecuritiesCard } from './SecuritiesCard';
import { SecurityItem } from './security.types';

export interface SecurityProps {
  securities: PublishedSecurityDTO[];
}

export const Guarantee = ({ securities }: SecurityProps) => {
  const guarantees: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    guarantees.push(getSecurityItem(item, undefined));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={guarantees}
        securityType={SecurityDetailTypeUniverseEnum.Guarantee}
        customField={undefined}
      />
    </Box>
  );
};

export const SharePledge = ({ securities }: SecurityProps) => {
  const sharePledges: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    sharePledges.push(getSecurityItem(item, item.company));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={sharePledges}
        securityType={SecurityDetailTypeUniverseEnum.SharePledge}
        customField="Company Name"
      />
    </Box>
  );
};

export const AccountPledge = ({ securities }: SecurityProps) => {
  const accountPledges: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    accountPledges.push(getSecurityItem(item, item.accountNumber));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={accountPledges}
        securityType={SecurityDetailTypeUniverseEnum.AccountPledge}
        customField="AccountNumber"
      />
    </Box>
  );
};

export const RigVesselMortgage = ({ securities }: SecurityProps) => {
  const mortgages: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    mortgages.push(getSecurityItem(item, item.assetDescription));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={mortgages}
        securityType={SecurityDetailTypeUniverseEnum.RigVesselMortgage}
        customField="Type"
      />
    </Box>
  );
};

export const Other = ({ securities }: SecurityProps) => {
  const others: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    others.push(getSecurityItem(item, item.securityType));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={others}
        securityType={SecurityDetailTypeUniverseEnum.Other}
        customField="Type"
      />
    </Box>
  );
};

export const NorwegianPropertyMortgage = ({ securities }: SecurityProps) => {
  const mortgages: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    mortgages.push(getSecurityItem(item, item.assetDescription));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={mortgages}
        securityType={SecurityDetailTypeUniverseEnum.NorwegianPropertyMortgage}
        customField="PropertyDescription"
      />
    </Box>
  );
};

export const PropertyMortgage = ({ securities }: SecurityProps) => {
  const sharePledges: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    sharePledges.push(getSecurityItem(item, item.assetDescription));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={sharePledges}
        securityType={SecurityDetailTypeUniverseEnum.PropertyMortgage}
        customField="PropertyDescription"
      />
    </Box>
  );
};

export const SwedishPropertyMortgage = ({ securities }: SecurityProps) => {
  const mortgages: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    mortgages.push(getSecurityItem(item, item.assetDescription));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={mortgages}
        securityType={SecurityDetailTypeUniverseEnum.SwedishPropertyMortgage}
        customField="PropertyDescription"
      />
    </Box>
  );
};

export const NorwegianSharePledge = ({ securities }: SecurityProps) => {
  const sharePledges: SecurityItem[] = [];

  securities.forEach((item: PublishedSecurityDTO) => {
    sharePledges.push(getSecurityItem(item, item.company));
  });

  return (
    <Box mt={2}>
      <SecuritiesCard
        securities={sharePledges}
        securityType={SecurityDetailTypeUniverseEnum.NorwegianSharePledge}
        customField="CompanyName"
      />
    </Box>
  );
};
