import { Box, Typography } from '@mui/material';
import { InterestPaymentDTO } from 'api/api.types';
import dompurify from 'dompurify';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { FinancialError, FinancialSubTabs, useFinancialParams } from '../atoms';
import { filterPaymentsForSubTab } from './filterPayments';
import { PaymentTable } from './PaymentTable';
import classes from './Payments.module.css';

interface OwnProps {
  interestPayment: InterestPaymentDTO;
  loading: boolean;
  includeLoanName?: boolean;
}

type ComponentProps = OwnProps;
export const Payments = ({
  interestPayment,
  loading,
  includeLoanName,
}: ComponentProps) => {
  const { subTab } = useFinancialParams();
  const filteredPayments = filterPaymentsForSubTab(
    interestPayment.interestPaymentItemDTOs || [],
    subTab,
  );
  return (
    <>
      <StyledCardHeader title="Payments" action={<FinancialSubTabs />} />
      <Box mt={1}>
        <LoadingContainer loading={loading} type="list" rows={30} cols={6} />
          {interestPayment.errorMessage && (
            <FinancialError errorMsg={interestPayment.errorMessage} />
          )}
        <PaymentTable
          payments={filteredPayments}
          includeLoanName={includeLoanName}
          loading={loading}
        />
        {interestPayment &&
          interestPayment.redemptionClauses &&
          interestPayment.redemptionClauses.length > 0 && (
            <Box m={2}>
              <Typography variant="h6">Redemption Clause(s)</Typography>
              {interestPayment.redemptionClauses.map((r) => (
                <Box key={r.isin} display="flex">
                  <Typography variant="body1" className={classes.key}>
                    {r.isin}:
                  </Typography>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: dompurify.sanitize(r.clause),
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        {interestPayment &&
          interestPayment.interestRegulationClauses &&
          interestPayment.interestRegulationClauses.length > 0 && (
            <Box m={2}>
              <Typography variant="h6">
                Interest Regulation Clause(s)
              </Typography>
              {interestPayment.interestRegulationClauses.map((r) => (
                <Box key={r.isin} display="flex">
                  <Typography variant="body1" className={classes.key}>
                    {r.isin}:
                  </Typography>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: dompurify.sanitize(r.clause),
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
      </Box>
    </>
  );
};
