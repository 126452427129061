import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';

interface ReportingUploadLimitProps {
  totalFileSize: number;
  documentType: HubDocumentTypeUniverseEnum;
}

type ComponentProps = ReportingUploadLimitProps;

export const ReportingUploadLimit: FC<ComponentProps> = ({
  totalFileSize,
  documentType,
}) => {
  if (
    ![
      HubDocumentTypeUniverseEnum.Reporting,
    ].includes(documentType)
  ) {
    return null;
  }
  const nonAttachableFileSize = totalFileSize >= 19.5;
  return (
    <Typography
      variant="body1"
      component="span"
      color={nonAttachableFileSize ? 'secondary' : 'textPrimary'}
      hidden={documentType !== HubDocumentTypeUniverseEnum.Reporting}
    >
      {`Total File Size: ${totalFileSize.toFixed(2)} MB`}
    </Typography>
  );
};
