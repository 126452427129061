import moment from "moment/moment";

export const setDatesWhenValid = (
  setDates: (
    id: string,
    fromDate?: moment.Moment,
    toDate?: moment.Moment,
  ) => void,
  id: string,
  fromDate?: moment.Moment,
  toDate?: moment.Moment,
) => {
  if (!fromDate || !fromDate.isValid()) {
    return;
  }

  if (toDate && !toDate.isValid()) {
    return;
  }
  setDates(id, fromDate, toDate);
}
