import { EventSearchFilterOption } from '../../types';

export const sortFilterOptions = (filterOptions: EventSearchFilterOption[]) => {
  const options = [...filterOptions];
  options.sort((a, b) => {
    if (b.id === 'all') {
      return 1;
    }
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  return options;
};
