import { FC } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from 'moment/moment';
import { StyledCardHeader } from 'components/Card';
import {
  EventsSearchFilterIDs,
  EventsSearchFilterSelection,
} from '../../EventsApi';
import { EventSearchFilterOption, EventSearchFilters } from '../../types';
import { DateFilterList } from './DateFilterList';
import { EventDateFilterOption } from './dateFilterOptions';
import { EventsFilterList } from './EventsFilterList';
import { EventsListSettings } from './EventsListSettings';
import classes from './FilterColumn.module.css';

interface FilterColumnProps {
  onDateChange: (
    id: string,
    fromDate?: moment.Moment,
    toDate?: moment.Moment,
  ) => void; // Replace 'any' with the appropriate type
  onReset: () => void;
  onToggle: (
    filterID: EventsSearchFilterIDs,
    filterOption: EventSearchFilterOption,
    selection: EventsSearchFilterSelection,
  ) =>  void;
  filters: EventSearchFilters;
  dateFilters: EventDateFilterOption[];
  loadingEvents: boolean;
}

export const FilterColumn: FC<FilterColumnProps> = ({
  onReset,
  onDateChange,
  onToggle,
  filters,
  dateFilters,
  loadingEvents,
  children
}) => {

  return (
    <>
      <Card className={classes.card} data-cy="filter-column">
        <StyledCardHeader
          avatar={<FilterListIcon />}
          title="Filters"
          action={
            <Box displayPrint="none">
              <Button
                onClick={onReset}
                variant="text"
                size="small"
                color="primary"
              >
                <ReplayIcon />
                Reset
              </Button>
            </Box>
          }
        />
        <CardContent className={classes.columnContent}>
          <>
            <DateFilterList
              setDates={onDateChange}
              dateFilters={dateFilters}
            />
            {children}
            {filters.eventTypes && (
              <EventsFilterList
                filterID="eventTypes"
                filterOptions={filters.eventTypes}
                handleToggle={onToggle}
                selection="multiple"
                loading={loadingEvents}
              />
            )}
          </>
        </CardContent>
      </Card>
      <Box displayPrint="none" display={{ xs: 'none', sm: 'block' }}>
        <EventsListSettings />
      </Box>
    </>
  );
};
