import { FC } from 'react';
import { Typography } from '@mui/material';
import { LoadingContainer } from 'components/Loaders';
import classes from './ReportingRemark.module.css';

export const ReportingRemark: FC<{ comment: string, isLoading: boolean }> = ({ comment, isLoading }) => {
  return (
    <div className={classes.container}>
      <LoadingContainer loading={isLoading} type="singleList" rows={1} cols={2}>
        <div className={classes.row}>
          <Typography variant="body1" color="textSecondary" pb={1}>Comment To Nordic Trustee</Typography>
          <Typography pb={1}>{comment}</Typography>
        </div>
      </LoadingContainer>
    </div>
  );
};