import React, { useMemo, useState } from 'react';
import { MainMenuItems, MenuItemsProps } from './MainMenuItems';
import { ButtonPopper } from './ButtonPopper';

type ComponentProps = Omit<MenuItemsProps, 'onItemSelect'>;

export const ExtendedMainMenu = ({
  logOut,
  openModal,
}: ComponentProps) => {
  const [open, setOpen] = useState(false);
  const menuItems = useMemo(() => {
    return (
      <MainMenuItems
        onItemSelect={() => setOpen(false)}
        logOut={logOut}
        openModal={openModal}
      />
    );
  }, []);
  const buttonContent = useMemo(() => <>Menu</>, []);
  return (
    <ButtonPopper open={open} setOpen={setOpen} buttonContent={buttonContent}>
      {menuItems}
    </ButtonPopper>
  );
};
