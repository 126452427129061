import React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import { EventDTO } from '../types';
import { getRedirectPathForEventAction } from './getRedirectPathForEventAction';

interface EventObject {
  event: EventDTO;
}

// TODO: get event from path and fetch event data from backend (currently relying on history state)
const getEvent = (value: unknown): EventDTO => (value as EventObject).event;

export const EventRedirect = () => {
  const history = useHistory();
  const event = getEvent(history.location.state);
  const redirectPath = getRedirectPathForEventAction(event);
  return (
    <Redirect to={redirectPath} />
  );
};

