import { Dispatch, MouseEvent, ReactElement, ReactNode, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

interface MenuLinkProps {
  to: string;
  label: string;
}
interface MenuButtonProps {
  icon: ReactNode;
  children: ReactElement<MenuLinkProps>[] | ReactElement<MenuLinkProps>;
}

function handleMenuClick(event: MouseEvent<HTMLElement>, setAnchorEl: Dispatch<any>): void {
  setAnchorEl(event.currentTarget);
}

const MenuButton = ({ icon, children }: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  return (
    <>
      <IconButton aria-haspopup="true" onClick={(event) => handleMenuClick(event, setAnchorEl)}>
        <>{icon}</>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
};

export default MenuButton;
