import React from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import classes from './CompanyLogo.module.css';

interface ComponentProps {
  url: string;
}

export const CompanyLogo = ({ url }: ComponentProps) => {
  if (url === '') {
    const Fallback = <DomainIcon className={classes.logoFallback} />;
    return Fallback;
  }
  return <img className={classes.logo} src={url} alt="Logo" />;
};

