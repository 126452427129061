import { Box } from '@mui/material';
import { LoansWithinExpandablePanels } from './LoansWithinExpandablePanels';
import { LoansWithinTable } from './LoansWithinTable';
import { LoansTableProps } from './props';

export const LoansTable = ({ loans, loanType  }: LoansTableProps) => (
  <>
    <Box display={{ xs: 'none', sm: 'block' }}>
      <LoansWithinTable loans={loans} loanType={loanType}  />
    </Box>
    <Box display={{ xs: 'block', sm: 'none' }}>
      <LoansWithinExpandablePanels loans={loans} loanType={loanType}  />
    </Box>
  </>
);
