import { Dispatch, SetStateAction, useEffect,useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CaseLogDTO } from 'api/api.types';
import { getLoanCaseLogs, getLoanRemarks } from 'modules/Loans/index';
import MessageFromNTList from './message_from_nt_list/MessageFromNTList';
import MessageInternalList from './messages_internal_list/MessageInternalList';

interface ComponentProps {
  issueID: number;
}

async function reloadMessages(issueID: number,
  setMessages: Dispatch<SetStateAction<CaseLogDTO[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>) {
  setLoading(true);
  getLoanRemarks(issueID).then((fetchedItems: any) => {
    setMessages(fetchedItems);
    setLoading(false);
  });
}

export const MessageBoard = ({ issueID }: ComponentProps) => {
  const [messagesInternal, setMessagesInternal] = useState<CaseLogDTO[]>([]);
  const [messagesFromNT, setMessagesFromNT] = useState<CaseLogDTO[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getLoanCaseLogs(issueID).then((fetchedItems: any) => {
      setMessagesFromNT(fetchedItems);
      setLoading(false);
    });
    getLoanRemarks(issueID).then((fetchedItems: any) => {
      setMessagesInternal(fetchedItems);
      setLoading(false);
    });
  }, [issueID]);

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <MessageInternalList
            messages={messagesInternal}
            loading={loading}
            loanID={issueID}
            onUploadSuccess={async () => { await reloadMessages(issueID, setMessagesInternal, setLoading); }}
          />
        </Grid>
        <Grid item xs={12}>
          <MessageFromNTList
            messagesNT={messagesFromNT}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

