import { ComponentType, ReactNode } from 'react';
import { TableCell, TableRow, Tooltip, TooltipProps, Typography } from '@mui/material';
import classes from './LabelContentRow.module.css';

interface ComponentProps {
  label: string;
  children: ReactNode;
  alignRight?: boolean;
  secondaryRow?: any | ComponentType<TooltipProps>;
}

export const LabelContentRow = ({
  label, children, alignRight, secondaryRow,
}: ComponentProps) => {
  // TODO: Can we not get the text value of children in a better way?
  const title = typeof children === 'string' ? children?.toString() : '';
  return (
    <TableRow className={classes.row}>
      <TableCell component="td" scope="row" className={classes.keyCell}>
        <Typography variant="body1" color="textSecondary">{label}</Typography>
      </TableCell>
      <TableCell component="td" scope="row" className={classes.valueCell} style={alignRight ? { textAlign: 'right' } : {}}>
        <Tooltip title={title} placement="bottom-end">
          <Typography variant="body1" className={classes.tableText}>
            {children}
          </Typography>
        </Tooltip>
        {secondaryRow}
      </TableCell>
    </TableRow>
  );
};

