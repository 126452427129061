import { Grid, Paper, Typography } from '@mui/material';
import classes from './PaperPage.module.css';


interface ComponentProps {
  showWelcomeText?: boolean;
  children: any;
}

export const PaperPage = (
  {
    children, showWelcomeText,
  }: ComponentProps,
) => (
  <Grid container className={classes.grid}>
    <Grid container direction="column" justifyContent="center" alignItems="center">
      {showWelcomeText && (
        <Grid>
          <Typography variant="h1" style={{ width: 'fit-content', margin: 'auto', textAlign: 'center' }}>
            Welcome to Nordic Trustee&apos;s Issuer Portal
          </Typography>
        </Grid>
      )}
    </Grid>
    <Grid item xs={12}>
      <Grid item xs={12} sm={10} md={7} lg={4} style={{ margin: 'auto' }}>
        <Paper className={classes.paper}>
          {children}
        </Paper>
      </Grid>
    </Grid>
  </Grid>
);

