import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { StyledCardHeader, SubCard } from 'components/Card';
import classes from './Faq.module.css';

interface FaqItemProps {
  question: string;
  answer: string | React.ReactElement;
}

const FaqItem = (({ question, answer }: FaqItemProps) => (
  <div>
    <Typography className={classes.question} variant="h4">{question}</Typography>
    <Typography className={classes.answer}>
      {answer}
    </Typography>
  </div>
));

export const Faq = () => (
  <Container maxWidth="xl">
    <Box mt={2} mb={2}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item lg={3} />
            <Grid item lg={6}>
              <SubCard>
                <StyledCardHeader title="Frequently Asked Questions" />
                <CardContent>
                  <Typography>
                    The most frequently asked questions about the Issuer Portal can be found on this page. You are also welcome to
                    <Link target="_blank" href="https://nordictrustee.com/contact"> Contact </Link>
                    us directly with any questions.
                  </Typography>
                  <br />
                  <FaqItem
                    question="What is the Issuer Portal?"
                    answer={(
                      <>
                        Issuer Portal is a digital, web-based service platform, specifically designed and developed by Nordic
                        Trustee for issuers of bonds on the Nordic market. The purpose and aim of the Issuer Portal is to reduce
                        overall costs for bond issuers through automatization as well as providing bond issuers with a convenient
                        tool for overall process control.
                        <Link target="_blank" href="https://youtu.be/wPrhBZGOQYg"> Click here </Link>
                        to view our product presentation
                      </>
                    )}
                  />

                  <FaqItem
                    question="What type of bonds are supported in the Issuer Portal?"
                    answer={(
                      <>
                        Currently, the Issuer Portal supports corporate bonds registered the Central Securities Depositories in all Nordic
                        countries (VPS Norway, Euroclear Sweden, Euroclear Finland and VP Denmark). Nordic Trustee is continuously
                        considering adding new content and features to the Issuer Portal based on feedback and requests from users. If you
                        have any requests or ideas, please send us an email at
                        <Link target="_blank" href="mailto:portals@nordictrustee.com"> portals@nordictrustee.com </Link>
                      </>
                    )}
                  />

                  <FaqItem
                    question="When will I receive access to the Issuer Portal?"
                    answer="Users designated by the issuer of the bond(s) will normally be invited by email by Nordic Trustee in
                     connection with the issue date of the bond(s)."
                  />

                  <FaqItem
                    question="Will my bond(s) remain on the Issuer Portal after it has been repaid?"
                    answer="Yes, the bond(s) will remain available and you will continue to have access to the Issuer Portal until we are
                     instructed by the issuer of the bond(s) to remove the bond(s) from the Issuer Portal and terminate access for all
                     users."
                  />

                  <FaqItem
                    question="How is the information on the Issuer Portal generated?"
                    answer="The information visible on the Issuer Portal is generated based on the information registered in
                     Nordic Trustee’s internal databases."
                  />

                  <FaqItem
                    question="What events are listed in the Event Calendar?"
                    answer="The following events will normally be visible in your Event Calendar: scheduled interest payment dates,
                     scheduled due dates compliance reporting, listing deadline for the bond(s), quotation dates, new call periods,
                      scheduled installments. In addition, Nordic Trustee may create other manual events and additional events may
                       also be created by the user. Deadlines for publication of financial reports (such as annual, quarterly or
                         semi-annual financial statements) are currently not registered and visible in the Event Calendar. "
                  />

                  <FaqItem
                    question="When will I receive email notifications, and can I turn them on or off?"
                    answer="Scheduled notifications are generally sent via email on two occasions: (i) 14 calendar days (might be
                       less or more days depending on the type of the event) before an event and (ii) on the date of the event.
                        The notifications are emailed at 10:00 a.m. CET. All or some of the notifications can be disabled on
                         your profile page."
                  />

                  <FaqItem
                    question="Will the information in the Issuer Portal be updated when changes are made to the terms of a bond?"
                    answer="Nordic Trustee's intention is to provide relevant information which will assist issuers in their
                     day-to-day operations. Nordic Trustee will therefore strive to keep the Issuer Portal updated in case
                      of any changes made relating to the bond. However, it is ultimately the user’s responsibility to ensure
                       that the information displayed is accurate and the user is encouraged to inform Nordic Trustee in case
                        of any inconsistencies or need for changes."
                  />

                  <FaqItem
                    question="How do I carry out payments in relation to my bond?"
                    answer="Payments of interest and principal are generally made through a Central Securities Depository, such as
                     Euroclear or VPS. More information is found under “Payments” on the “Financials-page” in the Issuer Portal. "
                  />

                  <FaqItem
                    question="How are the calculations for principal and interest made?"
                    answer="Nordic Trustee registers the relevant benchmark rate, margin, business day and interest rate conventions
                     and interest periods in Nordic Trustee’s internal databases. This information will then be used to generate all
                      amounts and calculations visible in the Issuer Portal."
                  />

                  <FaqItem
                    question="What shall I do if I notice incorrect information on the Issuer Portal?"
                    answer={(
                      <>
                        Please contact us as soon as possible at
                        <Link target="_blank" href="mailto:portals@nordictrustee.com"> portals@nordictrustee.com </Link>
                        with the details.
                      </>
                    )}
                  />

                  <FaqItem
                    question="How secure is the Issuer Portal?"
                    answer="The application is hosted in Microsoft Azure’s platform behind an application gateway that acts as a
                     reverse proxy and firewall to prevent common attacks. The communication between client and server is
                      encrypted with an RSA 2048 bits certificate. The portal client also contains policies to prevent loading
                       code from non-trusted locations (Cross Site Scripting, XSS). As for Authentication, user session requires
                        a username and password to gain access, passwords are stored securely as a SHA-hashed version along with
                         a cryptographic random salt."
                  />

                  <FaqItem
                    question="How are the documents on the Issuer Portal stored?"
                    answer="Documents are stored on Microsoft Azure Blob Storage, only accessible through the portal application."
                  />

                  <FaqItem
                    question="How can I add or remove users?"
                    answer={(
                      <>
                        Only Nordic Trustee can add and remove users, please contact us at
                        <Link target="_blank" href="mailto:portals@nordictrustee.com"> portals@nordictrustee.com </Link>
                        if you wish to add or remove users.
                      </>
                    )}
                  />

                  <FaqItem
                    question="Can I share my account with other persons?"
                    answer={(
                      <>
                        No, your account is personal to you only and sharing the account with other persons is
                        prohibited according to our general
                        <Link target="_blank" href="https://issuerportal.nordictrustee.com/terms-of-use"> Terms of Use </Link>
                      </>
                    )}
                  />

                  <FaqItem
                    question="Can people outside of my organization access my bond(s) on the Issuer Portal?"
                    answer={(
                      <>
                        Yes, please contact Nordic Trustee at
                        <Link target="_blank" href="mailto:portals@nordictrustee.com"> portals@nordictrustee.com </Link>
                        to discuss setting up an account for external users.
                      </>
                    )}
                  />

                  <FaqItem
                    question="Can Bondholders access our bond(s) on the Issuer Portal?"
                    answer="No, the Issuer Portal is accessible only to such users designated by the issuer of the bond(s)."
                  />

                  <FaqItem
                    question="Is it possible to obtain a list of Bondholders through the Issuer Portal?"
                    answer="No, Nordic Trustee does not provide access to bondholder information through the portal."
                  />

                  <FaqItem
                    question="Can I distribute information to Bondholders through the Issuer Portal?"
                    answer={(
                      <>
                        Issuers have the option to publish certain bond related information on
                        <Link target="_blank" href="https://www.stamdata.com"> Stamdata.com </Link>
                        - a Nordic
                        online hub for distribution of bond information and financial data to bondholders and other financial market participants.
                        Send an email to
                        <Link target="_blank" href="mailto:portals@nordictrustee.com"> portals@nordictrustee.com</Link>
                        , or get in touch with your Nordic Trustee contact person, to discuss the options
                        available to you.
                      </>
                    )}
                  />

                  <FaqItem
                    question="How do I perform my compliance reporting through the Issuer Portal?"
                    answer="Compliance reporting is done by the user by uploading the required compliance certificate and other related documents using the “Issuer Reporting” feature under the “Documents” tab or by clicking “Upload Document” in the “Event Calendar”."
                  />

                  <FaqItem
                    question="How does the Message Board work?"
                    answer={(
                      <>
                        “Internal Messages” contain information users choose to upload to the Issuer Portal and is solely visible for the users with the issuer.
                        <br />
                        <br />
                        “Messages from Nordic Trustee” are messages registered by Nordic Trustee and will be visible to all users. These messages can include any information Nordic Trustee thinks is of importance in relation to an issuer’s outstanding or matured bond(s), or more general messages about updates and information on security level in the portal (e.g. updates to security package, bond type is not supported in the portal until further notice, etc).
                        <br />
                        <br />
                        If you have questions relating to your bond loan or wish to speak to your main contact person about a general inquery, please use the Contact details on the Dashboard or the Message Board pages.
                      </>
                    )}
                  />

                  <FaqItem
                    question="My external auditor has asked me for a balance sheet confirmation, how can I generate this?"
                    answer="A balance sheet confirmation, or auditor confirmation, can be requested via the MainMenu drop-down."
                  />

                  <FaqItem
                    question="Is it possible to access bonds issued by different issuers on the Issuer Portal?"
                    answer={(
                      <>
                        Yes, send an email to
                        {' '}
                        <Link target="_blank" href="mailto:portals@nordictrustee.com"> portals@nordictrustee.com </Link>
                        {' '}
                        for assistance.
                      </>
                    )}
                  />
                  <FaqItem
                    question="What web browsers are reccomended to use with this site?"
                    answer="We reccomend the most common desktop web browsers: Google Chrome, Microsoft Edge, Mozilla Firefox and Apple Safari. Currently, this site is not optimized for mobile browsers."
                  />
                </CardContent>
              </SubCard>
            </Grid>
            <Grid item lg={3} />
          </Grid>
        </CardContent>
      </Card>

    </Box>
  </Container>
);
