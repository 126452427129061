import jwtDecode from 'jwt-decode';

export const extractUserIDFromAccessToken = (
  accessToken: string,
): string | undefined => {
  try {
    const authenticateResponseData: { sub: string } = jwtDecode(accessToken);
    return authenticateResponseData.sub;
  } catch (_e) {
    return undefined;
  }
};
