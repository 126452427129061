import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DocumentDTO } from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { DocumentsTable } from './DocumentsTable';

interface ComponentProps {
  documents: DocumentDTO[];
  action?: React.ReactNode;
  cardTitle: string;
  showReviewed: boolean;
  showUploadedBy: boolean;
  showReferenceDate: boolean;
  showUploadedDate: boolean;
  showIdentifier: boolean;
  additionalInfo?: string;
  loading: boolean;
}

export const DocumentCard = ({
  loading, documents, cardTitle, action, showReviewed, showUploadedBy,
  showReferenceDate, showUploadedDate, showIdentifier, additionalInfo,
}: ComponentProps) => (
  <Card>
    <StyledCardHeader
      title={cardTitle}
      subheader={additionalInfo && (
        additionalInfo
      )}
      action={action}
    />
    <CardContent>
      <LoadingContainer loading={loading} type="listWithIcon" rows={3} cols={0}>
        <DocumentsTable
          documents={documents}
          showReviewed={showReviewed}
          showUploadedBy={showUploadedBy}
          showReferenceDate={showReferenceDate}
          showUploadedDate={showUploadedDate}
          showIdentifier={showIdentifier}
        />
        { documents.length === 0 && (
          <Typography variant="body1">{`No ${cardTitle.toLowerCase()}`}</Typography>
        )}
      </LoadingContainer>
    </CardContent>
  </Card>
);

