import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import { Environment } from 'modules/Global/types';
import classes from './EnvironmentChip.module.css';

interface Props {
  environment: Environment;
}

export const EnvironmentChip = ({ environment }: Props) => {
  const chipColorClass = useMemo(() => {
    switch (environment) {
      case Environment.test: {
        return classes.test;
      }
      case Environment.uat: {
        return classes.uat;
      }
      default:
        return classes.hotfix;
    }
  }, [environment]);
  const classNames = `${classes.chip} ${chipColorClass}`
  return (
    <Chip className={classNames} label={environment} />
  );
};

