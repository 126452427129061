import { ReactElement } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {
  Card,
  CardContent,
  Grid,
  List,
  Typography,
} from '@mui/material';
import { ColoredText } from '../atoms';
import { HighlightHeader } from './HighlightHeader';
import { HighlightListItem, HighlightListItemProps } from './HighlightListItem';
import classes from './HighlightsSection.module.css';

interface HighlightListProps{
  children?: ReactElement<HighlightListItemProps> | ReactElement<HighlightListItemProps>[];
}

const HighlightList = ({ children }: HighlightListProps) => (
  <List aria-label="Risk Mitigation">
    {children}
  </List>
);

export const HighlightsSection = () => (
  <section className={classes.root}>
    <Typography variant="h1" className={classes.header}>
      <ColoredText text="Issuer Portal " color="primary" />
      at a glance
    </Typography>

    <Grid container spacing={3} justifyContent="center">
      <Grid item lg={4}>
        <Card className={classes.card}>
          <HighlightHeader
            color="primary"
            title="Risk Mitigation"
            icon={<NewReleasesIcon color="primary" className={classes.icon} />}
          />
          <CardContent>
            <HighlightList>
              <HighlightListItem
                color="primary"
                primary={(
                  <Typography variant="body1">
                    <ColoredText text="Mitigate " />
                    manual
                    <ColoredText text=" errors " />
                    through automation
                  </Typography>
                )}
              />
              <HighlightListItem
                color="primary"
                primary={(
                  <Typography variant="body1">
                    Ensure
                    <ColoredText text=" secure " />
                    administration of
                    <ColoredText text=" your bonds " />
                  </Typography>
                )}
              />
              <HighlightListItem
                color="primary"
                primary={(
                  <Typography variant="body1">
                    Decrease
                    <ColoredText text=" dependency " />
                    on key
                    <ColoredText text=" employees" />
                  </Typography>
                )}
              />
            </HighlightList>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card className={classes.card}>
          <HighlightHeader
            color="secondary"
            title="Process Control"
            icon={<AssignmentIcon color="secondary" className={classes.icon} />}
          />
          <CardContent>
            <HighlightList>
              <HighlightListItem
                color="secondary"
                primary={(
                  <Typography variant="body1">
                    All relevant
                    <ColoredText text=" information " />
                    and
                    <ColoredText text=" documentation " />
                    in
                    <ColoredText text=" one " />
                    hub
                  </Typography>
                )}
              />
              <HighlightListItem
                color="secondary"
                primary={(
                  <Typography variant="body1">
                    Access information from
                    <ColoredText text=" anywhere " />
                    at
                    <ColoredText text=" any " />
                    time
                  </Typography>
                )}
              />
              <HighlightListItem
                color="secondary"
                primary={(
                  <Typography variant="body1">
                    Always the most
                    <ColoredText text=" up-to-date " />
                    information available
                  </Typography>
                )}
              />
            </HighlightList>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4}>
        <Card className={classes.card}>
          <HighlightHeader
            title="Automation & Efficiency"
            icon={<BuildIcon className={classes.icon} />}
          />
          <CardContent>
            <HighlightList>
              <HighlightListItem primary={(
                <Typography variant="body1">
                  Completely
                  <ColoredText text=" cloud based " />
                  solution
                </Typography>
              )}
              />
              <HighlightListItem primary={(
                <Typography variant="body1">
                  <ColoredText text="No manual " />
                  maintenance or actions required
                </Typography>
              )}
              />
              <HighlightListItem primary={(
                <Typography variant="body1">
                  <ColoredText text="Reduce time " />
                  spent on on bond administration
                </Typography>
              )}
              />
            </HighlightList>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </section>
);
