import globalAxios from 'axios';
import moment from 'moment/moment';
import {
  EVENT_FILTER_OPTION_ALL,
  EventsOptions,
  getEventsOverdue,
} from '../../EventsApi';
import { EventModel, EventSearchFilterOption } from '../../types';

// eslint-disable-next-line max-len
const getQueryStringFromList = (arr: string[] | number[] | undefined, name: string) => (arr ? `${name}=${arr.join(`&${name}=`)}` : `${name}=`);
const getQueryString = (val: string | undefined, name: string) => (val ? `${name}=${val}` : `${name}=`);

export function getQueryStringToBeDeleted(opts: EventsOptions = {}): string {
  const loanIDs = getQueryStringFromList(opts.loanIDs, 'loanIDs');
  const eventTypes = getQueryStringFromList(opts.eventTypes, 'eventTypes');
  const issuers = getQueryStringFromList(opts.issuers, 'issuers');
  const fromDate = getQueryString(opts.fromDate?.toDate().toUTCString(), 'fromDate');
  const toDate = getQueryString(opts.toDate?.toDate().toUTCString(), 'toDate');
  const page = getQueryString(opts.page?.toString(), 'page');
  const pageSize = getQueryString(opts.pageSize?.toString(), 'pageSize');
  const showCompleted = getQueryString(opts.showCompleted?.toString(), 'showCompleted');
  const issueTypes = getQueryStringFromList(opts.issueTypes, 'issueTypes');

  const qs = `?${loanIDs}&${eventTypes}&${issuers}&${fromDate}&${toDate}&${page}&${pageSize}&${showCompleted}&${issueTypes}`;

  return qs;
}

export async function getEventsToBeDeletedWhenApiGenWorking(
  opts: EventsOptions = {},
): Promise<EventModel> {
  const qs = getQueryStringToBeDeleted(opts);
  const res = await globalAxios.get<EventModel>(
    `/api/events/${qs}`,{
      baseURL: `${window._env_.REACT_APP_API_BASE}`,
    }
  );
  if ((res as any)?.data?.data)
    (res as any).data.data = (res as any)?.data?.data.sort((a: any, b: any) =>
      moment(a.date).diff(moment(b.date)),
    );
  return (res as any)?.data;
}

const getFilterOptionAll = (
  filterOptions: EventSearchFilterOption[],
  text?: string,
): EventSearchFilterOption => ({
  id: EVENT_FILTER_OPTION_ALL,
  text: text || 'All',
  selected: !filterOptions.some((opt) => opt.selected === true),
});

export const getFormattedEvents = async (
  opts: EventsOptions,
): Promise<EventModel> => {
  const eventsSearch = await getEventsToBeDeletedWhenApiGenWorking(opts);

  if (eventsSearch.filters.issuers) {
    eventsSearch.filters.issuers.unshift(
      getFilterOptionAll(eventsSearch.filters.issuers, 'All companies'),
    );
  }

  if (eventsSearch.filters.issueTypes) {
    eventsSearch.filters.issueTypes.unshift(
      getFilterOptionAll(eventsSearch.filters.issueTypes, 'All issue types'),
    );
  }

  if (eventsSearch.filters.loanIDs) {
    eventsSearch.filters.loanIDs.unshift(
      getFilterOptionAll(eventsSearch.filters.loanIDs, 'All loans'),
    );
  }

  if (eventsSearch.filters.eventTypes) {
    eventsSearch.filters.eventTypes.unshift(
      getFilterOptionAll(
        eventsSearch.filters.eventTypes,
        'All types of events',
      ),
    );
  }

  return eventsSearch;
};

export const getFormattedOverdueEvents = async (
  opts: EventsOptions,
): Promise<EventModel> => {
  return await getEventsOverdue(opts);
};
