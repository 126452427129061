import {
  DocumentsApi, DocumentsApiAxiosParamCreator
} from 'api';
import {
  DocumentDTO,
  DocumentModel,
} from 'api/api.types';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import { getCancelToken } from 'utils/init/axiosConfig';

const DocumentsApiInstance = new DocumentsApi();

export interface DocumentsOptions{
  companyIDs?: number[];
  issueIDs?: number[];
}

export async function getDocuments(issuerID: number, loanID: number) {
  const res = await DocumentsApiInstance.getLoanDocuments(
    issuerID,
    loanID,
    { cancelToken: getCancelToken() },
  );
  if ((res as any)?.data) return (res as any).data;
  return [];
}

function getStamdataDocumentUrl(downloadUrl: string) {
  const environment = (/localhost|test/.test(window.location.host) ? 'test.' : 'www.');
  const url = `https://${environment}stamdata.com/documents/${downloadUrl}`;
  return url;
}

export async function getLatestDocumentsForCompany(companyID: number) {
  const res = await DocumentsApiInstance.getLatestDocumentsForCompany(companyID);
  return (res as any)?.data;
}

export async function downloadDocument(documentDTO: DocumentDTO): Promise<void> {
  if (!documentDTO.documentID || !documentDTO.type) {
    return;
  }
  if (documentDTO.type === HubDocumentTypeUniverseEnum.Market && documentDTO.downloadUrl) {
    const url = getStamdataDocumentUrl(documentDTO.downloadUrl);
    window.open(url);
  } else {
    async function getDocumentToken(documentType: HubDocumentTypeUniverseEnum, documentID: number) {
      const res = await DocumentsApiInstance.generateDocumentToken(documentType, documentID);
      return (res as any)?.data;
    }
    const token = await getDocumentToken(documentDTO.type, documentDTO.documentID);
    const { url: userDocUrl } =
      await DocumentsApiAxiosParamCreator().downloadLoanDocument(token);
    const requestConfig: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      baseURL: `${window._env_.REACT_APP_API_BASE}`,
    };
    await axios.get(`${userDocUrl}`, requestConfig)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const { document } = globalThis;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', documentDTO.name || 'unknownFilename.pdf');
          document.body.appendChild(link);
          link.click();
        }
      });
  }
}


export async function getFilteredDocuments(opts: DocumentsOptions = {}): Promise<DocumentModel> {
  function getDocumentsQueryString(opts: DocumentsOptions = {}): string {
    const getQueryStringFromList = (arr: string[] | number[] | undefined, name: string) => (arr ? `${name}=${arr.join(`&${name}=`)}` : `${name}=`);
    const companyIDs = getQueryStringFromList(opts.companyIDs, 'companyIDs');
    const issueIDs = getQueryStringFromList(opts.issueIDs, 'issueIDs');

    return `?${companyIDs}&${issueIDs}`;
  }
  const qs = getDocumentsQueryString(opts);
  const res = await axios.get<DocumentModel>(`${window._env_.REACT_APP_API_BASE}/api/documents/${qs}`);
  if ((res as any)?.data?.data) (res as any).data.data = (res as any)?.data?.data.sort((a: any, b: any) => moment(a.date).diff(moment(b.date)));

  return (res as any)?.data;
}
