import { FC, useCallback, useEffect,useState } from 'react';
import {
  Grid, } from '@mui/material'
import { Card } from '@nordictrustee/nt-ui-library';
import { LoadingContainer } from 'components/Loaders';
import { getUserNotificationSettings } from '../../../AccountApi';
import { NotificationSettingDTO } from '../../../AccountApi/api.types';
import { EditNotificationSettingsAction } from './EditNotificationSettingsAction';
import { NotificationSettingsList } from './NotificationSettingsList';

interface OwnProps {
  username: string;
}

export const ViewNotificationSettings: FC<OwnProps> = ({ username }) => {
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettingDTO[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchNotificationSettings = useCallback(async () => {
    if (username) {
      setLoading(true);
      const ns = await getUserNotificationSettings(username);
      setNotificationSettings((ns as any).data);
      setLoading(false);
    }
  }, [username]);

  useEffect(() => {
    // TODO: React Query
    fetchNotificationSettings();
  }, [fetchNotificationSettings, username]);

  return (
    <Card title="Notification Settings" customAction={(
      <EditNotificationSettingsAction onUpdate={fetchNotificationSettings} username={username} notificationSettings={notificationSettings} />
    )}>
      <LoadingContainer loading={loading} cols={2} rows={12} type="list">
        <Grid container>
          <Grid item sm={12} md={6}>
            <NotificationSettingsList
              header="Active Notifications"
              notificationSettings={notificationSettings.filter((ans) => !ans.selected)}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <NotificationSettingsList
              header="Disabled Notifications"
              notificationSettings={notificationSettings.filter((ans) => ans.selected)}
            />
          </Grid>
        </Grid>
      </LoadingContainer>
    </Card>
  );
}
