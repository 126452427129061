import { useState } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box, Grid, Typography } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import { ColoredText, ImageModalTriggerButton } from '../atoms';
import loans from 'assets/images/loans.png';
import classes from './ProcessControl.module.css';

export const ProcessControlSection = () => {
  const [openModal, setOpenModal] = useState(false);
  return(
<section className={classes.root}>
  <Grid container spacing={10} alignItems="center" justifyContent="center">
    <Grid item lg={5}>
      <Box display="flex" flexDirection="column" justifyContent="center" className={classes.text}>
        <Box display="flex" alignItems="center" className={classes.header}>
          <AssignmentIcon color="secondary" className={classes.icon}/>
          <Typography variant="h1">
            Process
            <ColoredText color="secondary" text=" Control"/>
          </Typography>
        </Box>
        <Typography variant="body1">
          Simplify your bond administration process and get complete control of processes.
          Ready for use on day one, no integration or input required. Give all parties involved
          in the adminisation and maintenance of bonds (such as Finance, Accounting and Legal
          teams) access to the portal, and empower them with access to personal notifications,
          workflows for internal and external reporting, and much more.
        </Typography>
      </Box>
    </Grid>
    <Grid item lg={7}>
      <Box position="relative">
        <ImageModalTriggerButton onClick={()=> setOpenModal(true)} imageSrc={loans} width="650" alt="Issuer Portal bonds page" />
        { openModal &&
        <SimpleModal
          onClose={()=> setOpenModal(false)}
          title="Issuer Portal bonds page"
          xlSize={10}
          lgSize={10}
          mdSize={10}
          content={(
            <img src={loans} alt="Issuer Portal bonds page" className={classes.image} width="100%"/>
          )}
        />
      }
        <AssignmentIcon
          color="primary"
          className={classes.backgroundIcon}
        />
      </Box>
    </Grid>
  </Grid>
</section>
)
};
