
const centerText: React.CSSProperties = {
    textAlign: 'center'
  };
  
export const centerColumnText = {
    cellStyle: centerText,
    headerStyle: centerText
  };

export const firstColPadding = {
  cellStyle: {
    paddingLeft: '1rem'
  },
  headerStyle: {
    paddingLeft: '1rem'
  }
}

export const lastColumnPadding = {
  cellStyle: {
    paddingRight: '1rem'
  }
}