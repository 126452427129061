import { Box } from '@mui/material';
import { Chart } from '@nordictrustee/nt-ui-library';
import {
  ManagerDistributionDTO,
  ManagerDistributionRowDTO,
} from 'api/api.types';
import { getChartColor } from 'modules/Global/theme';
import { formatMoney } from 'utils/money';

interface ManagerDistributionProps {
  managerDistribution: ManagerDistributionDTO | undefined;
  currency: string | undefined;
}

const { Doughnut } = Chart.RC2;
type ChartTypeRegistry = Chart.Chartjs.ChartTypeRegistry;
type TooltipItem = Chart.Chartjs.TooltipItem<keyof ChartTypeRegistry>;
type ChartOptions = Chart.Chartjs.ChartOptions<'doughnut'>;
type ChartData = Chart.Chartjs.ChartData<'doughnut'>;

const getChartData = (
  managerDistribution: ManagerDistributionDTO | undefined,
): ChartData => {
  if (!managerDistribution?.managerDistribution) {
    return { datasets: [] };
  }
  const ms: ManagerDistributionRowDTO[] =
    managerDistribution.managerDistribution;
  return {
    datasets: [
      {
        data: ms.map((m) => m.amount || 0),
        backgroundColor: ms.map((m, i) => getChartColor(i)),
      },
    ],
    labels: ms.map((m) => `${m.manager} (${m.percentage}%)` || ''),
  };
};

const getOptions = (currency: string | undefined): ChartOptions => ({
  plugins: {
    tooltip: {
      callbacks: {
        label(tooltipItem: TooltipItem) {
          const label: string = tooltipItem.label || 'N/A';
          const val: number = tooltipItem.raw as number;
          return `${label}: ${formatMoney(val, currency)}`;
        },
      },
    },
    legend: {
      position: 'top',
    },
  },
  responsive: true,
  maintainAspectRatio: false,
});

export const ManagerDistributionChart = ({
  managerDistribution,
  currency,
}: ManagerDistributionProps) => {
  const data = getChartData(managerDistribution);
  return (
    <Box style={{ height: '237px' }} mt={1}>
      <Doughnut data={data} options={getOptions(currency)} />
    </Box>
  );
};
