import { SecuritiesApi } from 'api';
import { getCancelToken } from 'utils/init/axiosConfig';

const SecurityApiInstance = new SecuritiesApi();
export async function loadSecurities(loanID: number) {
  async function getSecurities(loanID: number) {
    const res = await SecurityApiInstance.getSecurities(loanID, {
      cancelToken: getCancelToken(),
    });
    return (res as any)?.data;
  }
  return await getSecurities(loanID);
}
