import React from 'react';
import { Box, Grid, Hidden, Link, Typography } from '@mui/material';
import { ColoredText } from '../atoms';
import nt from 'assets/images/nt.jpg';
import classes from './OurVisionSection.module.css';

export const OurVisionSection = () => (
  <section className={classes.root}>
    <Grid container spacing={10} alignItems="center" justifyContent="center">
      <Grid item lg={6}>
        <Box display="flex" flexDirection="column" justifyContent="center" className={classes.text}>
          <Box className={classes.header}>
            <Typography variant="h1">
              Our
              <ColoredText color="primary" text=" Vision" />
            </Typography>
          </Box>
          <Typography variant="body1">
            Nordic Trustee is the leading provider of trustee and agency services for bonds in
            the Nordic region. Established in 1993 we are today an integral part of the infrastructrue
            creating a successful Nordic debt market.
          </Typography>

          <br />

          <Typography variant="body1">
            Our vision is to help stakeholders in the Nordic debt market transform complex to easy
            through innovation and technology. Our unique position as an independent partner with
            extensive experience from bond bonds and other debt instruments puts us in a strong
            position to develop digital and mobile solutions for issuers. Issuer portal is one
            of our many digital solutions. For more information about our broader service offering,
            please visit the
            <Link href="https://nordictrustee.com/services/" target="_blank"> services section </Link>
            on our website.
          </Typography>
        </Box>
      </Grid>
      <Hidden mdDown>
        <Grid item lg={6}>
          <Box display="flex" justifyContent="center">
            <img width="500" src={nt} alt="Vision" />
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </section>
);