import { Tab } from '@mui/material';
import { SubTabs } from 'components/SubTabs';
import { SubTabParam, useFinancialParams } from './useFinancialParams';

const tabs: {value: SubTabParam, label: string}[] = [
  {value: 'presentfuture', label: 'Future'},
  {value: 'past', label: 'Past'},
];

export const FinancialSubTabs = () => {
  const { subTab, setSubTab } = useFinancialParams();
  return (
    <SubTabs
      selectedTab={subTab}
      onChange={(_e: unknown, value: SubTabParam) => setSubTab(value)}
    >
      {tabs.map(({ value, label }) => (
        <Tab key={value} value={value} label={label} />
      ))}
    </SubTabs>
  );
}