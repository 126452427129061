import { PageFooter, PageHeader } from 'modules/Global';

export const UndergoingMaintenancePage: React.FC<{
  maintenanceText: string;
}> = ({ maintenanceText }) => {
  return (
    <>
      <PageHeader logOut={() => {}} isLoadingUser={true} />
      <section style={{ maxWidth: '1200px', alignSelf: 'center' }}>
        <h1>Portal Undergoing Maintenance</h1>
        <p>{maintenanceText}</p>
      </section>
      <PageFooter isLoggedIn={false} />
    </>
  );
};
