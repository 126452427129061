import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from 'modules/Global';
import { ConfigurePassword } from '../atoms';
import { changePasswordFromToken } from '../AccountApi';

interface SearchParams {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  token: string | null;
}

const useQuery = ()=>{
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search) ;
  const params: SearchParams = {
    firstName: searchParams.get('firstName'),
    lastName: searchParams.get('lastName'),
    email: searchParams.get('email'),
    token: searchParams.get('token'),
  }
  return params;
}

export const ResetAccount: FC = () => {
  const { showWarning, showSuccess } = useToast();
  const { token, email, firstName, lastName } = useQuery();

  const history = useHistory();
  useEffect(() => {
    //localStorage.clear();
  }, []);

  if(!(email && token)){
    history.push('/')
  }
  const handleSubmit = async (password: string) => {
    try {
      await changePasswordFromToken(token || '', password);
      history.replace('/login');
      showSuccess('Password has been reset.');
    } catch (e) {
      showWarning('There was a problem resetting your account');
    }
  };
  return (
      <ConfigurePassword
          title="Reset Password"
          text="You requested to reset the password for your account. Please enter your new credentials below."
          firstName={firstName || ''}
          lastName={lastName || ''}
          email={email!}
          submitTitle="Reset password"
          onSubmit={handleSubmit}
      />
  );
};

