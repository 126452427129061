import { FC } from 'react';
import { CardHeader } from '@mui/material';
import classes from './StyledCardHeader.module.css';

interface StyledHeaderProps {
  title: any;
  avatar?: any;
  subheader?: string;
  action?: any;
}

export const StyledCardHeader: FC<StyledHeaderProps> = ({
  avatar,
  title,
  subheader,
  action,
}) => (
  <CardHeader
    component="hgroup"
    avatar={avatar}
    titleTypographyProps={{ component: 'h3', variant: 'h3' }}
    title={title}
    subheaderTypographyProps={{ variant: 'body1', color: 'textPrimary' }}
    subheader={subheader}
    action={action}
    className={classes.cardHeader}
  />
);
