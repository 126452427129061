import {AddressDTO} from "api/api.types";

export enum Environment {
    test = 'test',
    uat = 'uat',
    prod = 'prod',
    hotfix = 'hotfix',
}

export interface CompanyDTO {
  name: string;
  companyID: number;
  issuerID: number;
  organizationNo: string;
}

interface CompanyContactPersonDTO {
  name?: string;
  title?: string;
  email?: string;
  phoneNumber?: string;
}

interface CompanyDetailsDTO {
  companyName?: string;
  companyRegistrationNumber?: string;
  vatNumber?: string;
  invoiceEmail?: string;
  invoiceContactPerson?: string;
}

export interface CompanyContactDetailsDTO {
  companyDetails?: CompanyDetailsDTO;
  companyContactPerson?: CompanyContactPersonDTO;
  postalAddress?: AddressDTO;
  invoiceAddress?: AddressDTO;
}

