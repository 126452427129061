type AccessTokenString = string;
const accessTokenKey = 'accessTokenIP';
const unauthorizedStateKey = 'unauthorizedIP';
const loadAccessToken = (): AccessTokenString => {
  try {
    const accessTokenInState = localStorage.getItem('accessTokenIP');
    if (!accessTokenInState) { return '' }
    return accessTokenInState;
  } catch (err) {
    console.warn('could not find any active session');
    return '';
  }
};
export const saveAccessToken = (token: AccessTokenString) => {
  try {
    localStorage.setItem(accessTokenKey, token);
  } catch (_e) {
    console.error('failed to save access token');
  }
};

export const setUnAuthorized = (state = 'true') => { // used by axios interceptor
  try {
    localStorage.setItem(unauthorizedStateKey, state);
  } catch (_e) {
    console.error('failed to save access token');
  }
};
export const getUnauthorized = ()=> !!localStorage.getItem(unauthorizedStateKey);
export const clearAccessToken = () => {
  localStorage.removeItem(accessTokenKey);
};
export const clearUnauthorizedState = () => {
  localStorage.removeItem(unauthorizedStateKey);
};

export const getAccessToken = (): string => loadAccessToken();
export const isUserAuthenticated = (): boolean => getAccessToken() !== '';
