import { FC, useEffect, useRef,useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Box from '@mui/material/Box';
import { CreateMaterialUiChangeHandler } from 'legacy/inputHandlers';
import { SubmitButton } from 'components/form';
import { useToast } from 'modules/Global';
import { cancelRequests } from 'utils/init/axiosConfig';
import createMessage from '../message/message';
import { MessageTextField } from './MessageTextField';

interface Message {
  loanID: number;
  message: string;
}

interface OwnProps {
  loanID: number;
  onUploadSuccess: () => Promise<void>;
}

export const AddMessage: FC<OwnProps> = ({
  loanID,
  onUploadSuccess,
}) => {
  const {showSuccess, showWarning} = useToast();
  const [message, setMessage] = useState<Message>({ loanID, message: '' });
  const [addingMessages, setAddingMessages] = useState<boolean>(false);
  const isMounted = useRef<boolean>();
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      cancelRequests();
    };
  }, []);

  const messageChangeHandler = CreateMaterialUiChangeHandler<{}>(
    (_key, value) => {
      setMessage({ message: value, loanID });
    },
  );

  const handleUploads = async () => {
    const submitMessage = async () => {
      setAddingMessages(true);
      let msg = '';
      try {
        const response = await createMessage(message);
        if ((response as any)?.status === 200) {
          showSuccess('The message has been created')
          await onUploadSuccess();
        } else {
          showWarning(JSON.stringify(response));
        }
      } catch (err) {
        const { response } = err as any;
        if (response?.data) {
          msg = response.data.message ?? JSON.stringify(response.data);
        } else if (response?.message) {
          msg = response.message;
        } else if ((err as any).message) {
          msg = (err as any).message;
        }
        showWarning(msg);
      }
      if(!isMounted.current) return;
      setAddingMessages(false);
    };
    await submitMessage();
  };

  return (
    <ValidatorForm
      onSubmit={handleUploads}
      onError={(errors) => console.log(errors)}
      instantValidate
      data-id="Add message form"
    >
      <Box mt={2}>
        <MessageTextField
          autoFocus
          value={message.message}
          changeHandler={messageChangeHandler}
          aria-describedby="message"
        />
        <SubmitButton
          text="Add Message"
          loading={addingMessages}
        />
      </Box>
    </ValidatorForm>
  );
};
