import { ChangeEventHandler } from 'react';
import { SelectValidator } from 'react-material-ui-form-validator';
import { ListSubheader, MenuItem } from '@mui/material';
import classes from './ReportingSelectWithValidation.module.css';

export interface ReportingValueLabelOption {
  date: string;
  value: number;
  label: string;
  received: boolean;
}
interface ReportingComponentProps {
  label?: string;
  value?: number;
  items: ReportingValueLabelOption[];
  changeHandler?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  required?: boolean;
}

export const ReportingSelectWithValidation = ({
  label, field, value, changeHandler, required, items,
}: ReportingComponentProps & { label: string; field: string }) => (
  <SelectValidator
    variant="standard"
    required={required}
    fullWidth
    label={label}
    onChange={changeHandler}
    name={field}
    value={value || ''}
    type="select"
    validators={required === false ? [] : ['required']}
    errorMessages={[`${label} is required`]}
    margin="normal"
    className={classes.text}
  >
    <ListSubheader key="future">Available Reportings</ListSubheader>
    {
      items.filter(
        (item: any) => !item.received,
      ).map((item: any) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
    }
    ,
    <ListSubheader key="past">Previous Reporting (for archiving purposes)</ListSubheader>
    ,
    {
      items.filter((item: any) => item.received)
        .sort((a: ReportingValueLabelOption, b: ReportingValueLabelOption) => b.date.localeCompare(a.date))
        .map((item: any) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
    }

  </SelectValidator>
);
