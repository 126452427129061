import React, { ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AccountProvider, useAccount } from 'modules/Account';
import { Providers as GlobalProviders } from 'modules/Global';
import 'react-toastify/dist/ReactToastify.css';

type ProviderProps = {
  children: ReactNode;
};

export const Global = ({ children }: ProviderProps) => {
  const { user } = useAccount();
  return (
    <GlobalProviders user={user}>
      <Router>{children}</Router>
    </GlobalProviders>
  );
};
export const Account = ({ children }: ProviderProps) => (
  <AccountProvider>
    <Global>{children}</Global>
  </AccountProvider>
);

export const Providers = ({ children }: ProviderProps) => (
  <Account>{children}</Account>
);
