import React from 'react';
import { TermsAndConditionsSection } from './TermsAndConditionsSection';
import { useGlobal } from '../../modules/Global';

interface OwnProps {
  children: React.ReactNode;
}

type ComponentProps = OwnProps;

export const LoggedInWrapper: React.FC<ComponentProps> = ({
  children,
}: ComponentProps) => {
  const {user } = useGlobal();
  if(!!user && !user.termsAcceptedDate) {
    return <TermsAndConditionsSection userID={user.hubUserID} />
  }
  return (<>{children}</>);
};
