import accounting from 'accounting';

// TODO: can this be replaced with accounting.formatColumn? https://openexchangerates.github.io/accounting.js/
export function formatMoney(
  value: number,
  currency?: string,
  values?: number[],
) {
  // TODO: localize the parameters
  const formattedValue = accounting.formatMoney(value, currency || '');
  const matches = formattedValue.match(/[a-zA-Z]+/g);
  const currencyStr = currency && matches ? matches[0] : '';

  if (!values || values.length === 0) {
    return `${currencyStr} ${formattedValue.replace(' ', '').replace(currencyStr, '')}`;
  }
  const hasNegativeValue = values.some((v) => v < 0);
  const longestValue = values
    .map(Math.abs)
    .sort((a, b) => b.toFixed().length - a.toFixed().length)[0];
  const isNegativeValue = value < 0;
  const spaces =
    longestValue.toFixed().length > value.toFixed().length
      ? longestValue.toFixed().length - value.toFixed().length
      : 0;

  const repeatTimes =
    // Between currency and value there is a space
    1 +
    // Visually, a number corresponds to two spaces, so each number missing represents 2 spaces
    spaces * 2 +
    // make sure to add a space where the values has a negative value, but not when the value is negative
    (+hasNegativeValue - +isNegativeValue);

  return (
    currencyStr +
    ' '.repeat(repeatTimes) +
    formattedValue.replace(currencyStr, '')
  );
}
