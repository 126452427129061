import { FC } from 'react';
import {
  EventsSearchFilterIDs,
  EventsSearchFilterSelection,
} from '../../EventsApi';
import { EventSearchFilterOption, EventSearchFilters } from '../../types';
import { EventsFilterList } from './EventsFilterList';

interface AggregatedFiltersProps {
  onToggle: (
    filterID: EventsSearchFilterIDs,
    filterOption: EventSearchFilterOption,
    selection: EventsSearchFilterSelection,
  ) => void;
  filters: EventSearchFilters;
  loadingEvents: boolean;
}

export const AggregatedFilters: FC<AggregatedFiltersProps> = ({
  onToggle,
  filters,
  loadingEvents,
}) => {
  return (
    <>
      {filters.issuers && filters.issuers.length > 2 && (
        <EventsFilterList
          filterID="issuers"
          filterOptions={filters.issuers}
          handleToggle={onToggle}
          selection="multiple"
          loading={loadingEvents}
        />
      )}
      {filters.issueTypes &&
        filters.issueTypes.length > 2 && (
          <EventsFilterList
            filterID="issueTypes"
            filterOptions={filters.issueTypes}
            handleToggle={onToggle}
            selection="multiple"
            loading={loadingEvents}
          />
        )}
      {filters.loanIDs && (
        <EventsFilterList
          filterID="loanIDs"
          filterOptions={filters.loanIDs}
          handleToggle={onToggle}
          selection="multiple"
          loading={loadingEvents}
        />
      )}
    </>
  );
};
