import React from 'react';
import AlertIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Link } from '@mui/material';
import classes from './FinancialError.module.css';

interface ComponentProps {
  errorMsg: string;
}

export const FinancialError = ({
  errorMsg,
}: ComponentProps) => (
  <Grid item sm={12}>
    <Box display="flex" mt={2} mr={2}>
      <Box mr={1}>
        <AlertIcon className={classes.largeIcon} />
      </Box>
      <Box flexGrow={1}>
        {
          errorMsg.split('\n').map((message) => (
            <Typography variant="body1" className={classes.fontBold} key={`ERR_${message.replace(' ', '')}`}>
              { message}
            </Typography>
          ))
        }
        <Typography variant="body1">
          {'If you are interested in this feature please contact us at '}
          <Link href="mailto:portals@nordictrustee.com">
            portals@nordictrustee.com
          </Link>
        </Typography>
      </Box>
    </Box>
    <br />
  </Grid>
);

