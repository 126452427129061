import React from 'react';
import Table, { TableProps } from '@mui/material/Table';
import classes from './ScrollableTable.module.css';

interface ScrollableTableProps {
  children: React.ReactElement<TableProps>[] | React.ReactElement<TableProps>;
}

export const ScrollableTable = ({
  children,
}: ScrollableTableProps) => (
  <div className={classes.scrollableTable}>
    <Table size="small">
      {children}
    </Table>
  </div>
);

