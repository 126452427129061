import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tab } from '@mui/material';
import { LoadingContainer } from 'components/Loaders';
import { TabHeader } from 'components/TabHeader';
import { FeatureFlags } from 'utils/types/FeatureFlags.types';
import { useLoans } from '../LoansContext';

export const LoanTabs: FC = () => {
  const { loanID, tab } = useParams<{ loanID: string; tab: string }>();
  const { loan, isLoadingLoan } = useLoans();

  const { FEATURE_FLAGS } = window._env_;
  const flags: FeatureFlags = JSON.parse(FEATURE_FLAGS);
  if(!loan) {
    return null;
  }
  return (
    <TabHeader
      header={
        <LoadingContainer
          loading={isLoadingLoan}
          type="text"
          rows={1}
          cols={10}
        >
          {loan?.name || ' '}
        </LoadingContainer>
      }
      subHeader={
        <LoadingContainer
          loading={isLoadingLoan}
          type="text"
          rows={1}
          cols={1.5}
        >
          {loan?.isin || ' '}
        </LoadingContainer>
      }
      value={tab}
    >
      <Tab
        value="overview"
        label="Overview"
        component={Link}
        to={`/loans/${loanID}/overview`}
      />
      <Tab
        value="events"
        label="Events"
        component={Link}
        to={`/loans/${loanID}/events`}
      />
      {flags['48065_ShowReportingPage'] && (
        <Tab
          value="reporting"
          label="Reporting"
          component={Link}
          to={`/loans/${loanID}/reporting`}
        />
      )}
      <Tab
        value="documents"
        label="Documents"
        component={Link}
        to={`/loans/${loanID}/documents`}
      />
      <Tab
        value="financials"
        label="Financials"
        component={Link}
        to={{
          pathname: `/loans/${loanID}/financials/cashflow`,
          state: { loan },
        }}
      />
      <Tab
        value="calls"
        label="Calls"
        component={Link}
        to={`/loans/${loanID}/calls`}
      />
      <Tab
        value="security-packages"
        label="Security"
        component={Link}
        to={{
          pathname: `/loans/${loanID}/security-packages`,
          state: { loan },
        }}
      />
      <Tab
        value="message-board"
        label="Message Board"
        component={Link}
        to={`/loans/${loanID}/message-board`}
      />
    </TabHeader>
  );
};
