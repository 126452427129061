import { Environment } from 'modules/Global/types';

export function initFavicon(): void {
  const { ENVIRONMENT_NAME } = window._env_;

  if (ENVIRONMENT_NAME && ENVIRONMENT_NAME !== Environment.prod) {
    const favicon = document.getElementById('favicon');
    favicon?.setAttribute('href', `/favicon-${ENVIRONMENT_NAME}.ico?v=1`);
  }
}
