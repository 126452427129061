import React from 'react';
import { Typography } from '@mui/material';
import classes from './TextParagraph.module.css';

interface ComponentProps {
  header: string;
  children?: any;
}

export const TextParagraph = ({ header, children }: ComponentProps) => (
  <div className={classes.root}>
    <Typography variant="h4">
      {header}
    </Typography>
    {children}
  </div>
);
