import { Chart } from '@nordictrustee/nt-ui-library';
import { CashFlowItemDTO } from 'api/api.types';
import moment from 'moment';
import theme from 'modules/Global/theme';
import { formatMoney, humanizeMoney } from 'utils/money';
import { getFuture } from './getFuture';
import { getPast } from './getPast';
import { DataPoint } from './PrincipalAmountChart.types';

interface ComponentProps {
  cashFlowItems: CashFlowItemDTO[];
  currency?: string;
}

const { Line } = Chart.RC2;
type ChartData = Chart.Chartjs.ChartData<'line', DataPoint[]>;
type ChartDataset = Chart.Chartjs.ChartDataset<'line', DataPoint[]>;
type ChartOptions = Chart.Chartjs.ChartOptions<'line'>;
type TooltipItem = Chart.Chartjs.TooltipItem<'line'>;


const principalOptionsMinDate = (cashflowItems: CashFlowItemDTO[]) =>
  moment(cashflowItems[0].date).toISOString();
const principalOptionsMaxDate = (cashflowItems: CashFlowItemDTO[]) =>
  moment(cashflowItems[cashflowItems.length - 1].date)
    .add(1, 'month')
    .toISOString();
const createColorArray = (
  points: DataPoint[],
  visible: string,
  invisible: string,
) => points.map((p) => (!!p ? visible : invisible));

const invisibleColor = 'rgba(0, 0, 0, 0)';
const visiblePointColor = theme.palette.primary.main;
const borderColor = theme.palette.primary.main;
const backgroundColor = 'rgba(63, 24, 170, 0.2)';

export const PrincipalAmountChart = ({
  cashFlowItems,
  currency,
}: ComponentProps) => {
  const {
    palette: { primary },
  } = theme;

  const principalAmountPastDataPoints = getPast(cashFlowItems);
  const principalAmountPast: ChartDataset = {
    label: 'Principal Amount',
    fill: true,
    data: principalAmountPastDataPoints,
    pointBorderColor: borderColor,
    pointBackgroundColor: borderColor,
    backgroundColor,
  };

  const principalAmountFutureDataPoints = getFuture(cashFlowItems);
  const principalAmountFuture: ChartDataset = {
    label: 'Principal Amount',
    data: principalAmountFutureDataPoints,
    borderDash: [10, 10],
    pointBorderColor: createColorArray(
      principalAmountFutureDataPoints,
      visiblePointColor,
      invisibleColor,
    ),
    pointBackgroundColor: createColorArray(
      principalAmountFutureDataPoints,
      visiblePointColor,
      invisibleColor,
    ),
    backgroundColor,
  };

  const options: ChartOptions = {
    plugins: {
      filler: {
        propagate:true
      },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label(tooltipItem: TooltipItem) {
            const label: number = (tooltipItem.raw as DataPoint).y as number;
            return formatMoney(label, currency);
          },
        },
      },
    },
    elements:{
      line: {
        borderColor,
        stepped: true,
        backgroundColor
      }
    },
    scales: {
      x: {
        grid: {
          color: invisibleColor,
        },
        type: 'time',
        time: {
          unit: 'month'
        },
        min: principalOptionsMinDate(cashFlowItems),
        max: principalOptionsMaxDate(cashFlowItems),
      },
      y: {
        suggestedMax:
          principalAmountPastDataPoints[0]?.y ||
          principalAmountFutureDataPoints[0]?.y,
        grid: {
          color: invisibleColor,
        },
        beginAtZero: true,
        ticks: {
          callback: (value: number | string, index, ticks) => {
            return humanizeMoney(currency || '', +value);
          },
        },
      },
    },
  };

  const data: ChartData = {
    datasets: [principalAmountPast, principalAmountFuture],
  };
  return <Line data={data} options={options} />;
};
