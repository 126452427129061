import { Dispatch, SetStateAction, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip, Typography } from '@mui/material';
import { DocumentDTO } from 'api/api.types';
import moment from 'moment';
import { LoadingContainer } from 'components/Loaders';
import { SimpleList, SimpleListItem } from 'components/SimpleList';
import { downloadDocument } from '../../DocumentsApi';
import classes from './DocumentsSimpleList.module.css';

interface ComponentProps {
  documents: DocumentDTO[];
}

const initDownloadDocument = (doc: DocumentDTO, setLoading: Dispatch<SetStateAction<boolean>>) => {
  setLoading(true);
  downloadDocument(doc).then(() => {
    setLoading(false);
  });
};

const MAXIMUM_LOAN_NAME = 20;

export const DocumentsSimpleList = ({ documents }: ComponentProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContainer loading={loading} type="list" rows={6} cols={4}>
      <SimpleList>
        {documents.map((doc, i) => (
          <SimpleListItem
            key={doc.documentID}
            onClick={() => initDownloadDocument(doc, setLoading)}
            listItemIcon={(
              <DescriptionIcon color="primary" />
            )}
            divider={documents.length !== i + 1}
          >
            <ListItemText
              primary={
                (
                  <Tooltip title={doc.name || ''}>
                    <Typography variant="body1" className={classes.text}>
                      {doc.name?.substr(0, MAXIMUM_LOAN_NAME) + (doc && doc.name && doc.name.length > MAXIMUM_LOAN_NAME ? '...' : '')}
                    </Typography>
                  </Tooltip>
                )
              }
              secondary={(
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {moment(doc.date).format('L')}
                </Typography>
              )}
            />
            <ListItemText
              secondary={(
                <Typography variant="body1" color="textSecondary">
                  {doc?.relatedLoans != null && doc?.relatedLoans.length > 0 ? doc?.relatedLoans[0]?.isin : ''}
                </Typography>
              )}
              className={classes.loanName}
            />
          </SimpleListItem>
        ))}
      </SimpleList>
    </LoadingContainer>
  );
};

