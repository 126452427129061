import React from 'react';

interface PropsFromState {
  hasError: boolean;
  error: any;
}

interface ComponentProps {
  children: React.ReactElement | React.ReactElement[];
  render: (error: any) => React.ReactNode;
}

class ErrorBoundary extends React.Component<ComponentProps, PropsFromState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: PropsFromState) {
    const { hasError } = this.state;
    if (prevState.hasError && hasError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error: Error) {
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    const { hasError, error } = this.state;
    const { children, render } = this.props;
    if (hasError) {
      return render(error.message);
    }
    return children;
  }
}

export default ErrorBoundary;
