import { useEffect, useState } from 'react';
import {
Card, CardContent,   Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableRow, Tooltip,
Typography, } from '@mui/material';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { getHubUsersInCurrentUsersGroups } from '../../../AccountApi';
import { GroupWithUsersDTO } from '../../../AccountApi/api.types';
import classes from './ViewOtherUsers.module.css';

interface ComponentProps {
  hubUserID: string;
}

export const ViewOtherUsers = ({
  hubUserID,
}: ComponentProps) => {
  const [hubGroupUsers, setHubGroupUsers] = useState<GroupWithUsersDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isCancelled = false;
    async function loadData() {
      // TODO: catch and handle error
      const groupWithUsersDTO = await getHubUsersInCurrentUsersGroups(hubUserID);
      if (!isCancelled) {
        setHubGroupUsers(groupWithUsersDTO);
        setLoading(false);
      }
    }
    loadData();
    return () => {
      isCancelled = true;
    };
  }, [hubUserID, loading]);

  return (
    <Grid item sm={12}>
      <Card title="My Groups">
        <StyledCardHeader
          title="My Groups"
        />
        <CardContent>
          <LoadingContainer loading={loading} cols={1} rows={34} type="list">
            <Grid container spacing={2}>
              {hubGroupUsers.map((group) => (
                <Grid item sm={12} key={((group.users || [])[0])?.hubUserID || group.groupName}>
                  <List className={classes.parentList}>
                    <ListItem key={group.groupName} className={classes.listItem}>
                      <ListItemText
                        classes={{ primary: classes.subGroupHeader }}
                        primary={
                          <Typography variant="h4">{group.groupName}</Typography>
                        }
                      />
                      <Table size="small" className={classes.table}>
                        <TableBody>
                          {group.users && group.users.map((user) => (
                            <TableRow key={user.hubUserID}>
                              <TableCell className={classes.userCell}>
                                <Tooltip title={`${user.firstName} ${user.lastName}`} placement="bottom-start">
                                  <Typography className={classes.tableText}>
                                    {user.firstName}
                                    {' '}
                                    {user.lastName}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title={user.email} placement="bottom-end">
                                  <Typography className={classes.tableText}>
                                    {user.email}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </ListItem>
                  </List>
                </Grid>
              ))}
            </Grid>
          </LoadingContainer>
        </CardContent>
      </Card>
    </Grid>
  );
};

