import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import classes from './CheckboxList.module.css';

interface Item {
  id: string;
  text: string;
  selected: boolean;
}

interface ComponentProps {
  items: Item[];
  onChange: ((id: string) => void);
}

export const CheckboxList = ({
  items, onChange,
}: ComponentProps) => (
  <>
    {items.map((item) => (
      <FormControl key={item.id} component="fieldset" fullWidth className={classes.formControl}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={item.selected}
              onChange={() => {
                onChange(item.id);
              }}
              value={item.selected}
              color="primary"
            />
          )}
          label={item.text}
        />
      </FormControl>
    ))}
  </>
);

