import { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { AxiosError } from 'axios';
import { SubmitButton } from 'components/form';
import { useToast } from 'modules/Global';
import { updateUserNotificationSettings as updateUserNotificationSettingsResource } from '../../../AccountApi';
import { NotificationSettingDTO } from '../../../AccountApi/api.types';
import { CheckboxList } from './CheckboxList';

interface ComponentProps {
  username: string;
  updateNotificationStateWhenChanged: () => Promise<void>;
  notificationSettings: NotificationSettingDTO[];
}

export const EditNotificationSettings: React.FC<ComponentProps> = ({
  username,
  updateNotificationStateWhenChanged,
  notificationSettings: initialNotificationSettings,
}) => {
  const [notificationSettings, setNotificationSettings] = useState<
    NotificationSettingDTO[]
  >(initialNotificationSettings);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { showWarning, showSuccess } = useToast();
  const dispatchUpdateUserNotificationSettings = async (
    username: string,
    data: NotificationSettingDTO[],
  ) => {
    try {
      await updateUserNotificationSettingsResource(username, data);
      showSuccess('Your notification settings have been updated');
    } catch (error) {
      showWarning(
        (error as AxiosError<{message: string}>)?.response?.data?.message ??
          'Failed to change the notification settings',
      );
    }
  };
  const handleSubmit = async () => {
    if (username) {
      setIsSaving(true);
      await dispatchUpdateUserNotificationSettings(
        username,
        notificationSettings,
      );
      setIsSaving(false);
      await updateNotificationStateWhenChanged();
    }
  };

  const notificationSettingChange = (id: string) => {
    setNotificationSettings((prevState) =>
      prevState.map((ns) => {
        const notificationSetting = { ...ns };
        notificationSetting.selected =
          notificationSetting.eventTypeUniverseID === id
            ? !notificationSetting.selected
            : notificationSetting.selected;
        return notificationSetting;
      }),
    );
  };

  const notificationSettingsAsItems = notificationSettings.map((ns) => ({
    id: ns.eventTypeUniverseID,
    text: ns.eventTypeText,
    selected: !ns.selected,
  }));

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <CheckboxList
        items={notificationSettingsAsItems}
        onChange={notificationSettingChange}
      />
      <SubmitButton text="Update notification settings" loading={isSaving} />
    </ValidatorForm>
  );
};

