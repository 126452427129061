import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';

export interface DocumentsToUpload {
  issuerID: number;
  loanID: number;
  documentType: HubDocumentTypeUniverseEnum;
  covenantReportingID?: number;
  documents: File[];
  uploadToStamdata: boolean[];
  comment?: string;
  emailRecipients: string[];
}

export async function uploadLoanDocuments(model: DocumentsToUpload): Promise<AxiosResponse<any>> {
  const form = new FormData();

  model.documents.forEach((file: File) => form.append('documents', file));
  model.uploadToStamdata?.forEach((flag: boolean) => form.append('uploadToStamdata', `${flag}`));
  model.emailRecipients?.forEach((recipient: string) => form.append('emailRecipients', recipient));
  form.append('companyFunctionID', String(model.issuerID));
  form.append('issueID', String(model.loanID));
  form.append('documentType', model.documentType);
  if (model.comment) {
    form.append('comment', model.comment);
  }

  if (model.covenantReportingID) {
    form.append('covenantReportingID', String(model.covenantReportingID));
  }

  const requestConfig: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    baseURL: `${window._env_.REACT_APP_API_BASE}`,
  };
  return axios.post('/api/documents/uploadLoanDocuments', form, requestConfig);
}
