import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import classes from './ConfirmDialog.module.css';

interface ComponentProps {
  contentText: string;
  handleConfirm: any;
  handleCancel: any;
  open: boolean;
  loading?: boolean;
}


const ConfirmDialog = ({
  contentText, handleConfirm, handleCancel, open, loading,
}: ComponentProps ) => (
  <Dialog
    open={open}
    onClose={handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Please confirm or cancel</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {contentText}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary" autoFocus disabled={loading === true}>
        Confirm
        {loading && <CircularProgress size={20} className={classes.buttonIcon} color="primary" />}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
