import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { StyledCardHeader } from 'components/Card';

interface ComponentProps {
  error: any;
  customMessage?: string;
}
const ErrorScreen = ({
  error, customMessage = 'Please',
}: ComponentProps) => (
  <Container maxWidth="xl">
    <Box mt={2} mb={2}>
      <Card>
        <StyledCardHeader title="Something went wrong..." />
        <CardContent>
          <Typography>
            {customMessage}
            {' '}
            <Link target="_blank" href="mailto:portals@nordictrustee.com">Contact</Link>
            {' '}
            us for more information.
          </Typography>

          <Typography>
            Error:
            {' '}
            {error}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  </Container>
);

export default ErrorScreen;
