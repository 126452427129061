import CardContent from '@mui/material/CardContent';
import { LoanDTO } from 'api/api.types';
import moment from 'moment';
import { SubCard } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { LoanOverviewDataList } from '../atoms/LoanOverviewDataList';

interface ComponentProps {
  loan: LoanDTO;
  loading: boolean;
}

export interface Data {
  [key: string]: string | undefined;
}

export const LoanOverviewDates = ({
  loan, loading,
}: ComponentProps) => {
  const maturityDate = loan.maturityDate ? moment(loan.maturityDate).format('L') : undefined;
  const data: Data = {
    'Issue Date': loan.issueDate ? moment(loan.issueDate).format('L') : undefined,
    'Maturity Date': loan.redemptionType === 'Perpetual' ? 'N/A' : maturityDate,
    'Next Term Date': loan.currentInterestPeriodEndDate ? moment(loan.currentInterestPeriodEndDate).format('L') : undefined,
    'Next Quotation Date': loan.nextQuotationDate ? moment(loan.nextQuotationDate).format('L') : undefined,
  };

  return (
    <SubCard title="Dates">
      <CardContent>
        <LoadingContainer loading={loading} type="singleList" rows={6} cols={2}>
          <LoanOverviewDataList data={data} />
        </LoadingContainer>
      </CardContent>
    </SubCard>
  );
};

