import { ChangeEvent, ChangeEventHandler, ReactNode } from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import theme from 'modules/Global/theme';

export class CheckboxValidator extends ValidatorComponent {
    state = { isValid: true }
  renderValidatorComponent() {
    const { isValid } = this.state;
    const { label, onChange } = this.props;

    return (
      <>
        <FormControlLabel
          style={{ marginTop: '16px' }}
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              color="primary"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                this.setState({ isValid: true });
                if (onChange != null) {
                  onChange(e);
                }
              }}
              style={
                isValid
                  ? {
                      alignSelf: 'baseline',
                    }
                  : {
                      alignSelf: 'baseline',
                      color: theme.palette.error.main,
                    }
              }
            />
          }
          label={
            <Typography
              variant="body1"
              style={
                isValid
                  ? {}
                  : {
                      color: theme.palette.error.main,
                    }
              }
            >
              {label}
              <span>*</span>
            </Typography>
          }
        />
        {this.errorText()}
      </>
    );
  }

  errorText() {
    const { isValid } = this.state;
    const { errorMessages } = this.props;

    if (isValid) {
      return null;
    }

    return (
      <div
        style={{
          fontSize: '12px',
          color: theme.palette.error.main,
          marginTop: '4px',
        }}
      >
        {errorMessages}
      </div>
    );
  }
}

interface ComponentProps {
  label?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  value?: string;
  changeHandler?: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >;
  required?: boolean;
  checked: boolean;
  field: string;
}

export const CheckboxWithValidation = ({
  label,
  field,
  changeHandler,
  required,
  checked,
}: ComponentProps) => (
  <CheckboxValidator
    required={required}
    fullWidth
    label={label}
    onChange={changeHandler}
    name={field}
    value={checked || null}
    validators={['required']}
    errorMessages={['This field is required']}
    margin="normal"
  />
);
