import { useState } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { CashFlowDTO, CashFlowItemDTO } from 'api/api.types';
import moment from 'moment';
import { LoadingContainer } from 'components/Loaders';
import { ScrollableTable } from 'components/Table';
import { Money } from '../atoms';
import { TableHeaderProps } from '../tableHeaderProps';
import { TablePagination } from '../TablePagination';
import classes from './CashflowList.module.css';

const headers: TableHeaderProps[] = [
  { title: 'Event', align: 'left' },
  { title: 'Date', align: 'left' },
  { title: 'Currency', align: 'left' },
  { title: 'Amount', align: 'right' },
];

interface CashflowHeaderRowProps {
  includeLoanName?: boolean;
}

const CashflowHeaderRow = ({ includeLoanName }: CashflowHeaderRowProps) => (
  <TableRow>
    <>
      {
        includeLoanName
          ? (
            <TableCell key="loan" component="th" scope="row" align="left">
              <Box displayPrint="block">
                <Typography variant="h5">Loan</Typography>
              </Box>
            </TableCell>
          ) : <></>
      }
      {headers.map((header) => (
        <TableCell key={header.title} component="th" scope="row" align={header.align}>
          <Box displayPrint={header.title === '' ? 'none' : 'block'}>
            <Typography variant="h5">{header.title}</Typography>
          </Box>
        </TableCell>
      ))}

    </>
  </TableRow>
);

interface CashflowRowProps {
  cashflowItem: CashFlowItemDTO;
  cashflowItems: CashFlowItemDTO[] | undefined;
  selected: boolean;
  includeLoanName?: boolean;
}

const CashflowRow = ({
  cashflowItem, cashflowItems, selected, includeLoanName,
}: CashflowRowProps) => {
  return (
    <TableRow selected={selected} className={classes.row}>
      {includeLoanName ? (
        <TableCell component="td" scope="row">
          <Typography variant="body1">{cashflowItem.isin}</Typography>
        </TableCell>
      ) : (
        <></>
      )}
      <TableCell component="td" scope="row">
        <Typography variant="body1">{cashflowItem.eventTypeName}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {moment(cashflowItem.date).format('L')}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body1">{cashflowItem.currency}</Typography>
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <Typography
          className={classes.typography}
          variant="body1"
          color={
            !cashflowItem.amount && cashflowItem.projectedInterestPayment
              ? 'secondary'
              : 'textPrimary'
          }
        >
          <Money
            value={cashflowItem.amount ?? cashflowItem.projectedInterestPayment}
            values={
              cashflowItems
                ? cashflowItems
                    .filter((c) => c.amount)
                    .map((c) => c.amount as number)
                : []
            }
          />
          {!cashflowItem.amount && cashflowItem.projectedInterestPayment && (
            <Tooltip title="Payment projected based on latest available relevant reference rate.">
              <InfoOutlined />
            </Tooltip>
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

interface OwnProps {
  cashflow: CashFlowDTO;
  loading: boolean;
  includeLoanName?: boolean;
  showFutureByDefault?: boolean;
}

function getFilteredRows(rows: CashFlowItemDTO[], showPresentFuture: boolean) {
  let filtered = rows.filter((p) => (
    showPresentFuture ? moment(p.date) >= moment().endOf('day') : moment(p.date) < moment().endOf('day')));
  if (!showPresentFuture) {
    filtered = filtered.sort((a: CashFlowItemDTO, b: CashFlowItemDTO) => moment(b.date).diff(moment(a.date)));
  }
  return filtered;
}

export const CashflowList = ({
  cashflow, loading, includeLoanName, showFutureByDefault = true,
}: OwnProps) => {
  const selected = cashflow.cashFlowItemDTOs?.filter((dto) => dto.currentCashFlowRow)[0];
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(15);
  const filteredRows = getFilteredRows(cashflow.cashFlowItemDTOs || [], showFutureByDefault);

  return (
    <LoadingContainer loading={loading} type="list" rows={28} cols={3}>
      <ScrollableTable>
        <TableHead>
          <CashflowHeaderRow includeLoanName={includeLoanName} />
        </TableHead>
        <TableBody>
          {filteredRows && (
            filteredRows
              .slice(page * pageSize, page * pageSize + pageSize)
              .map((cashflowItem) => (
                <CashflowRow
                  key={`${cashflowItem.id}${cashflowItem.date}${cashflowItem.eventTypeName}`}
                  cashflowItem={cashflowItem}
                  cashflowItems={filteredRows}
                  selected={selected != null && cashflowItem.id === selected.id}
                  includeLoanName={includeLoanName}
                />
              )))}
        </TableBody>
      </ScrollableTable>
      <TablePagination count={cashflow && filteredRows ? filteredRows.length : 0} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
    </LoadingContainer>
  );
};

