import { assertParamExists } from 'api/assertParamExists';
import { BASE_PATH } from 'api/const';
import { createRequestFunction } from 'api/createRequestFunction';
import { serializeDataIfNeeded } from 'api/serializeDataIfNeeded';
import { setSearchParams } from 'api/setSearchParams';
import axios, { AxiosRequestConfig } from 'axios';
import {
  AuthenticateResponse,
  HubUserUpdateDTO,
  HubUserWithCompaniesDTO,
  NotificationSettingDTO,
} from '../api.types';

export interface UserProfileType {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  countryCode?: string;
  momentjsTimezone?: string;
  reportingCurrency?: string;
}

export async function authenticate(
  username: string,
  password: string,
): Promise<AuthenticateResponse> {
  const result: AuthenticateResponse = {
    accessToken: '',
    message: ''
  };

  try {
    const payload = {
      username,
      password,
      appPublicID: window._env_.REACT_APP_IDENTITY,
    };
    const authenticateResponse = await axios.post(
      `${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/token`,
      payload,
    );
    if (authenticateResponse.request.status === 401) {
      result.message = 'Username and password do not match';
    } else {
      const { tokenValue } = authenticateResponse.data;
      result.accessToken = tokenValue;
    }
  } catch (e) {
    result.message = 'System unavailable, please try again later';
  }

  return result;
}

export const UserApiAxiosParamCreator = function () {
  return {
    acceptTerms: async (userid: string, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('acceptTerms', 'userid', userid)
      const localVarPath = `/api/user/${userid}/acceptterms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'PUT', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getHubUsersInCurrentUsersGroups: async (userIDFromUri: string, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'userIDFromUri' is not null or undefined
      assertParamExists('getHubUsersInCurrentUsersGroups', 'userIDFromUri', userIDFromUri)
      const localVarPath = `/api/user/${userIDFromUri}/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getUser: async (userid: string, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getUser', 'userid', userid)
      const localVarPath = `/api/user/${userid}`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    notificationsByUserID: async (options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/user/notificationsettings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    updateNotificationSettingsByUserID: async (notificationSettingDTO?: Array<NotificationSettingDTO>, options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/user/notificationsettings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(notificationSettingDTO, localVarRequestOptions)
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    updateUserProfile: async (hubUserUpdateDTO?: HubUserUpdateDTO, options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/user/updateuserprofile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(hubUserUpdateDTO, localVarRequestOptions)
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  }
};
const UserApiFp = function() {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator()
  return {
    async acceptTerms(userid: string, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTerms(userid, options);
      return createRequestFunction(localVarAxiosArgs, axios );
    },
    async getHubUsersInCurrentUsersGroups(userIDFromUri: string, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHubUsersInCurrentUsersGroups(userIDFromUri, options);
      return createRequestFunction(localVarAxiosArgs, axios );
    },
    async getUser(userid: string, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userid, options);
      return createRequestFunction(localVarAxiosArgs, axios );
    },
    async notificationsByUserID(options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsByUserID(options);
      return createRequestFunction(localVarAxiosArgs, axios );
    },
    async updateNotificationSettingsByUserID(notificationSettingDTO?: Array<NotificationSettingDTO>, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationSettingsByUserID(notificationSettingDTO, options);
      return createRequestFunction(localVarAxiosArgs, axios );
    },
    async updateUserProfile(hubUserUpdateDTO?: HubUserUpdateDTO, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(hubUserUpdateDTO, options);
      return createRequestFunction(localVarAxiosArgs, axios );
    },
  }
};

class UserApi {
  public acceptTerms(userid: string, options?: AxiosRequestConfig) {
    return UserApiFp().acceptTerms(userid, options).then((request) => request( axios ));
  }
  public getHubUsersInCurrentUsersGroups(userIDFromUri: string, options?: AxiosRequestConfig) {
    return UserApiFp().getHubUsersInCurrentUsersGroups(userIDFromUri, options).then((request) => request( axios ));
  }
  public getUser(userid: string, options?: AxiosRequestConfig) {
    return UserApiFp().getUser(userid, options).then((request) => request( axios ));
  }
  public notificationsByUserID(options?: AxiosRequestConfig) {
    return UserApiFp().notificationsByUserID(options).then((request) => request( axios ));
  }
  public updateNotificationSettingsByUserID(notificationSettingDTO?: Array<NotificationSettingDTO>, options?: AxiosRequestConfig) {
    return UserApiFp().updateNotificationSettingsByUserID(notificationSettingDTO, options).then((request) => request( axios ));
  }
  public updateUserProfile(hubUserUpdateDTO?: HubUserUpdateDTO, options?: AxiosRequestConfig) {
    return UserApiFp().updateUserProfile(hubUserUpdateDTO, options).then((request) => request( axios ));
  }
}

const UserAPIInstance = new UserApi();


export async function getUser(userId: string): Promise<HubUserWithCompaniesDTO> {
  const userResponse = await UserAPIInstance.getUser(userId);

  if ((userResponse as any)?.data?.hubUserID) {
    if (!(userResponse as any).data.momentjsTimezone) (userResponse as any).data.momentjsTimezone = 'Europe/Stockholm';
    if (!(userResponse as any).data.countryCode) (userResponse as any).data.countryCode = 'SE';
  }

  return (userResponse as any).data;
}

export async function getHubUsersInCurrentUsersGroups(userId: string) {
  const response = await UserAPIInstance.getHubUsersInCurrentUsersGroups(userId);
  return (response as any).data;
}

export async function acceptTerms(userID: string) {
  const response = await UserAPIInstance.acceptTerms(userID);
  return (response as any).data;
}



// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export async function updateUser(username: string, data: UserProfileType) {
  const response = await UserAPIInstance.updateUserProfile(data);
  return response;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export async function getUserNotificationSettings(username: string) {
  const response = await UserAPIInstance.notificationsByUserID();
  return response;
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export async function updateUserNotificationSettings(username: string, data: NotificationSettingDTO[]) {
  const response = await UserAPIInstance.updateNotificationSettingsByUserID(data);
  return response;
}

export async function activateUser(
  resetToken: string,
  password: string,
) {
  const payload = {
    token: resetToken,
    password,
    appPublicID: window._env_.REACT_APP_IDENTITY,
  };
  return axios.post(`${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/account-verification`, payload);
}

export async function resetPassword(
  googleCaptcha: string,
  userEmail: string,
) {
  const payload = {
    email: userEmail,
    googleReCaptchaResponse: googleCaptcha,
    appPublicID: window._env_.REACT_APP_IDENTITY,
  };
  await axios.post(`${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/password-reset`, payload);
}

export async function changePassword(
  currentPassword: string,
  newPassword: string,
) {
  const payload = {
    currentPassword,
    newPassword,
  };
  const response = await axios.put('/api/api/user/changepassword', payload);
  if (response.status === 204) {
    return true;
  }
  return false;
}

export async function changePasswordFromToken(
  token: string,
  password: string,
) {
  const payload = {
    token,
    newPassword: password,
    appPublicID: window._env_.REACT_APP_IDENTITY,
  };
  await axios.put(`${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/password`, payload);
}
