import { ChangeEvent, ReactElement } from 'react';
import { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import classes from './SubTabs.module.css';

interface SubTabProps {
  onChange: ((event: ChangeEvent<{}>, value: any) => void);
  children: ReactElement<TabProps>[] | ReactElement<TabProps>;
  selectedTab: any;
}

export const SubTabs = ({ onChange, children, selectedTab }: SubTabProps) => (
  <Tabs
    indicatorColor="primary"
    className={classes.subTabs}
    value={selectedTab}
    onChange={(e, val) => onChange(e, val)}
  >
    {children}
  </Tabs>
);

