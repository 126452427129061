import { FC, useCallback, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Box, Typography } from '@mui/material';
import { CreateMaterialUiChangeHandler } from 'legacy/inputHandlers';
import { SubmitButton } from 'components/form';
import { useToast } from 'modules/Global';
import { resetPassword } from '../AccountApi';
import { PaperHeader, PaperPage } from '../atoms';
import { ResetSucceeded } from './ResetSucceeded';

interface FormInput {
  email: string;
}

export const ForgotPasswordPage: FC = () => {
  const { showWarning } = useToast();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState<string | null>(null);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [resetSucceeded, setResetSucceeded] = useState(false);

  const changeHandler = useCallback(CreateMaterialUiChangeHandler<FormInput>(
      (key, value) => {
        setEmail(value);
      },
  ), []);

  const handleSubmit = useCallback(async () => {
    setSendingRequest(true);
    try {
      await resetPassword(token || '', email);
      setResetSucceeded(true);
    } catch (e) {
      setSendingRequest(false);
      showWarning('There was a problem resetting your account');
    }
  }, [email, showWarning, token]);

  const onRecaptchaChange = useCallback((token: string | null) => {
    setToken(token);
  }, []);

  if (resetSucceeded) {
    return (<ResetSucceeded />);
  }

  return (
      <PaperPage>
        <ValidatorForm
          onSubmit={handleSubmit}
          instantValidate
        >
          <PaperHeader>
            Forgot your password?
          </PaperHeader>
          <Box mt={4}>
            <Typography>
              In order to reset your password, we need to confirm your identity.
              Please enter your email address below, then click the &quot;Reset password&quot; button.
            </Typography>
          </Box>
          <Box mt={4}>
            <TextValidator
              fullWidth
              label="Email"
              onChange={changeHandler('email', (e) => e.target.value)}
              name="email"
              value={email}
              validators={['required', 'isEmail']}
              errorMessages={[
                'this field is required',
                'you must specify a valid email',
              ]}
            />
          </Box>
          <Box mt={4}>
            <ReCAPTCHA
              hl="en"
              sitekey={window._env_.REACT_APP_GOOGLE_CAPTCHA as string}
              onChange={onRecaptchaChange}
            />
          </Box>
          <Box mt={4}>
            <SubmitButton text="Reset password" loading={sendingRequest} />
          </Box>
        </ValidatorForm>
      </PaperPage>
  );
}
