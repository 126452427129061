import React from 'react';
import {
  ListItem, ListItemText, Typography,
} from '@mui/material';
import { CaseLogDTO } from 'api/api.types';
import moment from 'moment';

interface ListItemComponentProps {
  message: CaseLogDTO;
  divider?: boolean;
}

const MessageFromNTListItem = ({
  message, divider,
}: ListItemComponentProps) => {
  const primary = `${moment(message.date).format('L')} - ${message.userName}`;
  return (
    <ListItem
      key={message.text}
      divider={divider}
    >
      <ListItemText
        primary={
          (
            <Typography variant="body2">
              { primary}
            </Typography>
          )
        }
        secondary={(
          <Typography variant="body1">
            { message.text}
          </Typography>
        )}
      />
    </ListItem>
  );
};

export default MessageFromNTListItem;
