import React from 'react';
import { MaterialUiChangeHandler } from 'legacy/inputHandlers';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

interface ComponentProps {
  currencyCode?: string;
  changeHandler: MaterialUiChangeHandler<any>;
  changePropertyName: string;
}

const SelectCountry = ({ currencyCode, changeHandler, changePropertyName }: ComponentProps) => {
  const currencyCodeList = ['EUR', 'DKK', 'NOK', 'SEK'];

  return (
    <TextField
      select
      fullWidth
      label="Currency"
      name="currencyCode"
      value={currencyCode || ''}
      onChange={changeHandler(changePropertyName, (e) => e.target.value)}
      margin="normal"
      style={{ textAlign: 'left' }}
      variant="standard"
    >
      {currencyCodeList.map((code) => <MenuItem key={code} value={code}>{code}</MenuItem>)}
    </TextField>
  );
};

export default SelectCountry;
