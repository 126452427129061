import { FC, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { LoanDTO } from 'api/api.types';
import { useGlobal } from 'modules/Global';
import { FeatureFlags } from 'utils/types/FeatureFlags.types';
import { Calls } from './Calls';
import { LoanDocuments, OpenUploadFormTypes } from './Documents';
import { Events } from './Events';
import { Financials } from './Financials';
import { MessageBoard } from './MessageBoard';
import { Overview } from './Overview';
import { Reporting, ReportingProvider } from './Reporting';
import { SecuritiesOverview } from './Security';

type Props = {
  loanID: string;
  loan?: LoanDTO;
  loadingLoanDetails: boolean;
};

type SearchParams = {
  openUploadForm: OpenUploadFormTypes | null;
  reportingDate: string | null;
};
const useSearchParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: SearchParams = {
    openUploadForm: searchParams.get(
      'openUploadForm',
    ) as OpenUploadFormTypes | null,
    reportingDate: searchParams.get('reportingDate'),
  };
  return params;
};
export const LoanRoutes: FC<Props> = ({
  loanID,
  loan,
  loadingLoanDetails,
}: Props) => {
  const { openUploadForm, reportingDate } = useSearchParams();
  const { pathname } = useLocation();
  const { activeCompany: company } = useGlobal();
  const showReporting = useMemo(() => {
    const { FEATURE_FLAGS } = window._env_;
    const flags: FeatureFlags = JSON.parse(FEATURE_FLAGS);
    return flags['48065_ShowReportingPage'];
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this once
  }, []);

  if (!company) return null;
  const { companyID } = company;
  return (
    <Switch>
      <Route
        exact
        path="/loans/:loanID/security-packages"
        render={() => <SecuritiesOverview loanID={+loanID} />}
      />
      <Route
        exact
        path="/loans/:loanID/events"
        render={() => <Events selectedLoanID={+loanID} />}
      />
      {showReporting && loan && (
        <Route path="/loans/:loanID/reporting">
          <ReportingProvider loanID={loanID} trusteeType={loan.trustee}>
            <Reporting />
          </ReportingProvider>
        </Route>
      )}
      <Route
        exact
        path="/loans/:loanID/message-board"
        render={() => (
          <Box pt={2} pb={2}>
            <MessageBoard issueID={+loanID} />
          </Box>
        )}
      />
      <Route
        exact
        path="/loans/:loanID/documents"
        render={() => (
          <Box pt={2} pb={2}>
            <LoanDocuments
              issuerID={companyID}
              loanID={+loanID}
              isin={loan?.isin || ''}
              openUploadForm={openUploadForm ? openUploadForm : undefined}
              reportingDate={reportingDate ? reportingDate : undefined}
            />
          </Box>
        )}
      />
      <Route
        exact
        path="/loans/:loanID/financials/:subtab"
        render={() => (
          <Box pt={2} pb={2}>
            <Financials
              currency={loan && loan.currency}
              loanID={+loanID}
              defaultPath={pathname}
            />
          </Box>
        )}
      />
      <Route
        exact
        path="/loans/:loanID/calls"
        render={() => (
          <Box pt={2} pb={2}>
            <Calls loanID={+loanID} />
          </Box>
        )}
      />
      <Route
        render={() => (
          <Overview
            loanID={+loanID}
            loan={loan}
            issuerID={companyID}
            loadingLoanDetails={loadingLoanDetails}
          />
        )}
      />
    </Switch>
  );
};
