import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import { NotificationSettingDTO } from '../../../AccountApi/api.types';
import { EditNotificationSettings } from './EditNotificationSettings';
import classes from './EditNotificationSettingsAction.module.css';

type Props = {
  onUpdate: () => Promise<void>;
  username: string;
  notificationSettings: NotificationSettingDTO[];
};
export const EditNotificationSettingsAction: React.FC<Props> = ({
  onUpdate,
  username,
  notificationSettings,
}) => {
  const [open, setOpen] = useState(false);
  const onUpdateAndClose = async () => {
    await onUpdate();
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        size="small"
        color="primary"
      >
        <EditIcon className={classes.editIcon}/>
          Edit
      </Button>
      {open && (
        <SimpleModal
          onClose={() => setOpen(false)}
          title="Edit Notification Settings"
          content={
            <EditNotificationSettings
              username={username}
              updateNotificationStateWhenChanged={onUpdateAndClose}
              notificationSettings={notificationSettings}
            />
          }
        />
      )}
    </>
  );
};
