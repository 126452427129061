import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Link, Typography } from '@mui/material';
import classes from './ContactDetails.module.css';

interface ContactDetailsModalContentProps {
  email?: string;
  phoneNumber?: string;
}

export const ContactDetails = ({
  email,
  phoneNumber,
}: ContactDetailsModalContentProps) => (
  <Box component="ul" className={classes.contactDetails} mt={2}>
    {phoneNumber && (
      <Typography
        component="li"
        variant="body2"
        className={classes.contactItem}
      >
        <Link href={`tel:${phoneNumber}`}>
          <PhoneIcon fontSize="inherit" className={classes.icon} />
          {phoneNumber}
        </Link>
      </Typography>
    )}
    {email && (
      <Typography
        component="li"
        variant="body2"
        className={classes.contactItem}
      >
        <Link href={`mailto:${email}`}>
          <EmailIcon fontSize="inherit" className={classes.icon} />
          {email}
        </Link>
      </Typography>
    )}
  </Box>
);
