import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import theme from 'modules/Global/theme';
import { FeatureFlags } from 'utils/types/FeatureFlags.types';
import { EventDTO, EventTypeUniverseEnum } from '../../../types';
import { hasAction } from './hasAction';
import classes from './EventActions.module.css';

const completeTheEvent = async (completeEvent: (event: EventDTO) => void,
  event: EventDTO, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  setLoading(true);
  await completeEvent(event);
  setLoading(false);
};

const completedVerb = (event: EventDTO) => (event.eventType === EventTypeUniverseEnum.ComplianceReporting ? 'Submitted' : 'Completed');

const completedText = (event: EventDTO) => {
  let text = event.completedDate ? moment(event.completedDate).format('L') : '';
  const completeVerb = completedVerb(event);
  text += event.completedByName ? ` - ${completeVerb} by ${event.completedByName}` : ` - ${completeVerb}`;
  return text;
};

interface ComponentProps {
  completeEvent: (event: EventDTO) => void;
  event: EventDTO;
}


export const EventAction = ({
  event, completeEvent,
}: ComponentProps) => {
  const GOTO_COMPLIANCE_REPORTING_TEXT = useMemo(() => {
    const { FEATURE_FLAGS } = window._env_;
    const flags: FeatureFlags = JSON.parse(FEATURE_FLAGS);
    return flags["48065_ShowReportingPage"] ? 'Go to Reporting' : 'Upload document';
  }, []);
  const [loading, setLoading] = useState(false);
  return (
    <Box display="block" displayPrint="none">
      {event.completedDate ? (
        <>
          <Tooltip title={completedText(event)} placement="top">
            <>
              <Button variant="text" size="small" className={classes.completedButton}>
                <CheckCircleIcon />
                {completedVerb(event)}
              </Button>
            </>
          </Tooltip>
          <Typography
            component="span"
            variant="body1"
            display="block"
            color="textSecondary"
            className={classes.completedByName}
          >
            <Typography variant="body2" color="textSecondary">{event.completedByName}</Typography>
          </Typography>
        </>
      ) : (
        <>
          {hasAction(event) && (
            <Button
              component={Link}
              to={{ pathname: `/event-action/${event.eventID}`, state: { event } }}
              variant="outlined"
              color="primary"
              size="small"
            >
              {event.eventType === EventTypeUniverseEnum.ComplianceReporting && GOTO_COMPLIANCE_REPORTING_TEXT}
              {event.eventType === EventTypeUniverseEnum.InterestPaymentDate && 'Calculate interest'}
            </Button>
          )}

          { event.requiresManualCompletion && (

            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => completeTheEvent(completeEvent, event, setLoading)}
              disabled={loading}
            >
              Mark as complete
              {loading && <CircularProgress size={20} style={{ color: theme.palette.primary.main, position: 'absolute' }} />}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
