import { CompaniesApi } from 'api';
import { AxiosResponse } from 'axios';
import { CompanyContactDetailsDTO } from '../../Global/types';

const companyAPI = new CompaniesApi();

export async function getCompanyLogoUrl(companyId: number): Promise<string> {
  return companyAPI.getLogoForCompany(companyId).then((res) => {
    if (res.data) {
      const { logo } = (res as AxiosResponse<{ logo: string }, any>).data;
      return logo ? `data:image/png;base64,${logo}` : '';
    }
    return '';
  });
}
export async function getIssuerContactProfile(issuerID: number) {
  const response = await companyAPI.getCompanyContactDetails(issuerID);
  return (response as any).data;
}

export async function updateIssuerContactProfile(issuerID: number, data: CompanyContactDetailsDTO) {
  return await companyAPI.requestCompanyContactUpdate(issuerID, data);
}
