import React from 'react';
import { SimpleModal } from 'components/SimpleModal';
import { BalanceSheetRequestForm } from './BalanceSheetRequestForm';
import { CompanyDTO } from '../../../types';

interface ComponentProps {
  company?: CompanyDTO;
  companies: CompanyDTO[];
  open: boolean;
  onClose: () => void;
}

export const BalanceSheet = ({
  onClose,
  open,
  company,
  companies,
}: ComponentProps) => {
  if (!open) {
    return null;
  }
  return (
    <SimpleModal
      title="Request Auditor Confirmation"
      onClose={onClose}
      content={
        <BalanceSheetRequestForm
          company={company}
          companies={companies}
          onSubmitted={onClose}
        />
      }
    />
  );
};
