import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { DocumentSearchFilterOption } from 'api/api.types';
import { LoadingContainer } from 'components/Loaders';
import {
  DocumentsSearchFilterIDs,
  DocumentsSearchFilterSelection,
} from './DocumentsFilter.types';
import classes from './DocumentsFilterList.module.css';

interface ComponentProps {
  filterID: DocumentsSearchFilterIDs,
  filterOptions: DocumentSearchFilterOption[];
  handleToggle: (
    filterID: DocumentsSearchFilterIDs,
    filterOption: DocumentSearchFilterOption,
    selection: DocumentsSearchFilterSelection
  ) => void;
  selection: DocumentsSearchFilterSelection;
  loading: boolean;
}

export const DocumentsFilterList = ({
  filterID, filterOptions, handleToggle, selection, loading,
}: ComponentProps) => (
  <List disablePadding className={classes.list}>
    <LoadingContainer loading={loading} type="list" rows={30} cols={1}>
      {filterOptions.map((filterOption) => (
        <ListItem
          dense
          button
          className={classes.item}
          key={filterOption.id}
          divider={filterOption.id === 'all'}
          role={undefined}
          onClick={() => handleToggle(filterID, filterOption, selection)}
        >
          <ListItemIcon className={classes.listIcon}>
            <Checkbox
              edge="start"
              checked={filterOption.selected === true}
              tabIndex={-1}
              disableRipple
              color="primary"
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'body1' }}
            primary={filterOption.text}
          />
        </ListItem>
      ))}
    </LoadingContainer>
  </List>
);

