import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import classes from './DateBlock.module.css';

interface ComponentProps {
  date: string;
}

export const DateBlock = ({ date }: ComponentProps) => (
  <div className={classes.dateBlock}>
    <Typography variant="h3">
      {`${moment(date).format('DD')} ${moment(date).format('MMM')}`}
    </Typography>
    <Typography variant="h3" color="primary">
      {moment(date).format('YYYY')}
    </Typography>
  </div>
);
