import { CovenantReportingDTO } from 'api/api.types';
import moment from 'moment';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import { ReportingSelectWithValidation, ReportingValueLabelOption }
  from './SelectWithValidation/ReportingSelectWithValidation';

interface ComponentProps {
  value?: number;
  documentType: HubDocumentTypeUniverseEnum;
  covenantReportings: CovenantReportingDTO[];
  changeHandler?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
}

const GenerateLabel = (reportingDate?: string | null, referenceDate?: string | null) => {
  if (!reportingDate || !referenceDate) return '';
  return `${moment(reportingDate).format('L')} - (ref: ${moment(referenceDate).format('L')})`;
};

export const CovenantReportingPicker = ({
  value, changeHandler, documentType, covenantReportings: events,
}: ComponentProps) => {
  const options: ReportingValueLabelOption[] = events.filter((cr) => cr.covenantReportingID).map((cr) => ({
    value: cr.covenantReportingID || -1,
    label: GenerateLabel(cr.reportingDate, cr.referenceDate),
    received: Boolean(cr.received),
    date: cr.referenceDate || '',
  }));

  if (documentType !== HubDocumentTypeUniverseEnum.Reporting) {
    return null;
  }

  return (
    <ReportingSelectWithValidation
      label="Select Reporting Date"
      field="reporting date"
      value={value}
      items={options}
      changeHandler={changeHandler}
      required
    />
  );
};
