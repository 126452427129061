import { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { theme } from '@nordictrustee/nt-ui-library/build/themes';
import { HubUserWithCompaniesDTO } from 'modules/Account/AccountApi/api.types';
import { CookieConsentBar } from './CookieConsentBar';
import { GlobalProvider } from './GlobalContext';
import { ToastProvider } from './ToastContainer';

export type ProviderProps = {
  user?: HubUserWithCompaniesDTO;
};
export const Providers: FC<ProviderProps> = ({ user, children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalProvider user={user}>
          <ToastProvider>
            {children}
            <CookieConsentBar />
          </ToastProvider>
        </GlobalProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </ThemeProvider>
);

export { useToast } from './ToastContainer';
export { useGlobal } from './GlobalContext';
