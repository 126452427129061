import { Typography } from '@mui/material';

interface ComponentProps {
  children: any;
}

export const HelperText = ({
  children,
}: ComponentProps) => (
  <Typography variant="body2" color="textSecondary">
    {children}
  </Typography>
);
