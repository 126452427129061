import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button,Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CaseLogDTO } from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { SimpleList } from 'components/SimpleList';
import { SimpleModal } from 'components/SimpleModal';
import { AddMessage } from '../add_messages/AddMessage';
import MessageInternalListItem from './MessageInternalListItem';
import classes from './MessageInternalList.module.css';

interface ComponentProps {
  messages: CaseLogDTO[];
  loading: boolean;
  loanID: number;
  onUploadSuccess: () => Promise<void>;
}

const AddMessageButton: React.FC<{onClick: () => void}> = ({onClick}) => (
  <Button onClick={onClick} variant="text" size="small" color="primary">
    <AddCircleIcon />
    Add Message
  </Button>
);

const MessageInternalList = ({
  messages, loading, loanID, onUploadSuccess,
}: ComponentProps ) => {
  const [openModal, setOpenModal] = useState(false);
  const onSuccess = async ()=> {
    setOpenModal(false);
    await onUploadSuccess();
  }
  return (
    <Card className={classes.card}>
      <StyledCardHeader
        title="Internal Messages"
        action={
          <>
            <AddMessageButton onClick={() => setOpenModal(true)} />
            {openModal && (
              <SimpleModal
                onClose={() => setOpenModal(false)}
                title="Add Message"
                content={
                  <AddMessage
                    loanID={loanID}
                    onUploadSuccess={onSuccess}
                  />
                }
              />
            )}
          </>
        }
      />
      <CardContent>
        <LoadingContainer loading={loading} type="list" rows={6} cols={1}>
          <>
            {messages.length ? (
              <SimpleList>
                {messages.map((message, i) => (
                  <MessageInternalListItem
                    message={message}
                    divider={messages.length !== i + 1}
                    key={message.caseLogEntryID}
                    loanID={loanID}
                    handleRefresh={onUploadSuccess}
                  />
                ))}
              </SimpleList>
            ) : (
              <Typography variant="body1" color="textPrimary">
                No messages
              </Typography>
            )}
          </>
        </LoadingContainer>
      </CardContent>
    </Card>
  );
};

export default MessageInternalList;
