import { CallsApi,CaseLogsApi, FinancialReportingApi, LoansApi, SecuritiesApi } from 'api';
import { CaseLogDTO, CovenantReportingDTO, LoanDTO, PublishedSecurityDTO } from '../../api/api.types';
import { getCancelToken } from '../../utils/init/axiosConfig';

const LoanApiInstance = new LoansApi();
const SecurityApiInstance = new SecuritiesApi();
const FinancialReportingApiInstance = new FinancialReportingApi();
const LogsApiInstance = new CaseLogsApi();
const CallsApiInstance = new CallsApi();


export async function getAllLoans() {
  const res = await LoanApiInstance.getAllLoans();
  return (res as any)?.data;
}

export async function getLoans(issuerID: number) {
  const res = await LoanApiInstance.getLoansForCompany(issuerID);
  return (res as any)?.data;
}

export async function getLoan(loanID: string) {
  const res = await LoanApiInstance.get(loanID, { cancelToken: getCancelToken() });
  return (res as any)?.data as LoanDTO;
}

export async function getLoanCaseLogs(loanID: number, limit = 0): Promise<CaseLogDTO[]> {
  const res = await LogsApiInstance.getCaseLogsByLoanID(loanID, limit);
  return (res as any)?.data;
}

export async function getLoanRemarks(loanID: number, limit = 0): Promise<CaseLogDTO[]> {
  const res = await LogsApiInstance.getRemarksByLoanID(loanID, limit);
  return (res as any)?.data;
}

export async function getFinancialReportForLoan(loanID: number) {
  const res = await FinancialReportingApiInstance.getFinancialReportForLoan(loanID);
  return (res as any)?.data;
}

export async function getLoanCaseHandler(loanID: number) {
  const res = await LoanApiInstance.getCaseHandler(loanID, { cancelToken: getCancelToken() });
  return (res as any)?.data;
}

export async function getCallsForLoan(loanID: number) {
  const res = await CallsApiInstance.getCallsForLoan(loanID, { cancelToken: getCancelToken() });
  return (res as any)?.data;
}

export async function getCaseHandlerLogoUrl(loanID: number): Promise<string> {
  return LoanApiInstance.getCaseHandlerPhoto(loanID, { cancelToken: getCancelToken() })
    .then((res) => {
      if ((res as any)?.data?.logo) return `data:image/png;base64,${(res as any).data.logo}`;
      return '';
    })
    .catch(() => '');
}

export async function getCovenantReportings(issueID: number): Promise<CovenantReportingDTO[]> {
  const res = await LoanApiInstance.getCovenantReportings(issueID);
  return (res as any)?.data;
}

export async function getLoanSecurityPackages(loanID: number): Promise<PublishedSecurityDTO[]> {
  const res = await SecurityApiInstance.getSecurities(loanID, { cancelToken: getCancelToken() });
  return (res as any)?.data;
}
