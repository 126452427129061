import { FileInfo } from './FileUploads';
import { uploadReportingDocuments } from './uploadReportingDocuments';

export const uploadNewFiles = async (
  {
    loanID,
    complianceScheduleId,
    companyId,
    files,
    reportingId
  }:
  {
    loanID: string,
    complianceScheduleId: number,
    companyId: number,
    files: FileInfo[],
    reportingId: number
  }
) => {
  const newFiles = files.filter((file) => {
    return file.id == null;
  });
  if (newFiles.length > 0) {
    await uploadReportingDocuments(
      +loanID,
      reportingId,
      complianceScheduleId,
      companyId,
      newFiles.map((f) => f.file),
      newFiles.map((f) => f.publish),
    );
  }
};
