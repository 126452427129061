import moment from 'moment/moment';
import { ReportingEventDTO } from '../../reportingApi.types';

export const formatTitle = ({
  name: reportingName,
  deadlineDate: reportingDate,
  referenceDate,
}: ReportingEventDTO) => {
  return `Reporting - ${reportingName} - ${moment(reportingDate).format('L')} (${moment(referenceDate).format('L')})`;
};
