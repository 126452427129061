import React, { useState } from 'react';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Box, Grid, Typography } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import loans from '../../../../assets/images/loans.png';
import { ColoredText, ImageModalTriggerButton } from '../atoms';
import events from 'assets/images/events.png';
import classes from './MitigateRiskSection.module.css';

export const MitigateRiskSection = () => {
  const [openModal,setOpenModal] = useState(false);
  return (
    <section className={classes.root}>
      <Grid container spacing={10} alignItems="center" justifyContent="center">

          <Grid item order={{ xs: 2, lg: 1 }} lg={7}>
            <Box position="relative">
              <ImageModalTriggerButton
                onClick={() => setOpenModal(true)}
                imageSrc={loans}
                width="650"
                alt="Issuer Portal events page"
              />
              {openModal && (
                <SimpleModal
                  onClose={()=> setOpenModal(false)}
                  title="Issuer Portal events page"
                  xlSize={10}
                  lgSize={10}
                  mdSize={10}
                  content={
                    <img
                      src={events}
                      alt="Issuer Portal events page"
                      className={classes.image}
                      width="100%"
                    />
                  }
                />
              )}
              <NewReleasesIcon
                color="primary"
                className={classes.backgroundIcon}
              />
            </Box>
          </Grid>
          <Grid item order={{ xs: 1, lg: 2 }} lg={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className={classes.text}
            >
              <Box
                display="flex"
                alignItems="center"
                className={classes.header}
              >
                <NewReleasesIcon color="primary" className={classes.icon} />
                <Typography variant="h1">
                  Mitigate
                  <ColoredText color="primary" text=" Risk" />
                </Typography>
              </Box>
              <Typography variant="body1">
                Reduce the operational risks of your finance and back office
                department through cutting edge automatization and streamlined
                workflows. Event notifications, easy access to up-to-date
                documentation, interest rate calculations and other related
                information helps you mitigate risks of errors resulting from
                manual interactions, and reduces dependencies on documents
                stored locally on employees computers.
              </Typography>
            </Box>
          </Grid>
      </Grid>
    </section>
  );
};