import React from 'react';
import { Providers } from './Providers';
import { Routing } from 'Routing';
import { UndergoingMaintenanceGuard } from 'components/UndergoingMaintenance';

const App = () => {
  return (
    <Providers>
      <UndergoingMaintenanceGuard
        envText={window._env_.REACT_APP_UNDER_MAINTENANCE_TEXT}
      >
        <Routing />
      </UndergoingMaintenanceGuard>
    </Providers>
  );
};
export default App;
