import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid,Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import classes from './ReviewCell.module.css';

interface ComponentProps {
  isReviewed?: boolean;
}

export const ReviewCell = ({
  isReviewed,
}: ComponentProps) => {
  const reviewIcon = isReviewed
    ? <CheckCircleIcon className={classes.checkCircle} fontSize="small" />
    : <AccessTimeIcon className={classes.accessTime} fontSize="small" />;
  const reviewText = isReviewed ? 'Received' : 'Submitted';
  return (
    <TableCell className={classes.reviewTableCell}>
      <Grid container direction="row" alignItems="center" >
        <Grid item>
          { reviewIcon }
        </Grid>
        <Grid item>
          <Typography>
            { reviewText }
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};
