import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { IntroSection } from './IntroSection';
import { HighlightsSection } from './HighlightsSection';
import { MitigateRiskSection } from './MitigateRiskSection';
import { AutomationAndEfficiencySection } from './AutomationEfficiencySection';
import { ProcessControlSection } from './ProcessControlSection';
import { OurVisionSection } from './OurVisionSection';
import { JoinUsSection } from './JoinUsSection';

const BackgroundSvg = () => (
  <div style={{
    position: 'absolute', top: 0, left: 0, bottom: 1000, zIndex: -1,
  }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="calc(100vw - 17px)" height="1500" viewBox="0 0 1920 1050">
      <path id="Path_1" data-name="Path 1" d="M1920,351,0,900V1550L1920,700Z" transform="translate(0 -351)" fill="rgba(0,0,0,0.03)" />
    </svg>
  </div>
);

export const About = () => (
  <Box>
    <Container maxWidth="lg">
      <IntroSection />
      <HighlightsSection />
      <MitigateRiskSection />
      <ProcessControlSection />
      <AutomationAndEfficiencySection />
      <OurVisionSection />
    </Container>

    <JoinUsSection />
    <div style={{ height: 50 }} />
    <BackgroundSvg />
  </Box>
);

