import React, {ChangeEvent, ReactNode} from 'react';
import Tabs from '@mui/material/Tabs';
import { TabProps } from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import classes from './TabHeader.module.css';
import { TabDrawer } from './TabDrawer';

interface ItemProps extends TabProps {
  to: any;
}

interface OwnProps {
  header: ReactNode;
  subHeader?: ReactNode;
  value: string;
  logo?: ReactNode[];
  children?: (React.ReactElement<ItemProps> | boolean)[];
  onChange?: (e: ChangeEvent<{}>, tab: string) => void;
}

type ComponentProps = OwnProps;

export const TabHeader = ({
  header,
  value,
  children,
  onChange,
  logo,
  subHeader,
}: ComponentProps) => {
  return (
    <Container maxWidth="xl" className={classes.root} data-id="tabHeader">
      {logo}
      <Typography variant="h1" className={classes.header}>
        {header}
      </Typography>
      <Typography component="p" variant="h3" className={classes.subHeader}>
        {subHeader}
      </Typography>
      {!!children ? (
        <>
          <Box
            display={{
              xs: 'none',
              sm: 'block',
            }}
            displayPrint="none"
          >
            <Tabs
              className={classes.tabsRoot}
              value={value}
              onChange={onChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {children}
            </Tabs>
          </Box>
          <TabDrawer value={value}>{children}</TabDrawer>
        </>
      ) : null}
    </Container>
  );
};
