import { useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import {
  ColoredText,
  IssuerPortalVideo, WatchVideoTriggerButton
} from '../atoms';
import classes from './JoinUs.module.css';

export const JoinUsSection = () => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  return (<section className={classes.root} style={{ zIndex: 1 }}>
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography className={classes.header} align="center" variant="h1">
          Join our vision to
          <ColoredText color="secondary" text=" digitalize " />
          the Nordic debt market
        </Typography>

        <Box display="flex" className={classes.buttons}>
          <Button
            variant="contained"
            color="secondary"
            href="mailto:portals@nordictrustee.com?subject=Request%20demo"
            className={classes.requestButton}
          >
            Request Demo
          </Button>
          <WatchVideoTriggerButton
            onClick={() => setOpenVideoModal(true)}
            title="WatchTheVideo"
            color="secondary"
          />
          {openVideoModal && (
            <SimpleModal
              onClose={()=> setOpenVideoModal(false)}
              title="Issuer Portal Video"
              content={<IssuerPortalVideo />}
            />
          )}
        </Box>
      </Box>
    </Container>
  </section>);
};
