import { CardContent } from '@mui/material';
import accounting from 'accounting';
import { LoanDTO } from 'api/api.types';
import { SubCard } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { LoanOverviewDataList } from '../atoms';

interface ComponentProps {
  loan: LoanDTO;
  loading: boolean;
}

export interface Data {
  [key: string]: string | undefined;
}

export const Amounts = ({ loan, loading }: ComponentProps) => {
  const data: Data = {
    'Current Outstanding Amount': loan.outstandingAmount
      ? accounting.formatMoney(loan.outstandingAmount, loan.currency, 0)
      : undefined,
    'Max Principal Amount': loan.maximumAmount
      ? accounting.formatMoney(loan.maximumAmount, loan.currency, 0)
      : undefined,
    'Nominal Amount': loan.nominalAmount
      ? accounting.formatMoney(loan.nominalAmount, loan.currency, 0)
      : undefined,
  };

  return (
    <SubCard title="Amounts">
      <CardContent>
        <LoadingContainer loading={loading} type="singleList" rows={6} cols={2}>
          <LoanOverviewDataList data={data} />
        </LoadingContainer>
      </CardContent>
    </SubCard>
  );
};
