import { FC, useState } from 'react';
import { TableBody, TableHead } from '@mui/material';
import { InterestPaymentItemDTO } from 'api/api.types';
import { StickyTable } from 'components/Table';
import { TablePagination } from '../../TablePagination';
import { PaymentTableHeaders } from './PaymentTableHeaders';
import { PaymentTableRow } from './PaymentTableRow';

type PaymentTableProps = {
  payments: InterestPaymentItemDTO[];
  loading: boolean;
  includeLoanName?: boolean;
};
export const PaymentTable: FC<PaymentTableProps> = ({
  payments,
  loading,
  includeLoanName,
}) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(15);
  return (
    <>
    <StickyTable>
      <TableHead>
        <PaymentTableHeaders includeLoanName={includeLoanName ?? false} />
      </TableHead>
      <TableBody>
        {payments &&
          payments
            .slice(page * pageSize, page * pageSize + pageSize)
            .map((ip) => (
              <PaymentTableRow
                key={ip.id}
                interestPayment={ip}
                selected={ip.isCurrentPayment}
                loading={loading}
                includeLoanName={includeLoanName ?? false}
              />
            ))}
      </TableBody>
    </StickyTable>
      <TablePagination
        count={payments?.length || 0}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      </>
  );
};
