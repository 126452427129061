import { ChangeEvent } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import classes from './DocumentsToUploadTable.module.css';
import { UploadDocument } from './sections/FileUpload/processFiles';

const headers = [
  'Filename',
  'Publish to Stamdata',
];

interface DocumentRowProps {
  document: UploadDocument;
  documentType: HubDocumentTypeUniverseEnum;
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>, documentId: string) => void;
  isin: string;
  onRemoveDocument: (documentToRemove: UploadDocument) => void;
}

const TableHeaderRow = () => (
  <TableRow data-id="headerrow">
    {headers.map((header) => (
      <TableCell key={header} component="th" scope="row">
        <Typography variant="h5">{header}</Typography>
      </TableCell>
    ))}
    <TableCell component="th" scope="row" />
  </TableRow>
);

const shouldShowPublishToStamdata = (documentType: HubDocumentTypeUniverseEnum, isin?: string) => {
  return documentType === HubDocumentTypeUniverseEnum.Reporting && isin != null;

};

const DocumentRow = ({
  document, documentType, onCheckboxChange, isin, onRemoveDocument,
}: DocumentRowProps) => (
  <TableRow className={classes.row}>
    <TableCell component="td" scope="row">
      <Typography variant="body1">
        {document.file ? document.file.name : 'select a file'}
      </Typography>
    </TableCell>
    {(shouldShowPublishToStamdata(documentType, isin)) && (
      <TableCell component="td" scope="row">
        <Checkbox
          className={classes.checkbox}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          color="primary"
          checked={document.uploadToStamdata}
          onChange={(e) => onCheckboxChange(e, document.documentID)}
        />
      </TableCell>
    )}
    <TableCell className={classes.removeButtonCell}>
      <Button variant="text" size="small" onClick={() => onRemoveDocument(document)}>Remove</Button>
    </TableCell>
  </TableRow>
);

interface OwnProps {
  documents: UploadDocument[];
  documentType: HubDocumentTypeUniverseEnum;
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, documentId: string) => void;
  isin: string;
  onRemoveDocument: (documentToRemove: UploadDocument) => void;
}

export const DocumentsToUploadTable: React.FC<OwnProps> = ({
  documents,
  documentType,
  onCheckboxChange,
  isin,
  onRemoveDocument,
}) => {
  if (!(documents.length > 0 && documents.find((d) => d.file != null))) {
    return null;
  }
  return (
    <Table size="small">
      <TableHead>
        {shouldShowPublishToStamdata(documentType, isin) && <TableHeaderRow />}
      </TableHead>
      <TableBody className={classes.tableBody}>
        {documents.map((document) => (
          <DocumentRow
            key={document.documentID}
            document={document}
            documentType={documentType}
            onCheckboxChange={onCheckboxChange}
            isin={isin}
            onRemoveDocument={onRemoveDocument}
          />
        ))}
      </TableBody>
    </Table>
  );
};

