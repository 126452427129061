import { DocumentSearchFilters } from '../../../../api/api.types';
import { DocumentsOptions } from '../../DocumentsApi';
import { DOCUMENT_FILTER_OPTION_ALL } from './DocumentsFilter.types';

export const buildDocumentsOptionsFromFilters = (
  filters: DocumentSearchFilters,
  issueID?: number,
) => {
  const opts: DocumentsOptions = {};

  if (issueID) {
    // Unique loan: usually within a loan page context
    opts.issueIDs = [issueID];
  } else if (filters.issueIDs) {
    // All loans: out of unique loan context
    opts.issueIDs = filters.issueIDs
      .filter((fo) => fo.selected && fo.id !== DOCUMENT_FILTER_OPTION_ALL)
      .map((fo) => Number(fo.id));
  }

  if (filters.companyIDs) {
    opts.companyIDs = filters.companyIDs
      .filter((fo) => fo.selected && fo.id !== DOCUMENT_FILTER_OPTION_ALL)
      .map((fo) => Number(fo.id));
  }

  return opts;
};
