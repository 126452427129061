import React from 'react';
import { Typography } from '@mui/material';
import classes from './PaperHeader.module.css';

interface ComponentProps {
  children: any;
}

const PaperHeader = ({
  children,
}: ComponentProps ) => (
  <Typography data-id="title" variant="h3" gutterBottom className={classes.header}>
    {children}
  </Typography>
);

// @ts-ignore
export default PaperHeader;
