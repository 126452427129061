import { FC } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import classes from './FileIcon.module.css';

const getClassName = (fileType: string) => {
    switch (fileType) {
        case 'pdf':
            return classes.iconPdf;
        case 'docx':
            return classes.iconWord;
        case 'xlsx':
            return classes.iconExcel;
        default:
            return classes.icon;
    }
}

export const FileIcon: FC<{ fileExtension?: string, filename?: string }> = ({ fileExtension, filename }) => {
    if (!fileExtension && !filename) throw new Error('File extension or filename must be provided');
    if (!fileExtension && filename) {
        fileExtension = filename.split('.').pop();
    }
    return <DescriptionIcon fontSize="small" className={`${getClassName(fileExtension || '')} ${classes.icon}`} />
};