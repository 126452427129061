import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

interface ComponentProps {
  lastUpdatedDate?: string;
}

export const LastUpdated = (
  { lastUpdatedDate }: ComponentProps,
) => {
  const lastUpdated = `Last updated: ${lastUpdatedDate != null && lastUpdatedDate !== '' ? moment(lastUpdatedDate).format('L') : ''}`;
  return (
    <Typography variant="body1">
      {lastUpdated}
    </Typography>
  );
};
