import {
  DocumentsApi,
  DocumentsApiAxiosParamCreator,
  ReportingsApi,
} from 'api';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import {
  ReportingDetailsDTO,
  ReportingEventDTO,
  ReportingSubmitNewDTO,
  ReportingSubmitUpdateDTO,
} from './reportingApi.types';

const ReportingsApiInstance = new ReportingsApi();
const DocumentsApiInstance = new DocumentsApi();

export async function getReportings(
  loanID: number,
  fromDate?: string,
  toDate?: string,
): Promise<ReportingEventDTO[]> {
  const res = await ReportingsApiInstance.getReportings(
    loanID,
    fromDate,
    toDate,
  );
  return (res as any)?.data;
}

export async function getReporting(
  loanID: number,
  complianceScheduleItemID: number,
): Promise<ReportingDetailsDTO> {
  const res = await ReportingsApiInstance.getReporting(
    loanID,
    complianceScheduleItemID,
  );
  return (res as any)?.data;
}

export async function submitNewReporting(
  loanID: number,
  complianceScheduleItemID: number,
  reporting: ReportingSubmitNewDTO,
): Promise<number> {
  const res = await ReportingsApiInstance.sumbitNewReporting(
    loanID,
    complianceScheduleItemID,
    reporting,
  );
  return (res as any)?.data.id;
}

export async function submitUpdatedReporting(
  loanID: number,
  reportingID: number,
  reporting: ReportingSubmitUpdateDTO,
): Promise<ReportingEventDTO> {
  const res = await ReportingsApiInstance.submitUpdatedReporting(
    loanID,
    reportingID,
    reporting,
  );
  return (res as any)?.data;
}

export async function uploadDocuments(
  loanID: number,
  reportingID: number,
  files: File[],
  UploadToStamdata: boolean[],
): Promise<void> {
  await ReportingsApiInstance.uploadDocuments(
    loanID,
    reportingID,
    files,
    UploadToStamdata,
  );
}

export async function downloadDocument(
  fileID: number,
  fileName: string,
): Promise<void> {
  if (!fileID) {
    return;
  }

  async function getDocumentToken() {
    const res = await DocumentsApiInstance.generateDocumentToken(
      HubDocumentTypeUniverseEnum.Reporting,
      fileID,
    );
    return (res as any)?.data;
  }

  const token = await getDocumentToken();
  const { url: userDocUrl } =
    await DocumentsApiAxiosParamCreator().downloadLoanDocument(token);
  const requestConfig: AxiosRequestConfig = {
    responseType: 'arraybuffer',
    baseURL: `${window._env_.REACT_APP_API_BASE}`,
  };
  await axios
    .get(`${userDocUrl}`, requestConfig)
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        const { document } = globalThis;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName || 'unknownFilename.pdf');
        document.body.appendChild(link);
        link.click();
      }
    });
}
