import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import Tab from '@mui/material/Tab';
import { AccountingDTO, CashFlowDTO, InterestPaymentDTO } from 'api/api.types';
import { SubTabs } from 'components/SubTabs';
import { Accounting } from 'modules/Financials';
import { CashflowOverview } from 'modules/Financials/Cashflow';
import {
  downloadFinancialReportExcelForLoan,
  getFinancialReportingDTOForLoan,
} from 'modules/Financials/FinancialsApi';
import { Payments } from 'modules/Financials/Payments/Payments';
import classes from './Financials.module.css';

interface ComponentProps {
  loanID: number;
  defaultPath: string;
  currency?: string;
}

const getLastPartOfUrl = (url: string) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

export const Financials = ({
  loanID, defaultPath, currency,
}: ComponentProps) => {
  const [selectedTab, setSelectedTab] = useState(() => getLastPartOfUrl(defaultPath));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accounting, setAccounting] = useState<AccountingDTO>({});
  const [interestPayment, setInterestPayment] = useState<InterestPaymentDTO>({});
  const [cashflow, setCashflow] = useState<CashFlowDTO>({});

  useEffect(() => {
    async function loadData() {
      const financialReportingDTO = await getFinancialReportingDTOForLoan(loanID);

      setAccounting(financialReportingDTO.accountingDTO);
      setInterestPayment(financialReportingDTO.interestPaymentDTO);
      setCashflow(financialReportingDTO.cashFlowDTO);
      setIsLoading(false);
    }

    loadData();
  }, [loanID]);

  return (
    <Router>
      <Card>
        <CardHeader
          title={(
            <SubTabs selectedTab={selectedTab} onChange={(_e: unknown, v:string) => setSelectedTab(v)}>
              <Tab
                value="cashflow"
                label="Cashflow"
                disableRipple
                className={classes.tab}
                component={Link}
                to={{ pathname: `/loans/${loanID}/financials/cashflow` }}
              />
              <Tab
                value="payments"
                label="Payments"
                disableRipple
                className={classes.tab}
                component={Link}
                to={{ pathname: `/loans/${loanID}/financials/payments` }}
              />
              <Tab
                value="accounting"
                label="Accounting"
                disableRipple
                className={classes.tab}
                component={Link}
                to={{ pathname: `/loans/${loanID}/financials/accounting` }}
              />
            </SubTabs>
          )}
          action={(
            <Button
              onClick={() => downloadFinancialReportExcelForLoan(loanID)}
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
            >
              Export to Excel
            </Button>
          )}
        />
        <CardContent>
          <Switch>
            <Route
              exact
              path="/loans/:loanID/financials/cashflow"
              render={() => <CashflowOverview exchangeToCurrency={currency} cashflow={cashflow} loading={isLoading} />}
            />
            <Route
              exact
              path="/loans/:loanID/financials/accounting"
              render={() => <Accounting accounting={accounting} loading={isLoading} />}
            />
            <Route
              exact
              path="/loans/:loanID/financials/payments"
              render={() => (
                <Payments
                  interestPayment={interestPayment}
                  loading={isLoading}
                />
              )}
            />
            <Route
              exact
              path="/loans/:loanID/financials/payments/:interestPaymentDate"
              render={() => (
                <Payments
                  interestPayment={interestPayment}
                  loading={isLoading}
                />
              )}
            />
          </Switch>
        </CardContent>
      </Card>
    </Router>
  );
};