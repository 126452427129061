import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from 'modules/Global';
import { activateUser } from '../AccountApi';
import { ConfigurePassword } from '../atoms';

export interface OwnProps {
  firstName: string;
  lastName: string;
  email: string;
}

type ComponentProps = OwnProps;

export const VerifyAccountPage: FC<ComponentProps> = () => {
  const { showSuccess, showWarning } = useToast();
  const location = useLocation();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [token, setToken] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    localStorage.clear();

    const search = location.search;
    const params = new URLSearchParams(search);

    const token = params.get('token');
    const email = params.get('email');
    const firstName = params.get('firstname');
    const lastName = params.get('lastname');

    setEmail(email || '');
    setUserName(email || '');
    setToken(token || '');
    setFirstName(firstName || '');
    setLastName(lastName || '');
  }, [location]);


  const handleSubmit = async (password: string) => {
    
    try {
      if (!token) {
        throw new Error('Missing token');
      }
      const response: any = await activateUser(token, password);

      if (response.status !== 202) {
        showWarning("We couldn't create your account");
        return;
      }

      history.push('/login');
      showSuccess(
        'Your account has been created. Please login',
      );
    } catch (e) {
      showWarning("We couldn't create your account");
    }
  }

  return (
    <>
      <ConfigurePassword
        title="Verify your account"
        text="To verify your account, fill in the password fields and click Verify Account."
        submitTitle="Verify Account"
        firstName={firstName}
        lastName={lastName}
        email={email}
        showEulaConfirmation
        onSubmit={handleSubmit}
        userName={userName}
      />
    </>
  );
}
