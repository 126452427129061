import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ValueLabelOption } from 'legacy/types';
import classes from './SimpleSelect.module.css';

interface ComponentProps {
  label?: string;
  value?: string;
  items: ValueLabelOption[];
  changeHandler?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  required?: boolean;
}

const SimpleSelect = ({
  value, items, changeHandler, label,
}: ComponentProps) => (
  <TextField
    select
    fullWidth
    label={label}
    value={value}
    onChange={changeHandler}
    margin="normal"
    className={classes.text}
    variant="standard"
  >
    {items.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
  </TextField>
);

export default SimpleSelect;
