import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {handleRequestError} from "./handleRequestError";

export const createRequestFunction = function <T = unknown, R = AxiosResponse<T>>(
    axiosArgs: { url: URL, options: AxiosRequestConfig<any> },
    globalAxios: AxiosInstance,
) {
    return (axios: AxiosInstance = globalAxios ) => {
        const axiosRequestArgs = {
            ...axiosArgs.options,
            url: axiosArgs.url.href,
        };
        return axios
            .request<T, R>(axiosRequestArgs)
            .catch((error) => handleRequestError(error));
    };
};
