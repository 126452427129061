import Card from '@mui/material/Card';
import { StyledCardHeader } from './StyledCardHeader';
import classes from './SubCard.module.css';

interface ComponentProps {
  title?: string;
  children: any;
}

export interface Data {
  [key: string]: string | undefined;
}

export const SubCard = ({
  children, title,
}: ComponentProps) => (
  <Card className={classes.subCard}>
    {title && (
      <StyledCardHeader
        title={title}
      />
    )}
    {children}
  </Card>
);
