import { FC } from 'react';
import { Box, CardContent, Grid, Link } from '@mui/material';
import { InterestPaymentItemDTO } from 'api/api.types';
import moment from 'moment';
import { SubCard } from 'components/Card';
import { HelperText } from 'components/form';
import { CircularLoader } from 'components/Loaders';
import { SimpleModal } from 'components/SimpleModal';
import { PaymentFAQ } from '../PaymentFAQ';
import { PaymentDetailsTable } from './PaymentDetailsTable';
import { ScheduledRepaymentsTable } from './ScheduledRepaymentsTable';
import { showHelperTextAndFaq } from './showHelperTextAndFaq';

interface InterestPaymentProps {
  interestPayment: InterestPaymentItemDTO;
  loading: boolean;
  onClose: () => void;
}

export const PaymentRowDetailsDialog: FC<InterestPaymentProps> = ({
  interestPayment,
  loading,
  onClose,
}) => {
  if (loading) return <CircularLoader />;
  const showPaymentFaq = showHelperTextAndFaq(interestPayment);
  return (
    <SimpleModal
      onClose={onClose}
      title={`${moment(interestPayment.startDate).format('L')} - ${moment(interestPayment.endDate).format('L')}`}
      small={!showPaymentFaq}
      content={
        <Grid container spacing={2}>
          <Grid
            item
            sm={12}
            md={showPaymentFaq ? 7 : 12}
            lg={showPaymentFaq ? 6 : 12}
          >
            <SubCard title="Interest Payment">
              <CardContent>
                <PaymentDetailsTable interestPayment={interestPayment} />
                <Box mt={2}>
                  <HelperText>
                    <>
                      The above information and calculation is provided to
                      issuers for their convenience and as reference only, and
                      Nordic Trustee accepts no liability whatsoever in respect
                      of the information and calculation provided above. The
                      issuer is solely responsible for ensuring that
                      calculations and payments of interest are correct, also
                      when based on the above-mentioned information and
                      calculation. If the issuer believes any information or
                      calculation above is are incorrect, please contact us at{' '}
                      <Link href="mailto:portals@nordictrustee.com">
                        portals@nordictrustee.com
                      </Link>
                    </>
                  </HelperText>
                </Box>
              </CardContent>
            </SubCard>
            <SubCard title="Scheduled Repayment">
              <CardContent>
                <ScheduledRepaymentsTable interestPayment={interestPayment} />
              </CardContent>
            </SubCard>
          </Grid>
          {showPaymentFaq && (
            <Grid item sm={12} md={5} lg={6}>
              <PaymentFAQ />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
