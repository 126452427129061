import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, CardContent, Grid, TableBody } from '@mui/material';
import { Card } from '@nordictrustee/nt-ui-library';
import { LoadingContainer } from 'components/Loaders';
import { SimpleModal } from 'components/SimpleModal';
import { FixedTable, LabelContentRow as Row } from 'components/Table';
import { HubUserWithCompaniesDTO } from '../../../AccountApi/api.types';
import { ChangeUserPassword } from './ChangeUserPassword';
import EditUserProfile from './EditUserProfile';
import classes from './ViewUserProfile.module.css';


interface ComponentProps {
  user: HubUserWithCompaniesDTO;
}

export const ViewUserProfile = ({
  user
}: ComponentProps) => {

  const {
    username,
    firstName,
    lastName,
    email,
    phoneNumber,
    countryCode,
    reportingCurrency,
  } = user;
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  return (
    <Grid item sm={12}>
      <Card title={`${firstName} ${lastName}`} customAction={
            <>
              <span>
                <Button
                  onClick={() => setOpenEditProfileModal(true)}
                  variant="text"
                  size="small"
                  color="primary"
                >
                  <EditIcon className={classes.editIcon}/>
                  Edit
                </Button>
              </span>
              {openEditProfileModal && (
                <SimpleModal
                  title="Edit User Profile"
                  content={
                    <EditUserProfile username={username} profile={user} />
                  }
                  onClose={() => setOpenEditProfileModal(false)}
                />
              )}
            </>
          }>
        <CardContent>
          <LoadingContainer
            loading={username === null}
            type="contactCard"
            rows={0}
            cols={0}
          >
            <Grid container spacing={2}>
              <Grid item sm={11} md={4}>
                <Box className={classes.profileImageBox}>
                  <PersonIcon className={classes.profileAvatar} />
                </Box>
              </Grid>
              <Grid item sm={11} md={8}>
                <Box>
                  <FixedTable>
                    <TableBody>
                      <Row key={email} label="E-mail" alignRight>
                        {email}
                      </Row>
                      <Row key={phoneNumber} label="Phone" alignRight>
                        {phoneNumber}
                      </Row>
                      <Row key={countryCode} label="Country Code" alignRight>
                        {countryCode}
                      </Row>
                      <Row key={reportingCurrency} label="Currency" alignRight>
                        {reportingCurrency}
                      </Row>
                    </TableBody>
                  </FixedTable>
                  <div className={classes.changePasswordContainer}>
                    <Button
                      onClick={() => setOpenPasswordModal(true)}
                      variant="text"
                      size="small"
                      color="primary"
                    >
                      Change Password
                    </Button>
                    {openPasswordModal && (
                      <SimpleModal
                        title="Change Password"
                        content={
                          <ChangeUserPassword
                            userName={username}
                            onSubmitted={() => setOpenPasswordModal(false)}
                          />
                        }
                        onClose={() => setOpenPasswordModal(false)}
                      />
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </LoadingContainer>
        </CardContent>
      </Card>
    </Grid>
  );
}
