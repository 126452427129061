import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import accounting from 'accounting';
import { BasicLoanDTO } from 'api/api.types';
import moment from 'moment';
import { StickyTable } from 'components/Table';
import { LoansFilter } from '../../../LoansFilter/LoansFilter';
import { calcAmountLeft } from '../../../utils';
import { InfoDoughnut } from '../../InfoDoughnut';
import { LoanSummaryProgress } from '../../LoanSummaryProgress';
import classes from './LoansWithinTable.module.css';


const formatNextPaymentDate = (loan: BasicLoanDTO) => {
  const currentInterestPeriodEndDate = loan.currentInterestPeriodEndDate
    ? moment(loan.currentInterestPeriodEndDate).format('LL')
    : '';
  const nextPeriodInterestRate = loan.nextPeriodInterestRate
    ? ` (${loan.nextPeriodInterestRate}%)`
    : '';
  return `${currentInterestPeriodEndDate}${nextPeriodInterestRate}`;
};

const getIssueTypes = (loans: BasicLoanDTO[]): string[] => Array.from(
  new Set(loans.filter((l) => l.issueType !== null && l.issueType !== undefined).map((l) => l.issueTypeName)),
) as string[];

const getFilteredLoans = (loans: BasicLoanDTO[], filter: string[], nameFilter: string) => {
  const loansFilteredByName = loans.filter((l) => l.isin?.includes(nameFilter) || l.name?.toLowerCase().includes(nameFilter.toLowerCase()));

  if (filter === null || filter === undefined || filter.length === 0) {
    return loansFilteredByName;
  }

  return loansFilteredByName.filter((l) => filter.includes(l.issueTypeName || ''));
};

const getMaturedText = (loan: BasicLoanDTO): string => {
  if (!loan) return '';
  // TODO: control that the are compared correctly here
  return ((loan.lastPossibleMaturityDate || '0000-00-00') < (loan.maturityDate || '0000-00-00')) ? 'Early Redeemed' : 'Matured';
};

interface OwnProps {
  loans: BasicLoanDTO[];
  loanType: string;
}

type ComponentProps = OwnProps;

export const LoansWithinTable = ({
                            loans, loanType
                          }: ComponentProps) => {
  const history = useHistory();
  const [issueTypeFilter, setIssueTypeFilter] = useState<string[]>([]);
  const [issueNameFilter, setIssueNameFilter] = useState<string>('');
  const issueTypes = getIssueTypes(loans);
  return (
    <>
      <LoansFilter
        numberOfLoans={loans?.length}
        issueTypes={issueTypes}
        issueTypeFilter={issueTypeFilter}
        setIssueTypeFilter={setIssueTypeFilter}
        issueNameFilter={issueNameFilter}
        setIssueNameFilter={setIssueNameFilter}
      />

      <StickyTable>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Name</Typography>
            </TableCell>
            <TableCell className="hidePrintCell">
              <Typography variant="h5">Issue Type</Typography>
            </TableCell>
            <TableCell className="hidePrintCell">
              <Typography variant="h5">Progress</Typography>
            </TableCell>
            <TableCell align="right">
              {loanType === 'active' ? (
                <Typography variant="h5">Outstanding Amount</Typography>
              ) : (
                <div>&nbsp;</div>
              )}
            </TableCell>
            <TableCell align="center">
              {loanType === 'active' ? (
                <Typography variant="h5">Next Payment Date</Typography>
              ) : (
                <div>&nbsp;</div>
              )}
            </TableCell>

            <TableCell align="center">
              {loanType === 'active' ? (
                <Typography variant="h5">Undrawn Amount</Typography>
              ) : (
                <div>&nbsp;</div>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getFilteredLoans(loans, issueTypeFilter, issueNameFilter).map(
            (loan) => {
              return (
                <TableRow
                  hover
                  onClick={() => history.push(`/loans/${loan.issueID}`)}
                  key={loan.issueID}
                  className={classes.tableRow}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="h3">
                      {loan.name}
                      <br />
                      <Typography component="span" variant="body1">
                        {`ISIN: ${loan.isin}`}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '15%' }}>
                    <Typography component="span" variant="body1">
                      {loan.issueTypeName}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '25%' }} className="hidePrintCell">
                    <LoanSummaryProgress
                      loan={loan}
                      events={[]}
                      legendDisplay="below"
                    />
                  </TableCell>

                  <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                    {loanType === 'active' ? (
                      <Typography variant="body1">
                        {accounting.formatMoney(
                          loan.outstandingAmount || 0,
                          `${loan.currency}`,
                          0,
                        )}
                      </Typography>
                    ) : (
                      <Typography>{getMaturedText(loan)}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <Typography variant="body1">
                      {loanType === 'active' ? (
                        formatNextPaymentDate(loan)
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    {loanType === 'active' && loan.maximumAmount ? (
                      <>
                        <Box displayPrint="none">
                          <InfoDoughnut
                            size={1}
                            currentValue={loan.outstandingAmount || 0}
                            maximumValue={loan.maximumAmount || 0}
                            currency={`${loan.currency}`}
                            completeText="Fully drawn"
                            key={String(loan.issueID || -1)}
                            hideLabels
                          />
                        </Box>
                        <Box display="none" displayPrint="block">
                          <Typography variant="body1">
                            {accounting.formatMoney(
                              calcAmountLeft(loan),
                              `${loan.currency}`,
                              0,
                            )}
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </StickyTable>
    </>
  );
};
