import { createTheme } from '@mui/material/styles';

const spacing = 8;

export default createTheme({
  spacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1650,
    },
  },
  palette: {
    primary: {
      main: '#3f18aa',
      light: '#6546bb',
      dark: '#2c1076',
      // @ts-ignore
      extraLight: '#b6a7de',
      transparent: '#3f18aa33',
    },
    secondary: {
      main: '#e87f38',
      light: '#ec985f',
      dark: '#a25827',
      // @ts-ignore
      transparent: '#e87f383d',
    },
    tertiary: {
      one: '#7f96f7',
      two: '#a84924',
      three: '#003748',
      four: '#ced8f4',
      five: '#f9c4a0',
      six: '#91e5ce',
    },
    header: {
      main: '#13161c',
    },
    background: {
      paper: '#FAFAFA',
      default: '#f7f8f6',
    },
    text: {
      primary: '#13161c',
      secondary: '#0000008a',
      // @ts-ignore
      white: '#FFF',
    },
    noData: {
      default: '#cccccc',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#FF0000',
    },
  },
});

const chartColors = [
  '#3f18aa',
  '#003748',
  '#ced8f4',
  '#f9c4a0',
  '#91e5ce',
  '#e87f38',
  '#7f96f7',
  '#a84924',
];

export const getChartColor = (idx: number): string => {
  const idxModulo = idx % chartColors.length;
  return chartColors[idxModulo];
};
