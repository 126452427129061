import React, { useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MaterialUiChangeHandler } from 'legacy/inputHandlers';

interface TextFieldProps {
  value?: string;
  changeHandler: MaterialUiChangeHandler<any>;
  required?: boolean;
  autoFocus?: boolean;
  label?: string;
  field?: string;
}

export const MessageTextField = ({
  value,
  label,
  changeHandler,
  autoFocus,
}: TextFieldProps) => {
  useEffect(() => {
    console.log('rule added')
    ValidatorForm.addValidationRule('isLessThan200Characters', (v) => {
      return v.length <= 200;
    });
  }, []);
  return (
    <TextValidator
      autoFocus={autoFocus || false}
      fullWidth
      multiline
      label="Message *"
      onChange={changeHandler('message', (e) => e.target.value)}
      name="message"
      value={value || ''}
      type="text"
      validators={['required', 'isLessThan200Characters']}
      errorMessages={[
        'Message is required',
        'You have exceeded the maximum number of 200 characters in this field',
      ]}
      margin="normal"
      variant="standard"
    />
  );
};
