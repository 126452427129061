import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { useGlobal, useToast } from 'modules/Global';
import { CompanyDTO } from 'modules/Global/types';
import {
  completeEvent,
  EventsSearchFilterIDs,
  EventsSearchFilterSelection,
} from '../EventsApi';
import {
  EventDTO,
  EventSearchFilterOption,
  EventSearchFilters,
} from '../types';
import { CalendarEventList, OverdueEventList } from './EventLists';
import {
  AggregatedFilters,
  dateRangesEventSearchFilterOptions,
  EventDateFilterOption,
  FilterColumn,
} from './FilterColumn';
import {
  buildEventsOptionsFromFilters,
  getFormattedEvents,
  getFormattedOverdueEvents,
  hasMorePage,
  replaceEventWithinList,
  sortFilterOptions,
  toggleFilterOption,
} from './helpers';

const getDefaultIssuerEventSearchFilterOption = (company: CompanyDTO) => ({
  id: String(company.companyID),
  text: company.name,
  selected: true,
});

interface OwnProps {
  selectedLoanID?: number;
}

export const EventsScreen = ({ selectedLoanID }: OwnProps) => {
  const { activeCompany } = useGlobal();

  const [overduePage, setOverduePage] = useState(0);
  const [page, setPage] = useState(0);
  const [showPaginateButton, setShowPaginateButton] = useState(true);
  const [events, setEvents] = useState<EventDTO[]>([]);
  const [eventsOverdue, setEventsOverdue] = useState<EventDTO[]>([]);
  const [filters, setFilters] = useState<EventSearchFilters>({
    eventTypes: [],
    loanIDs: [],
    issueTypes: [],
    issuers:
      !selectedLoanID && activeCompany
        ? [getDefaultIssuerEventSearchFilterOption(activeCompany)]
        : [],
  });
  const searchID = useRef(1);
  const overdueSearchID = useRef(1);
  const [dateFilters, setDateFilters] = useState<EventDateFilterOption[]>(
    dateRangesEventSearchFilterOptions(),
  );
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingOverdueEvents, setLoadingOverdueEvents] = useState(false);
  const [numberOfEvents, setNumberOfEvents] = useState(0);
  const [numberOfEventsOverdue, setNumberOfEventsOverdue] = useState(0);

  const setFiltersSorted = useCallback(
    (unsortedFilters) =>
      setFilters((prevState) => {
        const combinedFilters = {
          ...prevState,
          ...unsortedFilters,
        };
        return {
          eventTypes: sortFilterOptions(combinedFilters.eventTypes),
          issueTypes: sortFilterOptions(combinedFilters.issueTypes),
          issuers: sortFilterOptions(combinedFilters.issuers),
          loanIDs: sortFilterOptions(combinedFilters.loanIDs),
        };
      }),
    [],
  );
  useEffect(() => {
    let cancel = false;
    if (overduePage === 0) {
      setOverduePage(1);
      return;
    }
    (async (requestedSearchID) => {
      setLoadingOverdueEvents(true);
      const opts = buildEventsOptionsFromFilters(
        overduePage,
        filters,
        selectedLoanID,
        undefined,
        undefined,
      );
      const eventsSearch = await getFormattedOverdueEvents(opts);
      if (cancel) return;
      if (overdueSearchID.current === requestedSearchID) {
        overdueSearchID.current = overdueSearchID.current + 1;
        if (overduePage > 1) {
          setEventsOverdue((prevState) => [...prevState, ...eventsSearch.data]);
        } else {
          setEventsOverdue(eventsSearch.data);
        }
        setNumberOfEventsOverdue(eventsSearch.numberOfEvents);
      }
      setLoadingOverdueEvents(false);
    })(overdueSearchID.current);
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run on overduePage change
  }, [overduePage]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    let cancel = false;
    (async (fetchingSearchID: number) => {
      setLoadingEvents(true);
      const dateFilter = dateFilters.find((df) => df.selected);
      if (!dateFilter) return;

      const opts = buildEventsOptionsFromFilters(
        page,
        filters,
        selectedLoanID,
        dateFilter.fromDate,
        dateFilter.toDate,
      );
      const eventsSearch = await getFormattedEvents(opts);
      if (cancel) return;
      if (searchID.current === fetchingSearchID) {
        searchID.current = searchID.current + 1;
        if (page > 1) {
          setEvents((prevState) => [...prevState, ...eventsSearch.data]);
        } else {
          setEvents(eventsSearch.data);
        }
        setShowPaginateButton(hasMorePage(page, eventsSearch.numberOfEvents));
        setFiltersSorted(eventsSearch.filters);
        setNumberOfEvents(eventsSearch.numberOfEvents);
      }
      setLoadingEvents(false);
    })(searchID.current);
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run on page change
  }, [page]);

  const { showWarning, showSuccess } = useToast();
  const dispatchCompleteEvent = async (event: EventDTO) => {
    try {
      const completedEvent = await completeEvent(event);
      const message = 'Successfully completed the event';
      showSuccess(message);
      return completedEvent;
    } catch (error: any) {
      const message =
        error.response?.data?.message ?? 'Could not complete event!';
      showWarning(message);
      return null;
    }
  };

  const handleCompleteEvent = async (event: EventDTO) => {
    const completedEvent = await dispatchCompleteEvent(event);
    if (completedEvent?.eventID === event.eventID) {
      setEvents(replaceEventWithinList(completedEvent, events));
    }
  };

  const handleCompleteOverdueEvent = async (event: EventDTO) => {
    const completedEvent = await dispatchCompleteEvent(event);
    if (completedEvent?.eventID === event.eventID) {
      setEventsOverdue(replaceEventWithinList(completedEvent, eventsOverdue));
      setOverduePage(0);
    }
  };

  const handleToggle = (
    filterID: EventsSearchFilterIDs,
    filterOption: EventSearchFilterOption,
    selection: EventsSearchFilterSelection,
  ) => {
    setEvents([]);
    setEventsOverdue([]);
    setPage(0);
    setOverduePage(0);
    setFilters((prevState) => {
      const filterOptions = prevState[filterID];
      if (filterOptions) {
        return {
          ...prevState,
          [filterID]: toggleFilterOption(
            filterOptions,
            filterOption,
            selection,
          ),
        };
      }
      return prevState;
    });
  };

  const handleDateChange = (
    id: string,
    fromDate?: moment.Moment,
    toDate?: moment.Moment,
  ) => {
    setEvents([]);
    setPage(0);
    setOverduePage(0);
    setDateFilters((prevState) =>
      prevState.map((df) => ({
        ...df,
        selected: df.id === id,
        fromDate: df.id === 'custom' ? fromDate : df.fromDate,
        toDate: df.id === 'custom' ? toDate : df.toDate,
      })),
    );
  };

  const resetFilters = () => {
    setFilters((prevState) => ({
      eventTypes: !prevState.eventTypes?.length
        ? []
        : toggleFilterOption(
            prevState.eventTypes,
            prevState.eventTypes[0],
            'multiple',
          ),
      issueTypes: !prevState.issueTypes?.length
        ? []
        : toggleFilterOption(
            prevState.issueTypes,
            prevState.issueTypes[0],
            'multiple',
          ),
      loanIDs: !prevState.loanIDs?.length
        ? []
        : toggleFilterOption(
            prevState.loanIDs,
            prevState.loanIDs[0],
            'multiple',
          ),
      issuers: !prevState.issuers?.length
        ? []
        : toggleFilterOption(
            prevState.issuers,
            prevState.issuers[0],
            'multiple',
          ),
    }));
    setEvents([]);
    setPage(0);
    setOverduePage(0);
    setDateFilters((prevState) =>
      prevState.map((df) => ({
        ...df,
        selected: df.id === 'default',
      })),
    );
  };
  const handlePaginate = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const showAggregatedFilters = useMemo( ()=> !selectedLoanID, [selectedLoanID]);
  return (
    <Box mt={2} mb={2} data-searchid={searchID} data-page={page}>
      <Grid container spacing={2}>
        <Grid component="aside" item xs={12} sm={4} lg={3}>
          <FilterColumn
            onReset={resetFilters}
            onToggle={handleToggle}
            onDateChange={handleDateChange}
            filters={filters}
            dateFilters={dateFilters}
            loadingEvents={loadingEvents}
          >
            {showAggregatedFilters && (
              <AggregatedFilters
                filters={filters}
                loadingEvents={loadingEvents}
                onToggle={handleToggle}
              />
            )}
          </FilterColumn>
        </Grid>
        <Grid component="main" item xs={12} sm={8} lg={9}>
          <OverdueEventList
            events={eventsOverdue}
            loading={loadingOverdueEvents}
            completeEvent={handleCompleteOverdueEvent}
            totalNumberOfEvents={numberOfEventsOverdue}
            data-page={overduePage}
          />
          <CalendarEventList
            events={events}
            loading={loadingEvents}
            completeEvent={handleCompleteEvent}
            onPaginate={handlePaginate}
            showPaginateButton={showPaginateButton}
            totalNumberOfEvents={numberOfEvents}
            data-page={page}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
