import { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Container } from '@mui/material';
import ErrorBoundary from 'components/error_screens/ErrorBoundary';
import ErrorScreen from 'components/error_screens/ErrorScreen';
import { LoadingContainer } from 'components/Loaders';
import { LoggedInWrapper } from 'components/LoggedIn';
import {
  AccountSettings,
  ForgotPasswordPage,
  LoginPage,
  ResetAccount,
  SessionExpiredPage,
  useAccount,
  VerifyAccountPage,
} from 'modules/Account';
import { Dashboard } from 'modules/Dashboard';
import { AggregatedDocuments } from 'modules/Documents';
import { AggregatedEvents, EventRedirect } from 'modules/Events';
import { AggregatedFinancials } from 'modules/Financials';
import { PageFooter,PageHeader } from 'modules/Global';
import { Loan, Loans, LoansProvider } from 'modules/Loans';
import { About, Faq, UserAgreement } from 'modules/Static';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';

const LoggedInRoutes: FC = () => {
  const { expiredAccess, logOut } = useAccount();
  const history = useHistory();
  if (expiredAccess()) {
    history.push('/session-expired', { redirectTo: history.location.pathname });
    logOut();
  }
  return (
    <LoggedInWrapper>
      <Route exact path="/dashboard" component={() => <Dashboard />} />
      <Route
        exact
        path="/error-screen"
        component={() => <ErrorScreen error={''} />}
      />
      <Route exact path="/loans" component={() => <Loans />} />
      <Route
        exact
        path="/loans/:loanID"
        render={({ location: { pathname } }) => (
          <Redirect to={`${pathname}/overview`} />
        )}
      />
      <Route path="/loans/:loanID/:tab">
        <LoansProvider>
          <Loan />
        </LoansProvider>
      </Route>
      <Route
        exact
        path="/financials/:tab"
        component={() => <AggregatedFinancials />}
      />
      <Route exact path="/account" component={() => <AccountSettings />} />
      <Route exact path="/account/:tab" component={() => <AccountSettings />} />
      <Route exact path="/events" component={() => <AggregatedEvents />} />
      <Route
        exact
        path="/documents"
        component={() => <AggregatedDocuments />}
      />
      <Route
        exact
        path="/event-action/:eventID"
        component={() => <EventRedirect />}
      />
      <Route exact path="/faq" component={() => <Faq />} />
      <Route
        exact
        path="/"
        render={() => (
          <Redirect to={`/dashboard`} />
        )}
      />
      <Route
        exact
        path="/login"
        render={() => (
          <Redirect to={`/dashboard`} />
        )}
      />
    </LoggedInWrapper>
  );
};

const NotLoggedInRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path="/login" render={() => <LoginPage />} />
      <Route
        exact
        path="/forgot-password"
        render={() => <ForgotPasswordPage />}
      />
      <Route
        render={({ location }) => {
          const { pathname } = location;
          return (
            <Redirect
              to={{
                pathname: `/login`,
                state: {
                  redirectTo: pathname,
                },
              }}
            />
          );
        }}
      />
    </Switch>
  );
};
const RouteSwitch: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => (
  <Switch>
    <Route
      exact
      path="/session-expired"
      render={() => <SessionExpiredPage />}
    />
    <Route exact path="/reset-account" component={ResetAccount} />
    <Route exact path="/verify-account" component={VerifyAccountPage} />
    <Route exact path="/about" component={About} />
    <Route exact path="/terms-of-use" component={UserAgreement} />
    {isLoggedIn ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
  </Switch>
);

export const Routing: FC = () => {
  const {
    isAuthenticated: isLoggedIn,
    logOut,
    user,
    isLoadingUser,
  } = useAccount();
  useGoogleAnalytics(user);
  return (
    <ErrorBoundary render={(error) => <ErrorScreen error={error} />}>
      <PageHeader logOut={logOut} isLoadingUser={isLoadingUser} />
      <ErrorBoundary
        render={(error) => {
          const userHasCompanies = !!user?.companies?.length;
          return (
            <ErrorScreen
              error={error}
              customMessage={
                userHasCompanies
                  ? undefined
                  : 'Your user is not registered with any groups, please'
              }
            />
          );
        }}
      >
        <LoadingContainer
          loading={isLoadingUser}
          type="page"
          rows={0}
          cols={0}
          ContainerComponent={({ children }) => (
            <Container maxWidth="xl">{children}</Container>
          )}
        />
        <RouteSwitch isLoggedIn={isLoggedIn} />
      </ErrorBoundary>
      <PageFooter isLoggedIn={isLoggedIn} />
    </ErrorBoundary>
  );
};
