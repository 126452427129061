import DescriptionIcon from '@mui/icons-material/Description';
import { Button, TableBody, TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DocumentDTO, RelatedLoanDTO } from 'api/api.types';
import moment from 'moment';
import { ScrollableTable } from 'components/Table';
import { downloadDocument } from '../../../DocumentsApi';
import { ReviewCell } from './ReviewCell';
import classes from './DocumentsTable.module.css';

interface ComponentProps {
  documents: DocumentDTO[];
  showReviewed: boolean;
  showUploadedBy: boolean;
  showUploadedDate: boolean;
  showReferenceDate: boolean;
  showIdentifier: boolean;
}

export const DocumentsTable = ({
  documents, showReviewed, showUploadedBy, showUploadedDate, showReferenceDate, showIdentifier,
}: ComponentProps) => (
  <ScrollableTable>
    <TableBody>
      { documents.map((document) => {
        let fileTypeClass;

        switch (document.fileType) {
          case 'pdf':
            fileTypeClass = classes.iconPdf;
            break;
          case 'docx':
            fileTypeClass = classes.iconWord;
            break;
          case 'xlsx':
            fileTypeClass = classes.iconExcel;
            break;
          default:
            fileTypeClass = classes.icon;
            break;
        }

        const shortName = (document.relatedLoans && document.relatedLoans.length > 0
          ? (document.relatedLoans
            .reduce((p: string, c: RelatedLoanDTO) => (p ? `${p}, ${c.shortName}` : c.shortName as string), ''))
          : '');
        const referenceDate = showReferenceDate && document.referenceDate ? moment(document.referenceDate).format('L') : '';
        return (
          <TableRow key={document.documentID} className={classes.row}>
            <TableCell className={classes.tableCellIcon}>
              <Button className={classes.linkButton} onClick={() => downloadDocument(document)}>
                <DescriptionIcon fontSize="small" className={`${fileTypeClass} ${classes.icon}`} />
                <Typography variant="body1" component="span" className={classes.fileTypeButtonText}>{document.fileType}</Typography>
              </Button>
            </TableCell>
            {showUploadedDate && (
              <TableCell className={classes.tableCellDate}>
                <Typography variant="body1">{moment(document.date).format('L')}</Typography>
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <Button className={classes.linkButton} onClick={() => downloadDocument(document)} title={document.name}>
                <Typography variant="body1" component="span" className={classes.buttonText}>{document.name}</Typography>
              </Button>
            </TableCell>
            { showIdentifier && (
              <TableCell title={shortName} className={classes.tableCell}>
                <Typography>{shortName}</Typography>
              </TableCell>
            )}
            { showReferenceDate && (
              <TableCell title={referenceDate} className={classes.tableCell}>
                {
                  referenceDate && (
                    <Typography variant="body1">
                      {'Ref. Date: '}
                      {referenceDate}
                    </Typography>
                  )
                }
              </TableCell>
            ) }
            { showUploadedBy && (
              <TableCell title={document.uploadedBy} className={classes.tableCell}>
                <Typography>
                  {'Uploaded By: '}
                  {document.uploadedBy}
                </Typography>
              </TableCell>
            ) }
            { showReviewed && <ReviewCell isReviewed={document.isReviewed} /> }
          </TableRow>
        );
      })}
    </TableBody>
  </ScrollableTable>
);
