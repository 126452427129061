import { Dispatch, SetStateAction, useState } from 'react';
import Filter from '@mui/icons-material/FilterList';
import { Box, Button, Fade, IconButton, Tooltip } from '@mui/material';
import MultiSelect from 'components/form/selects/multi_select/MultiSelect';
import { MemTextField } from 'components/form/text_fields/TextFields';
import classes from './LoansFilter.module.css';

interface LoansFilterProps {
  numberOfLoans?: number;
  issueTypes: string[];
  issueTypeFilter: string[];
  issueNameFilter: string;
  setIssueTypeFilter: Dispatch<SetStateAction<string[]>>;
  setIssueNameFilter: Dispatch<SetStateAction<string>>;
}

export const LoansFilter = ({
  numberOfLoans,
  issueTypes,
  issueNameFilter,
  issueTypeFilter,
  setIssueNameFilter,
  setIssueTypeFilter,
}: LoansFilterProps) => {
  const [showFilter, setShowFilter] = useState<boolean>(
    numberOfLoans !== undefined && numberOfLoans > 5,
  );
  const rootClasses = `${classes.filterContainer} ${showFilter ? classes.filterContainerOn :''}`;
  return (
    <div className={rootClasses}>
      <Box>
        <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
          <IconButton
            color="default"
            onClick={() => setShowFilter(!showFilter)}
          >
            <Filter />
          </IconButton>
        </Tooltip>
      </Box>
      <Fade in={showFilter}>
        <div className={classes.filterBox}>
          <div className={classes.issueNameInput}>
            <MemTextField
              fullWidth={false}
              boxStyle={{ margin: 0 }}
              value={issueNameFilter}
              onChange={setIssueNameFilter}
              label="ISIN"
              variant="standard"
            />
          </div>
          <div className={classes.filterItem}>
            {issueTypes.length > 1 && (
              <MultiSelect
                values={issueTypes}
                label="Issue Type"
                selectedValues={issueTypeFilter}
                setSelectedValues={setIssueTypeFilter}
                width="200px"
                variant="standard"
              />
            )}
          </div>
          <Button
            onClick={() => {
              setIssueTypeFilter([]);
              setIssueNameFilter('');
            }}
            size="small"
          >
            Clear All
          </Button>
        </div>
      </Fade>
    </div>
  );
};
