
/**
 * Check if the given MIME is a JSON MIME.
 * JSON MIME examples:
 *   application/json
 *   application/json; charset=UTF8
 *   APPLICATION/JSON
 *   application/vnd.company+json
 * @param mime - MIME (Multipurpose Internet Mail Extensions)
 * @return True if the given MIME is JSON, false otherwise.
 */
const isJsonMime = (mime: string): boolean => {
    const jsonMime: RegExp = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
}
export const serializeDataIfNeeded = function (
    value: any,
    requestOptions: any,
) {
    if (!value) {
        return '';
    }
    if (typeof value === 'string') {
        return value;
    }
    if (isJsonMime(requestOptions.headers['Content-Type'])) {
        return JSON.stringify(value);
    } else return '';
};
