interface Contact {
  displayName: string;
  email: string;
}

interface Contacts {
  [key: string]: Contact;
}

const contacts: Contacts = {
  SE: {
    displayName: 'Nordic Trustee Portals',
    email: 'portals@nordictrustee.com',
  },
};

export const getContact = (countryCode: string): Contact => contacts[countryCode];
