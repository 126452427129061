import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { DateBlock } from 'components/DateBlock';
import { EventDTO } from '../../types';
import { EventAction } from './EventActions';
import classes from './EventListItem.module.css';

type EventItem = {
  event: EventDTO;
  completeEvent: (event: EventDTO) => void;
  disabled?: boolean
};

export const EventListItem: React.FC<EventItem> = ({
  event,
  completeEvent,
  disabled,
  children,
}) => {
  return (
    <ListItem
      divider
      data-id="listItem"
      className={classes.listItem}
      disabled={disabled}
    >
      <DateBlock date={event.date} />
      <ListItemText
        primary={<Typography variant="h4">{event.title}</Typography>}
        secondary={
          <>
            <Typography component="span" variant="body1">
              <Box display="flex" alignItems="center" component="span">
                {children}
                {` - ${event.description}`}
              </Box>
            </Typography>
            <Typography
              variant="body1"
              component="span"
              display="block"
              color="textSecondary"
            >
              {event.loanName}
            </Typography>
          </>
        }
      />
      <div className={classes.eventActionsWrapper}>
        <EventAction event={event} completeEvent={completeEvent} />
      </div>
    </ListItem>
  );
};
