import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from 'modules/Global';
import { getReportings } from './ReportingApi';
import { useReporting } from './ReportingContext';
import { ReportingEventDTO } from './reportingApi.types';

export const useReportingItems = () => {
  const { loanID, from, to } = useReporting();
  const [loading, setLoading] = useState(false);
  const [reportings, setReportings] = useState<
    ReportingEventDTO[] | undefined
  >();

  const { showWarning } = useToast();
  const fetchingCompleted = useCallback(
    (reportingDTOs: ReportingEventDTO[]) => {
      setReportings(reportingDTOs);
      setLoading(false);
    },
    [],
  );

  const fetchReportingEvents = useCallback(
    async (calledByUseEffect = false) => {
      setLoading(true);
      try {
        const reportingDTOs = await getReportings(+loanID, from, to);
        if (!calledByUseEffect) {
          fetchingCompleted(reportingDTOs);
        }
        return reportingDTOs;
      } catch (_e) {
        showWarning('There was an error fetching the reporting events.');
        console.error('Error fetching reporting events', _e as AxiosError);
      }
      return [];
    },
    [fetchingCompleted, from, loanID, showWarning, to],
  );

  useEffect(() => {
    if (!from || !to) return;
    let cancel = false;
    (async () => {
      const reportingDTOs = await fetchReportingEvents(true);
      if (cancel) return;
      fetchingCompleted(reportingDTOs);
    })();
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only if reportId changes, from and to changes
  }, [from, to]);

  return { loading, reportingEvents: reportings, fetchReportingEvents };
};
