import { InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Table, TableBody, Tooltip, Typography } from '@mui/material';
import { InterestPaymentItemDTO } from 'api/api.types';
import moment from 'moment';
import { LabelContentRow as Row } from 'components/Table';
import { Money } from '../../../../atoms';
import classes from './PaymentDetailsTable.module.css';

interface ComponentProps {
  interestPayment: InterestPaymentItemDTO;
}

const interpolatedText = 'Interpolated reference rate information';

export const PaymentDetailsTable = ({
   interestPayment,
}: ComponentProps) => {
  const interestPaymentToShow = interestPayment;

  return (
    <Table size="small">
      <TableBody>
        <Row label="Payment Date" alignRight>
          {moment(interestPaymentToShow.paymentDate).format('L')}
        </Row>
        <Row label="Interest Days" alignRight>
          {`${interestPaymentToShow.days}`}
        </Row>
        <Row label="Day Count Convention" alignRight>
          {interestPaymentToShow.dayCountAndBusinessConvention}
        </Row>
        <Row label="Quotation Date" alignRight>
          {interestPaymentToShow.quotationDate ? moment(interestPaymentToShow.quotationDate).format('L') : 'N/A'}
        </Row>
        {interestPaymentToShow.screenRate && (
          <>
            <Row
              label="Screen Rate"
              alignRight
              secondaryRow={(
                interestPaymentToShow.isInterpolated && (
                  <Tooltip title={interpolatedText} placement="top">
                    <Chip size="small" label="Interpolated rate" variant="outlined" className={classes.interpolated} />
                  </Tooltip>
                )
              )}
            >
              {interestPaymentToShow.screenRate}
            </Row>
            <Row label="Margin" alignRight>
              {`${interestPaymentToShow.margin}%`}
            </Row>
            <Row label="Coupon Cap/Floor" alignRight>
              {interestPaymentToShow.couponCapFloor}
            </Row>
          </>
        )}
        <Row label="Coupon" alignRight>
          { interestPaymentToShow.coupon
            ? (
              <>
                {interestPaymentToShow.coupon ? `${interestPaymentToShow.coupon}%` : '-'}
              </>
            )
            : (
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography
                  className={classes.typography}
                  color={interestPaymentToShow.projectedCoupon ? 'secondary' : 'primary'}
                >
                  {interestPaymentToShow.projectedCoupon ? `${interestPaymentToShow.projectedCoupon}%` : '-'}
                  <Tooltip title="Based on latest available relevant reference rate.">
                    <InfoOutlined />
                  </Tooltip>
                </Typography>
              </Box>
            )}
        </Row>
        <Row label="Outstanding Amount" alignRight>
          <Money value={interestPaymentToShow.outstandingAmount} currency={interestPaymentToShow.currency || ''} />
        </Row>
        <Row label="Interest Amount" alignRight>
          <Box fontWeight="fontWeightMedium" component="span" style={{ whiteSpace: 'pre' }}>
            {interestPaymentToShow.interestAmount ? (
              <Money
                value={interestPaymentToShow.interestAmount}
                currency={interestPaymentToShow.currency || ''}
                values={interestPaymentToShow.outstandingAmount ? [interestPaymentToShow.outstandingAmount] : []}
              />
            ) : (
              <Typography
                className={classes.typography}
                color={interestPaymentToShow.projectedCoupon ? 'secondary' : 'primary'}
              >
                <Money
                  value={(interestPaymentToShow.projectedInterestPayment ?? 0) * -1}
                  currency={interestPaymentToShow.currency || ''}
                />
                <Tooltip title="Payment projected based on latest available relevant reference rate.">
                  <InfoOutlined />
                </Tooltip>
              </Typography>
            ) }
          </Box>
        </Row>
      </TableBody>
    </Table>
  );
};
