import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
// TODO: replace the buttons with the Button and set props accordingly
import {
  Card,
  ContainedButton,
  EditButton,
  Tooltip,
} from '@nordictrustee/nt-ui-library';
import { ReportingEventDTO, ReportingStateEnum } from '../reportingApi.types';
import { useReporting } from '../ReportingContext';
import { EventOfDefault } from './EventOfDefault';
import { formatReportingTitle } from './formatReportingsTitle';
import { InformationCovenants } from './InformationCovenants';
import { ReportingRemark } from './ReportingRemark';
import { ReportingSubmitForm } from './ReportingSubmitForm';
import { ReportingUploads } from './ReportingUploads';
import { useReportingDetails } from './useReportingDetails';

export const ReportingDetails: FC<{
  reportingEvent: ReportingEventDTO;
  fetchReportingEvents: (
    calledByUseEffect?: any
  ) => Promise<ReportingEventDTO[]>;
}> = ({ reportingEvent, fetchReportingEvents }) => {
  const { loanID, activeEvent } = useReporting();
  const [isReportingSubmitFormOpen, setIsReportingSubmitFormOpen] = useState(
    false
  );
  const {
    isLoadingReportingDetails,
    hasSubmittedReport,
    reportingDetails,
    fetchReportingDetails,
  } = useReportingDetails(loanID, reportingEvent.id);

  const startReportingButton = useMemo(() => {
    let disabledReason = '';
    if (isLoadingReportingDetails) {
      disabledReason = 'Loading';
    } else if (activeEvent?.reportingState === ReportingStateEnum.Received) {
      disabledReason = `Nordic Trustee has finalised reporting review and edit is blocked.
Please contact Nordic Trustee at mail@nordictrustee.com to enable the edit function including the reason for the request.`;
    }

    const buttonProps = {
      onClick: () => {
        setIsReportingSubmitFormOpen(true);
      },
      disabled: disabledReason !== '',
    };

    return (
      <Tooltip title={disabledReason}>
        <span>
          {hasSubmittedReport ? (
            <EditButton {...buttonProps} />
          ) : (
            <ContainedButton {...buttonProps}>Start Reporting</ContainedButton>
          )}
        </span>
      </Tooltip>
    );
  }, [
    activeEvent?.reportingState,
    hasSubmittedReport,
    isLoadingReportingDetails,
  ]);

  const reportingTitle = useMemo(() => formatReportingTitle(reportingEvent), [
    reportingEvent,
  ]);

  const updateValues = useCallback(async () => {
    await fetchReportingDetails();
    await fetchReportingEvents();
  }, [fetchReportingDetails, fetchReportingEvents]);

  const handleCloseSubmitForm = useCallback(
    async (updatedReportID?: number) => {
      setIsReportingSubmitFormOpen(false);
      if (updatedReportID != null) {
        await updateValues();
      }
    },
    [updateValues]
  );

  const handlePartiallySubmitted = useCallback(async () => {
    await updateValues();
  }, [updateValues]);

  const isLoading = reportingDetails === undefined || isLoadingReportingDetails;

  return (
    <>
      <Card
        title={reportingTitle}
        customAction={startReportingButton}
        data-cy="reporting-details-container"
        withoutPadding
      >
        <Divider />
        <Stack spacing={2} mb={2}>
          <InformationCovenants
            informationCovenants={reportingDetails?.informationCovenants}
            isLoading={isLoading}
          />
          <div>
            <Box my={2} mx={2}>
              <Typography variant="h4">Reporting</Typography>
            </Box>
            <ReportingUploads
              fileUploads={reportingDetails?.complianceDocuments || []}
              isLoading={isLoading}
            />
            <Grid container spacing={2} mx={0} mt={4}>
              <Grid item xs="auto">
                <EventOfDefault
                  hasConfirmedNoEventOfDefault={
                    reportingDetails?.hasConfirmedNoEventOfDefault || null
                  }
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs>
                <ReportingRemark
                  comment={reportingDetails?.comment || ''}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Card>
      {isReportingSubmitFormOpen && (
        <ReportingSubmitForm
          onClose={handleCloseSubmitForm}
          onPartiallySubmitted={handlePartiallySubmitted}
          reportingEvent={reportingEvent}
          reportingDetails={reportingDetails}
        />
      )}
    </>
  );
};
