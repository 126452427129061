import {
  FC,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import {
  loadActiveCompanyState, resetActiveCompany,
  saveActiveCompany
} from './localStorage';
import { CompanyDTO } from './types';
import { HubUserWithCompaniesDTO } from 'modules/Account/AccountApi/api.types';
import { userHasCompanyInCompanies } from './userHasCompanyInCompanies';

type GlobalProviderProps = {
  user?: HubUserWithCompaniesDTO;
}
interface GlobalContextValue extends GlobalProviderProps {
  activeCompany?: CompanyDTO;
  setActiveCompany: (company: CompanyDTO) => void;
}

const GlobalContext = createContext<GlobalContextValue | undefined>(undefined);

export const GlobalProvider: FC<GlobalProviderProps> = ({ user, children }) => {
  const [internalActiveCompany, setInternalActiveCompany] =
    useState<CompanyDTO>(loadActiveCompanyState());

  const activeCompany = useMemo(() => {
    if (!user) return undefined;
    if (internalActiveCompany.companyID !== -1) {
      saveActiveCompany(internalActiveCompany);
    }
    const activeCompanyState = loadActiveCompanyState();
    const storageAndContestHasSameCompany = activeCompanyState.companyID === internalActiveCompany.companyID;
    if(storageAndContestHasSameCompany && userHasCompanyInCompanies(user, activeCompanyState)){
      return activeCompanyState;
    }
    resetActiveCompany();
    const defaultState = (user.companies || [loadActiveCompanyState()])[0];
    setInternalActiveCompany(defaultState);
    return defaultState;
  }, [internalActiveCompany, user]);

  return (
    <GlobalContext.Provider
      value={{ activeCompany, setActiveCompany: setInternalActiveCompany, user }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }
  return context;
};
