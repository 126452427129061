import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import classes from './SkeletonLoader.module.css';

export interface SkeletonProps {
  type:
  | 'page'
  | 'text'
  | 'listWithIcon'
  | 'list'
  | 'singleList'
  | 'chart'
  | 'contactCard'
  | 'squareChart';
  rows: number;
  cols: number;
}

type ComponentProps = SkeletonProps;

interface ListWithIconProps {
  rows: number;
}

interface ListProps {
  rows: number;
  cols: number;
  colsWidth: number;
}

const ListWithIconItem = () => (
  <>
    <Grid item xs={2}>
      <Skeleton variant="rectangular" className={classes.rectIcon} />
    </Grid>
    <Grid item xs={10}>
      <Skeleton height={10} className={classes.listFullLine} />
      <Skeleton height={10} className={classes.shorterLine} />
    </Grid>
  </>
);

const ListWithIcon = ({ rows }: ListWithIconProps) => (
  <>
    {Array.from(Array(rows)).map((row, i) => (
      <ListWithIconItem key={i.toString()} />
    ))}
  </>
);

const ContactCard = () => (
  <>
    <Grid item xs={6}>
      <Skeleton variant="rectangular" className={classes.chartIcon} />
    </Grid>
    <Grid item xs={6}>
      <Skeleton height={10} className={classes.fullLine} />
      <Skeleton height={10} className={classes.fullLine} />
    </Grid>
  </>
);

const Chart = () => (
  <>
    <Grid item xs={2}>
      <Skeleton height={10} className={classes.fullLine} />
      <Skeleton height={10} className={classes.fullLine} />
    </Grid>
    <Grid item xs={3} />
    <Grid item xs={7}>
      <Skeleton variant="circular" className={classes.chartIcon} />
    </Grid>
  </>
);

const SquareChart = () => (
  <Grid item xs={12}>
    <Skeleton variant="rectangular" className={classes.squareChart} />
  </Grid>
);

const SingleListCol = ({ rows, colsWidth }: ListProps) => (
  <>
    {Array.from(Array(rows)).map((_row, i) => (
      <Grid item xs={colsWidth} key={i.toString()}>
        <Skeleton height={10} className={classes.fullLine} />
      </Grid>
    ))}
  </>
);

const SingleList = ({ rows, colsWidth, cols }: ListProps) => (
  <>
    {Array.from(Array(cols)).map((col, i) => (
      <SingleListCol
        rows={rows}
        colsWidth={colsWidth}
        cols={cols}
        key={i.toString()}
      />
    ))}
  </>
);

const ListRows = ({ rows, colsWidth }: ListProps) => (
  <>
    {Array.from(Array(rows)).map((row, j) => (
      // @ts-ignore (
      <Grid item xs={colsWidth} key={j.toString()}>
        <Skeleton height={10} className={classes.fullLine} />
      </Grid>
    ))}
  </>
);

const List = ({ rows, colsWidth, cols }: ListProps) => (
  <>
    {Array.from(Array(cols)).map((col, i) => (
      // @ts-ignore (
      <Grid item xs={colsWidth} key={i.toString()}>
        <Skeleton height={10} className={classes.headerLine} />
      </Grid>
    ))}
    {Array.from(Array(cols)).map((col, i) => (
      <ListRows
        key={i.toString()}
        rows={rows}
        cols={cols}
        colsWidth={colsWidth}
      />
    ))}
  </>
);

export const SkeletonLoader: React.FC<ComponentProps> = ({
  type,
  rows,
  cols,
}) => {
  if (type === 'page') {
    return (
      <Grid className={classes.page}>
        <Skeleton animation="pulse" variant="rectangular">
          <h1 className={classes.pageHeading}>Heading while loading </h1>
        </Skeleton>
        <Box component={'section'} className={classes.pageSection}>
          {['First', 'Second', 'Third'].map((part) => (
            <Skeleton key={part} animation="pulse" variant="rectangular">
              <Box className={classes.pageSectionColumn}>
                <h2>---{part} column---</h2>
              </Box>
            </Skeleton>
          ))}
        </Box>
      </Grid>
    );
  }
  if (type === 'text') {
    const loadingText = Array.from(Array(rows)).map((_row, i) => (
      <Skeleton key={i} animation="wave" variant="text" width={cols * 100} />
    ));
    return <>{loadingText}</>;
  }
  const colsWidth = 12 / cols;
  return (
    <Grid container spacing={2} >
      {type === 'listWithIcon' && <ListWithIcon rows={rows} />}
      {type === 'list' && (
        <List rows={rows} cols={cols} colsWidth={colsWidth} />
      )}
      {type === 'singleList' && (
        <SingleList rows={rows} cols={cols} colsWidth={colsWidth} />
      )}
      {type === 'chart' && <Chart />}
      {type === 'squareChart' && <SquareChart />}
      {type === 'contactCard' && <ContactCard />}
    </Grid>
  );
};
