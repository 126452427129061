import React from 'react';
import { CardContent } from '@mui/material';
import { SubCard } from 'components/Card';
import { MemTextField } from 'components/form/text_fields/TextFields';

interface InvoiceAddressProps{
  street?: string;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  extendedStreet?: string;
  setExtendedStreet: React.Dispatch<React.SetStateAction<string>>;
  postalCode?: string;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  city?: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  country?: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  invoiceEmail?: string;
  setInvoiceEmail: React.Dispatch<React.SetStateAction<string>>;
  invoiceContactPerson?: string;
  setInvoiceContactPerson: React.Dispatch<React.SetStateAction<string>>;
}

const EditInvoiceAddress = ({
  street, setStreet, extendedStreet, setExtendedStreet,
  postalCode, setPostalCode, city, setCity, country, setCountry, invoiceEmail,
  setInvoiceEmail, invoiceContactPerson, setInvoiceContactPerson,
}: InvoiceAddressProps) => (
  <SubCard title="Invoice Address">
    <CardContent>
      <MemTextField label="E-mail Address for Invoices" value={invoiceEmail} onChange={setInvoiceEmail} />
      <MemTextField label="Contact Person for Invoices" value={invoiceContactPerson} onChange={setInvoiceContactPerson} />
      <MemTextField label="Street Address" value={street} onChange={setStreet} />
      <MemTextField label="Extended Street Address" value={extendedStreet} onChange={setExtendedStreet} />
      <MemTextField label="Postal Code" value={postalCode} onChange={setPostalCode} />
      <MemTextField label="City" value={city} onChange={setCity} />
      <MemTextField label="Country" value={country} onChange={setCountry} />
    </CardContent>
  </SubCard>
);

export default EditInvoiceAddress;
