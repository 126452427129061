import { useEffect, useState } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccountingDTO, AccountingItemDTO } from 'api/api.types';
import moment from 'moment';
import { LoadingContainer } from 'components/Loaders';
import { StickyTable } from 'components/Table';
import { FinancialError, Money } from '../atoms';
import { TableHeaderProps } from '../tableHeaderProps';
import { TablePagination } from '../TablePagination';
import classes from './Accounting.module.css';

const headers: TableHeaderProps[] = [
  { title: 'Reporting Date', align: 'left' },
  { title: 'Currency', align: 'left' },
  { title: 'Accrued Interest', align: 'right' },
  { title: 'Loan Balance', align: 'right' },
  { title: '', align: 'right' },
  { title: 'Period', align: 'left' },
  { title: 'Interest Expense', align: 'right' },
];

interface AccountingHeaderRowProps {
  includeLoanName: boolean;
}

const AccountingHeaderRow = ({ includeLoanName }: AccountingHeaderRowProps) => (
  <TableRow>
    {
      includeLoanName && (
        <TableCell component="th" scope="row">
          <Box>
            <Typography variant="h5">Loan</Typography>
          </Box>
        </TableCell>
      )
    }
    {headers.map((header) => (
      <TableCell key={header.title} component="th" scope="row" align={header.align}>
        <Box displayPrint={header.title === '' ? 'none' : 'block'}>
          <Typography variant="h5">{header.title}</Typography>
        </Box>
      </TableCell>
    ))}
  </TableRow>
);

interface AccountingRowProps {
  accountingItem: AccountingItemDTO;
  currency?: string;
  selected: boolean;
  includeLoanName: boolean;
}

const AccountingRow = (({
  accountingItem, currency, selected, includeLoanName,
}: AccountingRowProps) => (
  <TableRow selected={selected} className={classes.row}>
    {
      includeLoanName && (
        <TableCell component="td" scope="row">
          <Typography variant="body1">
            {accountingItem.isin}
          </Typography>
        </TableCell>
      )
    }
    <TableCell component="td" scope="row">
      <Typography variant="body1">
        {moment(accountingItem.date).format('L')}
      </Typography>
    </TableCell>
    <TableCell component="td" scope="row">
      <Typography variant="body1">
        {currency}
      </Typography>
    </TableCell>
    <TableCell component="td" scope="row" align="right">
      <Typography
        className={classes.typography}
        variant="body1"
        color={(accountingItem.accruedInterest === null || accountingItem.accruedInterest === undefined)
          && accountingItem.projectedAccruedInterest ? 'secondary' : 'textPrimary'}
      >
        <Money
          value={
            accountingItem.accruedInterest !== null
            && accountingItem.accruedInterest !== undefined
              ? accountingItem.accruedInterest : accountingItem.projectedAccruedInterest
          }
        />
        {
          ((accountingItem.accruedInterest === null || accountingItem.accruedInterest === undefined))
          && accountingItem.projectedAccruedInterest && (
            <Tooltip title="Accrued interest projected based on latest available relevant reference rate.">
              <InfoOutlined />
            </Tooltip>
          )
        }
      </Typography>
    </TableCell>
    <TableCell component="td" scope="row" align="right">
      <Typography variant="body1">
        <Money
          value={accountingItem.loanBalance}
        />
      </Typography>
    </TableCell>
    <TableCell component="td" scope="row" align="right" />
    <TableCell component="td" scope="row">
      <Typography variant="body1">
        {moment(accountingItem.date).format('MMM')}
      </Typography>
    </TableCell>
    <TableCell component="td" scope="row" align="right">
      <Typography
        className={classes.typography}
        variant="body1"
        color={((accountingItem.interestExpense === null || accountingItem.interestExpense === undefined)
          && accountingItem.projectedInterestExpense) ? 'secondary' : 'textPrimary'}
      >
        <Money
          value={accountingItem.interestExpense ?? accountingItem.projectedInterestExpense}
        />
        {
          ((accountingItem.interestExpense === null || accountingItem.interestExpense === undefined)
          && accountingItem.projectedInterestExpense) && (
            <Tooltip title="Interest expense projected based on latest available relevant reference rate.">
              <InfoOutlined />
            </Tooltip>
          )
        }
      </Typography>
    </TableCell>
  </TableRow>
));

interface OwnProps {
  accounting: AccountingDTO;
  loading: boolean;
  includeLoanName?: boolean;
}

type ComponentProps = OwnProps;

function getDefaultPage(rows: AccountingItemDTO[], pageSize: number) {
  return Math.floor(rows.filter((r) => moment(r.date).diff(moment(), 'days') < 0).length / pageSize);
}

export const Accounting = ({
  accounting, loading, includeLoanName,
}: ComponentProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    setPage(getDefaultPage(accounting.accountingItemDTOs || [], pageSize));
  }, [accounting.accountingItemDTOs, pageSize]);

  return (
    <LoadingContainer loading={loading} type="list" rows={30} cols={6}>
      <Box mt={1}>
        {accounting.errorMessage
          && <FinancialError errorMsg={accounting.errorMessage} />}
        <StickyTable>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <TableHead>
            <AccountingHeaderRow includeLoanName={includeLoanName ?? false} />
          </TableHead>
          <TableBody>
            {accounting.accountingItemDTOs && (
              accounting.accountingItemDTOs
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((accountingItem) => (
                  <AccountingRow
                    key={accountingItem.date + (accountingItem.isin || '')}
                    accountingItem={accountingItem}
                    currency={accountingItem.currency}
                    selected={accountingItem.currentAccountingRow}
                    includeLoanName={includeLoanName ?? false}                 
                  />
                )))}
          </TableBody>
        </StickyTable>
        <TablePagination count={accounting && accounting.accountingItemDTOs ? accounting.accountingItemDTOs.length : 0} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
      </Box>
    </LoadingContainer>
  );
};

