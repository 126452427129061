import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import classes from './ImageModalTrigger.module.css';

interface ImageModalTriggerButtonProps {
  onClick: () => void;
  imageSrc: string;
  width: string;
  alt: string;
}

export const ImageModalTriggerButton = (
  {
    onClick, imageSrc, width, alt,
  }: ImageModalTriggerButtonProps,
) => (
  <ButtonBase onClick={onClick}>
    <img width={width} src={imageSrc} alt={alt} className={classes.image} />
  </ButtonBase>
);
