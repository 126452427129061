import { Dispatch, SetStateAction } from 'react';
import { TablePagination as MuiTablePagination } from '@mui/material';
import classes from './TablePagination.module.css';

interface ComponentProps {
    count: number;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    pageSize: number;
    setPageSize: Dispatch<SetStateAction<number>>;
  }

export const TablePagination = ({
    count, page, setPage, pageSize, setPageSize,
}: ComponentProps) => {
  return (
            <MuiTablePagination
              rowsPerPageOptions={[15, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page}              
              slotProps={{
                actions: { 
                  previousButton: {'aria-label': 'previous page'},
                  nextButton: {'aria-label': 'next page'}
                }
              }}
              onPageChange={(e, p) => setPage(p)}
              onRowsPerPageChange={(e: any) => setPageSize(parseInt(e.target.value, 10))}
              className={classes.tablePagination}
            />
  );
};
