import { Dispatch, SetStateAction } from 'react';
import { CardContent } from '@mui/material';
import { SubCard } from 'components/Card';
import { MemTextField } from 'components/form/text_fields/TextFields';

interface BasicInformationProps{
  companyName?: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  regNumber?: string;
  setRegNumber: Dispatch<SetStateAction<string>>;
  vatNumber?: string;
  setVatNumber: Dispatch<SetStateAction<string>>;
}

const EditBasicInformation = ({
  companyName, setCompanyName, regNumber, setRegNumber, vatNumber, setVatNumber,
}: BasicInformationProps) => (
  <SubCard title="Basic Information">
    <CardContent>
      <MemTextField label="Company Name" value={companyName} onChange={setCompanyName} />
      <MemTextField label="Company Registration Number" value={regNumber} onChange={setRegNumber} />
      <MemTextField label="VAT Number" value={vatNumber} onChange={setVatNumber} />
    </CardContent>
  </SubCard>
);

export default EditBasicInformation;
