import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {
  Box,
  Card,
  CardContent,
  CardProps,
  List,
  Typography,
} from '@mui/material';
import { Button } from '@nordictrustee/nt-ui-library';
import moment from 'moment-timezone';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { daysDiffText } from 'utils/localization/localization';
import { EventDTO } from '../../types';
import { EventListItem } from './EventListItem';
import { isPastEvent } from './isPastEvent';
import { isUpcomingEvent } from './isUpcomingEvent';
import classes from './EventList.module.css';

interface OwnProps {
  events: EventDTO[];
  loading: boolean;
  totalNumberOfEvents: number;
  completeEvent: (event: EventDTO) => void;
  showPaginateButton: boolean;
  onPaginate: () => void;
}

type ComponentProps = OwnProps & CardProps;
export const CalendarEventList = ({
  events,
  loading,
  totalNumberOfEvents,
  completeEvent,
  showPaginateButton,
  onPaginate,
  ...cardProps
}: ComponentProps) => {
  return (
    <Card component="section" className={classes.card} data-cy="main-events" {...cardProps}>
      <StyledCardHeader
        title="Events"
        subheader={
          totalNumberOfEvents > 0 ? `${totalNumberOfEvents} Events` : ''
        }
      />
      <CardContent className={classes.columnContent}>
        {events && (
          <>
            <List data-id="eventsList">
              {events.map((event) => (
                <EventListItem
                  key={event.eventID}
                  event={event}
                  completeEvent={completeEvent}
                  disabled={isPastEvent(event)}
                >
                  {isUpcomingEvent(event) ? (
                    <>
                      <LabelImportantIcon className={classes.upcoming} />
                      {daysDiffText(moment(event.date))}
                    </>
                  ) : (
                    moment(event.date).format('L')
                  )}
                </EventListItem>
              ))}
            </List>
          </>
        )}
        <LoadingContainer
          loading={loading}
          type="listWithIcon"
          rows={15}
          cols={4}
        >
          {!events?.length && <Typography>No events found.</Typography>}
        </LoadingContainer>
        {showPaginateButton && (
          <Box mt={3}>
            <Button
              onClick={onPaginate}
              variant="contained"
              color="primary"
              size="small"
              isLoading={loading}
            >
              View more
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
