import React from 'react';
import { Link } from 'react-router-dom';
import NotesIcon from '@mui/icons-material/Notes';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CaseLogDTO } from 'api/api.types';
import { StylesProps } from 'legacy/types';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { EmptyTablePlaceholder } from 'components/Table';
import MessagesSimpleList from '../messages_simple_list/MessagesSimpleList';

interface ComponentProps {
  loading: boolean;
  loanID: number;
  messages: CaseLogDTO[];
}

const MessagesOverview = (
  {
    classes, messages, loading, loanID,
  }: StylesProps & ComponentProps,
) => {
  const allMessagesURL = `/loans/${loanID}/message-board`;
  return (
    <Card className={classes.card}>
      <StyledCardHeader
        title={<Typography variant="h3">Latest Messages</Typography>}
        action={(
          <Button component={Link} size="small" to={allMessagesURL}>
            All messages
          </Button>
        )}
      />
      <CardContent>
        <LoadingContainer loading={loading} type="listWithIcon" rows={3} cols={0}>
          {(messages.length === 0 ? (
            <EmptyTablePlaceholder
              message="No recent messages"
              icon={<NotesIcon fontSize="large" color="inherit" />}
            />
          )
            : <MessagesSimpleList messages={messages} to={`/loans/${loanID}/message-board`} />
          )}
        </LoadingContainer>
      </CardContent>
    </Card>
  );
};

export default MessagesOverview;
