import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Timezones from 'timezones.json';
import { MaterialUiChangeHandler } from 'legacy/inputHandlers';
import classes from './SelectTimezone.module.css';

interface ComponentProps {
  value?: string;
  changeHandler: MaterialUiChangeHandler<any>;
}

export const SelectTimezone = ({ value, changeHandler }: ComponentProps) => (
  <TextField
    select
    fullWidth
    label="Timezone"
    name="timezone"
    value={value}
    onChange={changeHandler('timezone', (e) => e.target.value)}
    margin="normal"
    className={classes.text}
    variant="standard"
  >
    {Timezones.map((tz) => <MenuItem key={tz.text} value={value && tz.utc.includes(value) ? value : tz.utc[0]}>{tz.text}</MenuItem>)}
  </TextField>
);

