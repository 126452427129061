import { Box, Grid } from '@mui/material';
import { CashFlowDTO, CashFlowItemDTO } from 'api/api.types';
import moment from 'moment';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { FinancialReportingRowTypeEnum } from 'modules/Global/enums';
import { FinancialError, FinancialSubTabs, useFinancialParams } from '../atoms';
import { InterestPaymentChart, PrincipalAmountChart } from '../atoms/charts';
import { CashflowList } from './CashflowList';
import classes from './CashflowOverview.module.css';

const InterestPaymentChartData = (cashflowItems: CashFlowItemDTO[]) => cashflowItems
  .filter((e) => e.eventType === FinancialReportingRowTypeEnum.InterestPayment);

const convertToPositiveAmount = (amount: number | null | undefined) => {
  if (!amount) return 0;
  return amount - amount * 2;
};

interface OwnProps {
  cashflow: CashFlowDTO;
  loading: boolean;
  exchangeToCurrency?: string;
  includeLoanName?: boolean;
}

type ComponentProps = OwnProps;

export const CashflowOverview = ({
  cashflow, loading, exchangeToCurrency, includeLoanName,
}: ComponentProps) => {
  const {subTab} = useFinancialParams();
  return (
    <>
      <StyledCardHeader
        title="Cashflow"        
        action={(
          <FinancialSubTabs />
        )}
      />
        <Grid container spacing={2} px={2}>
          { cashflow.errorMessage && <FinancialError errorMsg={cashflow.errorMessage} />}
          <>
            <Grid item sm={12} md={12} lg={7} className={classes.cashFlowGrid} mt={1}>
              <CashflowList
                cashflow={cashflow}
                loading={loading}
                includeLoanName={includeLoanName ?? false}
                showFutureByDefault={subTab === 'presentfuture'}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={5}>
              <>
                {(cashflow.cashFlowItemDTOs?.length || 0) > 0
              && (
                <>
                  <StyledCardHeader title="Interest Payments" />
                  <Box mx={2}>
                    <LoadingContainer loading={loading} type="squareChart" rows={1} cols={1}>
                      {cashflow.cashFlowItemDTOs && (
                        <InterestPaymentChart
                          chartData={InterestPaymentChartData(cashflow.cashFlowItemDTOs).map((c) => (
                            {
                              date: moment(c.date),
                              amount: convertToPositiveAmount(c.exchangedAmount),
                              dateString: c.date,
                              projectedAmount: convertToPositiveAmount(c.exchangedProjectedInterestPayment),
                            }
                          ))}
                          currency={exchangeToCurrency}
                        />
                      )}
                    </LoadingContainer>
                  </Box>
                  <StyledCardHeader title="Principal Amounts" />
                  <Box mx={2} mb={2}>
                    <LoadingContainer loading={loading} type="squareChart" rows={1} cols={1}>
                      {cashflow.cashFlowItemDTOs && (
                        <PrincipalAmountChart
                          cashFlowItems={cashflow.cashFlowItemDTOs}
                          currency={exchangeToCurrency}
                        />
                      )}
                    </LoadingContainer>
                  </Box>
                </>
              )}
              </>
            </Grid>
          </>
        </Grid>
    </>
  );
};
