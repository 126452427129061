import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { InitialContent } from '@nordictrustee/nt-ui-library';
import { useReporting } from './ReportingContext';
import { ReportingDetails } from './ReportingDetails';
import { ReportingFilter } from './ReportingFilter';
import { ReportingList } from './ReportingList';
import { useReportingItems } from './useReportingItems';

export const Reporting = memo(() => {
  const { reportingEvents, loading, fetchReportingEvents } = useReportingItems();
  const { activeEvent } = useReporting();

  return (
    <Box mt={2}>
      <ReportingFilter />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <ReportingList
            loading={loading}
            reportingEvents={reportingEvents}
          />
        </Grid>
        <Grid item xs={7}>
          {activeEvent ? (
            <ReportingDetails
              reportingEvent={activeEvent}
              fetchReportingEvents={fetchReportingEvents}
            />
          ) : (
            <InitialContent description="Select a reporting date to the left for details" />
          )}
        </Grid>
      </Grid>
    </Box>
  );
});
