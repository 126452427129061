import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface ComponentProps {
  children: any;
}

export const SimplePaper = (
  { children }: ComponentProps,
) => (
  <Paper>
    <Grid
      container
      spacing={0}
      direction="column"
    >
      <Grid item sm={12} md={12} lg={12}>
        <Box m={2}>
          {children}
        </Box>
      </Grid>
    </Grid>
  </Paper>
);

