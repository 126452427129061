import { ReactNode } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import classes from './HighlightListItem.module.css';

export interface HighlightListItemProps{
  primary: ReactNode;
  color?: 'primary' | 'secondary';
}

export const HighlightListItem = ({ primary, color }: HighlightListItemProps) => (
  <ListItem>
    <ListItemIcon>
      <Typography>
        <FiberManualRecordIcon color={color || 'inherit'} className={classes.icon} />
      </Typography>
    </ListItemIcon>
    <ListItemText primary={primary} />
  </ListItem>
);
