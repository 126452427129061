import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Typography, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { BasicLoanDTO } from 'api/api.types';
import moment from 'moment';
import { EventDTO } from 'modules/Events/types';
import classes from './LoanSummaryProgress.module.css';

type LegendFormat = 'year' | 'fullDate' | 'notAvailable';
type LegendDisplay = 'side' | 'below';

interface LegendProps {
  legendFormat?: LegendFormat;
  date: moment.Moment;
  isPerpetualLoan?: boolean;
}
const Legend = ({ legendFormat, date, isPerpetualLoan }: LegendProps) => (
  <>
    {isPerpetualLoan && 'N/A'}
    {!isPerpetualLoan && legendFormat === 'year' && date.format('YYYY')}
    {!isPerpetualLoan && (!legendFormat || legendFormat === 'fullDate') && date.format('L')}
  </>
);

interface ComponentProps {
  events: EventDTO[];
  loan: BasicLoanDTO;
  legendFormat?: LegendFormat;
  legendDisplay: LegendDisplay;
}

const calcDaysDiff = (startDate: moment.Moment, endDate: moment.Moment) => (endDate.unix() - startDate.unix()) / (3600 * 24);

export const LoanSummaryProgress = (
  {
    events, loan, legendFormat, legendDisplay,
  }: ComponentProps,
) => {
  const { issueDate, maturityDate, lastPossibleMaturityDate } = loan;
  if (!issueDate || !maturityDate) return null;

  const yearBeforePerpertualDate: Date = new Date('2098-01-01');
  const isPerpetualLoan = loan.maturityDate
    ? moment(loan.maturityDate).format('YYYY') > moment(yearBeforePerpertualDate).format('YYYY') : false;

  let currentDate = moment();
  const endDate = (lastPossibleMaturityDate && lastPossibleMaturityDate < maturityDate)
    ? moment(lastPossibleMaturityDate)
    : moment(maturityDate);

  if (endDate < currentDate) {
    currentDate = endDate;
  }

  const startDate = moment(issueDate);
  const loanLengthDays = calcDaysDiff(endDate, startDate);
  const currentDay = calcDaysDiff(currentDate, startDate);
  const currentDayPct = (100 * currentDay) / loanLengthDays;

  return (
    <div className={legendDisplay === 'side' ? classes.rootSide : undefined}>
      {legendDisplay === 'side' && (
        <Typography variant="body1" className={classes.startDateBelow}>
          <Legend legendFormat={legendFormat} date={startDate} />
        </Typography>
      )}
      <div className={legendDisplay === 'side' ? classes.progressBarSideContrainer : undefined}>
        <LinearProgress
          value={currentDayPct}
          variant="determinate"
          className={[classes.progressBar, isPerpetualLoan ? classes.diagonalLine : ''].join(' ')}
        />
        {
          events
            .sort((a, b) => moment(a.date).diff(moment(b.date)))
            .filter((event) => moment(event.date) >= startDate && moment(event.date) <= endDate)
            .map((event) => {
              const eventDateIsPerpetualDate = moment(event.date).format('YYYY') > moment(yearBeforePerpertualDate).format('YYYY');
              const eventDate = moment(event.date);
              const eventDay = calcDaysDiff(eventDate, startDate);
              const eventDayPct = (100 * eventDay) / loanLengthDays;
              const isDone = eventDayPct <= currentDayPct;
              const title = `${event.title.includes('New Call') ? event.description : event.title}`
                + ` - ${eventDateIsPerpetualDate ? 'N/A' : eventDate.format('L')}`;
              // Position is percentage on the timeline, minus half the size of the step to center it
              const style = { position: 'absolute' as const, top: '-12px', left: `calc(${eventDayPct}% - 22px)` };

              return (
                <Tooltip key={event.eventID} enterTouchDelay={100} style={style} title={title}>
                  <IconButton color="primary" className={isDone ? classes.button : classes.buttonComplete}>
                    {isDone ? <DoneOutlineIcon fontSize="small" /> : <HourglassEmptyIcon fontSize="small" /> }
                  </IconButton>
                </Tooltip>
              );
            })
        }
      </div>
      {legendDisplay === 'side' && (
        <Typography variant="body1" className={classes.endDateBelow}>
          <Legend legendFormat={legendFormat} date={endDate} isPerpetualLoan={isPerpetualLoan} />
        </Typography>
      )}
      {legendDisplay === 'below' && (
        <Typography variant="body2" className={classes.startDateBelow}>
          <Legend legendFormat={legendFormat} date={startDate} />
        </Typography>
      )}
      {legendDisplay === 'below' && (
        <Typography variant="body2" className={classes.endDateBelow}>
          <Legend legendFormat={legendFormat} date={endDate} isPerpetualLoan={isPerpetualLoan} />
        </Typography>
      )}
    </div>
  );
};

