import { useState } from 'react';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { FinancialOverviewRowDTO } from 'api/api.types';
import moment from 'moment';
import { LoansFilter } from 'modules/Loans';
import { Money } from '../atoms';

interface ComponentProps {
  financialOverviewRows: FinancialOverviewRowDTO[] | undefined;
}

const getIssueTypes = (loans: FinancialOverviewRowDTO[] | undefined): string[] => {
  if (loans === undefined) {
    return [];
  }

  return Array.from(
    new Set(loans.filter((l) => l.issueType !== null && l.issueType !== undefined).map((l) => l.issueType)),
  ) as string[];
};

const getFilteredIssues = (loans: FinancialOverviewRowDTO[] | undefined, typeFilter: string[], nameFilter: string) => {
  if (loans === undefined) {
    return undefined;
  }

  const loansFilteredByName = loans.filter(
    (l) => l.isin?.toLowerCase().includes(nameFilter.toLowerCase()),
  );

  if (typeFilter === null || typeFilter === undefined || typeFilter.length === 0) {
    return loansFilteredByName;
  }

  return loansFilteredByName.filter((l) => typeFilter.includes(l.issueType || ''));
};

interface ManagerChipProps {
  managers: string[] | undefined;
}

const ManagerChip = ({ managers }: ManagerChipProps) => {
  const hasMulti = managers && managers.length > 1;
  const tooltip = managers ? managers.join(', ') : '';
  const [multiClicked, setMultiClicked] = useState(false);

  return (
    <div>
      { hasMulti && !multiClicked
        && (
          <Tooltip title={tooltip}>
            <Chip onClick={() => setMultiClicked(!multiClicked)} clickable label="Multiple" />
          </Tooltip>
        )}
      {
        hasMulti && multiClicked
        && (
          <div>{tooltip}</div>
        )
      }
      { !hasMulti && (managers ? managers[0] : 'N/A')}
    </div>
  );
};

export const FinancialOverviewPortfolio = ({ financialOverviewRows }: ComponentProps) => {
  const [issueTypeFilter, setIssueTypeFilter] = useState<string[]>([]);
  const [issueNameFilter, setIssueNameFilter] = useState<string>('');
  const issueTypes = getIssueTypes(financialOverviewRows);
  return (
    <>
      <LoansFilter
        numberOfLoans={financialOverviewRows?.length}
        issueTypes={issueTypes}
        issueTypeFilter={issueTypeFilter}
        setIssueTypeFilter={setIssueTypeFilter}
        issueNameFilter={issueNameFilter}
        setIssueNameFilter={setIssueNameFilter}
      />
      <TableContainer>
        <Table aria-label="Company Portfolio" size="small">
          <TableHead>
            <TableRow>
              <TableCell>ISIN</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Maturity Date</TableCell>
              <TableCell>Interest Type</TableCell>
              <TableCell>Next Term Date</TableCell>
              <TableCell>Days To Term</TableCell>
              <TableCell>Coupon</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell align="right">Outst. Amt</TableCell>
              <TableCell align="right">Max Amt</TableCell>
              <TableCell>Manager(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFilteredIssues(financialOverviewRows, issueTypeFilter, issueNameFilter)?.map((row) => (
              <TableRow key={row.isin}>
                <TableCell>{row.isin}</TableCell>
                <TableCell>{row.issueType}</TableCell>
                <TableCell>{moment(row.maturityDate).format('L')}</TableCell>
                <TableCell>{row.interestType}</TableCell>
                <TableCell>{moment(row.nextTermDate).format('L')}</TableCell>
                <TableCell>{row.daysToNextTerm}</TableCell>
                <TableCell>{row.coupon ? `${row.coupon}%` : 'N/A'}</TableCell>
                <TableCell>{row.currency}</TableCell>
                <TableCell align="right">
                  <Money value={row.outstandingAmount} />
                </TableCell>
                <TableCell align="right">
                  <Money value={row.maximumAmount} />
                </TableCell>
                <TableCell>
                  <ManagerChip managers={row?.managers} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
