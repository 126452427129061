import { FC } from 'react';
import {
  DocumentSearchFilterOption,
  DocumentSearchFilters,
} from 'api/api.types';
import {
  DocumentsSearchFilterIDs,
  DocumentsSearchFilterSelection,
} from './DocumentsFilter.types';
import { DocumentsFilterList } from './DocumentsFilterList';

type Props = {
  handleToggle: (
    filterID: DocumentsSearchFilterIDs,
    filterOption: DocumentSearchFilterOption,
    selection: DocumentsSearchFilterSelection,
  ) => void;
  loadingDocuments: boolean;
} & DocumentSearchFilters;

export const DocumentsFilter: FC<Props> = ({
  handleToggle,
  loadingDocuments,
  ...filters
}) => {
  return (
    <>
      {filters.companyIDs && filters.companyIDs.length > 2 && (
        <DocumentsFilterList
          filterID="companyIDs"
          filterOptions={filters.companyIDs}
          handleToggle={handleToggle}
          selection="multiple"
          loading={loadingDocuments}
        />
      )}
      {filters.issueIDs && (
        <DocumentsFilterList
          filterID="issueIDs"
          filterOptions={filters.issueIDs}
          handleToggle={handleToggle}
          selection="multiple"
          loading={loadingDocuments}
        />
      )}
    </>
  );
};
