import CardContent from '@mui/material/CardContent';
import { LoanDTO } from 'api/api.types';
import { SubCard } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { LoanAmountChart } from './LoanAmountChart';
import classes from './LoanOverviewAmountChart.module.css';

interface ComponentProps {
  loan: LoanDTO;
  loading: boolean;
}

export const LoanOverviewAmountChart = ({
  loan, loading,
}: ComponentProps) => (
  <SubCard>
    <CardContent className={classes.content}>
      <LoadingContainer loading={loading} type="chart" rows={0} cols={0}>
        <LoanAmountChart outstandingAmount={loan.outstandingAmount} maximumAmount={loan.maximumAmount} currency={loan.currency} />
      </LoadingContainer>
    </CardContent>
  </SubCard>
);
