import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import React, {useMemo, useState} from "react";
import classes from "./ButtonPopper.module.css";

export const ButtonPopper: React.FC<{
  buttonContent: React.ReactNode,
  open: boolean, setOpen: (open: boolean) => void
}> = ({buttonContent, open, setOpen, children})=> {
  const buttonRef = useMemo( ()=> React.createRef<HTMLButtonElement>(), []);
  return (
    <>
      <Button
        color="inherit"
        ref={buttonRef}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(!open)}
        className={classes.button}
        size="small"
      >
        {buttonContent}
        <ArrowDropDownIcon />
      </Button>
      <Popper open={open} placement="bottom-end" anchorEl={() => buttonRef.current as HTMLElement} className={classes.dropdown}>
        <Paper>
          <ClickAwayListener onClickAway={(_e:any) => setOpen(false)}>
            <MenuList>
              {children}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}