import { FC, useCallback } from 'react';
import { useToast } from 'modules/Global';
import { changePassword } from '../../../AccountApi';
import { ConfigurePassword } from '../../../atoms';

interface OwnProps {
  userName?: string;
  onSubmitted: ()=> void;
}

type ComponentProps = OwnProps;

export const ChangeUserPassword: FC<ComponentProps> = ({ userName, onSubmitted }) => {
  const { showWarning, showSuccess } = useToast();
  const handleSubmit = useCallback(async (password?: string, currentPassword?: string) => {
    try {
      if (currentPassword && password) {
        if (!await changePassword(currentPassword, password)) {
          showWarning('We couldn\'t change your password - did you enter the correct current password?');
          return;
        }
        showSuccess('Your password has been updated.');
        onSubmitted();
      }
    } catch (e) {
      showWarning('We couldn\'t change your password - did you enter the correct current password?');
    }
  }, [onSubmitted, showSuccess, showWarning]);

  return (
      <ConfigurePassword
          boxless
          enterExistingPassword
          text="Please enter your existing credentials along with your desired new password."
          submitTitle="Change password"
          onSubmit={handleSubmit}
          userName={userName}
      />
  );
}
