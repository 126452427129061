import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { PageHeaderButton } from './PageHeaderButton';
import classes from '../PageHeader.module.css';

export const VisitorMenu: FC = () => {
  const history = useHistory();
  const { pathname } = history.location;
  return (
    <>
      <Box className={classes.pageHeaderLeftSide} />
      {pathname !== '/login' && (
        <PageHeaderButton onClick={() => history.push('/login')}>
          Log in
        </PageHeaderButton>
      )}
    </>
  );
};
