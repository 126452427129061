import { Chart } from '@nordictrustee/nt-ui-library';
import moment, { Moment } from 'moment';
import theme from 'modules/Global/theme';
import { formatMoney, humanizeMoney } from 'utils/money';

interface ComponentProps {
  chartData: CData[];
  currency?: string;
}

export interface CData {
  date: Moment;
  dateString: string | undefined;
  amount: number;
  projectedAmount: number | null | undefined;
}

const { Bar } = Chart.RC2;
type TooltipItem = Chart.Chartjs.TooltipItem<'bar'>;
type ChartData = Chart.Chartjs.ChartData<'bar'>;
type ChartOptions = Chart.Chartjs.ChartOptions<'bar'>;
type ChartDataset = Chart.Chartjs.ChartDataset<'bar'>;
  
export const InterestPaymentChart = ({ chartData, currency }: ComponentProps) => {
  const {
    palette: { primary, secondary },
  } = theme;

  const historicIdx = chartData.findIndex((c) => c.date.isAfter(moment()));
  const past = chartData.slice(0, historicIdx);
  const future = chartData.slice(-(chartData.length - historicIdx));

  const interestPayments: ChartDataset = {
    label: 'Interest Payments',
    data: chartData.map((d) =>
      d.projectedAmount && d.amount === 0 ? d.projectedAmount : d.amount,
    ),
    backgroundColor: past
      .map(() => primary.main)
      .concat(
        future.map((m) => (m.projectedAmount ? '#e87f383d' : '#3f18aa33')),
      ),
  };

  const dataPayments: ChartData = {
    labels: chartData.map((d) => d.date.format('L')),
    datasets: [interestPayments],
  };

  const paymentOptions: ChartOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label(tooltipItem: TooltipItem) {
            const label: number = tooltipItem.raw as number;
            return formatMoney(label, currency);
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0)',
        },
        beginAtZero: true,
        ticks: {
          callback: (value: number | string) => {
            return humanizeMoney(currency || '', +value);
          },
        },
      },
    },
  };

  return <Bar data={dataPayments} options={paymentOptions} />;
};

