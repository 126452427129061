import { CashFlowItemDTO } from 'api/api.types';
import moment from 'moment/moment';
import { DataPoint } from './PrincipalAmountChart.types';

export const getPast = (cashflowItems: CashFlowItemDTO[]): DataPoint[] => {
  const returnArray = [];
  let sum = 0;

  const pastAmounts = cashflowItems.filter(
    (val) => moment(val.date).isBefore(moment()) && val.eventType === 'Tranche',
  );

  pastAmounts.forEach((pa: CashFlowItemDTO) => {
    if (pa.exchangedAmount) {
      sum += pa.exchangedAmount;
    }
    const elm = [{ x: moment(pa.date).toISOString(), y: sum }];
    returnArray.push(...elm);
  });
  returnArray.push({ x: moment().toISOString(), y: sum }); // now
  return returnArray;
};
