import { CaseLogsApi } from 'api';

const MessageApiInstance = new CaseLogsApi();

export interface MessageToAdd {
  loanID: number;
  message: string;
}

export interface MessageToDelete {
  loanID: number;
  caseLogEntryID: number;
}

export default async function createMessage(message: MessageToAdd) {
  const res = await MessageApiInstance.addNewLogEntry(
    message.loanID,
    {
      textEntry: message.message,
    },
  );
  return res;
}

export async function deleteMessage(messageToDelete: MessageToDelete) {
  const res = await MessageApiInstance.deleteLogEntry(
    messageToDelete.loanID,
    messageToDelete.caseLogEntryID,
  );
  return (res as any)?.data;
}
