import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { useLoans } from '../LoansContext';
import { LoanTabs } from './LoanTabs';
import { LoanRoutes } from './LoanRoutes';

export const Loan: FC = () => {
  const { loanID} = useParams<{ loanID: string; tab: string }>();
  const { loan, isLoadingLoan } = useLoans();

  return (
    <>
      <LoanTabs />
      <Container maxWidth="xl">
        <LoanRoutes
          loanID={loanID}
          loan={loan}
          loadingLoanDetails={isLoadingLoan}
        />
      </Container>
    </>
  );
};
