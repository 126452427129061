import React, { useEffect,useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AccountingDTO, AccountingItemDTO } from 'api/api.types';
import moment from 'moment';
import { FinancialError, Money } from '../atoms';
import { LoadingContainer } from 'components/Loaders';
import { StickyTable } from 'components/Table';
import { TableHeaderProps } from '../tableHeaderProps';
import { TablePagination } from '../TablePagination';
import classes from './Accounting.module.css';

const headers: TableHeaderProps[] = [
  { title: 'Details', align: 'left' },
  { title: 'Reporting Date', align: 'left' },
  { title: 'Currency', align: 'left' },
  { title: 'Accrued Interest', align: 'right' },
  { title: 'Loan Balance', align: 'right' },
  { title: '', align: 'right' },
  { title: 'Period', align: 'left' },
  { title: 'Interest Expense', align: 'right' },
];

const AccountingHeaderRow = () => (
  <TableRow>
    {headers.map((header) => (
      <TableCell key={header.title} component="th" scope="row" align={header.align}>
        <Box displayPrint={header.title === '' ? 'none' : 'block'}>
          <Typography variant="h5">{header.title}</Typography>
        </Box>
      </TableCell>
    ))}
  </TableRow>
);

interface AccountingRowProps {
  accountingItem: AccountingItemDTO;
  accountingItems: AccountingItemDTO[] | undefined;
  currency?: string;
  selected: boolean;
}

interface SubAccountingRowProps {
  accountingItem: AccountingItemDTO;
}

interface OwnProps {
  accounting: AccountingDTO;
  loading: boolean;
}

type ComponentProps = OwnProps;

const SubAccountingRow = (({
  accountingItem,
}: SubAccountingRowProps) => (
  <Box margin={0}>
    <Table size="small">
      <colgroup>
        <col style={{ width: '15%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>
      <TableHead />
      <TableBody>
        {accountingItem.accountingItems?.map((am, index) => (
          <TableRow key={`SUBROW_${am.date}${am.isin || ''}${index.toString()}`} selected>
            <TableCell component="td" scope="row">
              <Typography variant="body1">
                {am.isin}
              </Typography>
            </TableCell>
            <TableCell component="td" scope="row">
              <Typography variant="body1">
                {moment(am.date).format('L')}
              </Typography>
            </TableCell>
            <TableCell component="td" scope="row">
              <Typography variant="body1">
                {am.currency}
              </Typography>
            </TableCell>
            <TableCell component="td" scope="row" align="right">
              <Typography className={classes.typography} variant="body1" color={am.accruedInterest ? 'textPrimary' : 'secondary'}>
                <Money
                  value={am.accruedInterest ?? am.projectedAccruedInterest}
                />
                {
                  !am.accruedInterest && (
                    <Tooltip title="Accrued interest projected based on latest available relevant reference rate.">
                      <InfoOutlined />
                    </Tooltip>
                  )
                }
              </Typography>
            </TableCell>
            <TableCell component="td" scope="row" align="right">
              <Typography variant="body1">
                <Money
                  value={am.loanBalance}
                />
              </Typography>
            </TableCell>
            <TableCell component="td" scope="row" align="right" />
            <TableCell component="td" scope="row">
              <Typography variant="body1">
                {moment(am.date).format('MMM')}
              </Typography>
            </TableCell>
            <TableCell component="td" scope="row" align="right">
              <Typography className={classes.typography} variant="body1" color={am.interestExpense ? 'textPrimary' : 'secondary'}>
                <Money
                  value={am.interestExpense ?? am.projectedInterestExpense}
                />
                {
                  !am.interestExpense && (
                    <Tooltip title="Interest expense projected based on latest available relevant reference rate.">
                      <InfoOutlined />
                    </Tooltip>
                  )
                }
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
));

const AccountingRow = (({
  accountingItem, currency, selected,
}: AccountingRowProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow selected={selected} className={classes.row}>
        <TableCell component="td" scope="row">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="body1">
            {moment(accountingItem.date).format('L')}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="body1">
            {currency}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <Typography
            className={classes.typography}
            variant="body1"
            color={accountingItem.accountingItems?.some((a) => a.projectedAccruedInterest) ? 'secondary' : 'textPrimary'}
          >
            <Money
              value={accountingItem.accruedInterest ?? accountingItem.projectedAccruedInterest}
            />
            {
              accountingItem.accountingItems?.some((a) => a.projectedAccruedInterest) && (
                <Tooltip title="Accrued interest projected based on latest available relevant reference rate.">
                  <InfoOutlined />
                </Tooltip>
              )
            }
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <Typography variant="body1">
            <Money
              value={accountingItem.loanBalance}
            />
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row" align="right" />
        <TableCell component="td" scope="row">
          <Typography variant="body1">
            {moment(accountingItem.date).format('MMM')}
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row" align="right">
          <Typography
            className={classes.typography}
            variant="body1"
            color={accountingItem.accountingItems?.some((a) => a.projectedInterestExpense) ? 'secondary' : 'textPrimary'}
          >
            <Money
              value={accountingItem.interestExpense ?? accountingItem.projectedInterestExpense}
            />
            {
              accountingItem.accountingItems?.some((a) => a.projectedInterestExpense) && (
                <Tooltip title="Interest expense projected based on latest available relevant reference rate.">
                  <InfoOutlined />
                </Tooltip>
              )
            }
          </Typography>
        </TableCell>
      </TableRow>
      {
        open
                && (
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: 0,
                      }}
                      colSpan={8}
                    >
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <SubAccountingRow accountingItem={accountingItem} />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )
      }
    </>
  );
});

function getDefaultPage(rows: AccountingItemDTO[], pageSize: number) {
  return Math.floor(rows.filter((r) => moment(r.date).diff(moment(), 'days') < 0).length / pageSize);
}

export const AccountingCollapsible = ({
  accounting, loading,
}: ComponentProps) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    setPage(getDefaultPage(accounting.accountingItemDTOs || [], pageSize));
  }, [accounting.accountingItemDTOs, pageSize]);

  return (
    <LoadingContainer loading={loading} type="list" rows={30} cols={6}>
        {accounting.errorMessage
                    && <FinancialError errorMsg={accounting.errorMessage} />}
        <StickyTable>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <TableHead>
            <AccountingHeaderRow />
          </TableHead>
          <TableBody>
            {accounting.accountingItemDTOs && (
              accounting.accountingItemDTOs
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((accountingItem) => (
                  <AccountingRow
                    key={`MAINROW_${accountingItem.date}${accountingItem.isin || ''}`}
                    accountingItem={accountingItem}
                    accountingItems={accounting.accountingItemDTOs}
                    currency={accountingItem.currency}
                    selected={accountingItem.currentAccountingRow}
                  />
                )))}
          </TableBody>
        </StickyTable>
        <TablePagination count={accounting && accounting.accountingItemDTOs ? accounting.accountingItemDTOs.length : 0} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />        
    </LoadingContainer>
  );
};

export default (AccountingCollapsible);
