import globalAxios, { AxiosRequestConfig } from 'axios';
import { BASE_PATH } from './const';
import { setSearchParams } from './setSearchParams';
import { createRequestFunction } from './createRequestFunction';
import { assertParamExists } from './assertParamExists';
import { serializeDataIfNeeded } from './serializeDataIfNeeded';
import { BalanceSheetRequestDTO, NewRemarkEntry } from './api.types';
import {
  CurrencyCodeUniverseEnum,
  HubDocumentTypeUniverseEnum,
} from 'modules/Global/enums';
import { CompanyContactDetailsDTO } from 'modules/Global/types';
import {
  ReportingSubmitNewDTO,
  ReportingSubmitUpdateDTO,
} from 'modules/Loans/Loan/Reporting/reportingApi.types';

export const BalanceSheetApiAxiosParamCreator = function () {
  return {
    handleBalanceSheetRequest: async (
      balanceSheetRequestDTO?: BalanceSheetRequestDTO,
      options: AxiosRequestConfig = {},
    ) => {
      const localVarPath = `/api/balancesheet`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        balanceSheetRequestDTO,
        localVarRequestOptions,
      );
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const BalanceSheetApiFp = function () {
  const localVarAxiosParamCreator = BalanceSheetApiAxiosParamCreator();
  return {
    async handleBalanceSheetRequest(
      balanceSheetRequestDTO?: BalanceSheetRequestDTO,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handleBalanceSheetRequest(
          balanceSheetRequestDTO,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};
export class BalanceSheetApi {
  public handleBalanceSheetRequest(
    balanceSheetRequestDTO?: BalanceSheetRequestDTO,
    options?: AxiosRequestConfig,
  ) {
    return BalanceSheetApiFp()
      .handleBalanceSheetRequest(balanceSheetRequestDTO, options)
      .then((request) => request(globalAxios));
  }
}

export const CallsApiAxiosParamCreator = function () {
  return {
    getCallsForLoan: async (
      loanId: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('getCallsForLoan', 'loanId', loanId);
      const localVarPath = `/api/loans/${loanId}/calls`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const CallsApiFp = function () {
  const localVarAxiosParamCreator = CallsApiAxiosParamCreator();
  return {
    async getCallsForLoan(loanId: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCallsForLoan(
        loanId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};
export class CallsApi {
  public getCallsForLoan(loanId: number, options?: AxiosRequestConfig) {
    return CallsApiFp()
      .getCallsForLoan(loanId, options)
      .then((request) => request(globalAxios));
  }
}

export const CaseLogsApiAxiosParamCreator = function () {
  return {
    addNewLogEntry: async (
      loanId: number,
      newRemarkEntry?: NewRemarkEntry,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('addNewLogEntry', 'loanId', loanId);
      const localVarPath = `/api/loans/${loanId}/caselogs`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newRemarkEntry,
        localVarRequestOptions,
      );
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    deleteLogEntry: async (
      loanId: number,
      loanRemarkId: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('deleteLogEntry', 'loanId', loanId);
      // verify required parameter 'loanRemarkId' is not null or undefined
      assertParamExists('deleteLogEntry', 'loanRemarkId', loanRemarkId);
      const localVarPath = `/api/loans/${loanId}/caselogs/${loanRemarkId}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'DELETE', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getCaseLogsByLoanID: async (
      loanId: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('getCaseLogsByLoanID', 'loanId', loanId);
      const localVarPath = `/api/loans/${loanId}/caselogs`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getRemarksByLoanID: async (
      loanId: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('getRemarksByLoanID', 'loanId', loanId);
      const localVarPath = `/api/loans/${loanId}/remarks`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const CaseLogsApiFp = function () {
  const localVarAxiosParamCreator = CaseLogsApiAxiosParamCreator();
  return {
    async addNewLogEntry(
      loanId: number,
      newRemarkEntry?: NewRemarkEntry,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addNewLogEntry(
        loanId,
        newRemarkEntry,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async deleteLogEntry(
      loanId: number,
      loanRemarkId: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLogEntry(
        loanId,
        loanRemarkId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getCaseLogsByLoanID(
      loanId: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCaseLogsByLoanID(
          loanId,
          limit,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getRemarksByLoanID(
      loanId: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRemarksByLoanID(
          loanId,
          limit,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};

export class CaseLogsApi {
  public addNewLogEntry(
    loanId: number,
    newRemarkEntry?: NewRemarkEntry,
    options?: AxiosRequestConfig,
  ) {
    return CaseLogsApiFp()
      .addNewLogEntry(loanId, newRemarkEntry, options)
      .then((request) => request(globalAxios));
  }
  public deleteLogEntry(
    loanId: number,
    loanRemarkId: number,
    options?: AxiosRequestConfig,
  ) {
    return CaseLogsApiFp()
      .deleteLogEntry(loanId, loanRemarkId, options)
      .then((request) => request(globalAxios));
  }
  public getCaseLogsByLoanID(
    loanId: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return CaseLogsApiFp()
      .getCaseLogsByLoanID(loanId, limit, options)
      .then((request) => request(globalAxios));
  }
  public getRemarksByLoanID(
    loanId: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return CaseLogsApiFp()
      .getRemarksByLoanID(loanId, limit, options)
      .then((request) => request(globalAxios));
  }
}

export const CompaniesApiAxiosParamCreator = function () {
  return {
    getCompany: async (
      companyFunctionID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'companyFunctionID' is not null or undefined
      assertParamExists('getCompany', 'companyFunctionID', companyFunctionID);
      const localVarPath = `/api/companies/${companyFunctionID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getCompanyContactDetails: async (
      companyFunctionID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'companyFunctionID' is not null or undefined
      assertParamExists(
        'getCompanyContactDetails',
        'companyFunctionID',
        companyFunctionID,
      );
      const localVarPath = `/api/companies/${companyFunctionID}/contactdetails`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getLogoForCompany: async (
      companyFunctionID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'companyFunctionID' is not null or undefined
      assertParamExists(
        'getLogoForCompany',
        'companyFunctionID',
        companyFunctionID,
      );
      const localVarPath = `/api/companies/${companyFunctionID}/logo`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    requestCompanyContactUpdate: async (
      companyFunctionID: number,
      companyContactDetailsDTO?: CompanyContactDetailsDTO,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'companyFunctionID' is not null or undefined
      assertParamExists(
        'requestCompanyContactUpdate',
        'companyFunctionID',
        companyFunctionID,
      );
      const localVarPath = `/api/companies/${companyFunctionID}/contactdetails`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        companyContactDetailsDTO,
        localVarRequestOptions,
      );
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const CompaniesApiFp = function () {
  const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator();
  return {
    async getCompany(companyFunctionID: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(
        companyFunctionID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getCompanyContactDetails(
      companyFunctionID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCompanyContactDetails(
          companyFunctionID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getLogoForCompany(
      companyFunctionID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLogoForCompany(
          companyFunctionID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async requestCompanyContactUpdate(
      companyFunctionID: number,
      companyContactDetailsDTO?: CompanyContactDetailsDTO,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestCompanyContactUpdate(
          companyFunctionID,
          companyContactDetailsDTO,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};

export class CompaniesApi {
  public getCompany(companyFunctionID: number, options?: AxiosRequestConfig) {
    return CompaniesApiFp()
      .getCompany(companyFunctionID, options)
      .then((request) => request(globalAxios));
  }
  public getCompanyContactDetails(
    companyFunctionID: number,
    options?: AxiosRequestConfig,
  ) {
    return CompaniesApiFp()
      .getCompanyContactDetails(companyFunctionID, options)
      .then((request) => request(globalAxios));
  }
  public getLogoForCompany(
    companyFunctionID: number,
    options?: AxiosRequestConfig,
  ) {
    return CompaniesApiFp()
      .getLogoForCompany(companyFunctionID, options)
      .then((request) => request(globalAxios));
  }
  public requestCompanyContactUpdate(
    companyFunctionID: number,
    companyContactDetailsDTO?: CompanyContactDetailsDTO,
    options?: AxiosRequestConfig,
  ) {
    return CompaniesApiFp()
      .requestCompanyContactUpdate(
        companyFunctionID,
        companyContactDetailsDTO,
        options,
      )
      .then((request) => request(globalAxios));
  }
}

export const DocumentsApiAxiosParamCreator = function () {
  return {
    downloadLoanDocument: async (
      documentToken?: string,
      options: AxiosRequestConfig = {},
    ) => {
      const localVarPath = `/api/documents/download`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (documentToken !== undefined) {
        localVarQueryParameter['documentToken'] = documentToken;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    generateDocumentToken: async (
      documentType: HubDocumentTypeUniverseEnum,
      documentID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'documentType' is not null or undefined
      assertParamExists('generateDocumentToken', 'documentType', documentType);
      // verify required parameter 'documentID' is not null or undefined
      assertParamExists('generateDocumentToken', 'documentID', documentID);
      const localVarPath = `/api/documents/${documentType}/${documentID}/token`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getFilteredDocuments: async (
      companyIDs?: Array<number>,
      issueIDs?: Array<number>,
      options: AxiosRequestConfig = {},
    ) => {
      const localVarPath = `/api/documents`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (companyIDs) {
        localVarQueryParameter['companyIDs'] = companyIDs;
      }
      if (issueIDs) {
        localVarQueryParameter['issueIDs'] = issueIDs;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getLatestDocumentsForCompany: async (
      issuerID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issuerID' is not null or undefined
      assertParamExists('getLatestDocumentsForCompany', 'issuerID', issuerID);
      const localVarPath = `/api/documents/company/${issuerID}/latest`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getLoanDocuments: async (
      issuerID: number,
      issueID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issuerID' is not null or undefined
      assertParamExists('getLoanDocuments', 'issuerID', issuerID);
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getLoanDocuments', 'issueID', issueID);
      const localVarPath = `/api/documents/${issuerID}/${issueID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};

const DocumentsApiFp = function () {
  const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator();
  return {
    async downloadLoanDocument(
      documentToken?: string,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadLoanDocument(
          documentToken,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async generateDocumentToken(
      documentType: HubDocumentTypeUniverseEnum,
      documentID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateDocumentToken(
          documentType,
          documentID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getFilteredDocuments(
      companyIDs?: Array<number>,
      issueIDs?: Array<number>,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFilteredDocuments(
          companyIDs,
          issueIDs,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getLatestDocumentsForCompany(
      issuerID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLatestDocumentsForCompany(
          issuerID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getLoanDocuments(
      issuerID: number,
      issueID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLoanDocuments(
          issuerID,
          issueID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};

export class DocumentsApi {
  public generateDocumentToken(
    documentType: HubDocumentTypeUniverseEnum,
    documentID: number,
    options?: AxiosRequestConfig,
  ) {
    return DocumentsApiFp()
      .generateDocumentToken(documentType, documentID, options)
      .then((request) => request(globalAxios));
  }
  public getLatestDocumentsForCompany(
    issuerID: number,
    options?: AxiosRequestConfig,
  ) {
    return DocumentsApiFp()
      .getLatestDocumentsForCompany(issuerID, options)
      .then((request) => request(globalAxios));
  }
  public getLoanDocuments(
    issuerID: number,
    issueID: number,
    options?: AxiosRequestConfig,
  ) {
    return DocumentsApiFp()
      .getLoanDocuments(issuerID, issueID, options)
      .then((request) => request(globalAxios));
  }
}

export const FinancialReportingApiAxiosParamCreator = function () {
  return {
    getFinancialOverview: async (
      issuerID: number,
      currency?: CurrencyCodeUniverseEnum,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issuerID' is not null or undefined
      assertParamExists('getFinancialOverview', 'issuerID', issuerID);
      const localVarPath = `/api/company/${issuerID}/financialreportings/financialoverview`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (currency !== undefined) {
        localVarQueryParameter['currency'] = currency;
      }
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getFinancialReportForLoan: async (
      loanID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanID' is not null or undefined
      assertParamExists('getFinancialReportForLoan', 'loanID', loanID);
      const localVarPath = `/api/loans/${loanID}/financialreportings/download`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getFinancialReportingDTOForLoan: async (
      loanID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanID' is not null or undefined
      assertParamExists('getFinancialReportingDTOForLoan', 'loanID', loanID);
      const localVarPath = `/api/loans/${loanID}/financialreportings`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const FinancialReportingApiFp = function () {
  const localVarAxiosParamCreator = FinancialReportingApiAxiosParamCreator();
  return {
    async getFinancialOverview(
      issuerID: number,
      currency?: CurrencyCodeUniverseEnum,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFinancialOverview(
          issuerID,
          currency,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getFinancialReportForLoan(
      loanID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFinancialReportForLoan(
          loanID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getFinancialReportingDTOForLoan(
      loanID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFinancialReportingDTOForLoan(
          loanID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};

export class FinancialReportingApi {
  public getFinancialOverview(
    issuerID: number,
    currency?: CurrencyCodeUniverseEnum,
    options?: AxiosRequestConfig,
  ) {
    return FinancialReportingApiFp()
      .getFinancialOverview(issuerID, currency, options)
      .then((request) => request(globalAxios));
  }
  public getFinancialReportForLoan(
    loanID: number,
    options?: AxiosRequestConfig,
  ) {
    return FinancialReportingApiFp()
      .getFinancialReportForLoan(loanID, options)
      .then((request) => request(globalAxios));
  }
  public getFinancialReportingDTOForLoan(
    loanID: number,
    options?: AxiosRequestConfig,
  ) {
    return FinancialReportingApiFp()
      .getFinancialReportingDTOForLoan(loanID, options)
      .then((request) => request(globalAxios));
  }
}

export const LoansApiAxiosParamCreator = function () {
  return {
    get: async (issueID: string, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('get', 'issueID', issueID);
      const localVarPath = `/api/loans/${issueID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getAllLoans: async (options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/loans`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getCaseHandler: async (
      issueID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getCaseHandler', 'issueID', issueID);
      const localVarPath = `/api/loans/${issueID}/casehandler`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getCaseHandlerPhoto: async (
      issueID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getCaseHandlerPhoto', 'issueID', issueID);
      const localVarPath = `/api/loans/${issueID}/casehandler/photo`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getCovenantReportings: async (
      issueID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getCovenantReportings', 'issueID', issueID);
      const localVarPath = `/api/loans/${issueID}/covenantreportings`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getLoansForCompany: async (
      companyFunctionID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'companyFunctionID' is not null or undefined
      assertParamExists(
        'getLoansForCompany',
        'companyFunctionID',
        companyFunctionID,
      );
      const localVarPath = `/api/loans/company/${companyFunctionID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const LoansApiFp = function () {
  const localVarAxiosParamCreator = LoansApiAxiosParamCreator();
  return {
    async get(issueID: string, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(
        issueID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getAllLoans(options?: AxiosRequestConfig) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllLoans(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getCaseHandler(issueID: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCaseHandler(
        issueID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getCaseHandlerPhoto(issueID: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCaseHandlerPhoto(issueID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getCovenantReportings(issueID: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getCovenantReportings(issueID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getLoansForCompany(
      companyFunctionID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLoansForCompany(
          companyFunctionID,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};
export class LoansApi {
  public get(issueID: string, options?: AxiosRequestConfig) {
    return LoansApiFp()
      .get(issueID, options)
      .then((request) => request(globalAxios));
  }
  public getAllLoans(options?: AxiosRequestConfig) {
    return LoansApiFp()
      .getAllLoans(options)
      .then((request) => request(globalAxios));
  }
  public getCaseHandler(issueID: number, options?: AxiosRequestConfig) {
    return LoansApiFp()
      .getCaseHandler(issueID, options)
      .then((request) => request(globalAxios));
  }
  public getCaseHandlerPhoto(issueID: number, options?: AxiosRequestConfig) {
    return LoansApiFp()
      .getCaseHandlerPhoto(issueID, options)
      .then((request) => request(globalAxios));
  }
  public getCovenantReportings(issueID: number, options?: AxiosRequestConfig) {
    return LoansApiFp()
      .getCovenantReportings(issueID, options)
      .then((request) => request(globalAxios));
  }
  public getLoansForCompany(
    companyFunctionID: number,
    options?: AxiosRequestConfig,
  ) {
    return LoansApiFp()
      .getLoansForCompany(companyFunctionID, options)
      .then((request) => request(globalAxios));
  }
}
export const SecuritiesApiAxiosParamCreator = function () {
  return {
    getHistoricalSecurities: async (
      loanId: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('getHistoricalSecurities', 'loanId', loanId);
      const localVarPath = `/api/loans/${loanId}/historicalsecurities`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getSecurities: async (loanId: number, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'loanId' is not null or undefined
      assertParamExists('getSecurities', 'loanId', loanId);
      const localVarPath = `/api/loans/${loanId}/securities`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};
export const SecuritiesApiFp = function () {
  const localVarAxiosParamCreator = SecuritiesApiAxiosParamCreator();
  return {
    async getHistoricalSecurities(
      loanId: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getHistoricalSecurities(
          loanId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getSecurities(loanId: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSecurities(
        loanId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};

export class SecuritiesApi {
  public getHistoricalSecurities(loanId: number, options?: AxiosRequestConfig) {
    return SecuritiesApiFp()
      .getHistoricalSecurities(loanId, options)
      .then((request) => request(globalAxios));
  }
  public getSecurities(loanId: number, options?: AxiosRequestConfig) {
    return SecuritiesApiFp()
      .getSecurities(loanId, options)
      .then((request) => request(globalAxios));
  }
}

export const ReportingApiAxiosParamCreator = function () {
  return {
    getReportings: async (
      issueID: number,
      fromDate?: string,
      toDate?: string,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getReportings', 'issueID', issueID);
      const localVarPath = `/api/loans/${issueID}/compliance-schedule-items`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (fromDate != null) {
        localVarQueryParameter['fromDate'] = fromDate;
      }
      if (toDate != null) {
        localVarQueryParameter['toDate'] = toDate;
      }
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getReporting: async (
      issueID: number,
      complianceScheduleItemID: number,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getReporting', 'issueID', issueID);
      // verify required parameter 'complianceScheduleItemID' is not null or undefined
      assertParamExists(
        'getReporting',
        'complianceScheduleItemID',
        complianceScheduleItemID,
      );
      const localVarPath = `/api/loans/${issueID}/compliance-schedule-items/${complianceScheduleItemID}/reportings`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    sumbitNewReporting: async (
      issueID: number,
      complianceScheduleItemID: number,
      reportingDTO?: ReportingSubmitNewDTO,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('sumbitNewReporting', 'issueID', issueID);
      // verify required parameter 'complianceScheduleItemID' is not null or undefined
      assertParamExists(
        'sumbitNewReporting',
        'complianceScheduleItemID',
        complianceScheduleItemID,
      );
      const localVarPath = `/api/loans/${issueID}/reportings`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reportingDTO,
        localVarRequestOptions,
      );
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    submitUpdatedReporting: async (
      issueID: number,
      reportingID: number,
      reportingDTO?: ReportingSubmitUpdateDTO,
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('submitUpdatedReporting', 'issueID', issueID);
      const localVarPath = `/api/loans/${issueID}/reportings/${reportingID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'PUT', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reportingDTO,
        localVarRequestOptions,
      );
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    uploadDocuments: async (
      issueID: number,
      reportingID: number,
      documents?: File[],
      UploadToStamdata?: boolean[],
      options: AxiosRequestConfig = {},
    ) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('uploadDocuments', 'issueID', issueID);
      // verify required parameter 'complianceScheduleItemID' is not null or undefined
      assertParamExists('uploadDocuments', 'reportingID', reportingID);
      const localVarPath = `/api/loans/${issueID}/reportings/${reportingID}/files`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        documents,
        localVarRequestOptions,
      );

      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  };
};

export const ReportingsApiFp = function () {
  const localVarAxiosParamCreator = ReportingApiAxiosParamCreator();
  return {
    async getReportings(
      issueID: number,
      fromDate?: string,
      toDate?: string,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportings(
        issueID,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async getReporting(
      issueID: number,
      complianceScheduleItemID: number,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReporting(
        issueID,
        complianceScheduleItemID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async sumbitNewReporting(
      issueID: number,
      complianceScheduleItemID: number,
      reportingDTO?: ReportingSubmitNewDTO,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sumbitNewReporting(
          issueID,
          complianceScheduleItemID,
          reportingDTO,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async submitUpdatedReporting(
      issueID: number,
      reportingID: number,
      reportingDTO?: ReportingSubmitUpdateDTO,
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.submitUpdatedReporting(
          issueID,
          reportingID,
          reportingDTO,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
    async uploadDocuments(
      issueID: number,
      reportingID: number,
      documents?: File[],
      UploadToStamdata?: boolean[],
      options?: AxiosRequestConfig,
    ) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocuments(
        issueID,
        reportingID,
        documents,
        UploadToStamdata,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios);
    },
  };
};

export class ReportingsApi {
  public async getReportings(
    issueID: number,
    fromDate?: string,
    toDate?: string,
    options?: AxiosRequestConfig,
  ) {
    const request = await ReportingsApiFp().getReportings(
      issueID,
      fromDate,
      toDate,
      options,
    );
    return await request(globalAxios);
  }
  public async getReporting(
    issueID: number,
    complianceScheduleItemID: number,
    options?: AxiosRequestConfig,
  ) {
    const request = await ReportingsApiFp().getReporting(
      issueID,
      complianceScheduleItemID,
      options,
    );
    return await request(globalAxios);
  }
  public async sumbitNewReporting(
    issueID: number,
    complianceScheduleItemID: number,
    reportingDTO?: ReportingSubmitNewDTO,
    options?: AxiosRequestConfig,
  ) {
    const request = await ReportingsApiFp().sumbitNewReporting(
      issueID,
      complianceScheduleItemID,
      reportingDTO,
      options,
    );
    return await request(globalAxios);
  }
  public async submitUpdatedReporting(
    issueID: number,
    reportingID: number,
    reportingDTO?: ReportingSubmitUpdateDTO,
    options?: AxiosRequestConfig,
  ) {
    const request = await ReportingsApiFp().submitUpdatedReporting(
      issueID,
      reportingID,
      reportingDTO,
      options,
    );
    return await request(globalAxios);
  }
  public async uploadDocuments(
    issueID: number,
    reportingID: number,
    documents: File[],
    UploadToStamdata: boolean[],
    options?: AxiosRequestConfig,
  ) {
    const request = await ReportingsApiFp().uploadDocuments(
      issueID,
      reportingID,
      documents,
      UploadToStamdata,
      options,
    );
    return await request(globalAxios);
  }
}
