import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Input } from '@nordictrustee/nt-ui-library';
import { useFromToParams } from './useFromToParams';

const DATE_FORMAT = 'DD/MM/YYYY';

export const ReportingFilter = memo(() => {
  const { fromDateFilter, setFromDateFilter, toDateFilter, setToDateFilter } =
    useFromToParams();

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{ clearButtonLabel: 'CLEAR' }}
    >
      <Box mt={2} mb={2} display="flex" alignItems="center">
        <Box ml={2} mr={2}>
          <Typography>Filter by</Typography>
        </Box>
        <Box ml={2} mr={2}>
          <Input.Date
            clearable={false}
            format={DATE_FORMAT}
            label="From"
            value={fromDateFilter}
            onChange={(date) => setFromDateFilter(date)}
          />
        </Box>
        <Box ml={2} mr={2}>
          <Input.Date
            clearable={false}
            format={DATE_FORMAT}
            label="To"
            value={toDateFilter}
            onChange={(date) => setToDateFilter(date)}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
});
