import { CompanyDTO } from './types';


const undefinedCompanyState: CompanyDTO = {
  companyID: -1,
  issuerID: -1,
  name: '',
  organizationNo: '',
};
const activeCompanyStateKey = 'activeCompanyIP';
export const loadActiveCompanyState = (): CompanyDTO => {
  try {
    const serializedState = localStorage.getItem(activeCompanyStateKey);
    if (serializedState === null) {
      return undefinedCompanyState;
    }
    return JSON.parse(serializedState) as CompanyDTO;
  } catch (err) {
    return undefinedCompanyState;
  }
};

export const saveActiveCompany = (state: CompanyDTO) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(activeCompanyStateKey, serializedState);
  } catch (e) {
    console.warn('failed to save active company state');
  }
};
export const resetActiveCompany = () => {
  try {
    localStorage.removeItem(activeCompanyStateKey);
  } catch (e) {
    console.warn('failed to reset active company state');
  }
};
