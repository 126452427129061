import { CashFlowItemDTO } from 'api/api.types';
import moment from 'moment/moment';
import { FinancialReportingRowTypeEnum } from 'modules/Global/enums';
import { DataPoint } from './PrincipalAmountChart.types';

export const getFuture = (cashflowItems: CashFlowItemDTO[]): DataPoint[] => {
  const returnArray = [];
  let sum = 0;
  const futureAmounts = cashflowItems.filter(
    (e) =>
      moment(e.date).isAfter(moment()) &&
      e.eventType === FinancialReportingRowTypeEnum.Tranche,
  );
  const pastAmounts = cashflowItems.filter(
    (e) =>
      moment(e.date).isBefore(moment()) &&
      e.eventType === FinancialReportingRowTypeEnum.Tranche,
  );

  pastAmounts.forEach((pa: CashFlowItemDTO) => {
    if (pa.exchangedAmount) {
      sum += pa.exchangedAmount;
    }
  });

  returnArray.push({ x: moment().toISOString(), y: sum });

  futureAmounts.forEach((fa: CashFlowItemDTO) => {
    if (fa.exchangedAmount) {
      sum += fa.exchangedAmount;
    }
    const elm = { x: moment(fa.date).toISOString(), y: sum };
    returnArray.push(elm);
  });
  return returnArray;
};
