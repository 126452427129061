import React from 'react';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

interface WatchVideoTriggerButtonProps {
  onClick: () => void;
  title: string;
  color?: 'primary' | 'secondary';
}

export const WatchVideoTriggerButton = (
  { onClick, title, color }: WatchVideoTriggerButtonProps,
) => (
  <Button
    variant="outlined"
    color={color ?? 'primary'}
    onClick={onClick}
  >
    <PlayCircleOutlineIcon style={{ marginRight: 5 }} />
    {title}
  </Button>
);
