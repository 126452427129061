import { FC, useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { BasicLoanDTO } from 'api/api.types';
import { CircularLoader } from 'components/Loaders';
import { cancelRequests } from '../../utils/init/axiosConfig';
import { useGlobal, useToast } from '../Global';
import { LoansList } from './atoms';
import { getLoans } from './LoansApi';

export const Loans: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loans, setLoans] = useState<BasicLoanDTO[]>([]);
  const { activeCompany: company } = useGlobal();
  const { showWarning } = useToast();

  useEffect(() => {
    if(!company) return;
    let cancel = false;
      const fetchLoans = async () => {
      try {
          if (company.companyID !== -1) {
            const fetchedLoans = await getLoans(company.companyID);
            if(!cancel) setLoans(fetchedLoans);
          }
        } catch (e) {
          cancel = true;
          showWarning(`stopped fetching`);
          console.log(e)
        }
      };

    fetchLoans().finally(()=> setIsLoading(false));
    return ()=> {
      cancel = true;
      cancelRequests();
    }
  }, [company, showWarning]);

  return (
    <Container maxWidth="xl">
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <Grid item xs={12}>
              <LoansList loading={isLoading} loans={loans} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};
