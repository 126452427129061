import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ContactDetails } from './ContactDetails';
import classes from './PageFooter.module.css';

const isDemo = () => /demo/.test(window.location.host);

interface PageFooterProps{
  isLoggedIn: boolean;
}

export const PageFooter = ({ isLoggedIn }: PageFooterProps) => (
  <footer className={classes.root}>
    <Typography variant="body1" className={classes.item}>&copy; Nordic Trustee</Typography>
    <ContactDetails countryCode="SE" />
    <Typography variant="body1" className={classes.item}>
      <Link target="_blank" href="https://nordictrustee.com/contact">
        Contact
      </Link>
    </Typography>
    {
      isLoggedIn
    && (
      <>
        <Typography variant="body1" className={classes.item}>
          <Link href="/terms-of-use">
            Terms of Use
          </Link>
        </Typography>

        <Typography variant="body1" className={classes.item}>
          <Link href="/faq">
            Frequently Asked Questions
          </Link>
        </Typography>
      </>
    )
    }
    {isDemo()
      && (
        <Typography variant="body1" className={classes.item}>
          Note: Any emails from this demo environment is routed to the developers
        </Typography>
      )}
  </footer>
);
