import { PageHeaderButton } from './PageHeaderButton';
import { Link } from 'react-router-dom';
import React from 'react';

export const Dashboard = () => (
  <PageHeaderButton component={Link} to="/dashboard">
    Dashboard
  </PageHeaderButton>
);

export const MyEvents = () => (
  <PageHeaderButton component={Link} to="/events">
    Events
  </PageHeaderButton>
);

export const MyDocuments = () => (
  <PageHeaderButton component={Link} to="/documents">
    Documents
  </PageHeaderButton>
);

export const MyFinancials = () => (
  <PageHeaderButton component={Link} to="/financials/overview">
    Financials
  </PageHeaderButton>
);
