import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { type HubUserWithCompaniesDTO } from 'modules/Account/AccountApi/api.types';
import { listenToPages, setUser } from 'utils/googleAnalytics';

export const useGoogleAnalytics = (user?: HubUserWithCompaniesDTO) => {
  const location = useLocation();
  useEffect(() => {
    setUser(user?.hubUserID ?? undefined);
  }, [user]);
  useEffect(() => {
    listenToPages(location);
  }, [location]);
};
