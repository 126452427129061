import { useMemo, useState } from 'react';
import { Box, Card, CardContent, Tab, Typography } from '@mui/material';
import accounting from 'accounting';
import { BasicLoanDTO } from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { SubTabs } from 'components/SubTabs';
import {
  calcTotalOutstandingByCurrency,
  isLoanActive,
  sortLoansByDisbursement,
} from '../../utils';
import { LoansTable } from './LoansTable';

interface ComponentProps {
  loans: BasicLoanDTO[];
  loading: boolean;
}

export const LoansList = ({ loans, loading }: ComponentProps) => {
  const [loanType, setLoanType] = useState<'active'|'matured'>('active');

  const filteredLoans = useMemo(() => loans ? loans.filter((l) => (
    loanType === 'active' ? isLoanActive(l) : !isLoanActive(l)
  )) : [], [loanType, loans]);
  const sortedLoans = sortLoansByDisbursement(filteredLoans);
  const outstandingByCurrency = calcTotalOutstandingByCurrency(sortedLoans);

  return (
    <Card>
      <StyledCardHeader
        title="Loans"
        action={(
          <SubTabs selectedTab={loanType} onChange={(_e: unknown, value: typeof loanType) => setLoanType(value)}>
            <Tab value="active" label="Active" />
            <Tab value="matured" label="Matured" />
          </SubTabs>
        )}
      />
      <CardContent>
        <LoadingContainer loading={loading} type="list" rows={15} cols={4}>
          {sortedLoans.length > 0 && (
            <>
              <LoansTable loans={sortedLoans} loanType={loanType} />
              {loanType === 'active' && (
                <Box mt={2}>
                  <Typography variant="h5" align="right">Total Outstanding</Typography>
                  {
                    Object.keys(outstandingByCurrency).map((field) => (
                      <Typography key={field} align="right" variant="h3">
                        {accounting.formatMoney(outstandingByCurrency[field], { symbol: field, format: '%s %v', precision: 0 })}
                      </Typography>
                    ))
                  }
                </Box>
              )}
            </>
          )}
          {
            sortedLoans.length < 1 && (
              <Typography>
                No
                {' '}
                {loanType}
                {' '}
                loans
              </Typography>
            )
          }
        </LoadingContainer>
      </CardContent>
    </Card>
  );
};

