import React from 'react';
import Done from '@mui/icons-material/Done';
import { CardContent,Typography } from '@mui/material';
import { StyledCardHeader, SubCard } from 'components/Card';
import { PaperPage } from '../atoms';
import classes from './ResetSucceeded.module.css';

export const ResetSucceeded = () => (
  <PaperPage>
    <SubCard>
      <StyledCardHeader
        title="We sent you an email"
        avatar={<Done className={classes.success} />}
      />
      <CardContent>
        <Typography>
          Please check your email account and follow the link therein to set
          your new password.
        </Typography>
        <br />
        <Typography>
          If you have not received an email within a few minutes, the email
          might have been caught in your spam filter, or you provided an email
          address that does not exist in our systems.
        </Typography>
      </CardContent>
    </SubCard>
  </PaperPage>
);

