import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { StyledCardHeader } from 'components/Card';
import { SecurityDetailTypeUniverseEnum } from 'modules/Global/enums';
import { firstLetterToUpperCase, insertSpaceBeforeUpperCase } from 'utils/strings/stringManipulation';
import { SecurityItem } from '../security.types';
import { SecuritiesTable } from './SecuritiesTable';

interface ComponentProps {
  securities: SecurityItem[];
  securityType: SecurityDetailTypeUniverseEnum;
  customField?: string;
}

export const SecuritiesCard = ({
  securityType, securities, customField,
}: ComponentProps) => {
  if (securities.length === 0) {
    return null;
  }
  return (
    <Card data-id="securitiesCard">
      <StyledCardHeader
        title={firstLetterToUpperCase(insertSpaceBeforeUpperCase(securityType))}
      />
      <CardContent>
        { securities.length > 0 && (
          <SecuritiesTable
            securityItems={securities}
            customField={customField}
            securityType={securityType}
          />
        )}
      </CardContent>
    </Card>
  );
};
