import getISOWithoutTime from 'utils/strings/getISOWithoutTime';
import { FeatureFlags } from 'utils/types/FeatureFlags.types';
import { EventDTO, EventTypeUniverseEnum } from '../types';
import { createReportingPath } from './createReportingPath';

export const getRedirectPathForEventAction = (event: EventDTO): string => {
  if (event.eventType === EventTypeUniverseEnum.ComplianceReporting) {
    const { FEATURE_FLAGS } = window._env_;
    const flags: FeatureFlags = JSON.parse(FEATURE_FLAGS || '{}');
    const shouldMakePathForReporting = flags['48065_ShowReportingPage'];
    return shouldMakePathForReporting
      ? createReportingPath(event)
      : `/loans/${event.loanID}/documents?openUploadForm=reportingDocuments&reportingDate=${event.date}`;
  }

  if (event.eventType === EventTypeUniverseEnum.InterestPaymentDate) {
    return `/loans/${event.loanID}/financials/payments?interestPaymentDate=${getISOWithoutTime(event.date)}`;
  }

  return `/loans/${event.loanID}`;
};
