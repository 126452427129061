import React from 'react';
import { CardContent } from '@mui/material';
import { SubCard } from 'components/Card';
import { MemTextField } from 'components/form/text_fields/TextFields';

interface ContactPersonProps{
  contactPersonName?: string;
  setContactPersonName: React.Dispatch<React.SetStateAction<string>>;
  contactPersonTitle?: string;
  setContactPersonTitle: React.Dispatch<React.SetStateAction<string>>;
  contactPersonEmail?: string;
  setContactPersonEmail: React.Dispatch<React.SetStateAction<string>>;
  contactPersonPhone?: string;
  setContactPersonPhone: React.Dispatch<React.SetStateAction<string>>;
}

const EditContactPerson = ({
  contactPersonName, setContactPersonName, contactPersonTitle, setContactPersonTitle,
  contactPersonEmail, setContactPersonEmail, contactPersonPhone, setContactPersonPhone,
}: ContactPersonProps) => (
  <SubCard title="Contact Person">
    <CardContent>
      <MemTextField label="Main Contact Person" value={contactPersonName} onChange={setContactPersonName} />
      <MemTextField label="Title of Contact Person" value={contactPersonTitle} onChange={setContactPersonTitle} />
      <MemTextField label="Email" value={contactPersonEmail} onChange={setContactPersonEmail} />
      <MemTextField label="Phone Number" value={contactPersonPhone} onChange={setContactPersonPhone} />
    </CardContent>
  </SubCard>
);

export default EditContactPerson;
