import React from 'react';
import Typography from '@mui/material/Typography';

interface HeaderTypographyProps{
  children?: React.ReactNode;
}

export const HeaderTypography = ({ children }: HeaderTypographyProps) => (
  <Typography style={{ fontSize: '80px', lineHeight: '95px' }}>
    {children}
  </Typography>
);

