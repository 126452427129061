import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { NotificationSettingDTO } from '../../../AccountApi/api.types';
import classes from './NotificationSettingsList.module.css';

interface NotificationSettingsListProps {
  header: string;
  notificationSettings: NotificationSettingDTO[];
}

export const NotificationSettingsList = ({ header, notificationSettings }: NotificationSettingsListProps) => (
  <>
    <Typography variant="h4">{header}</Typography>
    <List>
      {notificationSettings.map((anf) => (
        <ListItem key={anf.eventTypeText} className={classes.listItem}>
          <ListItemText primary={anf.eventTypeText} />
        </ListItem>
      ))}
    </List>
  </>
);
