import {
    Checkbox, FormControl, FormControlLabel, MenuItem, Select, InputLabel, TextFieldVariants,
} from '@mui/material';
import React from 'react';

interface MutliSelectProps {
  values: string[];
  selectedValues: string[];
  label: string;
  maxValueLength?: number;
  width?: string | number;
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  variant?: TextFieldVariants;
}

const DEFAULT_MAX_VALUE_LENGTH = 20;

const MultiSelect = ({
  values, label, selectedValues, setSelectedValues, maxValueLength, width, variant
}: MutliSelectProps) => (
  <FormControl style={{ width: (width ?? '100%') }}>
    <InputLabel
      htmlFor="age-native-simple"
    >
      {label}
    </InputLabel>
    <Select
      variant={variant}
      value={selectedValues}
      renderValue={(r) => {
        const length = maxValueLength ?? DEFAULT_MAX_VALUE_LENGTH;
        const renderValue: string = [...r as string[]].join(', ').slice(0, length);
        return (
          <>
            {renderValue + (renderValue.length >= length ? '...' : '')}
          </>
        );
      }}
      onChange={(e) => { setSelectedValues(e.target.value as string[]); }}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' }
      }}
      multiple
    >
      {
        values.map((m) => (
          <MenuItem value={m} key={m}>
            <FormControlLabel
              style={{ pointerEvents: 'none' }}
              control={<Checkbox color="primary" checked={selectedValues.includes(m)} />}
              label={m}
            />
          </MenuItem>

        ))
      }
    </Select>
  </FormControl>
);

export default MultiSelect;
