import { ElementType, FC, HTMLAttributes, ReactNode } from 'react';
import './sr-only.css';

interface HiddenProps {
  element?: ElementType;
  children: ReactNode;
}

export const Hidden: FC<HiddenProps & HTMLAttributes<HTMLElement>> = ({
  element: Element = 'span',
  children,
  ...rest
}) => {
  return (
    <Element {...rest} className="sr-only">
      {children}
    </Element>
  );
};
