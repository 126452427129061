import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { FixedTable, LabelContentRow as Row } from 'components/Table';
import { CompanyContactDetailsDTO } from 'modules/Global/types';
import classes from './CompanyContactDetailsReadonly.module.css';

interface ComponentProps {
  profile: CompanyContactDetailsDTO;
  loading: boolean;
}

interface Dict{
  [key: string]: string | undefined;
}

interface SectionProps{
  data: Dict;
  header: string;
  loading: boolean;
}

const CompanyDetails = ({
  data, header, loading
}: SectionProps) => (
  <Card className={classes.subCard}>
    <StyledCardHeader
      title={header}
    />
    <CardContent>
      <LoadingContainer loading={loading} type="list" cols={2} rows={7}>
        <FixedTable>
          <TableBody>
            <Row label="Company Name" alignRight>
              {data.companyName}
            </Row>
            <Row label="Company Registration Number" alignRight>
              {data.companyRegistrationNumber}
            </Row>
            <Row label="VAT Number" alignRight>
              {data.vatNumber}
            </Row>
          </TableBody>
        </FixedTable>
      </LoadingContainer>
    </CardContent>
  </Card>
);

const PostalAddress = ({
  data, header, loading
}: SectionProps) => (
  <Card className={classes.subCard}>
    <StyledCardHeader
      title={header}
    />
    <CardContent>
      <LoadingContainer loading={loading} type="list" cols={2} rows={7}>
        <FixedTable>
          <TableBody>
            <Row label="Street Name" alignRight>
              {data.street}
            </Row>
            <Row label="Extended Street" alignRight>
              {data.extendedStreet}
            </Row>
            <Row label="Postal Code" alignRight>
              {data.postalCode}
            </Row>
            <Row label="City" alignRight>
              {data.city}
            </Row>
            <Row label="Country" alignRight>
              {data.country}
            </Row>
          </TableBody>
        </FixedTable>
      </LoadingContainer>
    </CardContent>
  </Card>
);

const InvoiceAddress = ({
  data, header, loading
}: SectionProps) => (
  <Card className={classes.subCard}>
    <StyledCardHeader
      title={header}
    />
    <CardContent>
      <LoadingContainer loading={loading} type="list" cols={2} rows={7}>
        <FixedTable>
          <TableBody>
            <Row label="E-mail Address for Invoices" alignRight>
              {data.invoiceEmail}
            </Row>
            <Row label="Contact Person for Invoices" alignRight>
              {data.invoiceContactPerson}
            </Row>
            <Row label="Street Name" alignRight>
              {data.street}
            </Row>
            <Row label="Extended Street" alignRight>
              {data.extendedStreet}
            </Row>
            <Row label="Postal Code" alignRight>
              {data.postalCode}
            </Row>
            <Row label="City" alignRight>
              {data.city}
            </Row>
            <Row label="Country" alignRight>
              {data.country}
            </Row>
          </TableBody>
        </FixedTable>
      </LoadingContainer>
    </CardContent>
  </Card>
);

const ContactPerson = ({
  data, header, loading
}: SectionProps) => (
  <Card className={classes.subCard}>
    <StyledCardHeader
      title={header}
    />
    <CardContent>
      <LoadingContainer loading={loading} type="list" cols={2} rows={7}>
        <FixedTable>
          <TableBody>
            <Row label="Main Contact Person" alignRight>
              {data.name}
            </Row>
            <Row label="Title of Contact Person" alignRight>
              {data.title}
            </Row>
            <Row label="Email" alignRight>
              {data.email}
            </Row>
            <Row label="Phone Number" alignRight>
              {data.phoneNumber}
            </Row>
          </TableBody>
        </FixedTable>
      </LoadingContainer>
    </CardContent>
  </Card>
);

export const CompanyContactDetailsReadonly = ({
  profile, loading,
}: ComponentProps) => {
  const {
    companyDetails, companyContactPerson, postalAddress, invoiceAddress,
  } = profile;
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CompanyDetails
          header="Basic Information"
          data={{
            companyName: companyDetails ? companyDetails.companyName : '',
            companyRegistrationNumber: companyDetails ? companyDetails.companyRegistrationNumber : '',
            vatNumber: companyDetails ? companyDetails.vatNumber : '',
          }}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ContactPerson
          header="Contact Person"
          data={{
            name: companyContactPerson ? companyContactPerson.name : '',
            title: companyContactPerson ? companyContactPerson.title : '',
            email: companyContactPerson ? companyContactPerson.email : '',
            phoneNumber: companyContactPerson ? companyContactPerson.phoneNumber : '',
          }}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PostalAddress
          header="Postal Address"
          data={{
            street: postalAddress ? postalAddress.address : '',
            extendedStreet: postalAddress ? postalAddress.extendedStreet : '',
            postalCode: postalAddress ? postalAddress.postalCode : '',
            city: postalAddress ? postalAddress.city : '',
            country: postalAddress ? postalAddress.country : '',
          }}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InvoiceAddress
          header="Invoice Address"
          data={{
            invoiceEmail: companyDetails ? companyDetails.invoiceEmail : '',
            invoiceContactPerson: companyDetails ? companyDetails.invoiceContactPerson : '',
            street: invoiceAddress ? invoiceAddress.address : '',
            extendedStreet: invoiceAddress ? invoiceAddress.extendedStreet : '',
            postalCode: invoiceAddress ? invoiceAddress.postalCode : '',
            city: invoiceAddress ? invoiceAddress.city : '',
            country: invoiceAddress ? invoiceAddress.country : '',
          }}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

