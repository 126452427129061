import Dropzone, { FileRejection } from 'react-dropzone';
import { TextValidator } from 'react-material-ui-form-validator';
import allowedFileTypes from './allowedFileTypes.json';
import classes from './FileUpload.module.css';

interface ComponentProps {
    files: { key: string; file: File }[];
    onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
    maxSizeBytes?: number;
}

export const FileUpload = ({ files, onDrop, maxSizeBytes }: ComponentProps) => {
    const emptyInputLabel =
        'Drag and drop one or multiple files here, or click to select files';
    let inputText = '';
    let label = emptyInputLabel;
    if (files != null && files.length > 1) {
        inputText = `${files.length} files`;
        label = 'Files (drag and drop or click to add more)';
    } else if (files != null && files.length === 1) {
        inputText = files[0].file.name;
        label = 'Files (drag and drop or click to add more)';
    } else if (files == null || files.length <= 0) {
        inputText = '';
        label = emptyInputLabel;
    }

    return (
        <div>
            <Dropzone
                multiple
                onDrop={onDrop}
                accept={allowedFileTypes.mimeTypes}
                maxSize={maxSizeBytes}
            >
                {({ getRootProps, getInputProps }) => (
                    <section
                        className={classes.uploadSection}
                        {...getRootProps()}
                        data-cy="dropzone"
                    >
                        <input {...getInputProps()} />
                        <TextValidator
                            className={classes.fileFieldText}
                            variant="filled"
                            label={label}
                            fullWidth
                            multiline
                            min-rows="1"
                            required
                            name="Files"
                            value={inputText || ''}
                            validators={['required']}
                            helperText={[
                                'You can upload more than one file at a time, at least one file is required',
                            ]}
                            errorMessages={['At least one file is required']}
                            InputProps={{
                                readOnly: true,
                            }}
                            margin="normal"
                        />
                    </section>
                )}
            </Dropzone>
        </div>
    );
};