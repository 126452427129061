import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CaseLogDTO } from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { SimpleList } from 'components/SimpleList';
import MessageFromNTListItem from './MessageFromNTListItem';
import classes from './MessageFromNTList.module.css';

interface ComponentProps {
  messagesNT: CaseLogDTO[];
  loading: boolean;
}

const MessageFromNTList = ({
  messagesNT, loading,
}: ComponentProps) => (
  <Card className={classes.card}>
    <StyledCardHeader
      title="Messages from Nordic Trustee"
    />
    <CardContent>
      <LoadingContainer loading={loading} type="list" rows={6} cols={1}>
        <>
          {messagesNT.length
            ? (
              <SimpleList>
                {messagesNT.map((message, i) => (
                  <MessageFromNTListItem
                    message={message}
                    divider={messagesNT.length !== i + 1}
                    key={message.caseLogEntryID}
                  />
                ))}
              </SimpleList>
            )
            : (<Typography variant="body1" color="textPrimary">No messages</Typography>)}
        </>
      </LoadingContainer>
    </CardContent>
  </Card>
);
export default MessageFromNTList;
