
export const humanizeMoney = (currency: string, value: number, isMaxOneDecimal = false): string => {
  let formattedValue = value;
  let sign: '' | '-' = '';

  if (formattedValue < 0) {
    formattedValue = Math.abs(value);
    sign = '-';
  }

  const suffix = ['', 'k', 'm', 'bn'];
  let i = 0;
  while (formattedValue >= 1000 && i < suffix.length - 1) {
    i += 1;
    formattedValue = ((formattedValue / 1000) * 100) / 100;
  }

  if (isMaxOneDecimal) {
    return `${sign}${Math.round(formattedValue * 10) / 10} ${suffix[i]}${currency}`;
  }
  return `${sign}${formattedValue} ${suffix[i]}${currency}`;
};
