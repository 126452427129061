import { FC, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { acceptTerms } from 'modules/Account/AccountApi';
import { UserAgreement } from 'modules/Static';
import classes from './TermsAndConditionsSection.module.css';

interface AcceptTermsAndConditionProps {
  userID: string;
}

const acceptTermsAndConditions = async (userID: string) => {
  await acceptTerms(userID);
  window.location.reload();
};

export const TermsAndConditionsSection: FC<AcceptTermsAndConditionProps> = ({
  userID,
}: AcceptTermsAndConditionProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.termsHeader}
    >
      <Typography variant="h1" className={classes.termsHeader}>
        Accept Terms and Conditions
      </Typography>
      In order to use the issuer portal, you need to read and accept the general
      terms and conditions stated below.
      <UserAgreement />
      <Box display="flex" flexDirection="column" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              inputProps={{ 'aria-label': 'Accept Terms and Condition' }}
              color="primary"
            />
          }
          label="I have read and understood the Terms and Conditions"
        />

        <Button
          onClick={() => acceptTermsAndConditions(userID || '')}
          variant="contained"
          color="primary"
          disabled={!termsAccepted}
        >
          Accept Terms and Conditions
        </Button>
      </Box>
    </Box>
  );
};
