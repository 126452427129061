/* eslint-disable react/no-unused-prop-types */
import React, { memo } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { MaterialUiChangeHandler } from 'legacy/inputHandlers';
import { PHONE_NUMBER_REGEX_STRING } from 'modules/Global/validationHelpers/validationHelpers';
import {Box, BoxProps, TextField, TextFieldVariants} from '@mui/material';

interface TextFieldProps {
  value?: string;
  changeHandler: MaterialUiChangeHandler<any>;
  required?: boolean;
  autoFocus?: boolean;
  label?: string;
  field?: string;
}

interface ReadOnlyTextFieldProps {
  value?: string;
}

// String field is a 'base' field and shouldn't be used
// directly but should be wrapper within another XField component
const StringTextField = ({
  label, field, value, changeHandler, required,
}: TextFieldProps & { label: string; field: string }) => (
  <TextValidator
    variant="standard"
    fullWidth
    label={required === false ? label : `${label} *`}
    onChange={changeHandler(field, (e) => e.target.value)}
    name={field}
    value={value || ''}
    type="text"
    validators={required === false ? [] : ['required']}
    errorMessages={[`${label} is required`]}
    margin="normal"
  />
);

export const ReadOnlyStringTextField = ({
  label, field, value,
}: ReadOnlyTextFieldProps & { label: string; field: string }) => (
  <TextValidator
    variant="standard"
    fullWidth
    label={label}
    name={field}
    value={value || ''}
    type="text"
    margin="normal"
    disabled
  />
);

export const PasswordTextField = ({ value, changeHandler }: TextFieldProps) => (
  <TextValidator
    variant="standard"
    fullWidth
    label="Password"
    onChange={changeHandler('password', (e) => e.target.value)}
    name="password"
    value={value}
    type="password"
    validators={['minStringLength:1']}
    errorMessages={['password is required']}
    data-testid="password"
    margin="normal"
    autoComplete="current-password"
  />
);

export const UsernameTextField = ({ value, changeHandler }: TextFieldProps) => (
  <TextValidator
    variant="standard"
    fullWidth
    autoFocus
    label="Username"
    onChange={changeHandler('username', (e) => e.target.value)}
    name="username"
    value={value}
    validators={['minStringLength:1']}
    errorMessages={['username is required']}
    data-testid="username"
    margin="normal"
    autoComplete="username"
  />
);

export const TitleTextField = ({ value, changeHandler }: TextFieldProps) => (
  <StringTextField
    label="Title"
    field="title"
    value={value}
    changeHandler={changeHandler}
  />
);

// TODO: Validation rowMax="3" confirm change to rowsMax
export const DescriptionTextField = ({ value, changeHandler }: TextFieldProps) => (
  <TextValidator
    variant="standard"
    fullWidth
    multiline
    rowsMax={3}
    label="Description *"
    onChange={changeHandler('description', (e) => e.target.value)}
    name="description"
    value={value || ''}
    type="text"
    validators={['required']}
    errorMessages={['description is required']}
    margin="normal"
  />
);

export const NameTextField = ({
  value, changeHandler, required, field, label,
}: TextFieldProps) => (
  <StringTextField
    label={label || 'Name'}
    field={field || 'name'}
    value={value}
    required={required}
    changeHandler={changeHandler}
  />
);

export const FirstNameReadOnlyTextField = ({ value }: ReadOnlyTextFieldProps) => (
  <ReadOnlyStringTextField
    label="First Name"
    field="firstName"
    value={value}
  />
);

export const LastNameReadOnlyTextField = ({ value }: ReadOnlyTextFieldProps) => (
  <ReadOnlyStringTextField
    label="Last Name"
    field="lastName"
    value={value}
  />
);

interface EmailTextFieldProps {
  label?: string;
  field?: string;
}
export const EmailTextField = (
  {
    disabled,
    label, field, value, changeHandler, required = true,
  }: TextFieldProps & EmailTextFieldProps & {disabled?: boolean},
) => (
  <TextValidator
    disabled={disabled}
    variant="standard"
    fullWidth
    label={(label || 'Email') + (required ? ' *' : '')}
    onChange={changeHandler(field || 'email', (e) => e.target.value)}
    name="email"
    value={value || ''}
    type="text"
    validators={[required ? 'required' : null, 'isEmail'].filter((v) => v)}
    errorMessages={[
      required ? 'Email is required' : null,
      'you must specify a valid email',
    ].filter((v) => v)}
    margin="normal"
  />
);

export const PhoneNumberTextField = (
  {
    value, changeHandler, required = true, label,
  }: TextFieldProps,
) => (
  <TextValidator
    variant="standard"
    fullWidth
    label={label || 'Phone Number'}
    onChange={changeHandler('phoneNumber', (e) => e.target.value)}
    name="phoneNumber"
    value={value || ''}
    type="text"
    validators={[
      required ? 'required' : null,
      `matchRegexp:${PHONE_NUMBER_REGEX_STRING}`,
    ].filter((v) => v)}
    errorMessages={[
      required ? 'phone number is required' : null,
      'you must specify a valid phone number',
    ].filter((v) => v)}
    margin="normal"
  />
);

export const CommentTextField = ({ value, changeHandler }: TextFieldProps) => (
  <StringTextField
    label="Comment to Nordic Trustee"
    field="comment"
    value={value}
    changeHandler={changeHandler}
    required={false}
  />
);

export const OptionalTextField = ({
  label, field, value, changeHandler,
}: TextFieldProps) => (
  <StringTextField
    label={label || ''}
    field={field || ''}
    value={value}
    changeHandler={changeHandler}
    required={false}
  />
);

type MemTextFieldProps = Omit<BoxProps, 'onChange'> & {
  label: string;
  value?: string;
  boxStyle?: React.CSSProperties | undefined;
  fullWidth?: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  variant?: TextFieldVariants
}

export const MemTextField = memo(
  ({ label, value, onChange, boxStyle, variant="standard", fullWidth=true, ...rest }: MemTextFieldProps) => (
    <Box mt={2} mb={1} style={boxStyle} {...rest}>
      <TextField
        fullWidth={fullWidth}
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant={variant}
      />
    </Box>
  ),
);
