import { PublishedSecurityDTO } from 'api/api.types';
import { SecurityItem } from './security.types';

export const getSecurityItem = (
  security: PublishedSecurityDTO,
  custom?: string,
): SecurityItem => ({
  id: security.publishedSecurityID != null ? security.publishedSecurityID : 0,
  pledgor: security.pledgor != null ? security.pledgor : '',
  agreementSignedDate:
    security.agreementSignedDate != null ? security.agreementSignedDate : '',
  custom: custom != null ? custom : '',
});
