import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classes from './SimpleModal.module.css';

interface ComponentProps {
  title: string;
  content: JSX.Element;
  onClose: () => void;
  small?: boolean;
  xlSize?: 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lgSize?: 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  mdSize?: 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const SimpleModal = ({
  title,
  content,
  onClose,
  small,
  mdSize,
  lgSize,
  xlSize,
}: ComponentProps) => {
  const handleClose = () => {
    onClose();
  };

  const handleClickAway = (_event: MouseEvent | TouchEvent) => {
    handleClose();
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby={title}
      open
      onClose={handleClose}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        className={classes.grid}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={mdSize ?? 10}
          lg={small ? 6 : lgSize ?? 8}
          xl={small ? 4 : xlSize ?? 6}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={handleClickAway}
          >
            <Paper className={classes.paper}>
              <IconButton
                className={classes.close}
                aria-label="Close"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              {content}
            </Paper>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </Modal>
  );
};
