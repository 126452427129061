import { submitNewReporting } from '../../ReportingApi';
import { ReportingSubmitNewDTO } from '../../reportingApi.types';
import { ComplianceReportForm } from './ReportingSubmitForm.types';

export const submitReport = async ({
  loanID,
  complianceScheduleId,
  formData,
}: {
  loanID: string;
  complianceScheduleId: number;
  formData: ComplianceReportForm;
}) => {
  const dataDTO: ReportingSubmitNewDTO = {
    informationCovenantStates: formData.informationCovenants.map((covenant) => {
      return {
        id: covenant.id,
        isAvailableFromPublicSources: covenant.isAvailableFromPublicSources,
      };
    }),
    complianceScheduleItemID: complianceScheduleId,
    comment: formData.comment,
    hasConfirmedNoEventOfDefault: formData.hasConfirmedNoEventOfDefault!,
  };

  return await submitNewReporting(+loanID, complianceScheduleId, dataDTO);
};
