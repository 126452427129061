import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { CaseLogDTO } from 'api/api.types';
import moment from 'moment';
import { useToast } from 'modules/Global';
import {
  deleteMessage,
  MessageToDelete,
} from '../message/message';
import MenuButton from './menu_button/MenuButton';
import ConfirmDialog from './ConfirmDialog';

const confirmHandler = async (
  loanID: number,
  dispatchDelete: (message: MessageToDelete) => Promise<void>,
  setOpen: any,
  handleRefresh: () => void,
  caseLogEntryID: number,
  setDeletingMessage: (deleting: boolean) => void,
) => {
  setDeletingMessage(true);
  await dispatchDelete({ loanID, caseLogEntryID });
  setDeletingMessage(false);
  handleRefresh();
  setOpen(false);
};

interface ListItemComponentProps {
  message: CaseLogDTO;
  divider?: boolean;
  loanID: number;
  handleRefresh: () => void;
}

const MessageInternalListItem = ({
  message,
  divider,
  loanID,
  handleRefresh,
}: ListItemComponentProps) => {
  const { showSuccess, showWarning } = useToast();
  const primary = `${moment(message.date).format('L')} - ${message.userName}`;
  const [open, setOpen] = useState(false);
  const [deletingMessage, setDeletingMessage] = useState(false);

  const dispatchDeleteMessage = async (messageToDelete: MessageToDelete) => {
    try {
      await deleteMessage(messageToDelete);
      showSuccess('The message has been deleted');
    } catch (e) {
      let msg = '';
      if ((e as any).response?.data) {
        msg = (e as any).response.data;
      } else if ((e as any).message) {
        msg = (e as any).message;
      }
      showWarning(
        'Deleting message failed. Note: only the author of the entry can delete it.',
      );
      console.error(msg);
    }
  };

  return (
    <ListItem key={message.text} divider={divider}>
      <ListItemText
        primary={<Typography variant="body2">{primary}</Typography>}
        secondary={<Typography variant="body1">{message.text}</Typography>}
      />
      <ListItemSecondaryAction>
        <MenuButton icon={<MoreVertIcon />}>
          <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
        </MenuButton>
        <ConfirmDialog
          open={open}
          contentText="Are you sure you want to delete this message?"
          handleConfirm={() =>
            confirmHandler(
              loanID,
              dispatchDeleteMessage,
              setOpen,
              handleRefresh,
              message.caseLogEntryID,
              setDeletingMessage,
            )
          }
          handleCancel={() => setOpen(false)}
          loading={deletingMessage}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MessageInternalListItem;
