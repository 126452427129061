import { useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { CardContent, Container, Tab } from '@mui/material';
import { Card } from '@nordictrustee/nt-ui-library';
import { FinancialOverviewDTO } from 'api/api.types';
import { LoadingContainer } from 'components/Loaders';
import { TabHeader } from 'components/TabHeader';
import { useGlobal } from 'modules/Global';
import { CurrencyCodeUniverseEnum } from 'modules/Global/enums';
import { Payments } from './Payments';
import { Accounting, AccountingCollapsible } from './Accounting';
import { CashflowOverview } from './Cashflow';
import { getFinancialOverview } from './FinancialsApi';
import { FinancialOverview } from './Overview';

interface AggregatedFinancialsLoaderProps {
  loading: boolean;
}

const AggregatedFinancialsLoader = ({
  loading,
}: AggregatedFinancialsLoaderProps) => (
  <Card>
    <CardContent>
      <LoadingContainer loading={loading} type="singleList" rows={20} cols={3}>
        <></>
      </LoadingContainer>
    </CardContent>
  </Card>
);

const getLastPartOfUrl = (url: string) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

export const AggregatedFinancials = () => {
  const { user } = useGlobal();
  const currency = useMemo(()=> {
      return user?.reportingCurrency || CurrencyCodeUniverseEnum.Nok;
  },[user])
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(() => getLastPartOfUrl(pathname));
  const [loadingFinancialOverview, setLoadingFinancialOverview] =
    useState<boolean>(false);
  const [financialOverview, setFinancialOverview] =
    useState<FinancialOverviewDTO | null>(null);
  const { activeCompany: company } = useGlobal();
  useEffect(() => {
    let cancel = false;
    if (!company) return;
    (async () => {
      setLoadingFinancialOverview(true);
      const overview = await getFinancialOverview(
        company.companyID,
        currency,
      );
      if (cancel) return;
      setFinancialOverview(overview);
      setLoadingFinancialOverview(false);
    })();
    return ()=> {cancel = true}
  }, [company, company?.companyID, currency]);

  return (
    <Router>
      <TabHeader
        header="Financials"
        value={activeTab}
        onChange={(_e: unknown, v: string) => setActiveTab(v)}
      >
        <Tab
          value="overview"
          label="Overview"
          component={Link}
          to={{ pathname: '/financials/overview' }}
        />
        <Tab
          value="cashflow"
          label="Cashflow"
          component={Link}
          to={{ pathname: '/financials/cashflow' }}
        />
        <Tab
          value="payments"
          label="Payments"
          component={Link}
          to={{ pathname: '/financials/payments' }}
        />
        <Tab
          value="accounting"
          label="Accounting"
          component={Link}
          to={{ pathname: '/financials/accounting' }}
        />
      </TabHeader>
      <Container maxWidth="xl">
        <Switch>
          {!financialOverview && (
            <AggregatedFinancialsLoader loading={loadingFinancialOverview} />
          )}
          {financialOverview && (
            <>
              <Route
                exact
                path="/financials/overview"
                render={() => (
                  <FinancialOverview
                    financialOverview={financialOverview}
                  />
                )}
              />
              <Route
                exact
                path="/financials/cashflow"
                render={() => (
                  <Card isHeader={false} withoutPadding>
                    <CashflowOverview
                      cashflow={
                        financialOverview.financialReporting.cashFlowDTO
                      }
                      loading={loadingFinancialOverview}
                      includeLoanName
                      exchangeToCurrency={financialOverview.currency}
                    />
                  </Card>
                )}
              />
              <Route
                exact
                path="/financials/payments"
                render={() => (
                  <Card isHeader={false} withoutPadding>
                    <Payments
                      interestPayment={
                        financialOverview.financialReporting
                          .interestPaymentDTO
                      }
                      loading={loadingFinancialOverview}
                      includeLoanName
                    />
                  </Card>
                )}
              />
              <Route
                exact
                path="/financials/accounting"
                render={() => (
                  <Card title="Accounting" withoutPadding>
                      {financialOverview.financialReporting.accountingDTO.accountingItemDTOs?.some(
                        (a) => (a.accountingItems?.length || 0) > 1,
                      ) ? (
                        <AccountingCollapsible
                          accounting={
                            financialOverview.financialReporting
                              .accountingDTO
                          }
                          loading={loadingFinancialOverview}
                        />
                      ) : (
                        <Accounting
                          accounting={
                            financialOverview.financialReporting
                              .accountingDTO
                          }
                          loading={loadingFinancialOverview}
                          includeLoanName
                        />
                      )}
                  </Card>
                )}
              />
            </>
          )}
        </Switch>
      </Container>
    </Router>
  );
};

