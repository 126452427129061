import { CardContent, Link } from '@mui/material';
import accounting from 'accounting';
import { LoanDTO } from 'api/api.types';
import moment from 'moment';
import { SubCard } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { FRN_INTEREST_TYPE } from 'modules/Global/constants';
import getISOWithoutTime from 'utils/strings/getISOWithoutTime';
import { LoanOverviewDataList } from '../atoms';

interface ComponentProps {
  loan: LoanDTO;
  loading: boolean;
}

export interface Data {
  [key: string]: any;
}

export const LoanOverviewCurrentInterest = ({
  loan, loading,
}: ComponentProps) => {
  const data: Data = {};

  if (loan.currentInterestPeriodStartDate && loan.currentInterestPeriodEndDate) {
    const currentInterestPeriodString = `${moment(loan.currentInterestPeriodStartDate).format('L')} - `
    + `${moment(loan.currentInterestPeriodEndDate).format('L')}`;
    data['Current Interest Period'] = (
      <Link href={`/loans/${loan.issueID}/financials/payments?interestPaymentDate=${getISOWithoutTime(loan.currentInterestPeriodEndDate)}`}>
        {currentInterestPeriodString}
      </Link>
    );
  }

  data['Interest Type'] = loan.interestType;
  data['Payment Type'] = loan.interestPaymentType;
  data.Frequency = loan.frequency;

  if (loan.interestType === FRN_INTEREST_TYPE) {
    data['Reference Rate'] = loan.referenceRate;
  }

  data.Margin = loan.margin ? `${loan.margin}%` : undefined;
  data['Current Interest Rate'] = loan.nextPeriodInterestRate ? `${loan.nextPeriodInterestRate}%` : undefined;
  data.Amount = loan.interestAmount ? accounting.formatMoney(loan.interestAmount, loan.currency) : undefined;

  return (
    <SubCard title="Current Interest">
      <CardContent>
        <LoadingContainer loading={loading} type="singleList" rows={6} cols={2}>
          <LoanOverviewDataList data={data} />
        </LoadingContainer>
      </CardContent>
    </SubCard>
  );
};

