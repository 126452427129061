import { InterestPaymentItemDTO } from 'api/api.types';
import moment from 'moment/moment';

export const filterPaymentsForSubTab = (
  payments: InterestPaymentItemDTO[],
  subTab: 'presentfuture' | 'past',
) => {
  const endOfDay = moment().endOf('day');
  const isPresentFuture = subTab === 'presentfuture';

  return payments
    .filter(({ paymentDate }) =>
      isPresentFuture ? moment(paymentDate).isSameOrAfter(endOfDay) : moment(paymentDate).isBefore(endOfDay)
    )
    .sort((a, b) =>
      isPresentFuture ? 0 : moment(b.paymentDate).diff(moment(a.paymentDate))
    );
};