import moment from "moment";

export interface EventDateFilterOption {
  id: '1'|'default'|'3'|'4'|'custom';
  text: string;
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  selected: boolean;
}

export const dateRangesEventSearchFilterOptions = (): EventDateFilterOption[] => ([
  {
    id: '1', fromDate: moment().add({ days: -30 }), toDate: moment(), text: 'Past 30 days', selected: false,
  },
  {
    id: 'default', fromDate: moment(), toDate: undefined, text: 'Upcoming', selected: true,
  },
  {
    id: '3', fromDate: moment().startOf('year'), toDate: moment().endOf('year'), text: `${moment().format('YYYY')}`, selected: false,
  },
  {
    id: '4',
    fromDate: moment().add({ years: 1 }).startOf('year'),
    toDate: moment().add({ years: 1 }).endOf('year'),
    text: `${moment().add({ years: 1 }).format('YYYY')}`,
    selected: false,
  },
  {
    id: 'custom', fromDate: moment(), toDate: moment().add({ days: 7 }), text: 'Custom', selected: false,
  },
]);
