import moment from 'moment/moment';
import { ReportingEventDTO } from '../reportingApi.types';

const REPORTING_UPCOMING_WITHIN_DAYS = 30;

export const isEventUpcoming = (event: ReportingEventDTO) => {
  const days = moment(event.deadlineDate)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
  return days >= 0 && days <= REPORTING_UPCOMING_WITHIN_DAYS;
};
