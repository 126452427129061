import { useEffect,useState } from 'react';
import { DocumentDTO } from 'api/api.types';
import { DocumentsListDetails } from 'modules/Documents';
import { getDocuments as reloadDocuments } from 'modules/Documents/DocumentsApi';

export type OpenUploadFormTypes = 'reportingDocuments' | 'otherDocuments';

interface ComponentProps {
  issuerID: number;
  loanID: number;
  isin: string;
  openUploadForm?: OpenUploadFormTypes;
  covenantReportingID?: string;
  reportingDate?: string;
}

export const LoanDocuments = ({
  issuerID, loanID, openUploadForm, covenantReportingID, isin, reportingDate,
}: ComponentProps) => {
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (loanID != null && issuerID != null) {
      reloadDocuments(issuerID, loanID).then((docs) => {
        setDocuments(docs);
        setLoading(false);
      });
    }
  }, [issuerID, loanID]);

  return (
    <DocumentsListDetails
      documents={documents}
      issuerID={issuerID}
      loanID={loanID}
      isin={isin}
      openUploadForm={openUploadForm}
      covenantReportingID={covenantReportingID}
      reportingDate={reportingDate}
      onUploadSuccess={async () => {
        setDocuments(await reloadDocuments(issuerID, loanID));
      }}
      loading={loading}
    />
  );
};
