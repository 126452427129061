import { useCallback } from 'react';
import { useSearchParams } from 'utils/hooks';

export const subTabs = ['presentfuture', 'past'] as const;
export type SubTabParam = typeof subTabs[number];

let preferredSubTab: SubTabParam = 'presentfuture';

export const useFinancialParams = (init: SubTabParam = preferredSubTab) => {
  const [{ subTab }, setSearchParams] = useSearchParams<{
    subTab: 'presentfuture' | 'past';
  }>(previous => ({
    ...previous,
    subTab: previous?.subTab ?? init,
  }));
  const setSubTab = useCallback(
    (subTab: SubTabParam) =>
      setSearchParams((previous) => ({
        ...previous,
        subTab
      })),
    [setSearchParams]
  );
  if(subTab) preferredSubTab = subTab; // Remember the preferred subTab when switching main tab
  return { subTab: subTab ?? init, setSubTab };
};