import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { InterestPaymentItemDTO } from 'api/api.types';
import moment from 'moment/moment';
import { Money } from '../../atoms';
import { PaymentDetails } from './PaymentDetails';
import classes from './PaymentTableRow.module.css';

interface InterestPaymentRowProps {
  interestPayment: InterestPaymentItemDTO;
  loading: boolean;
  selected: boolean;
  includeLoanName: boolean;
}

export const PaymentTableRow = ({
  interestPayment,
  loading,
  selected,
  includeLoanName,
}: InterestPaymentRowProps) => {

  return (
    <TableRow selected={selected} className={classes.row}>
      {includeLoanName && (
        <TableCell component="td" scope="row">
          <Typography variant="body1">{interestPayment.isin}</Typography>
        </TableCell>
      )}
      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {moment(interestPayment.paymentDate).format('L')}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {`${moment(interestPayment.startDate).format('L')} - ${moment(interestPayment.paymentDate).format('L')}`}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <Typography variant="body1">{interestPayment.days}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body1">
          {interestPayment.quotationDate
            ? moment(interestPayment.quotationDate).format('L')
            : 'N/A'}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <Typography
          className={classes.typography}
          variant="body1"
          color={
            !interestPayment.coupon && interestPayment.projectedCoupon
              ? 'secondary'
              : 'textPrimary'
          }
        >
          {interestPayment.coupon && `${interestPayment.coupon}%`}
          {!interestPayment.coupon && interestPayment.projectedCoupon && (
            <>
              {`${interestPayment.projectedCoupon}%`}
              <Tooltip title="Based on latest available relevant reference rate.">
                <InfoOutlined />
              </Tooltip>
            </>
          )}
          {!interestPayment.coupon && !interestPayment.projectedCoupon && '-'}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row" align="left">
        <Typography variant="body1">{interestPayment.currency}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography
          className={classes.typography}
          variant="body1"
          color={
            !interestPayment.interestAmount &&
            interestPayment.projectedInterestPayment
              ? 'secondary'
              : 'textPrimary'
          }
        >
          <Money
            value={
              interestPayment.interestAmount ??
              (interestPayment.projectedInterestPayment
                ? interestPayment.projectedInterestPayment * -1
                : undefined)
            }
          />
          {!interestPayment.interestAmount &&
            interestPayment.projectedInterestPayment && (
              <Tooltip title="Payment projected based on latest available relevant reference rate.">
                <InfoOutlined />
              </Tooltip>
            )}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <Typography variant="body1">
          <Money value={interestPayment.repaymentAmount} />
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row" align="right">
        <Typography
          className={classes.typography}
          variant="body1"
          color={
            !interestPayment.totalAmount &&
            interestPayment.projectedInterestPayment
              ? 'secondary'
              : 'textPrimary'
          }
        >
          {!interestPayment.totalAmount &&
            interestPayment.projectedInterestPayment && (
              <>
                <Money
                  value={
                    (interestPayment.projectedInterestPayment ?? 0) * -1 +
                    (interestPayment.repaymentAmount ?? 0)
                  }
                />
                <Tooltip title="Payment projected based on latest available relevant reference rate.">
                  <InfoOutlined />
                </Tooltip>
              </>
            )}
          {!!interestPayment.totalAmount && (
            <Money value={interestPayment.totalAmount} />
          )}
        </Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <PaymentDetails
          interestPayment={interestPayment}
          loading={loading}
        />
      </TableCell>
    </TableRow>
  );
};
