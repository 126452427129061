import { createContext, FC, ReactNode,useCallback, useContext } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { ThemedToastContainer } from './ThemedToastContainer';

interface ToastContextValue {
  showSuccess: (message: string) => void;
  showWarning: (message: string) => void;
  showError: (message: string) => void;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

const showToast = (message: string, opts: ToastOptions) => {
    toast( message, {
      ...opts,
        autoClose: ['warning', 'error'].includes(opts.type || '') ? false : 6000
      });
  };
export const ToastProvider: FC<{children: ReactNode}> = ({ children }) => {
  const showSuccess = useCallback(
    (message: string) =>
      showToast(message, { type: 'success' }),
    [],
  );
  const showWarning = useCallback(
    (message: string) =>
      showToast(message, { type: 'warning' }),
    [],
  );
  const showError = useCallback(
    (message: string) =>
      showToast(message, { type: 'error' }),
    [],
  );

  return (
    <ToastContext.Provider value={{ showSuccess, showWarning, showError }}>
      {children}
      <ThemedToastContainer />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
