import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import classes from './SubmitButton.module.css';

interface ComponentProps {
  text: string;
  loading?: boolean;
  disabled?: boolean;
  variant?: 'contained' | 'outlined';
  width?: number;
}

export const SubmitButton = ({
  text, loading, disabled, variant, width,
}: ComponentProps) => (
  <Button
    type="submit"
    fullWidth={!width || width === 100}
    variant={variant || 'contained'}
    color="primary"
    className={classes.button}
    disabled={disabled === true || loading === true}
    style={width && width < 100 ? { width: `${width}%` } : {}}
  >
    <span>
      {text}
    </span>
    {loading && <CircularProgress size={20} className={classes.buttonIcon} color="primary" />}
  </Button>
);

