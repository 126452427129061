import React, { useState } from 'react';
import { CompanyDTO } from '../../types';
import { Typography } from '@mui/material';
import { BalanceSheet } from './BalanceSheet/BalanceSheet';

export const BalanceSheetOpener: React.FC<{ company?: CompanyDTO; companies: CompanyDTO[] }> =
  ({
     company,
     companies,
   }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <Typography id="openModal" onClick={() => setOpen(true)}/>
        <BalanceSheet
          company={company}
          companies={companies}
          open={open}
          onClose={() => setOpen(false)}
        />
      </>
    );
  };