import { Dispatch, FC, SetStateAction } from 'react';
import { MuiChipsInput } from 'mui-chips-input';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import classes from '../DocumentsUploadForm.module.css';

const validEmailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?(25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.)((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
);

interface EmailRecipients {
  totalFileSize: number;
  documentType: HubDocumentTypeUniverseEnum;
  emailRecipients: any[];
  setEmailRecipients: Dispatch<SetStateAction<string[]>>;
}

type ComponentProps = EmailRecipients;

export const ReportingMarketEmailFormFields: FC<ComponentProps> = ({
  documentType,
  emailRecipients,
  setEmailRecipients,
  totalFileSize,
}) => {
  if (
    ![
      HubDocumentTypeUniverseEnum.Market,
      HubDocumentTypeUniverseEnum.Reporting,
    ].includes(documentType)
  ) {
    return null;
  }
  const handleAddEmailChip = (chip: any) => {
    setEmailRecipients((prevState) => [...(prevState || []), chip]);
  };

  const handleAddEmailChipValidation = (chip: any): boolean => {
    if (emailRecipients.includes(chip as string)) {
      return false;
    }
    return validEmailPattern.test(chip) && chip.split('@').length === 2;
  };

  const handleDeleteEmailChip = (chip: any) => {
    const filteredRectipients = emailRecipients.filter(
      (email) => email !== chip,
    );
    setEmailRecipients(filteredRectipients);
  };

  const handleEmailChipKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const nonAttachableFileSize = totalFileSize >= 19.5;
  return (
    <MuiChipsInput
      variant="standard"
      label="Email recipients"
      fullWidth
      helperText="Note: Recipients will not be notified if total file size exceeds 19.5 MB"
      onAddChip={(chip) => handleAddEmailChip(chip)}
      onDeleteChip={(chip) => handleDeleteEmailChip(chip)}
      validate={(chip) => handleAddEmailChipValidation(chip)}
      addOnWhichKey={['Enter', ' ', ',', ';']}
      onKeyPress={handleEmailChipKeyPress}
      placeholder="Use Enter or Space to add multiple email addresses"
      value={emailRecipients}
      FormHelperTextProps={{
        className: nonAttachableFileSize
          ? classes.helperTextWarning
          : classes.helperTextDefault,
      }}
    />
  );
};
