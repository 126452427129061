import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { StyledCardHeader, SubCard } from 'components/Card';
import { TextParagraph } from './TextParagraph';

export const UserAgreement = () => (
  <Container maxWidth="xl">
    <Box mt={2} mb={2}>
      <Grid container>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item lg={3} />
              <Grid item lg={6}>
                <SubCard>
                  <StyledCardHeader title="GENERAL TERMS AND CONDITIONS FOR THE USE OF THE ISSUER PORTAL" />
                  <CardContent>
                    <TextParagraph header="">
                      <Typography variant="body1">
                        These general terms and conditions (the
                        <strong>“General Terms and Conditions”</strong>
                        ) govern a User’s individual right to use and access the Issuer Portal.
                        If a User fails to comply with any provision contained in these terms
                        and conditions or uses the Issuer Portal in conflict with these General
                        Terms and Conditions, it may lose its right to access and use the Issuer
                        Portal. A User’s access and use of the Issuer Portal may also be governed
                        by an applicable agency agreement between Nordic Trustee in its capacity
                        as bond trustee and the Issuer. In the event of any inconsistency with
                        these General Terms and Conditions, the terms of such agency agreement shall
                        prevail.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Definitions">
                      <Typography>
                        <strong>“Bond” </strong>
                        means any bond(s) or other financial instrument registered with a Central Securities Depository,
                        which is included in the Issuer Portal from time to time.
                      </Typography>
                      <br />
                      <Typography>
                        <strong>“Issuer” </strong>
                        means a company which has issued one or more Bonds.
                      </Typography>
                      <br />
                      <Typography>
                        <strong>“Issuer Portal” </strong>
                        means the digital, web-based service platform designed and developed (and exclusively owned)
                        by Nordic Trustee for issuers of Bonds to support issuers with their loan administration
                        which is accessible at
                        <Link href="https://issuerportal.nordictrustee.com" target="_blank"> https://issuerportal.nordictrustee.com</Link>
                        .
                      </Typography>
                      <br />
                      <Typography>
                        <strong>“Nordic Trustee” </strong>
                        means, as applicable, Nordic Trustee AS, Nordic Trustee & Agency AB (publ), Nordic Trustee Oy,
                        Nordic Trustee A/S or any of its affiliates.
                      </Typography>
                      <br />
                      <Typography>
                        <strong>“User” </strong>
                        means a natural person having been approved by the Issuer and duly authorized by Nordic Trustee
                        to access and use the Issuer Portal in respect of one or more Bonds, in each case identifiable
                        by its unique email address.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Account safety">
                      <Typography>
                        Users may be required to create an account (an
                        {' '}
                        <strong>“Account”</strong>
                        ) and provide Nordic Trustee with a username, password, and certain other required information.
                        The User is solely responsible for the information associated with an Account. An Account is
                        personal, and the User shall not give any other person access to an Account without Nordic Trustee’s
                        prior written approval. The User shall promptly notify Nordic Trustee if unpermitted accessed is
                        suspected or discovered.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Content on the Issuer Portal">
                      <Typography>
                        Data or data files derived from the Issuer Portal may not be redistributed, in part or whole, in any
                        form, by a User, without prior written consent from Nordic Trustee.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Information on the Issuer Portal">
                      <Typography>
                        Information on the Issuer Portal is provided by Nordic Trustee on a best effort basis for the Issuer’s
                        and User’s convenience. Nordic Trustee shall have no responsibility for the information on the Issuer
                        Portal. Nordic Trustee does not verify any User-submitted content, but may view, store and process any
                        such content. The User shall promptly inform Nordic Trustee of any errors or inconsistencies in the
                        information on the Issuer Portal.
                      </Typography>
                      <br />
                      <Typography>
                        The Issuer Portal is an information platform for convenience only and does not affect any obligations
                        under any underlying financial arrangements of a Bond. In the event of inconsistencies between the
                        information in the Issuer Portal and the applicable legal documents governing the Issuer’s obligations
                        in respect of the relevant Bond(s), the latter will prevail. In particular, users should be aware
                        there may be undertakings, obligations and deadlines pursuant to the underlying financial documentation
                        that are not visible in the Issuer Portal.
                      </Typography>
                      <br />
                      <Typography>
                        Nordic Trustee does not guarantee that the Issuer Portal is up-to-date, complete, free of errors or available
                        at all times, and does not take any responsibility for the accuracy, completeness or timeliness of any
                        information or documentation provided by Nordic Trustee in the Issuer Portal. Nordic Trustee is in certain
                        aspects of the Issuer Portal dependent on receipt of information from third parties in order to provide up-to-date
                        information on the Issuer Portal. Nordic Trustee will not be responsible for the unavailability, delay or
                        incorrectness of information on the Issuer Portal arising from the acts or omissions by any third parties.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Modifications to the Issuer Portal">
                      <Typography>
                        Nordic Trustee reserves the right to modify, add or remove, in part or in whole, material, content and
                        features presented on the Issuer Portal at any time.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Intellectual Property rights">
                      <Typography>
                        Nordic Trustee has the right to any information and other material (including texts, picture material, graphical
                        interfaces and databases) on the Issuer Portal. Users are not permitted to make any copies or redistribution of
                        any information or other material without the prior written consent from Nordic Trustee. Users of the Issuer Portal
                        are not permitted to make any amendments of any information of the Issuer Portal, or otherwise take any actions
                        which commercially exploit the Issuer Portal.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Bond Trustee Provision">
                      <Typography>
                        The User acknowledges that Nordic Trustee in its capacity as bond trustee (the “Bond Trustee”) in respect of a Bond
                        shall never be deemed to have knowledge of any fact or circumstance evident from the Issuer Portal.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Limitation of liability and disclaimer">
                      <Typography>
                        Nordic Trustee shall never be liable towards the User for any damage or loss of any kind arising from or relating to
                        the Issuer Portal. Nordic Trustee will use its best efforts to maintain adequate security to prevent unlawful access
                        by third parties to Nordic Trustee’s infrastructures.
                      </Typography>
                      <br />
                      <Typography>
                        No content and data available on the Issuer Portal shall be construed as Nordic Trustee providing financial, investment,
                        legal, tax or other advice of any kind.
                      </Typography>
                    </TextParagraph>
                    <TextParagraph header="Personal data">
                      <Typography>
                        All personal data provided by a User will be handled in accordance with relevant data protection regulations from time
                        to time, and in accordance with Nordic Trustee’s privacy statement available at
                        <Link target="_blank" href="https://nordictrustee.com/about/governance"> https://nordictrustee.com/about/governance</Link>
                        .
                      </Typography>
                      <br />
                      <Typography>
                        The personal data collected through the Issuer Portal is only for internal use and will not be handed over to any external
                        parties. Nordic Trustee only collects information that is required to fulfill the purpose of the actual service, however
                        Nordic Trustee may use external technology providers (such as Hotjar and Google Analytics) in order to use data for
                        better understanding of Nordic Trustee’s customers’ needs and to optimize the Issuer Portal. Such external providers may
                        use cookies and other technologies to collect data on Nordic Trustee’s users’ behavior and their devices. This data does
                        not contain any personally identifiable information and will never be used to identify individual users. You may opt-out
                        of Hotjar’s tracking service by visiting
                        <Link target="_blank" href="https://www.hotjar.com/legal/compliance/opt-out"> https://www.hotjar.com/legal/compliance/opt-out </Link>
                        and opt-out of Google Analytics by downloading Google’s official browser add-on here:
                        <Link target="_blank" href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</Link>
                        .
                      </Typography>
                      <br />
                      <Typography>
                        More information about Nordic Trustee’s use of cookies can also be found at
                        <Link target="_blank" href="https://www.nordictrustee.com/privacy/"> https://www.nordictrustee.com/privacy/</Link>
                        .
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Maintenance of the Issuer Portal">
                      <Typography>
                        For IT maintenance purposes, Nordic Trustee may access the Issuer Portal from a User’s view in relation to a Bond without
                        prior notice to the Issuer or the User.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Termination of a User's access to the Issuer Portal">
                      <Typography>
                        Nordic Trustee may, by written notice to the User, suspend or terminate a User’s access to the Issuer Portal in case of (i)
                        any breach or misuse by a the User or these General Terms and Conditions, (ii) unpaid fees in respect of the Issuer Portal
                        (to the extent applicable) or (iii) if Nordic Trustee for any reason is unable to provide access to the Issuer Portal and the
                        services contemplated by this Agreement.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Assignments">
                      <Typography>
                        No User may assign any of its rights and obligations under these General Terms and Conditions to another party without the prior
                        written consent from Nordic Trustee.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Fees payable in respect of the Issuer Portal">
                      <Typography>
                        The Issuer Portal is an integrated part of Nordic Trustee&apos;s service offering as bond trustee. Nordic
                        Trustee may introduce a fee for any additional services or features subject in each case to the user&apos;s
                        consent.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Notices">
                      <Typography>
                        Any notice or other communication to be made to Nordic Trustee in relation to these General Terms and Conditions shall be sent to
                        the following email address:

                        <br />
                        <br />
                        <Link target="_blank" href="mailto:portals@nordictrustee.com">portals@nordictrustee.com</Link>
                        {' '}
                        or as may be notified by Nordic Trustee from time to time.

                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Changes to these General Terms and Conditions">
                      <Typography>
                        Nordic Trustee may change these General Terms and Conditions at any time and will notify the User of any changes by sending an email
                        to the address(es) associated with the User. By continuing to use the Issuer Portal after the date of revision, the User agrees to
                        be bound by the changes.
                      </Typography>
                    </TextParagraph>

                    <TextParagraph header="Governing Law and Jurisdiction">
                      <Typography>
                        These General Terms and Conditions shall be exclusively governed by the laws of Norway. All disputes arising out of, or in connection
                        with these General Terms and Conditions shall be exclusively resolved by the courts of Norway, with the District Court of Oslo
                        as first legal venue.
                      </Typography>
                    </TextParagraph>
                  </CardContent>
                </SubCard>
              </Grid>
              <Grid item lg={3} />
            </Grid>
          </CardContent>
        </Card>

      </Grid>
    </Box>
  </Container>
);
