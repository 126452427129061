import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { LabelContentRow as Row } from 'components/Table';
import classes from './LoanOverviewDataList.module.css';

export interface Data<T> {
  [key: string]: T;
}

interface ComponentProps {
  data: Data<any>;
}

export const LoanOverviewDataList = ({
  data
}: ComponentProps) => (
  <Table size="small">
    <TableBody>
      {Object.keys(data).map((key) => (
        <Row key={key} label={key} alignRight>
          { (key === 'Status' && data[key] !== '') ? <div className={classes.valueCellAlert}>{data[key]}</div> : data[key] || 'N/A'}
        </Row>
      ))}
    </TableBody>
  </Table>
);

