import React from 'react';
import { MaterialUiChangeHandler } from 'legacy/inputHandlers';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Countries from 'i18n-iso-countries';

interface ComponentProps {
  countryCode?: string;
  changeHandler: MaterialUiChangeHandler<any>;
}

const SelectCountry = ({ countryCode, changeHandler }: ComponentProps) => {
  const countriesList = Countries.getNames('en');

  return (
    <TextField
      select
      fullWidth
      label="Country"
      name="countryCode"
      value={countryCode || ''}
      onChange={changeHandler('countryCode', (e) => e.target.value)}
      margin="normal"
      style={{ textAlign: 'left' }}
      variant="standard"
    >
      {Object.keys(countriesList).map((code) => <MenuItem key={code} value={code}>{countriesList[code]}</MenuItem>)}
    </TextField>
  );
};

export default SelectCountry;
