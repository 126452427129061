import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment/moment';
import getISOWithoutTime from 'utils/strings/getISOWithoutTime';
import { useReporting } from '../ReportingContext';

const isValidDate = (date: Moment | null) => !!date && date.isValid();
export const useFromToParams = () => {
  const defaultFromDate = getISOWithoutTime(
    moment.utc().add(-3, 'M').toISOString(),
  );
  const defaultToDate = getISOWithoutTime(
    moment.utc().add(1, 'y').toISOString(),
  );
  const { from, to, setFilter } = useReporting();

  const [fromDateFilter, setFromDateFilter] = useState<Moment | null>(
    from ? moment.utc(from) : moment.utc(defaultFromDate),
  );
  const [toDateFilter, setToDateFilter] = useState<Moment | null>(
    to ? moment.utc(to) : moment.utc(defaultToDate),
  );

  useEffect(() => {
    if (!fromDateFilter || !toDateFilter) {
      return;
    }
    const fromDateFormatted = getISOWithoutTime(fromDateFilter.toISOString());
    const toDateFormatted = getISOWithoutTime(toDateFilter.toISOString());
    if (from !== fromDateFormatted || to !== toDateFormatted) {
      setFilter({ from: fromDateFormatted, to: toDateFormatted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, fromDateFilter, to, toDateFilter]);

  return {
    fromDateFilter,
    setFromDateFilter: (date: Moment | null) => {
      if (isValidDate(date)) {
        setFromDateFilter(date);
      }
    },
    toDateFilter,
    setToDateFilter: (date: Moment | null) => {
      if (isValidDate(date)) {
        setToDateFilter(date);
      }
    },
  };
};
