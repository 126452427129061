import { CardContent, Grid, Link, Typography } from '@mui/material';
import { SubCard } from 'components/Card';

export const PaymentFAQ = () => (
  <Grid container>
    <Grid item sm={12}>
      <SubCard title="Register Payments">
        <CardContent>
          <Typography variant="body1">
            Please use the link below to register interest and redemption payments through Euroclear.
            <br />
            <Link href="https://portal.euroclear.eu/bondpayments/" target="_blank">
              Register payments through BondPayments
            </Link>
          </Typography>
        </CardContent>
      </SubCard>
      <SubCard title="Euroclear Portal">
        <CardContent>
          <Typography variant="body1">
            In order to register payments with Euroclear, a person needs to be authorised to access Euroclear’s portal.
            Please use the link below for more information on how to gain access.
            <br />
            <Link href="https://www.euroclear.com/sweden/en/logga-in.html" target="_blank">Euroclear&quot;s portal page</Link>
          </Typography>
        </CardContent>
      </SubCard>
      <SubCard title="Rules and Regulations">
        <CardContent>
          <Typography variant="body1">
            The deadline for registering a payment with Euroclear varies depending on the type of
            payment (interest or redemption, whether the payment is scheduled or unscheduled, etc.).
            Please click the link below for more detailed information.
            <br />
            <Link href="https://www.euroclear.com/sweden/en/regelverk-Euroclear-Sweden.html" target="_blank">
              Euroclear rules and regulations
            </Link>
          </Typography>
        </CardContent>
      </SubCard>
    </Grid>
  </Grid>
);
