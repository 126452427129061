import { Box, Card, CardContent, Grid } from '@mui/material';
import { EmployeeDTO, LoanDTO } from 'api/api.types';
import { EventDTO, EventTypeUniverseEnum } from 'modules/Events/types';
import { LoanSummaryProgress } from '../../atoms';
import { isLoanActive } from '../../utils';
import { LoanOverviewAmountChart as AmountChart } from './AmountChart';
import { Amounts } from './Amounts';
import { Basic } from './Basic';
import { Contact } from './Contact';
import { CurrentInterest } from './CurrentInterest';
import { Dates } from './Dates';
import classes from './LoanDetails.module.css';

interface LoanDetailsProps {
  loan: LoanDTO | undefined;
  contact: EmployeeDTO | undefined;
  events: EventDTO[];
  loading: boolean;
}

const isIssueDate = (event: EventDTO, loan: LoanDTO) =>
  event.eventType === EventTypeUniverseEnum.InterestPaymentDate &&
  loan.issueDate === event.date;
const isMaturityDate = (event: EventDTO, loan: LoanDTO) =>
  event.eventType === EventTypeUniverseEnum.InterestPaymentDate &&
  loan.maturityDate === event.date;
const isCallDate = (event: EventDTO) =>
  event.eventType === EventTypeUniverseEnum.CallDate;
const isInstallmentDate = (event: EventDTO) =>
  event.eventType === EventTypeUniverseEnum.InstallmentDate;

export const LoanDetails = ({
  loan,
  contact,
  events,
  loading,
}: LoanDetailsProps) => (
  <Card className={classes.loanDetailsCard}>
    <CardContent>
      <Box display={{ xs: 'none', sm: 'block' }} displayPrint="none">
        {loan && (
          <LoanSummaryProgress
            loan={loan}
            events={events.filter(
              (e) =>
                isIssueDate(e, loan) ||
                isMaturityDate(e, loan) ||
                isCallDate(e) ||
                isInstallmentDate(e),
            )}
            legendFormat="year"
            legendDisplay="side"
          />
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {loan && (
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Basic loan={loan} loading={loading} />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrentInterest loan={loan} loading={loading} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Amounts loan={loan} loading={loading} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Dates loan={loan} loading={loading} />
              </Grid>
            </Grid>
          )}
        </Grid>
        {loan && isLoanActive(loan) && (
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                { loan.isTapIssue && <AmountChart loan={loan} loading={loading} />}
              </Grid>
              <Grid item md={6} xs={12}>
                {loan.issueID && contact && (
                  <Contact
                    contact={contact}
                    loanID={loan.issueID}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
);
