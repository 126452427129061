import React, { useEffect, useMemo, useState } from 'react';
import { CompanyDTO } from 'modules/Global/types';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { ButtonPopper } from './ButtonPopper';
import { useGlobal } from '../../Providers';

const ACTIVE_COMPANY_MAX_LENGTH = 20;

interface OwnProps {
  companies: CompanyDTO[];
}

type ComponentProps = OwnProps;
export const CompanySelect = ({ companies }: ComponentProps) => {
  const [open, setOpen] = useState(false);
  const sortedCompanies = useMemo(() => {
    const companiesToSort = [...companies];
    companiesToSort.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return companiesToSort;
  }, [companies]);
  const { activeCompany, setActiveCompany } = useGlobal();
  useEffect(() => {
    if (!activeCompany || activeCompany.companyID === -1) {
      setActiveCompany(sortedCompanies[0]);
    }
  }, [sortedCompanies]);
  if (companies.length > 1 && activeCompany)
    return (
      <ButtonPopper
        open={open}
        setOpen={setOpen}
        buttonContent={
          <>
            {activeCompany.name.length > ACTIVE_COMPANY_MAX_LENGTH
              ? `${activeCompany.name.substring(0, ACTIVE_COMPANY_MAX_LENGTH)}...`
              : activeCompany.name}
          </>
        }
      >
        {sortedCompanies.map((company) => (
          <MenuItem
            selected={company.companyID === activeCompany.companyID}
            key={company.companyID}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setActiveCompany(company);
              setOpen(false);
            }}
            component={Link}
            to="/dashboard"
          >
            {company.name}
          </MenuItem>
        ))}
      </ButtonPopper>
    );
  return null;
};
