import { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import { LoadingContainer } from 'components/Loaders';
import classes from './EventOfDefault.module.css';

export const EventOfDefault: FC<{ hasConfirmedNoEventOfDefault: boolean|null, isLoading: boolean }> = ({ hasConfirmedNoEventOfDefault, isLoading }) => {

  const eodText = useMemo(()=>{
    if(hasConfirmedNoEventOfDefault === true){
      return 'yes';
    }
    if(hasConfirmedNoEventOfDefault === false){
      return 'no';
    }
    return '';
  },[hasConfirmedNoEventOfDefault]);  

  return (
    <div className={classes.container}>
      <LoadingContainer loading={isLoading} type="singleList" rows={1} cols={2}>
        <div className={classes.row}>
          <Typography variant="body1" color="textSecondary" pb={1}>Confirmed No Event Of Default</Typography>
          <Typography>{eodText}</Typography>
        </div>
      </LoadingContainer>
    </div>
  );
};