import { ReactElement } from 'react';
import Table, { TableProps } from '@mui/material/Table';
import classes from './FixedTable.module.css';

interface FixedTableProps {
  children: ReactElement<TableProps>[] | ReactElement<TableProps>;
}

export const FixedTable = ({
  children
}: FixedTableProps ) => (
  <Table size="small" className={classes.table}>
    {children}
  </Table>
);

