
export enum ChangeStateEnum {
    Unchanged = 'Unchanged',
    Modified = 'Modified',
    Added = 'Added',
    Deleted = 'Deleted'
}

export enum HubRoleUniverseEnum {
    LeadManager = 'LeadManager',
    Issuer = 'Issuer'
}
export enum CurrencyCodeUniverseEnum {
    Aud = 'AUD',
    Cad = 'CAD',
    Chf = 'CHF',
    Cny = 'CNY',
    Dkk = 'DKK',
    Eur = 'EUR',
    Gbp = 'GBP',
    Hkd = 'HKD',
    Isk = 'ISK',
    Jpy = 'JPY',
    Mxn = 'MXN',
    Nok = 'NOK',
    Pln = 'PLN',
    Sek = 'SEK',
    Sgd = 'SGD',
    Try = 'TRY',
    Usd = 'USD',
    Zar = 'ZAR'
}
export enum CentralSecurityDepositoryUniverseEnum {
    NoVpsNorway = 'NO_VPS_Norway',
    SeEuroclearSweden = 'SE_Euroclear_Sweden',
    FiEuroclearFinland = 'FI_Euroclear_Finland',
    DkVpDenmark = 'DK_VP_Denmark',
    XsEuroclearEurope = 'XS_Euroclear_Europe',
    LuClearstreamEurope = 'LU_Clearstream_Europe',
    IsNasdaqIceland = 'IS_Nasdaq_Iceland',
    FrEuroclearFrance = 'FR_EuroclearFrance',
    DeClearstream = 'DE_Clearstream',
    Nocsd = 'NOCSD'
}

export enum IssueTypeStamdataUniverseEnum {
    Bonds = 'Bonds',
    Cds = 'CDs',
    Convertibles = 'Convertibles',
    LinkedNotes = 'LinkedNotes',
    Warrants = 'Warrants',
    AdditionalTier1 = 'AdditionalTier1',
    CreditLinkedNotes = 'CreditLinkedNotes',
    Tier2 = 'Tier2',
    Tier3 = 'Tier3',
    ClaimInterest = 'ClaimInterest',
    ClaimRedemption = 'ClaimRedemption'
}


export enum IssueTypeUniverseEnum {
    Bond = 'Bond',
    Cd = 'CD',
    Convertible = 'Convertible',
    Tier2 = 'Tier2',
    LinkedNotes = 'LinkedNotes',
    Miscellaneous = 'Miscellaneous',
    Warrants = 'Warrants',
    Tier1 = 'Tier1',
    CreditLinkedNotes = 'CreditLinkedNotes',
    Exchangeable = 'Exchangeable',
    DirectLending = 'DirectLending',
    Tier3 = 'Tier3',
    ClaimInterest = 'ClaimInterest',
    ClaimRedemption = 'ClaimRedemption'
}

export enum TrusteeUniverseEnum {
    DnBnorBankAsa = 'DnBNORBankASA',
    KongeriketSverige = 'KongeriketSverige',
    IntertrustFinland = 'IntertrustFinland',
    InactiveNordeaHypotekAb = 'InactiveNordeaHypotekAB',
    InactiveSparebankenMidtNorge = 'InactiveSparebankenMidtNorge',
    InactiveKommunalbankenAs = 'InactiveKommunalbankenAS',
    NordicTrusteeFi = 'NordicTrusteeFI',
    LawDebentureTrustCorpPlc = 'LawDebentureTrustCorpPLC',
    InactiveSebEnskildaAs = 'InactiveSEBEnskildaAS',
    IntertrustSweden = 'IntertrustSweden',
    InactiveSparebankenNordNorge = 'InactiveSparebankenNordNorge',
    Sparebank1SrBank = 'Sparebank1SRBank',
    IntertrustNorwayAs = 'IntertrustNorwayAS',
    DeutscheTrusteeCompanyLimited = 'DeutscheTrusteeCompanyLimited',
    VestenfjelskeBykredittAs = 'VestenfjelskeBykredittAS',
    InactiveSectorSpeculareAs = 'InactiveSectorSpeculareAS',
    InactiveCreditSuisseInternational = 'InactiveCreditSuisseInternational',
    LandwirtschaftlicheRentenbank = 'LandwirtschaftlicheRentenbank',
    DanskeBank = 'DanskeBank',
    InactiveSkandinaviskaEnskildaBankenOslo = 'InactiveSkandinaviskaEnskildaBankenOslo',
    NoTrustee = 'NoTrustee',
    NordicTrusteeDk = 'NordicTrusteeDK',
    InactiveLandkreditt = 'InactiveLandkreditt',
    NordicTrusteeNo = 'NordicTrusteeNO',
    NordicTrusteeSe = 'NordicTrusteeSE',
    InactiveNordlandsbankenAsa = 'InactiveNordlandsbankenASA',
    InactiveBankersTrustInternationalPlc = 'InactiveBankersTrustInternationalPLC',
    CitibankNaLondonBranch = 'CitibankNALondonBranch',
    OtherTrustee = 'OtherTrustee',
    CiticorpTrusteeCompanyLimited = 'CiticorpTrusteeCompanyLimited',
    SparebankenVest = 'SparebankenVest',
    AhlfordAdvokatbyrAb = 'AhlfordAdvokatbyr??AB',
    InactiveMunicipalityFinancePlc = 'InactiveMunicipalityFinancePLC',
    InactiveBoligOgNringsbankenAsa = 'InactiveBoligOgN??ringsbankenASA',
    NordeaBankNorgeAsa = 'NordeaBankNorgeASA',
    InactiveDenNorskeBankAsa = 'InactiveDenNorskeBankASA',
    InactiveLandshypotekAb = 'InactiveLandshypotekAB',
    InactiveASresundsforbindelsen = 'InactiveAS??resundsforbindelsen',
    FokusBankAsa = 'FokusBankASA',
    BankersTrustInternationalPlc = 'BankersTrustInternationalPLC',
    UsBankTrusteesLimited = 'USBankTrusteesLimited',
    InactiveNorgeskredittAs = 'InactiveNorgeskredittAS',
    NorgesBank = 'NorgesBank',
    DnBnorHypotekAs = 'DnBNORHypotekAS',
    SbankenAsa = 'SbankenASA'
}

export enum SecurityDetailTypeUniverseEnum {
    Guarantee = 'Guarantee',
    AccountPledge = 'AccountPledge',
    RigVesselMortgage = 'RigVesselMortgage',
    SharePledge = 'SharePledge',
    Other = 'Other',
    NorwegianPropertyMortgage = 'NorwegianPropertyMortgage',
    PropertyMortgage = 'PropertyMortgage',
    NorwegianSharePledge = 'NorwegianSharePledge',
    SwedishPropertyMortgage = 'SwedishPropertyMortgage',
    // Securities types below should never be display
    //
    // InterCreditorAgreement = 'InterCreditorAgreement',
    // QuietEnjoymentLetter = 'QuietEnjoymentLetter',
    // LegalOpinion = 'LegalOpinion',
    // Indemnity = 'Indemnity',
}

export enum FinancialReportingRowTypeEnum {
    Tranche = 'Tranche',
    InterestPayment = 'InterestPayment',
    QuarterEnd = 'QuarterEnd',
    YearEnd = 'YearEnd',
    MonthEnd = 'MonthEnd'
}
export enum HubDocumentTypeUniverseEnum {
    Loan = 'Loan',
    Market = 'Market',
    Reporting = 'Reporting',
    Internal = 'Internal',
    Bond = 'Bond'
}
