import { submitUpdatedReporting } from '../../ReportingApi';
import { ReportingSubmitUpdateDTO } from '../../reportingApi.types';
import { ComplianceReportForm } from './ReportingSubmitForm.types';

export const updateReport = async ({
  loanID,
  complianceScheduleId,
  reportingId,
  formData,
}: {
  loanID: string;
  complianceScheduleId: number;
  companyId: number;
  reportingId: number;
  formData: ComplianceReportForm;
}) => {
  const dataDTO: ReportingSubmitUpdateDTO = {
    complianceReport: {
      id: reportingId,
      informationCovenantStates: formData.informationCovenants.map(
        (covenant: any) => {
          return {
            id: covenant.id,
            isAvailableFromPublicSources: covenant.isAvailableFromPublicSources,
          };
        },
      ),
      complianceScheduleItemID: complianceScheduleId,
      comment: formData.comment,
      hasConfirmedNoEventOfDefault: formData.hasConfirmedNoEventOfDefault!,
    },
    complianceDocuments: formData.files
      .filter((file) => {
        return file.id != null;
      })
      .map((file) => {
        return { id: file.id!, isPublishRequested: file.publish };
      }),
  };

  await submitUpdatedReporting(+loanID, reportingId, dataDTO);
  return reportingId;
};
