import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useGlobal } from '../GlobalContext';
import { Environment } from '../types';
import { EnvironmentChip } from './EnvironmentChip';
import { CompanySelect, MainMenu, VisitorMenu } from './Menus';
import { ReactComponent as SVGLogo } from 'assets/images/NT_Logo_original_primaer_negativ_RGB.svg';
import classes from './PageHeader.module.css';

const Logo: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  if (isLoggedIn) {
    return (
      <div>
        <Link to="/" className={classes.logoLink}>
          <SVGLogo />
        </Link>
      </div>
    );
  }
  return (
    <div>
      <a href="/" className={classes.logoLink}>
        <SVGLogo />
      </a>
    </div>
  );
};

export const PageHeader: React.FC<{
  isLoadingUser: boolean;
  logOut: () => void;
}> = ({ isLoadingUser, logOut }) => {
  const {  user: activeUser } = useGlobal();
  const isLoggedIn = !!activeUser;
  const companies = activeUser?.companies;
  const { ENVIRONMENT_NAME } = window._env_;
  return (
    <Box display="block" displayPrint="none">
      <AppBar position="static" className={classes.root} data-id="pageHeader">
        <Container maxWidth="xl">
          <Toolbar className={classes.toolbar}>
            <Logo isLoggedIn={isLoggedIn} />
            {isLoggedIn && (
              <>
                <Box className={classes.pageHeaderLeftSide}>
                  { companies && (
                    <CompanySelect companies={companies} />
                  )}
                  {ENVIRONMENT_NAME &&
                    ENVIRONMENT_NAME !== Environment.prod && (
                      <EnvironmentChip environment={ENVIRONMENT_NAME} />
                    )}
                </Box>
                {activeUser && companies && (
                  <MainMenu logOut={logOut} companies={companies} />
                )}
              </>
            )}
            {!isLoggedIn && !isLoadingUser && <VisitorMenu />}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
