import { FC, useMemo } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Link } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import { LoadingContainer } from 'components/Loaders';
import { downloadDocument } from '../../ReportingApi';
import { ComplianceDocumentDTO } from '../../reportingApi.types';
import { useReporting } from '../../ReportingContext';
import { CheckedState } from '../CheckedState/CheckedState';
import { FileIcon } from '../FileIcon';
import { centerColumnText, firstColPadding, lastColumnPadding } from '../tableStyles';
import classes from './ReportingUploads.module.css';

export const ReportingUploads: FC<{ fileUploads: ComplianceDocumentDTO[], isLoading: boolean }> = ({ fileUploads, isLoading }) => {
  const { isSwedishTrustee } = useReporting();

  const columns: Table.Column<ComplianceDocumentDTO>[] = useMemo(() => [
    {
      field: 'fileName',
      title: 'File name',
      render: ({ fileName, fileType, id }) => {
        return <Table.ColumnWithTooltip title={fileName}>
          <FileIcon fileExtension={fileType} />
          <Link onClick={() => { downloadDocument(id, fileName) }} className={classes.link}>{fileName}</Link>
        </Table.ColumnWithTooltip>
      },
      ...firstColPadding
    },
    {
      field: 'isRequestPublishToStamdata',
      title: (<Table.ColumnWithTooltip title="This box shall be ticked if you want Nordic Trustee to publish the document on Stamdata.com. Please note that a compliance certificate shall normally not be made publicly available. ">
        Request publish to Stamdata
        <InfoIcon />
      </Table.ColumnWithTooltip>),
      width: '35%',
      render: ({ isPublishRequested }) => {
        return CheckedState(isPublishRequested);
      },
      hidden: isSwedishTrustee,
      ...centerColumnText
    },
    {
      field: 'uploadedBy',
      title: 'Uploaded by',
      width: '20%',
      render: ({ uploadedBy }) => {
        return <Table.ColumnWithTooltip title={uploadedBy}>{uploadedBy}</Table.ColumnWithTooltip>
      },
      ...lastColumnPadding
    },
  ], [isSwedishTrustee]);

  return (
    <LoadingContainer loading={isLoading} type="list" rows={1} cols={2}>
      <Table.Root
        columns={columns}
        data={fileUploads}
        options={{
          paging: false,
          search: false,
          toolbar: false,
          maxBodyHeight: '70vh',
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          pageSizeOptions: [10, 25, 50, 100],
          rowStyle: { padding: '0 1rem' },
          tableLayout: 'fixed',
        }}
      />
    </LoadingContainer>
  );
};