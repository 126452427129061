import Table from '@mui/material/Table';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { SecurityDetailTypeUniverseEnum } from 'modules/Global/enums';
import {
  firstLetterToUpperCase,
  insertSpaceBeforeUpperCase,
} from 'utils/strings/stringManipulation';
import { SecurityItem } from '../../security.types';
import classes from './SecuritiesTable.module.css';

interface ComponentProps {
  securityItems: SecurityItem[];
  customField?: string;
  securityType: SecurityDetailTypeUniverseEnum;
}

export const SecuritiesTable = ({
  securityItems, customField, securityType,
}: ComponentProps) => (
  <Table
    data-id={`${securityType}Table`}
  >
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography variant="h5">
            { securityType === SecurityDetailTypeUniverseEnum.Guarantee ? 'Guarantor' : 'Pledgor'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h5">
            {customField != null ? firstLetterToUpperCase(insertSpaceBeforeUpperCase(customField)) : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h5">
            Agreement Signed
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {securityItems.map((security) => (
        <TableRow
          key={`${security.id}`}
          className={classes.row}
        >
          <TableCell scope="row" className={classes.pledgorCell}>
            <Typography variant="body1">
              {security.pledgor}
            </Typography>
          </TableCell>
          <TableCell scope="row" className={classes.customCell}>
            <Typography variant="body1">
              {security.custom}
            </Typography>
          </TableCell>
          <TableCell scope="row" className={classes.agreementReceivedDateCell}>
            <Typography variant="body1">
              { security.agreementSignedDate && moment(security.agreementSignedDate).format('L') }
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
