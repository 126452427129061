import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { SimpleList, SimpleListItem } from 'components/SimpleList';
import { daysDiffText } from 'utils/localization/localization';
import { EventDTO } from '../../types';
import { isUpcomingEvent } from './isUpcomingEvent';
import classes from './EventsSimpleList.module.css';

interface ComponentProps {
  events: EventDTO[];
  to: string;
}

const openUrl = (url: string) => {
  window.open(url, '_self');
};

export const EventsSimpleList = ({ events, to }: ComponentProps) => (
  <SimpleList>
    {events.map((event, i) => (
      <SimpleListItem
        key={event.eventID}
        onClick={() => openUrl(to)}
        listItemIcon={(
          <CalendarTodayOutlinedIcon
            className={classes.eventIcon}
            color="primary"
          />
        )}
        divider={events.length !== i + 1}
      >
        <ListItemText
          className={classes.listItemContainer}
          primary={(
            <Tooltip title={event.title}>
              <Typography variant="body1" className={classes.text}>{event.title}</Typography>
            </Tooltip>
          )}
          secondary={(
            <Typography
              variant="body1"
              color="textSecondary"
            >
              {
                isUpcomingEvent(event)
                  ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      component="span"
                    >
                      <>
                        <LabelImportantIcon className={classes.upcoming} />
                        {daysDiffText(moment(event.date))}
                      </>
                    </Box>
                  )
                  : moment(event.date).format('L')
              }
            </Typography>
          )}
        />
        <ListItemText
          secondary={(
            <Typography variant="body1" color="textSecondary">{ event.loanName}</Typography>
          )}
          className={classes.loanName}
        />
      </SimpleListItem>
    ))}
  </SimpleList>
);
