import { DocumentSearchFilterOption } from 'api/api.types';
import { DOCUMENT_FILTER_OPTION_ALL } from './DocumentsFilter.types';

export const getFilterOptionAll = (
  filterOptions: DocumentSearchFilterOption[],
  text?: string,
): DocumentSearchFilterOption => ({
  id: DOCUMENT_FILTER_OPTION_ALL,
  text: text || 'All',
  selected: !filterOptions.some((opt) => opt.selected === true),
});
