import React from 'react';
import Typography from '@mui/material/Typography';

interface ColoredTextProps{
  text?: string;
  color?: 'primary' | 'secondary';
}

export const ColoredText = ({ text, color }: ColoredTextProps) => (
  <Typography component="span" color={color} variant="inherit" style={{ fontWeight: 700 }}>
    {text}
  </Typography>
);

