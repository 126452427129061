import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import accounting from 'accounting';
import { calcAmountLeft } from '../../../utils';
import { formatNextPaymentDate } from './formatNextPaymentDate';
import { LoansTableProps } from './props';
import classes from './LoansWithinExpandablePanels.module.css';

export const LoansWithinExpandablePanels = ({ loans }: LoansTableProps) => (
  <>
    {loans.map((loan) => (
      <Accordion key={loan.issueID}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{loan.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            <Typography component="span" variant="body2" className={classes.label}>ISIN</Typography>
            <br />
            {loan.isin}
            <br />
            <Typography component="span" variant="body2" className={classes.label}>Outstanding Amount</Typography>
            <br />
            {accounting.formatMoney(loan.outstandingAmount || 0, `${loan.currency}`, 0)}
            <br />
            <Typography component="span" variant="body2" className={classes.label}>Next Term Date</Typography>
            <br />
            {formatNextPaymentDate(loan)}
            <br />
            <Typography component="span" variant="body2" className={classes.label}>Undrawn Amount</Typography>
            <br />
            {accounting.formatMoney(calcAmountLeft(loan), `${loan.currency}`, 0)}
          </Typography>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" color="primary" component={RouterLink} to={`/loans/${loan.issueID}`}>
            See loan details
          </Button>
        </AccordionActions>
      </Accordion>
    ))}
  </>
);
