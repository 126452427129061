import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { StyledCardHeader } from 'components/Card';
import classes from './QuickAccess.module.css';

const openRequestAuditorConfirmationModal = () => {
  const element: HTMLElement = document.getElementById('openModal') as HTMLElement;
  element.click();
};

export const QuickAccess = () => (
  <Card className={classes.card}>
    <StyledCardHeader
      title={(
        <Typography variant="h3">Quick Access</Typography>
      )}
    />
    <CardContent>
      <List component="nav" dense disablePadding>
        <ListItem component={Link} to="/account/profile" button divider dense>
          <ListItemText
            primary={<Typography color="primary" variant="body1">Profile</Typography>}
            secondary={<Typography variant="body1" color="textSecondary">Edit Profile</Typography>}
          />
        </ListItem>
        <ListItem onClick={openRequestAuditorConfirmationModal} button divider dense>
          <ListItemText
            primary={<Typography color="primary" variant="body1">Auditor Confirmation</Typography>}
            secondary={<Typography variant="body1" color="textSecondary">Request auditor confirmation sheet</Typography>}
          />
        </ListItem>
        <ListItem component={Link} to="/faq" button dense>
          <ListItemText
            primary={<Typography color="primary" variant="body1">FAQ</Typography>}
            secondary={<Typography variant="body1" color="textSecondary">Frequently Asked Questions</Typography>}
          />
        </ListItem>
      </List>
    </CardContent>
  </Card>
);
