import ErrorIcon from '@mui/icons-material/Error';
import { Card, CardContent, CardProps } from '@mui/material';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { daysOverdueDiffText } from 'utils/localization/localization';
import { EventDTO } from '../../types';
import { EventListItem } from './EventListItem';
import classes from './EventList.module.css';

interface ComponentProps extends CardProps {
  events: EventDTO[];
  completeEvent: (event: EventDTO) => void;
  loading: boolean;
  totalNumberOfEvents: number;
}

export const OverdueEventList = ({
  events, completeEvent,
  loading, totalNumberOfEvents,
  ...cardProps
}: ComponentProps) => {
  if(!loading && !totalNumberOfEvents) {
    return null;
  }
  const eventNumbers = !loading && totalNumberOfEvents > 0 ? `${totalNumberOfEvents} Event(s)` : '';
  return (
    <Card component="section" className={classes.card} data-cy="overdue-events" {...cardProps}>
      <StyledCardHeader
        title="Overdue Events"
        subheader={eventNumbers}
      />
      <CardContent className={classes.columnContent}>
        <LoadingContainer loading={loading} type="listWithIcon" rows={3} cols={4}>
          {!events?.length
            ? (<Typography>No events found.</Typography>)
            : (
              <>
                <List data-id="eventsOverdueList">
                  {events.map((event) => (
                    <EventListItem key={event.eventID} event={event} completeEvent={completeEvent}>
                      <ErrorIcon className={classes.error} />
                      {daysOverdueDiffText(moment(event.date))}
                    </EventListItem>
                  ))}
                </List>
              </>
            )}
        </LoadingContainer>
      </CardContent>
    </Card>
  );
};

