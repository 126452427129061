import ReactDOM from 'react-dom';
import React from 'react';
import App from 'App';
import './index.css';


import {
  setAccountingFormat,
  loadCountries,
  configureGlobalAxios,
  initHotjar,
  initFavicon,
  initChartjs,
  initGoogleAnalytics,
} from 'utils/init';

import * as serviceWorker from './serviceWorker';

initChartjs();
loadCountries();

configureGlobalAxios();
setAccountingFormat();


ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
initFavicon();
serviceWorker.unregister();
initHotjar(window._env_.REACT_APP_HOTJAR_ID);
initGoogleAnalytics(window._env_.REACT_APP_GOOGLE_ANALYTICS_ID);