import { Input } from '@nordictrustee/nt-ui-library';
import moment, { Moment } from 'moment';

interface ComponentProps {
  value: Moment;
  changeHandler: (date: moment.Moment | null) => void;
  label?: string;
  minDate?: Moment;
}

const datePickerSlotProps = {
  textField: {
    variant: 'standard',
  },
};
export const DatePicker = ({
  value,
  changeHandler,
  label,
  minDate,
}: ComponentProps) => (
  <Input.Date
    clearable={false}
    label={label}
    value={value}
    onChange={changeHandler}
    minDate={minDate}
    slotProps={datePickerSlotProps as any}
  />
);
