import moment from 'moment/moment';
import { IssueTypeUniverseEnum } from 'modules/Global/enums';
import { EVENT_PAGE_SIZE } from '../../constants';
import {
  EVENT_FILTER_OPTION_ALL,
  EventsOptions,
} from '../../EventsApi';
import { EventSearchFilters, EventTypeUniverseEnum } from '../../types';

export const buildEventsOptionsFromFilters = (
  page: number,
  filters: EventSearchFilters,
  loanID?: number,
  fromDate?: moment.Moment,
  toDate?: moment.Moment,
) => {
  if(page === 0) throw 'Page must be greater than 0';

  const opts: EventsOptions = {};

  if (loanID) {
    // Unique loan: usually within a loan page context
    opts.loanIDs = [loanID];
  } else if (filters.loanIDs) {
    // All loans: out of unique loan context
    opts.loanIDs = filters.loanIDs
      .filter((fo) => fo.selected && fo.id !== EVENT_FILTER_OPTION_ALL)
      .map((fo) => Number(fo.id));
  }

  if (filters.eventTypes) {
    opts.eventTypes = filters.eventTypes
      .filter((fo) => fo.selected && fo.id !== EVENT_FILTER_OPTION_ALL)
      .map((fo) => fo.id as EventTypeUniverseEnum);
  }

  if (filters.issuers) {
    opts.issuers = filters.issuers
      .filter((fo) => fo.selected && fo.id !== EVENT_FILTER_OPTION_ALL)
      .map((fo) => Number(fo.id));
  }

  if (filters.issueTypes) {
    opts.issueTypes = filters.issueTypes
      .filter((fo) => fo.selected && fo.id !== EVENT_FILTER_OPTION_ALL)
      .map((fo) => fo.id as IssueTypeUniverseEnum);
  }

  opts.page = page;
  opts.pageSize = EVENT_PAGE_SIZE;
  opts.fromDate = fromDate;
  opts.toDate = toDate;
  opts.showCompleted = true;

  return opts;
};
