import { TableBody } from '@mui/material';
import { InterestPaymentItemDTO } from 'api/api.types';
import { LabelContentRow as Row, StickyTable } from 'components/Table';
import { formatMoney } from 'utils/money';

interface ComponentProps {
  interestPayment: InterestPaymentItemDTO;
}

export const ScheduledRepaymentsTable = ({ interestPayment }: ComponentProps) => (
  <StickyTable>
    <TableBody>
      <Row label="Nominal Amount" alignRight>
        {interestPayment.nominalAmount ? formatMoney(interestPayment.nominalAmount || 0, interestPayment.currency) : 'N/A'}
      </Row>
      <Row label="Repayment Price" alignRight>
        {interestPayment.repaymentPrice ? `${interestPayment.repaymentPrice}%` : 'N/A'}
      </Row>
      <Row label="Repayment Amount" alignRight>
        {interestPayment.repaymentAmount ? formatMoney(interestPayment.repaymentAmount || 0, interestPayment.currency) : 'N/A'}
      </Row>
    </TableBody>
  </StickyTable>
);

