import { FC } from 'react';
import {
  Link, Redirect, Route, Switch, useRouteMatch
} from 'react-router-dom';
import { Tab } from '@mui/material';
import { TabHeader } from 'components/TabHeader';
import { useGlobal } from 'modules/Global';
import { CompanyContactDetails } from './CompanyContactDetails';
import { Profile } from './Profile';

export const AccountSettings: FC = () => {
  const { params } = useRouteMatch<{ tab: string }>();
  const activeTab  = params.tab || 'profile';
  const { user, activeCompany } = useGlobal();

  if (!user || !activeCompany) return null;

  return (
      <>
        <TabHeader header="My Account" value={activeTab}>
          <Tab value="profile" label="Profile" component={Link} to="/account/profile" />
          <Tab value="company-profile" label="Company Contact Details" component={Link} to="/account/company-profile" />
        </TabHeader>
        <Switch>
          <Route
              exact
              path="/account/profile"
              render={() => <Profile user={user} />}
          />
          <Route
              exact
              path="/account/company-profile"
              render={() => <CompanyContactDetails user={user} activeCompany={ activeCompany } />}
          />
          <Route render={() => <Redirect to="/account/profile" />} />
        </Switch>
      </>
  );
}
