import React from 'react';
import { Button } from '@mui/material';
import classes from './PageHeaderButton.module.css';

interface ComponentProps {
  children: any;
  component?: any;
  onClick?: any;
  to?: string;
}

export const PageHeaderButton: React.FC<ComponentProps> = ({
  children, component, onClick, to,
}) => (
  <Button
    color="inherit"
    component={component}
    to={to}
    onClick={onClick}
    className={classes.button}
    size="small"
  >
    {children}
  </Button>
);


