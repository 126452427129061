import { Grid } from '@mui/material';
import { CaseLogDTO, DocumentDTO } from 'api/api.types';
import { DocumentsOverview } from 'modules/Documents';
import { EventsCard } from 'modules/Events';
import { EventDTO } from 'modules/Events/types';
import { MessagesOverview } from '../MessageBoard';
import classes from './LoanSecondaryDetails.module.css';

interface LoanSecondaryDetailsProps {
  loanID: number;
  events: EventDTO[];
  loadingEvents: boolean;
  documents: DocumentDTO[];
  loadingDocuments: boolean;
  messages: CaseLogDTO[];
  loadingMessages: boolean;
}

export const LoanSecondaryDetails = ({
  loanID,
  events,
  loadingEvents,
  documents,
  loadingDocuments,
  messages,
  loadingMessages,
}: LoanSecondaryDetailsProps) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <EventsCard
        events={events}
        loanID={loanID}
        className={classes.singleCard}
        loading={loadingEvents}
      />
    </Grid>
    <Grid item xs={12}>
      <DocumentsOverview
        documents={documents}
        loanID={loanID}
        classes={{ card: classes.singleCard, neutralLink: classes.neutralLink }}
        loading={loadingDocuments}
      />
    </Grid>
    <Grid item xs={12}>
      <MessagesOverview
        messages={messages.slice(0, 4)}
        loanID={loanID}
        classes={{ card: classes.singleCard }}
        loading={loadingMessages}
      />
    </Grid>
  </Grid>
);
