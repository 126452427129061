import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import React, { ReactNode, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { RouterProps } from '../../legacy/types';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { TabProps } from '@mui/material/Tab';
import * as H from 'history';

interface ItemProps extends TabProps {
  to: H.LocationDescriptor;
}

interface TabHeaderAsDrawerProps {
  isOpen: boolean;
  toggleDrawer: (status: boolean) => void;
  items: ItemProps[];
}

const TabHeaderAsDrawer = withRouter(
  ({ isOpen, toggleDrawer, items }: TabHeaderAsDrawerProps & RouterProps) => (
    <Drawer open={isOpen} onClose={() => toggleDrawer(false)}>
      <div
        role="presentation"
        onClick={() => toggleDrawer(false)}
        onKeyDown={() => toggleDrawer(false)}
      >
        <List>
          {items.map((item) => (
            <ListItem key={item.value}>
              <ListItemText>
                <Link component={RouterLink} to={item.to}>
                  {item.label}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  ),
);

export const TabDrawer = ({
  children,
  value,
}: {
  children?: (React.ReactElement<ItemProps> | boolean)[];
  value: string;
}) => {
  const [isOpen, setOpen] = useState(false);

  // Collect props from Tab elements to create the mobile drawer
  const items: ItemProps[] = [];
  let activeLabel = '';
  (children || []).forEach((c) => {
    if (typeof c !== 'boolean') {
      items.push(c.props);
      if (c.props.value === value) {
        activeLabel = c.props.label as string;
      }
    }
  });
  return (
    <Box
      display={{ xs: 'block', sm: 'none' }}
      displayPrint="none"
      pt={1}
      pb={1}
      pl={2}
      pr={2}
    >
      <Button
        fullWidth
        onClick={() => setOpen(!isOpen)}
        variant="outlined"
        size="small"
      >
        {activeLabel}
        <ArrowDropDownIcon />
      </Button>
      <TabHeaderAsDrawer isOpen={isOpen} toggleDrawer={setOpen} items={items} />
    </Box>
  );
};
