import { FC, useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Card, CardContent, Grid } from '@mui/material';
import {
  DocumentDTO,
  DocumentSearchFilterOption,
  DocumentSearchFilters,
} from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { useGlobal } from '../../Global';
import { DocumentsListDetails } from '../documents/DocumentListDetails';
import {
  DocumentsSearchFilterIDs,
  DocumentsSearchFilterSelection,
} from './DocumentsFilter/DocumentsFilter.types';
import {
  buildDocumentsOptionsFromFilters,
  DocumentsFilter,
  toggleFilterOption,
} from './DocumentsFilter';
import { getDefaultCompanyDocumentSearchFilterOption } from './getDefaultCompanyDocumentSearchFilterOption';
import { getFormattedDocuments } from './getFormattedDocuments';
import classes from './DocumentsList.module.css';

interface OwnProps {
  isin?: string;
  selectedIssueID?: number;
}

export const DocumentsList: FC<OwnProps> = ({
  isin,
  selectedIssueID,
}) => {
  const { activeCompany: company } = useGlobal();

  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [filters, setFilters] = useState<DocumentSearchFilters>({
    issueIDs: [],
    companyIDs:
      !selectedIssueID && company
        ? [getDefaultCompanyDocumentSearchFilterOption(company)]
        : [],
  });
  const [searchID, setSearchID] = useState(1);
  const [loadingDocuments, setLoadingDocuments] = useState(true);

  const handleToggle = (
    filterID: DocumentsSearchFilterIDs,
    filterOption: DocumentSearchFilterOption,
    selection: DocumentsSearchFilterSelection,
  ) => {
    const newSearchID = searchID + 1;
    setSearchID(newSearchID);
    const newFilters = { ...filters };
    const filterOptions = newFilters[filterID];
    if (filterOptions) {
      newFilters[filterID] = toggleFilterOption(
        filterOptions,
        filterOption,
        selection,
      );
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    let componentDidUnmount = false;
    setLoadingDocuments(true);

    const fetchDocuments = async () => {
      const opts = buildDocumentsOptionsFromFilters(filters, selectedIssueID);

      const documentSearch = await getFormattedDocuments(opts);
      if (componentDidUnmount) return;
      setDocuments(documentSearch.data);
      setFilters(documentSearch.filters);
    };
    fetchDocuments().then(() => {
      if (componentDidUnmount) return;
      setLoadingDocuments(false);
    });
    return () => {
      componentDidUnmount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should only update when search ID changes
  }, [searchID]);

  return (
    <Box mt={2} mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Card className={classes.card}>
            <StyledCardHeader avatar={<FilterListIcon />} title="Filters" />
            <CardContent className={classes.columnContent}>
              {!selectedIssueID && (
                <DocumentsFilter
                  loadingDocuments={loadingDocuments}
                  handleToggle={handleToggle}
                  {...filters}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={9}>
          <DocumentsListDetails
            documents={documents}
            isin={isin || ''}
            loading={loadingDocuments}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
