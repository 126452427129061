import { assertParamExists } from 'api/assertParamExists';
import { BASE_PATH } from 'api/const';
import { createRequestFunction } from 'api/createRequestFunction';
import { serializeDataIfNeeded } from 'api/serializeDataIfNeeded';
import { setSearchParams } from 'api/setSearchParams';
import globalAxios, { AxiosRequestConfig } from 'axios';
import moment from 'moment/moment';
import { IssueTypeUniverseEnum } from 'modules/Global/enums';
import { getCancelToken } from '../../utils/init/axiosConfig';
import { CreateEventDTO, EventDTO, EventModel, EventSearchFilters, EventTypeUniverseEnum } from './types';

const eventsApiAxiosParamCreator = function () {
  return {
    completeEvent: async (eventID: number, eventDTO?: EventDTO, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'eventID' is not null or undefined
      assertParamExists('completeEvent', 'eventID', eventID)
      const localVarPath = `/api/events/${eventID}/complete`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'PUT', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(eventDTO, localVarRequestOptions)
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    createEvent: async (createEventDTO?: CreateEventDTO, options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/events`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'POST', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(createEventDTO, localVarRequestOptions)
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getEvents: async (loanIDs?: Array<number>, eventTypes?: Array<EventTypeUniverseEnum>, fromDate?: string, toDate?: string, page?: number, pageSize?: number, issuers?: Array<number>, showCompleted?: boolean, issueTypes?: Array<IssueTypeUniverseEnum>, options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/events`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (loanIDs) {
        localVarQueryParameter['loanIDs'] = loanIDs;
      }
      if (eventTypes) {
        localVarQueryParameter['eventTypes'] = eventTypes;
      }
      if (fromDate !== undefined) {
        localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
          (fromDate as any).toISOString() :
          fromDate;
      }
      if (toDate !== undefined) {
        localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
          (toDate as any).toISOString() :
          toDate;
      }
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }
      if (issuers) {
        localVarQueryParameter['issuers'] = issuers;
      }
      if (showCompleted !== undefined) {
        localVarQueryParameter['showCompleted'] = showCompleted;
      }
      if (issueTypes) {
        localVarQueryParameter['issueTypes'] = issueTypes;
      };
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getEventsOverdue: async (loanIDs?: Array<number>, eventTypes?: Array<EventTypeUniverseEnum>, page?: number, pageSize?: number, issuers?: Array<number>, showCompleted?: boolean, issueTypes?: Array<IssueTypeUniverseEnum>, options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/events/overdue`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (loanIDs) {
        localVarQueryParameter['loanIDs'] = loanIDs;
      }
      if (eventTypes) {
        localVarQueryParameter['eventTypes'] = eventTypes;
      }
      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }
      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }
      if (issuers) {
        localVarQueryParameter['issuers'] = issuers;
      }
      if (showCompleted !== undefined) {
        localVarQueryParameter['showCompleted'] = showCompleted;
      }
      if (issueTypes) {
        localVarQueryParameter['issueTypes'] = issueTypes;
      };
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getFilters: async (options: AxiosRequestConfig = {}) => {
      const localVarPath = `/api/events/filters`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getUpcomingEventsForIssue: async (issueID: number, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'issueID' is not null or undefined
      assertParamExists('getUpcomingEventsForIssue', 'issueID', issueID)
      const localVarPath = `/api/events/upcoming/issue/${issueID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    getUpcomingEventsForIssuer: async (issuerID: number, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'issuerID' is not null or undefined
      assertParamExists('getUpcomingEventsForIssuer', 'issuerID', issuerID)
      const localVarPath = `/api/events/upcoming/issuer/${issuerID}`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'GET', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
    undoCompleteEvent: async (eventID: number, eventDTO?: EventDTO, options: AxiosRequestConfig = {}) => {
      // verify required parameter 'eventID' is not null or undefined
      assertParamExists('undoCompleteEvent', 'eventID', eventID)
      const localVarPath = `/api/events/${eventID}/undocomplete`;
      const localVarUrlObj = new URL(`${BASE_PATH}${localVarPath}`);
      const localVarRequestOptions = { method: 'PUT', ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      // authentication Bearer required
      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(eventDTO, localVarRequestOptions)
      return {
        url: localVarUrlObj,
        options: localVarRequestOptions,
      };
    },
  }
};
 const eventsApiFp = function() {
  const localVarAxiosParamCreator = eventsApiAxiosParamCreator()
  return {
    async completeEvent(eventID: number, eventDTO?: EventDTO, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeEvent(eventID, eventDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async createEvent(createEventDTO?: CreateEventDTO, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createEventDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async getEvents(loanIDs?: Array<number>, eventTypes?: Array<EventTypeUniverseEnum>, fromDate?: string, toDate?: string, page?: number, pageSize?: number, issuers?: Array<number>, showCompleted?: boolean, issueTypes?: Array<IssueTypeUniverseEnum>, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(loanIDs, eventTypes, fromDate, toDate, page, pageSize, issuers, showCompleted, issueTypes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async getEventsOverdue(loanIDs?: Array<number>, eventTypes?: Array<EventTypeUniverseEnum>, page?: number, pageSize?: number, issuers?: Array<number>, showCompleted?: boolean, issueTypes?: Array<IssueTypeUniverseEnum>, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsOverdue(loanIDs, eventTypes, page, pageSize, issuers, showCompleted, issueTypes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async getFilters(options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilters(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async getUpcomingEventsForIssue(issueID: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingEventsForIssue(issueID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async getUpcomingEventsForIssuer(issuerID: number, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingEventsForIssuer(issuerID, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
    async undoCompleteEvent(eventID: number, eventDTO?: EventDTO, options?: AxiosRequestConfig) {
      const localVarAxiosArgs = await localVarAxiosParamCreator.undoCompleteEvent(eventID, eventDTO, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios );
    },
  }
};

class EventsApi {
  public completeEvent(eventID: number, eventDTO?: EventDTO, options?: AxiosRequestConfig) {
    return eventsApiFp().completeEvent(eventID, eventDTO, options).then((request) => request( globalAxios ));
  }
  public getEventsOverdue(loanIDs?: Array<number>, eventTypes?: Array<EventTypeUniverseEnum>, page?: number, pageSize?: number, issuers?: Array<number>, showCompleted?: boolean, issueTypes?: Array<IssueTypeUniverseEnum>, options?: AxiosRequestConfig) {
    return eventsApiFp().getEventsOverdue(loanIDs, eventTypes, page, pageSize, issuers, showCompleted, issueTypes, options).then((request) => request( globalAxios ));
  }
  public getUpcomingEventsForIssue(issueID: number, options?: AxiosRequestConfig) {
    return eventsApiFp().getUpcomingEventsForIssue(issueID, options).then((request) => request( globalAxios ));
  }
  public getUpcomingEventsForIssuer(issuerID: number, options?: AxiosRequestConfig) {
    return eventsApiFp().getUpcomingEventsForIssuer(issuerID, options).then((request) => request( globalAxios ));
  }
  public undoCompleteEvent(eventID: number, eventDTO?: EventDTO, options?: AxiosRequestConfig) {
    return eventsApiFp().undoCompleteEvent(eventID, eventDTO, options).then((request) => request( globalAxios ));
  }
}

const EventApiInstance = new EventsApi();

export const EVENT_FILTER_OPTION_ALL = 'all';

export type EventsSearchFilterIDs = keyof EventSearchFilters;
export type EventsSearchFilterSelection = 'single' | 'multiple';

export interface EventsOptions {
  eventTypes?: EventTypeUniverseEnum[];
  issueTypes?: IssueTypeUniverseEnum[];
  loanIDs?: number[];
  fromDate?: moment.Moment;
  toDate?: moment.Moment;
  page?: number;
  pageSize?: number;
  issuers?: number[];
  fetchFilters?: boolean;
  showCompleted?: boolean;
}

export async function getEventsOverdue(opts: EventsOptions = {}): Promise<EventModel> {
  const res = await EventApiInstance.getEventsOverdue(
    opts.loanIDs || [],
    opts.eventTypes || [],
    opts.page,
    opts.pageSize,
    opts.issuers || [],
    opts.showCompleted,
    opts.issueTypes || [],
    { cancelToken: getCancelToken() },
  );
  if ((res as any)?.data?.data) (res as any).data.data = (res as any)?.data?.data.sort((a: any, b: any) => moment(a.date).diff(moment(b.date)));
  return (res as any)?.data;
}

export async function getUpcomingEventsForIssuer(issuerId: number): Promise<EventDTO[]> {
  const res = await EventApiInstance.getUpcomingEventsForIssuer(issuerId);
  return (res as any)?.data;
}

export async function getUpcomingEventsForIssue(issueId: number): Promise<EventDTO[]> {
  const res = await EventApiInstance.getUpcomingEventsForIssue(issueId);
  return (res as any)?.data;
}

export async function completeEvent(event: EventDTO): Promise<EventDTO> {
  const res = await EventApiInstance.completeEvent(event.eventID, event, { cancelToken: getCancelToken() });
  return (res as any)?.data;
}
