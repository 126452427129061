import { AxiosResponse } from 'axios';

export const generateErrorMessage = (error: {response: AxiosResponse}) => {
  const {response} = error;
  if(!response) {
    return {msg: JSON.stringify(error), status: 500};
  }
  let msg = '';
  if (response.status === 400) {
    if (response.data && response.data.errors) {
      msg = Object.keys(response.data.errors).map((r: string) => `${r}: ${response.data.errors[r][0]}`).join(' ');
    } else if (response.data.message) {
      msg = `Invalid request: ${response.data.message}`;
    }
  } else if (response.status === 500) {
    msg = 'Server error, please try again later';
  } else {
    msg = `${response.status}: ${response.statusText}`;
  }
  return {msg, status: response.status}
}