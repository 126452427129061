import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Callback<Y> = ((previous: Partial<Y>) => Partial<Y>) | Partial<Y>;
type SetSearchParams<T> = (
  newSearchParams?: Callback<T>,
  navigateOptions?: { replace?: boolean },
) => void;

export function useSearchParams<
  SearchParams extends { [K in keyof SearchParams]?: string } = {},
>(init?: Callback<SearchParams>): [SearchParams, SetSearchParams<SearchParams>] {
  const history = useHistory();
  const location  = useLocation();
  const searchParams = useMemo(
    () =>
      location.search
        .slice(1)
        .split('&')
        .reduce((acc, searchParam) => {
          const [key, value] = searchParam.split('=');
          return {
            ...acc,
            [key]: decodeURIComponent(value),
          };
        }, {} as SearchParams),
    [location.search],
  );

  const setSearchParams = useCallback(
    (newSearchParams, navigateOptions) => {
      if(newSearchParams === searchParams) return; // reference equality check
      if(newSearchParams === undefined || newSearchParams === null) { // clear search params
        history.replace(location.pathname);
        return;
      }
      // handle callback
      const updatedParams = typeof newSearchParams === 'function' ? newSearchParams(searchParams) : newSearchParams;

      const searchString = newSearchParams && Object.entries(updatedParams)
        .flatMap(([key, value]) => {
          if(key === '') return [] // ignore empty keys
          let sanitizedValue = value;
          if (typeof value === 'object') {
            // Convert object or array to string before encoding
            sanitizedValue = JSON.stringify(value);
          }
          return sanitizedValue ? [`${key}=${encodeURIComponent(sanitizedValue as (string | number| boolean))}`] : [] ;
        })
        .join('&');
      const path = searchString ?  '?' + searchString : location.pathname
      if(path === location.search) {// no need to navigate
        return;
      }
      if (navigateOptions?.replace) {
        history.replace(path);
      } else history.push(path);
    },
    [history, location.pathname, location.search, searchParams],
  );

  useEffect(() => {
    if (init) {
      setSearchParams(init, { replace: true });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  return [searchParams, setSearchParams];
}
