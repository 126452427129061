import { FC, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { CreateMaterialUiChangeHandler } from 'legacy/inputHandlers';
import { SubmitButton } from 'components/form';
import SelectCountry from 'components/form/selects/select_country/SelectCountry';
import SelectCurrency from 'components/form/selects/select_currency/SelectCurrency';
import {
  EmailTextField,
  FirstNameReadOnlyTextField,
  LastNameReadOnlyTextField,
  PhoneNumberTextField,
} from 'components/form/text_fields/TextFields';
import { useToast } from 'modules/Global';
import { updateUser, UserProfileType } from '../../../AccountApi';
import { SelectTimezone } from './SelectTimezone';

interface OwnProps {
  username: string;
  profile: UserProfileType;
}

/**
 * TODO:
 * Email editing should be disabled until work item #45383 is complete
 * */
const EditUserProfile: FC<OwnProps> = ({ username, profile: initialProfile }) => {
  const {showWarning, showSuccess} = useToast();
  const [profile, setProfile] = useState(initialProfile);

  const changeHandler = CreateMaterialUiChangeHandler<UserProfileType>(
      (key, value) => {
        const nextProfile = { ...profile };
        nextProfile[key] = value;
        setProfile(nextProfile);
      },
  );

  const handleSubmit = async (): Promise<void> => {
    if (username) {
      try {
        await updateUser(username, profile);
        showSuccess('Your profile has been updated');
        window.location.reload(); // simpler than using dispatch hook
      } catch (error) {
        showWarning(
          (
            error as AxiosError<{
              message: string;
            }>
          )?.response?.data?.message ??
            'There was a problem with updating your profile.',
        );
      }
    }
  };

  const {
    firstName, lastName, email, phoneNumber, momentjsTimezone, countryCode, reportingCurrency,
  } = profile;

  return (
      <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.warn(errors)}
          instantValidate
      >
        <FirstNameReadOnlyTextField value={firstName} />
        <LastNameReadOnlyTextField value={lastName} />
        <Tooltip title="If you need to change your email please contact portals@nordictrustee.com.">
          <span>
        <EmailTextField required={false} disabled value={email} changeHandler={changeHandler} />
          </span>
        </Tooltip>
        <PhoneNumberTextField value={phoneNumber} changeHandler={changeHandler} />
        <SelectTimezone value={momentjsTimezone} changeHandler={changeHandler} />
        <SelectCountry countryCode={countryCode} changeHandler={changeHandler} />
        <SelectCurrency currencyCode={reportingCurrency} changeHandler={changeHandler} changePropertyName="reportingCurrency" />
        <SubmitButton text="Update profile" />
      </ValidatorForm>
  );
};

export default EditUserProfile;
