import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';

export const SessionExpiredPage: FC = () => {
  const history = useHistory();
  const [secondsLeft, setSecondsLeft] = useState(3);
  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      }
      else {
        history.push('/login', { redirectTo: (history.location.state as any)?.redirectTo ?? '/dashboard' });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [history, secondsLeft]);
  
  return (
      <Grid container direction="row" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Grid item style={{ textAlign: 'center', marginBottom: '5em' }}>
          <h1>Your session has expired</h1>
          <h2>
            {`You will be redirected to the login page in ${secondsLeft} seconds...`}
          </h2>
        </Grid>
      </Grid>
  );
};
