import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export const handleRequestError = (error: AxiosError) => {
  const { response } = error;
  if (!response) {
    throw new Error('No response');
  }

  if (response.status && response.status - (response.status % 100) === 400) {
    console.warn('client side warning', response); // TODO: look for this and assert that it's being handled properly
    throw response;
  } else if ((response.data as { message: string })?.message!) {
    toast(
      //TODO: this is temporary error toasting until we know we have control of our api calls
      (response.data as { message: string })!.message!.replace(
        // TODO: update this when proper handling is done on serverside
        'Tillit team (Slack channel "nt-tillit")',
        'us at portals@nordictrustee.com if this error keeps appearing.',
      ),
      { type: 'warning' },
    );
    console.error('server error', response.data);
    throw response;
  }

  console.error('unknown error', error);
  // This is an edge case we currently don't have control over
  toast(
    'Something went wrong. Check your connection and try again. If the problem persists, please contact us!',
    { type: 'error' },
  );
  console.error('connection error??', error);
  throw error;
};
