import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { TableHeaderProps } from '../../tableHeaderProps';

interface InterestPaymentHeaderRowProps {
  includeLoanName: boolean;
}

const tableHeaders: TableHeaderProps[] = [
  { title: 'Payment Date', align: 'left' },
  { title: 'Period', align: 'left' },
  { title: 'Days', align: 'right' },
  { title: 'Quotation Date', align: 'left' },
  { title: 'Coupon', align: 'right' },
  { title: 'Currency', align: 'left' },
  { title: 'Interest Amount', align: 'right' },
  { title: 'Installment', align: 'right' },
  { title: 'Total Payment', align: 'right' },
  { title: '', align: 'left' },
];

export const PaymentTableHeaders = ({ includeLoanName }: InterestPaymentHeaderRowProps) => (
  <TableRow>
    {
      includeLoanName
        ? (
          <TableCell key="loan" component="th" scope="row" align="left">
            <Box displayPrint="block">
              <Typography variant="h5">Loan</Typography>
            </Box>
          </TableCell>
        ) : <></>
    }
    {tableHeaders.map((header) => (
      <TableCell key={header.title} component="th" scope="row" align={header.align}>
        <Box displayPrint={header.title === '' ? 'none' : 'block'}>
          <Typography variant="h5">{header.title}</Typography>
        </Box>
      </TableCell>
    ))}
  </TableRow>
);
