import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { getContact } from './contactList';
import classes from './ContactDetails.module.css';

interface ComponentProps {
  countryCode: string;
}

export const ContactDetails = ({ countryCode }: ComponentProps) => {
  const contact = getContact(countryCode);

  return (
    <>
      <Typography variant="body1" className={classes.item}>
        <Link href={`mailto:${encodeURIComponent(contact.displayName)}<${contact.email}>`}>
          <EmailIcon fontSize="inherit" className={classes.icon} />
          <Box component="span" display={{ xs: 'none', sm: 'inline-block' }}>
            {contact.email}
          </Box>
        </Link>
      </Typography>
    </>
  );
};

