import accounting from 'accounting';
import Countries from "i18n-iso-countries";
import CountriesEN from "i18n-iso-countries/langs/en.json";

export function setAccountingFormat() {
  // Adding a space between the currency and the value
  // In the future and for different country, we could simply change the order here
  accounting.settings.currency.format = '%s %v';
}

export function loadCountries() {
  Countries.registerLocale(CountriesEN);
}
