import { FC, forwardRef, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Face from '@mui/icons-material/Face';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { Box, Button, Link } from '@mui/material';
import { CreateMaterialUiChangeHandler } from 'legacy/inputHandlers';
import { SubmitButton } from 'components/form';
import {
  PasswordTextField,
  UsernameTextField,
} from 'components/form/text_fields/TextFields';
import { useToast } from 'modules/Global';
import { authenticate } from '../AccountApi';
import { useAccount } from '../AccountContext';
import {
  clearAccessToken,
  clearUnauthorizedState,
} from '../accountLocalStorage';
import { PaperHeader, PaperPage } from '../atoms';

interface FormInput {
  username: string;
  password: string;
}


interface RedirectObject {
  redirectTo: string;
}

const ForgotPasswordLink = forwardRef((props, ref) => (
  <RouterLink innerRef={ref as any} to="forgot-password" {...props} />
));

export const Login: FC = () => {
  clearUnauthorizedState();
  const history = useHistory();
  const { state } = history.location;
  const { showWarning } = useToast();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const { setAccessToken } = useAccount();

  const changeHandler = CreateMaterialUiChangeHandler<FormInput>(
    (key, value) => {
      switch (key) {
        case 'username':
          setUsername(value);
          break;
        case 'password':
          setPassword(value);
          break;
      }
    }
  );
  const logIn = async (username: string, password: string, redirectPath: string) => {
    setLoggingIn(true);
    try {
      const response = await authenticate(username, password);
      if (response.accessToken) {
        setAccessToken(response.accessToken);
      } else {
        clearAccessToken();
        showWarning(response.message ?? 'Could not log you in.');
      }
      history.replace(redirectPath)
    } catch (_e) {
      showWarning('There was an error while logging in.')
    } finally {
      setLoggingIn(false);
    }
  }

  const handleSubmit = async () => {
    const redirectPath = (state as RedirectObject)?.redirectTo || '/';
    await logIn(username, password, redirectPath);
  }

  const Form = (
    <ValidatorForm
      onSubmit={handleSubmit}
      onError={(errors: any) => console.warn(errors)}
      instantValidate
    >
      <PaperHeader>
        Login
      </PaperHeader>
      <Box display="flex">
        <Box mt={4.5} mr={2}>
          <Face />
        </Box>
        <Box flexGrow={1}>
          <UsernameTextField value={username} changeHandler={changeHandler} />
        </Box>
      </Box>
      <Box display="flex">
        <Box mt={4.5} mr={2}>
          <FingerprintIcon />
        </Box>
        <Box flexGrow={1}>
          <PasswordTextField value={password} changeHandler={changeHandler} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          disableFocusRipple
          disableRipple
          variant="text"
          color="primary"
        >
          <Link component={ForgotPasswordLink}>Forgot password?</Link>
        </Button>
      </Box>
      <Box mt={4}>
        <SubmitButton text="Log in" loading={loggingIn} />
      </Box>
    </ValidatorForm>
  );

  return (
    <div id="loginForm">
      <PaperPage showWelcomeText>
        {Form}
      </PaperPage>
    </div>
  );
};
