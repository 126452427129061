import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { setTimezone } from 'utils/localization/localization';
import { HubUserWithCompaniesDTO } from './AccountApi/api.types';
import { extractUserIDFromAccessToken, getUser } from './AccountApi';
import {
  getAccessToken,
  getUnauthorized,
  isUserAuthenticated,
  saveAccessToken,
} from './accountLocalStorage';

interface AccountContextValue {
  user?: HubUserWithCompaniesDTO;
  isAuthenticated: boolean;
  isLoadingUser: boolean;
  setAccessToken: (token: string) => void;
  logOut: () => void;
  expiredAccess: () => boolean;
}

const AccountContext = createContext<AccountContextValue | undefined>(
  undefined,
);

export const AccountProvider: FC = ({ children }) => {
  const [internalAccessToken, setInternalAccessToken] =
    useState(getAccessToken());
  const accessToken = useMemo(() => {
    const storedToken = getAccessToken();
    if (internalAccessToken !== storedToken) {
      saveAccessToken(internalAccessToken);
    }
    return getAccessToken();
  }, [internalAccessToken]);
  const isAuthenticated = useMemo(
    () => isUserAuthenticated(),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- required to redirect when the user logs in/out
    [internalAccessToken],
  );
  const userId = useMemo(
    () => extractUserIDFromAccessToken(accessToken),
    [accessToken],
  );
  const [user, setUser] = useState<HubUserWithCompaniesDTO>();
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(isAuthenticated); // initially loading userdata if authenticated

  useEffect(() => {
    if (user) {
      if (!user.momentjsTimezone) user.momentjsTimezone = 'Europe/Stockholm';
      if (!user.countryCode) user.countryCode = 'SE';
      setTimezone(user?.momentjsTimezone!);
    }
  }, [user]);

  const fetchUserData = useCallback(async () => {
    if (!isAuthenticated) {
      return undefined;
    }
    try {
      if (!userId) return undefined;
      return await getUser(userId);
    } catch (error) {
      console.error('Failed to fetch user data', error);
      return undefined;
    }
  }, [isAuthenticated, userId]);
  useEffect(() => {
    let cancel = false;
    setIsLoadingUser(true);
    fetchUserData()
      .then((userData) => {
        if (!cancel) setUser(userData);
      })
      .finally(() => {
        setIsLoadingUser(false);
      });
    return () => {
      cancel = true;
    };
  }, [fetchUserData, userId]);
  const logOut = useCallback(() => {
    setInternalAccessToken('');
  }, []);


  return (
    <AccountContext.Provider
      value={{
        expiredAccess: getUnauthorized,
        user,
        isAuthenticated,
        isLoadingUser,
        setAccessToken: setInternalAccessToken,
        logOut,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
};
