import { DocumentSearchFilterOption } from 'api/api.types';
import {
  DOCUMENT_FILTER_OPTION_ALL,
  DocumentsSearchFilterSelection,
} from './DocumentsFilter.types';

export const  toggleFilterOption = (
  filterOptions: DocumentSearchFilterOption[],
  filterOption: DocumentSearchFilterOption,
  selection: DocumentsSearchFilterSelection,
): DocumentSearchFilterOption[] => {
  let newFilterOptions = [...filterOptions];

  if (filterOption.id === DOCUMENT_FILTER_OPTION_ALL || selection === 'single') {
    newFilterOptions = newFilterOptions.map((fo) => ({ ...fo, selected: fo.id === filterOption.id }));
  } else if (selection === 'multiple') {
    const toggledFilterOption = newFilterOptions.find((fo) => fo.id === filterOption.id);
    if (toggledFilterOption) toggledFilterOption.selected = !toggledFilterOption.selected;
    const allFilterOption = newFilterOptions.find((fo) => fo.id === DOCUMENT_FILTER_OPTION_ALL);
    if (allFilterOption) allFilterOption.selected = !newFilterOptions.some((opt) => opt.selected === true);
  }

  return newFilterOptions;
};
