import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Box } from '@mui/material';
import { CovenantReportingDTO } from 'api/api.types';
import { SubmitButton } from 'components/form';
import { CommentTextField } from 'components/form/text_fields/TextFields';
import { useToast } from 'modules/Global';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import { getCovenantReportings } from 'modules/Loans';
import { FileUpload } from './sections/FileUpload/FileUpload';
import { processFiles, UploadDocument } from './sections/FileUpload/processFiles';
import { CheckboxWithValidation } from './CheckboxWithValidation';
import { CovenantReportingPicker } from './CovenantReportingPicker';
import { DocumentsToUploadTable } from './DocumentsToUploadTable';
import { generateErrorMessage } from './generateErrorMessage';
import {
  DocumentType,
  MarketStamdataNote,
  ReportingMarketEmailFormFields,
  ReportingUploadLimit,
} from './sections';
import { uploadLoanDocuments } from './uploadLoanDocuments';

interface Props {
  issuerID: number;
  loanID: number;
  documentType: HubDocumentTypeUniverseEnum;
  covenantReportingID?: number;
  isin: string;
  onUploadSuccess?: () => void;
  reportingDate?: string;
}

function calculateFileSizeLimit(documentType: HubDocumentTypeUniverseEnum) {
  return (documentType === HubDocumentTypeUniverseEnum.Market ? 19.5 : 500) * 1024 * 1024;
}

export const DocumentsUploadForm: FC<Props> = ({
  issuerID,
  loanID,
  documentType,
  covenantReportingID: covenantReportingIDProp,
  isin,
  onUploadSuccess,
  reportingDate,
}: Props) => {
  const [documents, setDocuments] = useState<UploadDocument[]>([]);
  const [uploadingDocument, setUploadingDocuments] = useState(false);
  const [covenantReportings, setCovenantReportings] = useState<CovenantReportingDTO[]>([]);
  const [publishedToStockExchange, setPublishedToStockExchange] = useState(false);
  const [covenantReportingID, setCovenantReportingID] = useState<number | undefined>(covenantReportingIDProp);
  const [comment, setComment] = useState('');
  const [emailRecipients, setEmailRecipients] = useState<string[]>([]);
  const { showWarning, showSuccess } = useToast();

  useEffect(() => {
    // TODO: handle with React Query
    const fetchCovenantReportings = async () => {
      if (documentType === HubDocumentTypeUniverseEnum.Reporting) {
        return await getCovenantReportings(loanID);
      };
      return undefined;
    };

    fetchCovenantReportings().then((data) => {
      if (data) {
        setCovenantReportings(data);
      }
    });
  }, [loanID, documentType]);

  useEffect(() => {
    const setCovenantReportingIDFromReportingDate =
      (reportingDate?: string) => {
        const covenantReporting = covenantReportings.find(
          (cr) => cr.reportingDate === reportingDate,
        );
        if (!covenantReporting) return;
        setCovenantReportingID(covenantReporting.covenantReportingID);
      }
    setCovenantReportingIDFromReportingDate(reportingDate);
  }, [reportingDate, covenantReportings]);

  const onDrop = useCallback((files: File[], fileRejections: FileRejection[]) => {
    if (!files) {
      showWarning('Something went wrong when picking file');
      return;
    }
    fileRejections.forEach((rejectedFile) => {
      showWarning(`Document '${rejectedFile.file.name}' can't be uploaded, because its file is not supported: `
        + `${rejectedFile.errors.map((err) => err.message).join(', additional reason: ')}`);
    });

    const nextDocuments = processFiles(files, documents, documentType);

    setDocuments(nextDocuments);

  }, [documents, documentType, showWarning]);

  const onRemoveFile = useCallback(
    (fileToRemove: UploadDocument) => {
      setDocuments((prevState) =>
        prevState.filter(
          ({ documentID }) => documentID !== fileToRemove.documentID,
        ),
      );
    },
    [],
  );

  const uploadDocuments = async (documents: UploadDocument[]) => {
    setUploadingDocuments(true);
    if (documents.some((d) => d.file) != null) {
      try {
        const documentType = documents[0].type;
        const response = await uploadLoanDocuments({
          issuerID,
          loanID,
          documentType,
          covenantReportingID,
          uploadToStamdata: documents.map((d) => d.uploadToStamdata),
          documents: documents.map((d) => d.file) as File[],
          emailRecipients,
          comment,
        });
        if (response.status === 200) {
          const message =
            documentType === HubDocumentTypeUniverseEnum.Reporting
              ? 'Thank you for your document. Nordic Trustee employee will review your document shortly'
              : 'The document has been successfully submitted to Nordic Trustee which will process the request';
          showSuccess(message);
          if (onUploadSuccess) onUploadSuccess();
        } else {
          const message = 'Failed to upload document';
          showWarning(message);
        }
      } catch (error) {
        const { msg } = generateErrorMessage(error as any);
        const message = `Failed to upload document: ${msg}`;
        showWarning(message);
      }
      setUploadingDocuments(false)
    }
  }

  const { files, totalFileSize } = useMemo(() => {
    const files: { key: string; file: File }[] = [];

    documents.forEach((document, i) => {
      if (document.file != null) {
        files.push({ key: i.toString(), file: document.file });
      }
    });

    let totalBytes = 0;
    files.forEach((file) => {
      totalBytes += file.file.size;
    });
    const totalFileSize = (totalBytes / 1024 / 1024);
    return { files, totalFileSize };
  }, [documents]);

  return (
    <ValidatorForm
      onSubmit={async () => uploadDocuments(documents)}
      onError={(errors) => console.log(errors)}
      instantValidate
      data-id="Upload document form"
    >
      <Box mt={2}>
        <DocumentType documentType={documentType} />
        <CovenantReportingPicker
          value={covenantReportingID}
          covenantReportings={covenantReportings}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setCovenantReportingID(Number(e.target.value))}
          documentType={documentType}
        />

        <FileUpload
          files={files}
          maxSizeBytes={calculateFileSizeLimit(documentType)}
          onDrop={onDrop}
        />
        <DocumentsToUploadTable
          documents={documents}
          documentType={documentType}
          onCheckboxChange={(e, documentId) => {
            const updatedDocuments = documents.map((d) => {
              if (d.documentID === documentId) {
                const updatedDoc = { ...d, uploadToStamdata: e.target.checked }
                return updatedDoc;
              } else {
                return d;
              }
            });
            setDocuments(updatedDocuments)
          }}
          isin={isin}
          onRemoveDocument={onRemoveFile}
        />
        <ReportingUploadLimit documentType={documentType} totalFileSize={totalFileSize} />
        <MarketStamdataNote documentType={documentType} />

        {documentType === HubDocumentTypeUniverseEnum.Reporting &&
          documents.find((d) => d.uploadToStamdata) && (
            <CheckboxWithValidation
              value=""
              label="If applicable, we acknowledge our responsibility to inform any relevant market place about
            the content of the document(s) prior to up-loading them."
              field=""
              checked={publishedToStockExchange}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setPublishedToStockExchange(e.target.checked)}
            />
          )}
        {documentType === HubDocumentTypeUniverseEnum.Reporting && (
          <CommentTextField
            changeHandler={(((_key: string, fn: (e: any) => string) => {
              return (e: any) => {
                setComment(fn(e));
              }
            }) as any)}
            value={comment}
          />
        )}
        <ReportingMarketEmailFormFields
          documentType={documentType}
          totalFileSize={totalFileSize}
          emailRecipients={emailRecipients}
          setEmailRecipients={setEmailRecipients}
        />
        <Box mt={3}>
          <SubmitButton text="Upload" loading={uploadingDocument} />
        </Box>
      </Box>
    </ValidatorForm>
  );
};
