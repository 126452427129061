// The is apparently in bug in typescript
// https://stackoverflow.com/questions/55591663/typescript-generic-type-does-not-enforce-type-safety
import React from 'react';

export type MaterialUiInput = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
export type MaterialUiChangeHandler<T> =
(<K2 extends keyof T>(key: K2, val: (event: MaterialUiInput) => T[K2]) => (event: MaterialUiInput) => void)
& {_hack?: T }; // <- workaround - see slack

export function CreateMaterialUiChangeHandler<T>(cb: <K extends keyof T>(key: K, val: T[K]) => void): MaterialUiChangeHandler<T> {
  const handler: MaterialUiChangeHandler<T> = (k, v) => (event) => {
    const val = v(event);
    cb(k, val);
  };
  return handler;
}
