import ReactGA from 'react-ga4';

const googleAnalyticsDisabled =
  !!window._env_.REACT_APP_GOOGLE_ANALYTICS_ID ||
  window._env_.REACT_APP_GOOGLE_ANALYTICS_ID === 'disabled';
const debugGoogleAnalytics = () =>
  window._env_.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'true';
export const listenToPages = (location: {
  pathname: string;
  search: string;
}): void => {
  const send = {
    hitType: 'pageview',
    page: location.pathname + location.search,
    title: document.title,
  };
  if (debugGoogleAnalytics()) {
    console.info('GA page nav:', {
      send,
    });
  }
  if (googleAnalyticsDisabled) {
    return;
  }
  ReactGA.send(send);
};

export const setUser = (userID?: string): void => {
  const setUserId = { userId: userID };
  const setDimension2 = { dimension2: userID };
  if (debugGoogleAnalytics()) {
    console.info('GA set user:', {
      setUserId,
      setDimension2,
    });
  }
  if (googleAnalyticsDisabled) {
    return;
  }
  ReactGA.set(setUserId);
  ReactGA.set(setDimension2);
};
