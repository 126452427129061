import { FC, useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { getLatestDocumentsForCompany } from 'modules/Documents/DocumentsApi';
import { EventsCard } from 'modules/Events';
import { DocumentsOverview } from 'modules/Documents';
import { QuickAccess } from './QuickAccess';
import { getLoans } from 'modules/Loans';
import { BasicLoanDTO, DocumentDTO } from 'api/api.types';
import { EventDTO } from '../Events/types';
import { getCompanyLogoUrl } from 'modules/Account/AccountApi';
import { CompanyLogo } from './CompanyLogo/CompanyLogo';
import { getUpcomingEventsForIssuer } from 'modules/Events';
import { Container, Typography } from '@mui/material';
import { OVERVIEW_ITEM_COUNT } from 'modules/Global/constants';
import { LoansList } from 'modules/Loans';
import Box from '@mui/material/Box';
import { useGlobal } from '../Global';
import { cancelRequests } from '../../utils/init/axiosConfig';
import classes from './Dashboard.module.css';

export const Dashboard: FC = () => {
  const { activeCompany: company } = useGlobal();
  const companyID = useMemo( ()=> {
    if(!company) return -1;
    return company.companyID;
  },[company]);
  const [logourl, setLogourl] = useState<string>('');
  const [loadingLoans, setLoadingLoans] = useState<boolean>(true);
  const [loans, setLoans] = useState<BasicLoanDTO[]>([]);
  const [loadingEvents, setLoadingEvents] = useState<boolean>(true);
  const [events, setEvents] = useState<EventDTO[]>([]);
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

  useEffect(() => {
    if (companyID === -1) return;
    let cancel = false;
    getUpcomingEventsForIssuer(companyID).then((response) => {
      if(!cancel)setEvents(response);
      setLoadingEvents(false);
    });

    getLatestDocumentsForCompany(companyID).then((documents) => {
      if(!cancel)setDocuments(documents);
      setLoadingDocuments(false);
    });

    getLoans(companyID).then((loans) => {
      if(!cancel)setLoans(loans);
      setLoadingLoans(false);
    });

  }, [companyID]);

  useEffect(() => {
    if (companyID === -1) return;
    let cancel = false;
    getCompanyLogoUrl(companyID).then((logourl) => {
      if(!cancel)setLogourl(logourl);
    }).catch((e)=> {
      console.warn('no company logo url could be received', e);
    });
    return () => {
      cancel = true;
      cancelRequests();
    };
  }, [companyID]);

  if (!company) return null;
  const { name: companyName } = company;

  return (
    <Container maxWidth="xl">
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.logoAlign}>
            <Typography variant="h1" className={classes.title}>
              {companyName || ''}
            </Typography>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <CompanyLogo url={logourl} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <EventsCard
              includePastEvents
              className={classes.card}
              events={events}
              loading={loadingEvents}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DocumentsOverview
              classes={classes}
              documents={documents?.slice(0, OVERVIEW_ITEM_COUNT) ?? []}
              loading={loadingDocuments}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <QuickAccess />
          </Grid>

          <Grid item xs={12}>
            <LoansList loading={loadingLoans} loans={loans} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
