import { Box, Typography } from '@mui/material';
import classes from './HighlightHeader.module.css';

interface HighlightHeaderProps{
  title?: string;
  color?: 'primary' | 'secondary';
  icon?: React.ReactNode;
}

export const HighlightHeader = ({
                           title, icon, color,
                         }: HighlightHeaderProps) => (
  <Box display="flex" alignItems="center" className={classes.root}>
    {icon}
    <Typography color={color} className={classes.title} variant="h3">
      {title}
    </Typography>
  </Box>
);