import { useState } from 'react';
import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import loans from '../../../../assets/images/loans.png';
import {
  ColoredText,
  HeaderTypography,
  ImageModalTriggerButton,
  IssuerPortalVideo,
  WatchVideoTriggerButton,
} from '../atoms';
import dashboard from 'assets/images/dashboard.png';
import classes from './IntroSection.module.css';

export const IntroSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  return (<section className={classes.root}>
    <Grid container spacing={6} justifyContent="center" alignItems="center">
      <Grid item lg={6}>
        <Box>
          <HeaderTypography>
            Your
            <ColoredText color="primary" text=" bonds" />
          </HeaderTypography>
          <HeaderTypography>
            made
            <ColoredText color="primary" text=" simple" />
          </HeaderTypography>

          <Typography variant="h2" className={classes.ingress}>
            Remove operational risk and gain overall process control of your
            bond portfolio.
          </Typography>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              href="mailto:portals@nordictrustee.com?subject=Request%20demo"
              className={classes.requestButton}
            >
              Request Demo
            </Button>
            <WatchVideoTriggerButton
              onClick={() => setOpenVideoModal(true)}
              title="Watch the video"
              color="primary"
            />
            {openVideoModal && (
            <SimpleModal
              onClose={()=> setOpenVideoModal(false)}
              title="Issuer Portal Video"
              content={<IssuerPortalVideo />}
            />)}
          </div>
        </Box>
      </Grid>
      <Hidden mdDown>
        <Grid item lg={6}>
          <Box display="flex" alignItems="center" justifyContent="left">
            <ImageModalTriggerButton
              onClick={() => setOpenModal(true)}
              imageSrc={loans}
              width="720"
              alt="Issuer Portal dashboard"
            />
            {openModal && (
              <SimpleModal
                onClose={()=> setOpenModal(false)}
                title="Dashboard"
                xlSize={10}
                lgSize={10}
                mdSize={10}
                content={
                  <img
                    src={dashboard}
                    alt="Issuer Portal dashboard"
                    className={classes.image}
                    width="100%"
                  />
                }
              />
            )}
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </section>);
};
