import { useCallback, useEffect, useState } from 'react';
import { getReporting } from '../ReportingApi';
import { ReportingDetailsDTO } from '../reportingApi.types';

export const useReportingDetails = (
  loanID: string,
  complianceScheduleItemID: number,
) => {
  const [isLoadingReportingDetails, setIsLoadingReportingDetails] =
    useState(false);
  const [hasSubmittedReport, setHasSubmittedReport] = useState(false);
  const [reportingDetails, setReportingDetails] =
    useState<ReportingDetailsDTO>();

  const fetchingCompleted = useCallback(
    (reportingDetailsDTO: ReportingDetailsDTO) => {
      setReportingDetails(reportingDetailsDTO);
      setHasSubmittedReport(reportingDetailsDTO.id > 0);
      setIsLoadingReportingDetails(false);
    },
    [],
  );

  const fetchReportingDetails = useCallback(
    async (calledByUseEffect = false) => {
      if (!calledByUseEffect) {
        setIsLoadingReportingDetails(true);
      }
      const reportingDetailsDTO = await getReporting(
        +loanID,
        complianceScheduleItemID,
      );
      if (!calledByUseEffect) {
        fetchingCompleted(reportingDetailsDTO);
      }
      return reportingDetailsDTO;
    },
    [complianceScheduleItemID, fetchingCompleted, loanID],
  );

  useEffect(() => {
    let cancel = false;
    setIsLoadingReportingDetails(true);
    (async () => {
      const reportingDetailsDTO = await fetchReportingDetails(true);
      if (cancel) return;
      fetchingCompleted(reportingDetailsDTO);
    })();
    return () => {
      cancel = true;
    };
  }, [fetchReportingDetails, fetchingCompleted]);

  return {
    isLoadingReportingDetails,
    hasSubmittedReport,
    reportingDetails,
    fetchReportingDetails,
  };
};
