import {
  EVENT_FILTER_OPTION_ALL,
  EventsSearchFilterSelection,
} from '../../EventsApi';
import { EventSearchFilterOption } from '../../types';

export const toggleFilterOption = (
  filterOptions: EventSearchFilterOption[],
  filterOption: EventSearchFilterOption,
  selection: EventsSearchFilterSelection,
): EventSearchFilterOption[] => {
  let newFilterOptions = [...filterOptions];

  if (filterOption.id === EVENT_FILTER_OPTION_ALL || selection === 'single') {
    newFilterOptions = newFilterOptions.map((fo) => ({
      ...fo,
      selected: fo.id === filterOption.id,
    }));
  } else if (selection === 'multiple') {
    const toggledFilterOption = newFilterOptions.find(
      (fo) => fo.id === filterOption.id,
    );
    if (toggledFilterOption)
      toggledFilterOption.selected = !toggledFilterOption.selected;
    const allFilterOption = newFilterOptions.find(
      (fo) => fo.id === EVENT_FILTER_OPTION_ALL,
    );
    if (allFilterOption)
      allFilterOption.selected = !newFilterOptions.some(
        (opt) => opt.selected === true,
      );
  }

  return newFilterOptions;
};
