import React from 'react';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';

interface MarketStamdataNoteProps {
  documentType: HubDocumentTypeUniverseEnum;
}

export const MarketStamdataNote: React.FC<MarketStamdataNoteProps> = ({
  documentType,
}) => {
  if (documentType !== HubDocumentTypeUniverseEnum.Market) return null;
  return (
    <i>
      The document will be visible on Stamdata after being reviewed by NT loan
      operations.
    </i>
  );
};
