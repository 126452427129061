import React, {useMemo} from 'react';
import { Link } from 'react-router-dom';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { StyledCardHeader } from 'components/Card';
import { InheritingLink } from 'components/links/InheritingLink';
import { LoadingContainer } from 'components/Loaders';
import { EmptyTablePlaceholder } from 'components/Table';
import { OVERVIEW_ITEM_COUNT } from 'modules/Global/constants';
import { EventsSimpleList, isPastEvent} from '../EventsScreen';
import { EventDTO } from '../types';

interface ComponentProps {
  loading: boolean;
  loanID?: number;
  events: EventDTO[];
  className: string;
  includePastEvents?: boolean;
}

export const EventsCard = (
  {
    className, events, loading, loanID,
  includePastEvents }: ComponentProps,
) => {
  const allEventsURL = loanID ? `/loans/${loanID}/events` : '';
  const renderEvents = useMemo(
    () => (includePastEvents ? events : events.filter((e) => !isPastEvent(e))),
    [events, includePastEvents],
  );
  return (
    <Card className={className}>
      <StyledCardHeader
        title={<InheritingLink href="/events">Upcoming Events</InheritingLink>}
        action={loanID ? (
          <Button component={Link} size="small" to={allEventsURL}>
            All events
          </Button>
        ) : ''}
      />
      <CardContent>
        <LoadingContainer loading={loading} type="listWithIcon" rows={3} cols={0}>
          {(renderEvents || []).length === 0
            ? (
              <EmptyTablePlaceholder
                message="No upcoming events"
                icon={<CalendarTodayOutlinedIcon fontSize="large" color="inherit" />}
              />
            )
            : <EventsSimpleList to={loanID ? `/loans/${loanID}/events` : '/events'} events={renderEvents.slice(0, OVERVIEW_ITEM_COUNT)} />}
        </LoadingContainer>
      </CardContent>
    </Card>
  );
};

