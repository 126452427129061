export const confirmClose = (
  isDirty: boolean,
  handleClose: () => void,
  callback?: (arg0: boolean) => void,
) => {
  if (isDirty) {
    const closeDialog = window.confirm('There are unsaved changes. Close?');
    if (closeDialog) {
      if (callback) {
        callback(false);
      }
      handleClose();
    }
  } else {
    if (callback) {
      callback(false);
    }
    handleClose();
  }
};
