import { HubDocumentTypeUniverseEnum } from '../../../../../../../Global/enums';

export interface UploadDocument {
  documentID: string;
  date?: string;
  type: HubDocumentTypeUniverseEnum;
  file?: File;
  uploadToStamdata: boolean;
}

export function processFiles(
  files: File[],
  documents: UploadDocument[],
  documentType: HubDocumentTypeUniverseEnum,
): UploadDocument[] {
  const nextDocuments = [...documents]; // using spread operator to avoid mutation of original array
  let currentHighestIDNumber = 0;

  documents.forEach((document: UploadDocument) => {
    const numberFromID = document.documentID
      ? document.documentID.replace('new', '')
      : '0';
    if (+numberFromID >= currentHighestIDNumber) {
      currentHighestIDNumber = +numberFromID + 1;
    }
  });

  files.forEach((file: File, i: number) => {
    const newDocument: UploadDocument = {
      documentID: `new${i + currentHighestIDNumber}`,
      date: '',
      type: documentType,
      file,
      uploadToStamdata: false,
    };
    nextDocuments.push(newDocument);
  });

  return nextDocuments;
}