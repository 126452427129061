import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { TrusteeUniverseEnum } from 'modules/Global/enums';
import { useSearchParams } from '../../../../utils/hooks';
import { ReportingEventDTO } from './reportingApi.types';

interface ReportingSearchParams {
  from?: string;
  to?: string;
  reportId?: string;
}

interface ReportingContextValue extends ReportingSearchParams {
  loanID: string;
  isSwedishTrustee: boolean;
  selectEvent: (event: ReportingEventDTO, replace?: boolean) => void;
  activeEvent?: ReportingEventDTO;
  setFilter: (range: { from: string; to: string }) => void;
}

const ReportingContext = createContext<ReportingContextValue | undefined>(
  undefined,
);

type ReportingProviderProps = {
  loanID: string;
  trusteeType?: TrusteeUniverseEnum;
};

export const ReportingProvider: FC<ReportingProviderProps> = ({
  loanID,
  trusteeType,
  children,
}) => {
  const isSwedishTrustee = useMemo(
    () => trusteeType === TrusteeUniverseEnum.NordicTrusteeSe,
    [trusteeType],
  );
  const [{ from, to, reportId }, setSearchParams] =
    useSearchParams<ReportingSearchParams>();

  const [activeEvent, setActiveEvent] = useState<ReportingEventDTO>();

  const selectEvent = useCallback(
    (event: ReportingEventDTO, replace?: boolean) => {
      if(!event) return;
      setActiveEvent(event);
      const reportId = event.id?.toString() || undefined;
      setSearchParams((prev) => ({ ...prev, reportId }), {
        replace,
      });
    },[setSearchParams],
  );

  const setFilter = useCallback(
    ({ from, to }: { from: string; to: string }) => {
      setSearchParams((prev) => ({ ...prev, from, to }), { replace: true }); // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
    },[setSearchParams],
  );

  return (
    <ReportingContext.Provider
      value={{
        loanID,
        isSwedishTrustee,
        from,
        to,
        reportId,
        activeEvent,
        selectEvent,
        setFilter,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export const useReporting = () => {
  const context = useContext(ReportingContext);
  if (context === undefined) {
    throw new Error('useReporting must be used within a ReportingProvider');
  }
  return context;
};
