import { BasicLoanDTO } from 'api/api.types';
import moment from 'moment/moment';

export const formatNextPaymentDate = (loan: BasicLoanDTO) => {
  const currentInterestPeriodEndDate = loan.currentInterestPeriodEndDate
    ? moment(loan.currentInterestPeriodEndDate).format('LL')
    : '';
  const nextPeriodInterestRate = loan.nextPeriodInterestRate
    ? ` (${loan.nextPeriodInterestRate}%)`
    : '';
  return `${currentInterestPeriodEndDate}${nextPeriodInterestRate}`;
};
