import { MouseEvent as ReactMouseEvent, ReactElement } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItemTextProps } from '@mui/material/ListItemText';

interface SimpleListItemProps {
  onClick: ((event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void);
  children: ReactElement<ListItemTextProps>[] | ReactElement<ListItemTextProps>;
  listItemIcon: any;
  divider: any;
}

export const SimpleListItem = ({
  onClick,
  children,
  listItemIcon,
  divider,
}: SimpleListItemProps) => (
  <>
    <ListItemButton
      onClick={(e) => onClick(e)}
      divider={divider}
    >
      <ListItemIcon>
        {listItemIcon}
      </ListItemIcon>
      {children}
    </ListItemButton>
  </>
);

