import { EventDTO } from '../types';

export const createReportingPath = ({loanID, date, complianceScheduleID}: Partial<EventDTO>): string => {
  if(!date) {
    throw new Error('Date is required to create reporting path');
  }
  const loanPath = `/loans/${loanID}/reporting`;
  const calendarDate = new Date(date);
  const fromDate = new Date(Date.UTC(calendarDate.getUTCFullYear(), calendarDate.getUTCMonth() - 3, calendarDate.getUTCDate()));
  const toDate = new Date(Date.UTC(fromDate.getUTCFullYear() + 1, fromDate.getUTCMonth(), fromDate.getUTCDate()));
  const from = fromDate.toISOString().split('T')[0];
  const to = toDate.toISOString().split('T')[0];
  const pathWithFilter =  `${loanPath}?from=${from}&to=${to}`;
  if(complianceScheduleID) {
    return `${pathWithFilter}&reportId=${complianceScheduleID}`;
  }
  return pathWithFilter;
}