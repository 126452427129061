import React from 'react';

export const IssuerPortalVideo = () => (
  <div style={{
    position: 'relative', marginTop: 20, paddingBottom: '56.25%', height: 0,
  }}
  >
    <iframe
      style={{
        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: "none"
      }}
      title="Issuer Portal"
      width="100%"
      height="300"
      src="https://www.youtube-nocookie.com/embed/wPrhBZGOQYg"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

