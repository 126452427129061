import moment, { Moment } from 'moment-timezone';

export function setTimezone(timezone: string): void {
  // Updating the moment 'locale' is changing the language used.
  // We don't want to change the text to German if the user has "Germany" set his country.
  // We are only using the default english language, but adjust the format mostly used
  // throughout the website.
  moment.updateLocale('en', {
    longDateFormat: {
      L: 'DD/MM/YYYY',
      LTS: 'DD/MM/YYYY',
      LT: 'DD/MM/YYYY',
      LL: 'DD/MM/YYYY',
      LLL: 'DD/MM/YYYY',
      LLLL: 'DD/MM/YYYY',
    },
  });

  // Set the timezone from user profile:
  // All dates manipulated with moment will automatically be adjusted
  // to the user timezone.
  moment.tz.setDefault(timezone);
}

export const daysDiffText = (date: Moment) => {
  const days = date.startOf('day').diff(moment().startOf('day'), 'days');
  if (days === 0) { return 'Today'; }
  if (days === 1) { return 'In 1 day'; }
  if (days > 0) { return `In ${days} days`; }
  return undefined;
};

export const daysOverdueDiffText = (date: Moment) => {
  const days = date.startOf('day').diff(moment().startOf('day'), 'days');
  if (days === -1) { return 'Yesterday'; }
  if (days < -1) { return `Since ${days * -1} days`; }
  return undefined;
};
