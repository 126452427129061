import { useEffect, useState } from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import { PublishedSecurityDTO } from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { SecurityDetailTypeUniverseEnum } from 'modules/Global/enums';
import { Disclaimer } from './Disclaimer';
import { LastUpdated } from './LastUpdated';
import {
  AccountPledge,
  Guarantee,
  NorwegianPropertyMortgage,
  NorwegianSharePledge,
  Other,
  PropertyMortgage,
  RigVesselMortgage,
  SharePledge,
  SwedishPropertyMortgage,
} from './Securities';
import { loadSecurities } from './security';
import { SimplePaper } from './SimplePaper';

interface ComponentProps {
  loanID: number;
}

export const SecuritiesOverview = ({ loanID }: ComponentProps) => {
  const [securities, setSecurities] = useState<PublishedSecurityDTO[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (loanID != null) {
      loadSecurities(loanID).then((secs) => {
        setSecurities(secs);
        setLoading(false);
      });
    }
  }, [loanID]);

  const guarantees = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.Guarantee,
  );
  const accountPledges = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.AccountPledge,
  );
  const rigVesselMortgage = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.RigVesselMortgage,
  );
  const sharePledges = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.SharePledge,
  );
  const others = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID === SecurityDetailTypeUniverseEnum.Other,
  );

  const norwegianPropertyMortgage = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.NorwegianPropertyMortgage,
  );
  const propertyMortgage = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.PropertyMortgage,
  );
  const norwegianSharePledge = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.NorwegianSharePledge,
  );
  const swedishPropertyMortgage = securities.filter(
    (sec) =>
      sec.securityDetailTypeUniverseID ===
      SecurityDetailTypeUniverseEnum.SwedishPropertyMortgage,
  );

  const lastPublishedDate =
    securities.length > 0 ? securities[0].publishedDate : '';

  return (
    <>
      <Box mt={2} mb={2}>
        <Disclaimer />
      </Box>
      <Box mt={2}>
        <SimplePaper>
          <StyledCardHeader title="Securities" />
          <CardContent>
            <LoadingContainer
              loading={loading}
              type="singleList"
              rows={20}
              cols={3}
            >
              {securities.length === 0 && (
                <Typography variant="body1">
                  No current securities found
                </Typography>
              )}

              {securities.length > 0 && (
                <LastUpdated lastUpdatedDate={lastPublishedDate} />
              )}
              {!loading && (
                <>
                  {guarantees.length > 0 && (
                    <Guarantee securities={guarantees} />
                  )}
                  {accountPledges.length > 0 && (
                    <AccountPledge securities={accountPledges} />
                  )}
                  {rigVesselMortgage.length > 0 && (
                    <RigVesselMortgage securities={rigVesselMortgage} />
                  )}
                  {sharePledges.length > 0 && (
                    <SharePledge securities={sharePledges} />
                  )}
                  {others.length > 0 && <Other securities={others} />}
                  {norwegianPropertyMortgage.length > 0 && (
                    <NorwegianPropertyMortgage
                      securities={norwegianPropertyMortgage}
                    />
                  )}
                  {propertyMortgage.length > 0 && (
                    <PropertyMortgage securities={propertyMortgage} />
                  )}
                  {norwegianSharePledge.length > 0 && (
                    <NorwegianSharePledge securities={norwegianSharePledge} />
                  )}
                  {swedishPropertyMortgage.length > 0 && (
                    <SwedishPropertyMortgage
                      securities={swedishPropertyMortgage}
                    />
                  )}
                </>
              )}
            </LoadingContainer>
          </CardContent>
        </SimplePaper>
      </Box>
    </>
  );
};
