import { Grid } from '@mui/material';
import { Card } from '@nordictrustee/nt-ui-library';
import { FinancialOverviewDTO } from 'api/api.types';
import { ManagerDistributionChart,MaturityProfileChart } from '../atoms/charts';
import { FinancialOverviewPortfolio } from './FinancialOverviewPortfolio';
import { KeyFigures } from './KeyFigures';

interface ComponentProps{
  financialOverview: FinancialOverviewDTO | null;
}

export const FinancialOverview = ({ financialOverview }: ComponentProps) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6} lg={4}>
      <Card title="Maturity Profile (Excluding Perpetuals)">
        <MaturityProfileChart maturityProfile={financialOverview?.maturityProfile} />
      </Card>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Card title="Manager Distribution">
        <ManagerDistributionChart managerDistribution={financialOverview?.managerDistribution} currency={financialOverview?.currency} />
      </Card>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Card title="Key Figures">
        <KeyFigures keyFigures={financialOverview?.keyFigures} />
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card title="Portfolio">
        <FinancialOverviewPortfolio financialOverviewRows={financialOverview?.financialOverviewRows} />
      </Card>
    </Grid>
  </Grid>
);

