import { DocumentModel } from 'api/api.types';
import { DocumentsOptions, getFilteredDocuments } from '../DocumentsApi';
import { getFilterOptionAll } from './DocumentsFilter';

export const getFormattedDocuments = async (opts: DocumentsOptions): Promise<DocumentModel> => {
  const documentSearch = await getFilteredDocuments(opts);

  if (documentSearch.filters.companyIDs) {
    documentSearch.filters.companyIDs.unshift(getFilterOptionAll(documentSearch.filters.companyIDs, 'All companies'));
  }

  if (documentSearch.filters.issueIDs) {
    documentSearch.filters.issueIDs.unshift(getFilterOptionAll(documentSearch.filters.issueIDs, 'All loans'));
  }

  return documentSearch;
};