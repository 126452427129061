import { useState } from 'react';
import BuildIcon from '@mui/icons-material/Build';
import { Box, Grid, Typography } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import { ColoredText, ImageModalTriggerButton } from '../atoms';
import cashflow from 'assets/images/cashflow.png';
import loans from 'assets/images/loans.png';
import classes from './AutomationAndEfficiencySection.module.css';

export const AutomationAndEfficiencySection = () => {
  const [openModal, setOpenModal] = useState(false);
  return (<section className={classes.root}>
    <Grid container spacing={10} alignItems="center" justifyContent="center">
      <Grid item order={{ xs: 2, lg: 1 }} lg={7}>
        <Box position="relative">
            <ImageModalTriggerButton
              onClick={() => setOpenModal(true)}
              imageSrc={loans}
              width="650"
              alt="Issuer Portal cashflow page"
            />
            {openModal && (
              <SimpleModal
                onClose={()=> setOpenModal(false)}
                title="Issuer Portal cashflow page"
                xlSize={10}
                lgSize={10}
                mdSize={10}
                content={
                  <img
                    src={cashflow}
                    alt="Issuer Portal cashflow page"
                    className={classes.image}
                    width="100%"
                  />
                }
              />
            )}
            <BuildIcon color="primary" className={classes.backgroundIcon} />
          </Box>
      </Grid>
      <Grid item order={{ xs: 1, lg: 2 }} lg={5}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className={classes.text}
        >
          <Box display="flex" alignItems="center" className={classes.header}>
            <BuildIcon className={classes.icon} />
            <Typography variant="h1">
              Automation &
              <ColoredText text=" Efficiency" />
            </Typography>
          </Box>
          <Typography variant="body1">
            Reduce the overall cost associated with your bond or bond
            portfolio through our cutting edge solution. Get instant access to
            automatically updated bond data, documents, securities and
            security package, audit confirmations and much more. Our team has
            through decades of experience in the Nordic bond market developed
            advanced processes, freeing up your time to be spent on mission
            critical tasks within your organization.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </section>);
};
