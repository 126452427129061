import { useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, Container } from '@mui/material';
import { StyledCardHeader } from 'components/Card';
import { HelperText } from 'components/form';
import SimpleSelect from 'components/form/selects/simple_select/SimpleSelect';
import { CompanyContactDetailsDTO, CompanyDTO } from 'modules/Global/types';
import { HubUserWithCompaniesDTO } from '../../AccountApi/api.types';
import { getIssuerContactProfile } from '../../AccountApi';
import { CompanyContactDetailsReadonly } from './CompanyContactDetailsReadonly';
import { EditContactDetailsButton } from './EditCompanyContactDetails';

type ComponentProps = {
  activeCompany: CompanyDTO;
  user: HubUserWithCompaniesDTO;
};

const sortCompanies = (companies: CompanyDTO[]) => {
  const sortedCompanies = [...companies];
  sortedCompanies.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return sortedCompanies;
};
const findCompanyIndex = (companies: CompanyDTO[], companyID: number) =>
  companies.findIndex((c) => c.companyID === companyID);

export const CompanyContactDetails: React.FC<ComponentProps> = ({ user, activeCompany }) => {
  const companies = useMemo(() => {
    return sortCompanies(user.companies || []);
  }, [user]);
  const [profile, setProfile] = useState<CompanyContactDetailsDTO>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<number>(-1);
  const selectedCompany = useMemo(()=> companies[selectedCompanyIndex], [companies, selectedCompanyIndex])
  const switchCompany = (index: number) => {
    setSelectedCompanyIndex(index);
  };
  useEffect(() => {
    setSelectedCompanyIndex(
      findCompanyIndex(companies, activeCompany.companyID),
    );
  }, [activeCompany.companyID, companies]);

  const companiesAsItemsForSelect = useMemo( ()=> companies.map((c, i) => ({
    value: i.toString(),
    label: c.name,
  })),[companies]);

  useEffect(() => {
    if (!selectedCompany) return;
    const fetchProfile = async () => {
      if (companies) {
        setLoading(true);
        const issuerID = selectedCompany.companyID;
        const profile = await getIssuerContactProfile(issuerID);
        setProfile(profile);
        setLoading(false);
      }
    };
    //TODO: handle with React Query
    fetchProfile();
  }, [companies, selectedCompany]);

  if (!companies) {
    return null;
  }

  return (
    <Container maxWidth="xl">
      <Box mt={2} mb={2}>
        <Card>
          <StyledCardHeader
            title="Company Contact Details"
            action={
              <EditContactDetailsButton
                profile={profile}
                issuerID={selectedCompany?.companyID || -1}
                onSubmitted={() => {}}
              />
            }
          />
          <CardContent>
            {companies.length > 1 && (
              <Box mb={2}>
                <SimpleSelect
                  value={selectedCompanyIndex.toString()}
                  items={companiesAsItemsForSelect}
                  changeHandler={(e: any) =>
                    switchCompany(Number(e.target.value))
                  }
                />
              </Box>
            )}
            <CompanyContactDetailsReadonly
              profile={profile}
              loading={loading}
            />
            <HelperText>
              The contact details will be used for communication and invoicing
              during the course of our assignment as agent under your bond
              loan(s). We will process your change request within two business
              days. If you have any question, do not hesitate to contact us
              using the email or phone number present in the footer.
            </HelperText>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};
