import { useEffect, useState } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Card } from '@nordictrustee/nt-ui-library';
import { CallDTO } from 'api/api.types';
import moment from 'moment';
import { LoadingContainer } from 'components/Loaders';
import { ScrollableTable } from 'components/Table';
import { getCallsForLoan } from '../../LoansApi';

interface CallsProps {
  loanID: number;
}

interface CallRowProps {
  call: CallDTO;
}

const getCallPeriod = (call: CallDTO) => {
  if (call.termDate) {
    return moment(call.termDate).format('L');
  }

  if (call.periodStartDate && call.periodEndDate) {
    return `${moment(call.periodStartDate).format('L')} - ${moment(call.periodEndDate).format('L')}`;
  }

  return 'N/A';
};

const CallHeaderRow = () => (
  <TableRow>
    <TableCell component="th" scope="row">
      <Typography variant="h5">Call Period / Call Date</Typography>
    </TableCell>
    <TableCell component="th" scope="row">
      <Typography variant="h5">Last Notice</Typography>
    </TableCell>
    <TableCell component="th" scope="row">
      <Typography variant="h5">Price</Typography>
    </TableCell>
  </TableRow>
);

const CallRow = ({ call }: CallRowProps) => (
  <TableRow selected={call.isCurrent}>
    <TableCell component="td" scope="row">
      <Typography variant="body1">{getCallPeriod(call)}</Typography>
    </TableCell>
    <TableCell component="td" scope="row">
      <Typography variant="body1">
        {moment(call.lastNoticeDate || '').format('L')}
      </Typography>
    </TableCell>
    <TableCell component="td" scope="row">
      <Typography variant="body1">{call.price}</Typography>
    </TableCell>
  </TableRow>
);

export const Calls = ({ loanID }: CallsProps) => {
  const [calls, setCalls] = useState<CallDTO[] | undefined>(undefined);

  useEffect(() => {
    async function loadData() {
      const callDTOs = await getCallsForLoan(loanID);
      setCalls(callDTOs);
    }

    loadData();
  }, [loanID]);

  return (
    <Card title="Calls">
      <LoadingContainer loading={!calls} type="list" rows={15} cols={3}>
        <ScrollableTable>
          <TableHead>
            <CallHeaderRow />
          </TableHead>
          <TableBody>
            {(!calls || calls.length === 0) && (
              <Typography>No calls found</Typography>
            )}
            {calls &&
              calls.length > 0 &&
              calls.map((c) => <CallRow key={c.periodStartDate} call={c} />)}
          </TableBody>
        </ScrollableTable>
      </LoadingContainer>
    </Card>
  );
};
