import React from 'react';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DocumentDTO } from 'api/api.types';
import { StyledCardHeader } from 'components/Card';
import { InheritingLink } from 'components/links/InheritingLink';
import { LoadingContainer } from 'components/Loaders';
import { EmptyTablePlaceholder } from 'components/Table';
import { OVERVIEW_ITEM_COUNT } from 'modules/Global/constants';
import { DocumentsSimpleList } from './DocumentsSimpleList';

interface ComponentProps {
  loading: boolean;
  loanID?: number;
  documents: DocumentDTO[];
  classes: any; // TODO
}

export const DocumentsOverview = (
  {
    documents, loading, loanID, classes,
  }: ComponentProps,
) => {
  const allDocumentsURL = loanID ? `/loans/${loanID}/documents` : '';
  return (
    <Card className={classes.card}>
      <StyledCardHeader
        title={<InheritingLink href="/documents">Recent Documents</InheritingLink>}
        action={loanID ? (
          <Button component={Link} size="small" to={allDocumentsURL}>
            All documents
          </Button>
        ) : ''}
      />
      <CardContent>
        <LoadingContainer loading={loading} type="listWithIcon" rows={3} cols={0}>
          {(documents.length === 0
            ? (
              <EmptyTablePlaceholder
                message="No recent documents"
                icon={<DescriptionIcon fontSize="large" color="inherit" />}
              />
            )
            : <DocumentsSimpleList documents={documents.slice(0, OVERVIEW_ITEM_COUNT)} />
          )}
        </LoadingContainer>
      </CardContent>
    </Card>
  );
};

