import { AuthorizedIssueDTO, IssueTypeUniverseDTO } from '../../api/api.types';
import { CompanyDTO } from '../Global/types';

export enum EventTypeUniverseEnum {
  InterestPaymentDate = 'InterestPaymentDate',
  ComplianceReporting = 'ComplianceReporting',
  ListingDeadline = 'ListingDeadline',
  Manual = 'Manual',
  QuotationDate = 'QuotationDate',
  AddedByNt = 'AddedByNT',
  NtPublishedMessage = 'NTPublishedMessage',
  NtPublishedDocument = 'NTPublishedDocument',
  CallDate = 'CallDate',
  InstallmentDate = 'InstallmentDate',
}

export interface CreateEventDTO {
  date: string;
  title: string;
  description?: string;
  completedDate?: string;
  completedByID?: number | null;
  completedByName?: string;
  eventType: EventTypeUniverseEnum;
}

export interface EventDTO {
  eventID: number;
  loanName?: string;
  requiresManualCompletion?: boolean;
  date: string;
  title: string;
  description?: string;
  completedDate?: string;
  completedByID?: number | null;
  completedByName?: string;
  eventType: EventTypeUniverseEnum;
  loanID: number;
  complianceScheduleID?: number;
}

export interface EventFiltersDTO {
  issues: Array<AuthorizedIssueDTO>;
  companies: Array<CompanyDTO>;
  issueTypes?: Array<IssueTypeUniverseDTO>;
  eventTypes?: Array<EventTypeUniverseDTO>;
}

export interface EventModel {
  data: Array<EventDTO>;
  filters: EventSearchFilters;
  numberOfEvents: number;
  numberOfPages?: number | null;
}

export interface EventSearchFilterOption {
  id: string;
  text: string;
  selected?: boolean | null;
}

export interface EventSearchFilters {
  eventTypes: Array<EventSearchFilterOption>;
  loanIDs: Array<EventSearchFilterOption>;
  issuers: Array<EventSearchFilterOption>;
  issueTypes: Array<EventSearchFilterOption>;
}

export interface EventTypeUniverseDTO {
  eventTypeUniverseID?: EventTypeUniverseEnum;
  name?: string;
  notificationSchedule?: string;
  requiresManualCompletion?: boolean;
  shouldNotify?: boolean;
}
