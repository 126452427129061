import ReactGA from 'react-ga4';

export function initGoogleAnalytics(googleAnalyticsID?: string): void {
    if (
        !googleAnalyticsID ||
        googleAnalyticsID === 'disabled'
    ) {
        return;
    }


    ReactGA.initialize(window._env_.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.send({
        hitType: 'pageview',
        page: document.location.pathname,
        title: document.title,
    });
}