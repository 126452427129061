import { useEffect, useState } from 'react';
import classes from './MultilineTruncatedText.module.css';

interface MultilineTruncatedTextProps {
  text: string;
}

export const MultilineTruncatedText = ({
  text,
}: MultilineTruncatedTextProps) => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const elm = document.getElementById(text);
    if (!elm) return;
    const textHeight = elm.clientHeight;
    setHeight(textHeight);
  }, [text]);

  return (
    <>
      <span id={text} className={classes.max}>
        { text}
      </span>
      {
        height > 40
      && (
        <span>
          ...
        </span>
      )
      }
    </>
  );
};
