import React from 'react';
import { UndergoingMaintenancePage } from './UndergoingMaintenancePage';

export const UndergoingMaintenanceGuard: React.FC<{ envText: string, children: React.ReactNode }> = ({
  envText, children
}) => {
  if(!envText){
    return <>{children}</>;
  }
  const maintenanceText =
    envText.toLowerCase() === 'true'
      ? 'The Issuer Portal is currently undergoing maintenance.'
      : envText;
  return (
    <UndergoingMaintenancePage maintenanceText={maintenanceText} />
  );
};
