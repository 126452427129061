import React, { PropsWithChildren } from 'react';
import { SkeletonLoader, SkeletonProps } from './SkeletonLoader';

interface LoadingContainerProps extends SkeletonProps {
  loading: boolean;
  children?: React.ReactNode;
  ContainerComponent?: React.FC<PropsWithChildren<unknown>>
}

export const LoadingContainer = ({
  ContainerComponent = React.Fragment,
  children,
  loading,
  type,
  rows,
  cols,
}: LoadingContainerProps) => {
  if (loading) {
    return (
      <ContainerComponent>
        <SkeletonLoader type={type} rows={rows} cols={cols} />
      </ContainerComponent>
    );
  }
  return <>{children}</>;
};

