import NotesIcon from '@mui/icons-material/Notes';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { CaseLogDTO } from 'api/api.types';
import moment from 'moment';
import { SimpleList, SimpleListItem } from 'components/SimpleList';
import { MultilineTruncatedText } from './MultilineTruncatedText';

interface ComponentProps {
  messages: CaseLogDTO[];
  to: string;
}
const openUrl = (url: string) => {
  window.open(url, '_self');
};

const MessagesSimpleList = ({ messages, to }: ComponentProps) => (
  <SimpleList>
    {messages.map((message, i) => (
      <SimpleListItem
        key={message.text}
        onClick={() => openUrl(to)}
        listItemIcon={(
          <NotesIcon color="primary" />
        )}
        divider={messages.length !== i + 1}
      >
        <ListItemText
          primary={(
            <Typography variant="body2">
              { moment(message.date).format('L')}
            </Typography>
          )}
          secondaryTypographyProps={{ variant: 'body1', color: 'textPrimary' }}
          secondary={(
            <MultilineTruncatedText text={message.text} />
          )}
        />
      </SimpleListItem>
    ))}
  </SimpleList>
);

export default MessagesSimpleList;
