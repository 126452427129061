import List from '@mui/material/List';
import classes from './SimpleList.module.css';

interface SimpleListProps {
  children: any;
}

export const SimpleList = ({
  children,
}: SimpleListProps) => (
  <>
    <List dense disablePadding className={classes.list}>
      {children}
    </List>
  </>
);