import moment from 'moment/moment';
import { EVENT_UPCOMING_WITHIN_DAYS } from 'modules/Events/constants';
import { EventDTO } from '../../types';

export const isUpcomingEvent = (event: EventDTO) => {
  const days = moment(event.date)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
  return days >= 0 && days <= EVENT_UPCOMING_WITHIN_DAYS;
};
