import {
  FinancialReportingApi,
  FinancialReportingApiAxiosParamCreator} from 'api';
import { AxiosResponse } from 'axios';
import { getAccessToken } from 'modules/Account/accountLocalStorage';
import { CurrencyCodeUniverseEnum } from 'modules/Global/enums';
import { FinancialReportingDTO } from '../../api/api.types';

const FinancialReportingApiInstance = new FinancialReportingApi();

export async function downloadFinancialReportExcelForLoan(loanID: number) {
  const requestConfig =
    await FinancialReportingApiAxiosParamCreator().getFinancialReportForLoan(
      loanID,
    );
  const token = await getAccessToken();
  const response = await fetch(requestConfig.url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const blob = await response.blob();
  const filename = response.headers
    .get('Content-Disposition')
    ?.split('filename="')[1]
    .split('"')[0];
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('type', 'hidden');
  link.setAttribute('download', filename || '');
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const getFinancialReportingDTOForLoan = async (loanID: number) => {
  try {
    const response =
      await FinancialReportingApiInstance.getFinancialReportingDTOForLoan(
        loanID,
      );
    return (response as AxiosResponse<FinancialReportingDTO>).data;
  } catch (response) {
    const { message: errorMessage } = (response as AxiosResponse<{
      message: string;
    }>).data;
    return {
      cashFlowDTO: { errorMessage },
      accountingDTO: { errorMessage },
      interestPaymentDTO: { errorMessage },
    };
  }
}

export async function getFinancialOverview(
  issuerID: number,
  currency: CurrencyCodeUniverseEnum,
) {
  const response = await FinancialReportingApiInstance.getFinancialOverview(
    issuerID,
    currency,
  );
  return (response as any).data;
}
