import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import classes from './EmptyTablePlaceholder.module.css';

interface ComponentProps {
  icon: ReactNode;
  message: string;
}

export const EmptyTablePlaceholder = (({ icon, message }: ComponentProps) => (
  <Typography color="inherit" align="center" variant="body1" className={classes.noData}>
    {icon}
    <br />
    {message}
  </Typography>
));

