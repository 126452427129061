import { CSSProperties, memo } from 'react';
import { ToastContainer } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import appTheme from '../theme';
import 'react-toastify/dist/ReactToastify.css';

export const ThemedToastContainer = memo(() => {
  const theme = useTheme<typeof appTheme>();
  const style: any & CSSProperties = {
    '--toastify-color-info': theme.palette.info.main,
    '--toastify-color-success': theme.palette.success.main,
    '--toastify-color-warning': theme.palette.warning.main,
    '--toastify-color-error': theme.palette.error.main,
  };
  return <ToastContainer theme="colored" style={style} />;
});
