import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Stack, Typography } from '@mui/material';
import { Button, Dialog, Input } from '@nordictrustee/nt-ui-library';
import { useGlobal, useToast } from 'modules/Global';
import { confirmClose } from 'utils/hooks/confirmClose';
import {
  InformationCovenantDTO,
  ReportingDetailsDTO,
  ReportingEventDTO,
} from '../../reportingApi.types';
import { useReporting } from '../../ReportingContext';
import { Deliveries } from './Deliveries';
import { FileUploads } from './FileUploads';
import { formatTitle } from './formatTitle';
import { ComplianceReportForm } from './ReportingSubmitForm.types';
import { submitReport } from './submitReport';
import { updateReport } from './updateReport';
import { uploadNewFiles } from './uploadNewFiles';

interface Props {
  onClose: (updatedReportID?: number) => Promise<void>;
  onPartiallySubmitted: () => Promise<void>;
  reportingEvent: ReportingEventDTO;
  reportingDetails?: ReportingDetailsDTO;
}

export const ReportingSubmitForm: FC<Props> = ({
  onClose,
  onPartiallySubmitted,
  reportingEvent,
  reportingDetails,
}: Props) => {
  const isEditMode = !!reportingDetails && reportingDetails.id > 0;
  const { id: complianceScheduleId } = reportingEvent;
  const { loanID, isSwedishTrustee } = useReporting();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showError, showSuccess } = useToast();
  const {
    control,
    formState: { isDirty },
    watch,
    getValues,
    handleSubmit,
  } = useForm<ComplianceReportForm>({
    defaultValues: {
      informationCovenants: reportingDetails?.informationCovenants || [],
      comment: reportingDetails?.comment || '',
      hasConfirmedNoEventOfDefault: isEditMode
        ? reportingDetails?.hasConfirmedNoEventOfDefault ?? undefined
        : undefined,
      files:
        reportingDetails?.complianceDocuments.map((complianceDocument) => ({
          publish: complianceDocument.isPublishRequested,
          ...complianceDocument,
        })) || [],
    },
    mode: 'onSubmit',
  });

  const files = watch('files') || [];
  const { activeCompany } = useGlobal();
  if (!activeCompany) return null;
  const { companyID: companyId } = activeCompany;

  const onValidSubmit = async () => {
    setIsSubmitting(true);
    const formData = getValues();
    let reportingId = reportingDetails?.id;
    try {
      reportingId = isEditMode
        ? await updateReport({
          loanID,
          complianceScheduleId,
          companyId,
          reportingId: reportingId!,
          formData,
        })
        : await submitReport({
          loanID,
          complianceScheduleId,
          formData,
        });
    } catch (error) {
      showError('An error occurred while submitting the report');
      setIsSubmitting(false);
      return;
    }
    try {
      await uploadNewFiles({
        loanID,
        complianceScheduleId,
        companyId,
        files: formData.files,
        reportingId,
      });
    } catch {
      showError(
        'An error occurred while uploading files. Please check if your filename(s) for any special characters.',
      );
      setIsSubmitting(false);
      await onPartiallySubmitted();
      return;
    }
    showSuccess('Report has been successfully submitted');
    await onClose(reportingId);
  };

  const handleConfirmClose = () => {
    if (isSubmitting) return;
    confirmClose(isDirty, onClose);
  };
  return (
    <Dialog
      onClose={handleConfirmClose}
      open
      title={formatTitle(reportingEvent)}
      maxWidth="md"
      fullWidth
      dialogActions={
        <>
          <Button form="report-form" type="submit" isLoading={isSubmitting}>
            {isEditMode ? 'Save' : 'Submit'}
          </Button>
          <Button
            variant="outlined"
            onClick={handleConfirmClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </>
      }
    >
      <Grid
        component="form"
        container
        display="flex"
        onSubmit={handleSubmit(onValidSubmit)}
        noValidate
        id="report-form"
      >
        <Grid item xs={12}>
          <Controller
            control={control}
            name="informationCovenants"
            defaultValue={[]}
            render={({ field: { value, onChange } }) => {
              return (
                <Deliveries
                  informationCovenants={value}
                  onChange={(
                    informationCovenants: InformationCovenantDTO[],
                  ) => {
                    onChange(informationCovenants);
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography variant="h3" mb={1}>
            Reporting
          </Typography>
          <Stack spacing={3}>
            <Controller
              control={control}
              name="files"
              defaultValue={[]}
              render={({ field: { onChange } }) => {
                return (
                  <FileUploads
                    files={files}
                    onChange={onChange}
                    isEditMode={isEditMode}
                    isSwedishTrustee={isSwedishTrustee}
                    isBeingReviewedByNT={!!reportingDetails?.isBeingReviewedByNT}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="comment"
              rules={{
                maxLength: {
                  value: 4000,
                  message: 'Comment can be max 4000 characters',
                },
              }}
              render={({
                field: { value, onChange, ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <Input.Text
                  label="Comment to Nordic Trustee"
                  onChange={onChange}
                  value={value}
                  multiline
                  rows={5}
                  error={!!error?.message}
                  helperText={error?.message || `${value.length}/4000`}
                  {...rest}
                />
              )}
            />
            <Controller
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input.Radio
                  title="We confirm that, to the best of our knowledge, no event of default has occurred or is likely to occur."
                  name="hasConfirmedNoEventOfDefault"
                  value={value}
                  onChange={onChange}
                  data={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  required
                  errorMessage={error?.message}
                />
              )}
              name="hasConfirmedNoEventOfDefault"
              control={control}
              rules={{
                validate: (value) => {
                  if (value === undefined) {
                    return 'This field is required';
                  }
                  return true;
                },
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
};
