import { FC, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import { useToast } from 'modules/Global';
import { CompanyContactDetailsDTO } from 'modules/Global/types';
import { updateIssuerContactProfile as updateIssuerContactProfileResource } from '../../AccountApi';
import EditBasicInformation from './EditBasicInformation';
import EditContactPerson from './EditContactPerson';
import EditInvoiceAddress from './EditInvoiceAddress';
import EditPostalAddress from './EditPostalAddress';

interface EditCompanyContactDetailsProps {
  profile: CompanyContactDetailsDTO;
  issuerID: number;
  onSubmitted: ()=>void;
}

const EditCompanyContactDetails = ({
  profile, issuerID, onSubmitted
}: EditCompanyContactDetailsProps) => {
  const { showSuccess, showWarning } = useToast();
  const [editedProfile, setEditedProfile] = useState<CompanyContactDetailsDTO>();

  const [companyName, setCompanyName] = useState<string>('');
  const [regNumber, setRegNumber] = useState<string>('');
  const [vatNumber, setVatNumber] = useState<string>('');
  const [invoiceEmail, setInvoiceEmail] = useState<string>('');
  const [invoiceContactPerson, setInvoiceContactPerson] = useState<string>('');

  const [contactPersonName, setContactPersonName] = useState<string>('');
  const [contactPersonTitle, setContactPersonTitle] = useState<string>('');
  const [contactPersonEmail, setContactPersonEmail] = useState<string>('');
  const [contactPersonPhone, setContactPersonPhone] = useState<string>('');

  const [street, setStreet] = useState<string>('');
  const [extendedStreet, setExtendedStreet] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');

  const [invoiceStreet, setInvoiceStreet] = useState<string>('');
  const [invoiceExtendedStreet, setInvoiceExtendedStreet] = useState<string>('');
  const [invoicePostalCode, setInvoicePostalCode] = useState<string>('');
  const [invoiceCity, setInvoiceCity] = useState<string>('');
  const [invoiceCountry, setInvoiceCountry] = useState<string>('');

  useEffect(() => {
    if (!profile) {
      return;
    }

    setEditedProfile(profile);
    if (profile.companyDetails) {
      setCompanyName(profile.companyDetails.companyName ? profile.companyDetails.companyName : '');
      setRegNumber(profile.companyDetails.companyRegistrationNumber ? profile.companyDetails.companyRegistrationNumber : '');
      setVatNumber(profile.companyDetails.vatNumber ? profile.companyDetails.vatNumber : '');
      setInvoiceEmail(profile.companyDetails.invoiceEmail ? profile.companyDetails.invoiceEmail : '');
      setInvoiceContactPerson(profile.companyDetails.invoiceContactPerson ? profile.companyDetails.invoiceContactPerson : '');
    } else {
      setCompanyName('');
      setRegNumber('');
      setVatNumber('');
      setInvoiceEmail('');
      setInvoiceContactPerson('');
    }

    if (profile.companyContactPerson) {
      setContactPersonName(profile.companyContactPerson.name ? profile.companyContactPerson.name : '');
      setContactPersonTitle(profile.companyContactPerson.title ? profile.companyContactPerson.title : '');
      setContactPersonEmail(profile.companyContactPerson.email ? profile.companyContactPerson.email : '');
      setContactPersonPhone(profile.companyContactPerson.phoneNumber ? profile.companyContactPerson.phoneNumber : '');
    }

    if (profile.postalAddress) {
      setStreet(profile.postalAddress.address ? profile.postalAddress.address : '');
      setExtendedStreet(profile.postalAddress.extendedStreet ? profile.postalAddress.extendedStreet : '');
      setPostalCode(profile.postalAddress.postalCode ? profile.postalAddress.postalCode : '');
      setCity(profile.postalAddress.city ? profile.postalAddress.city : '');
      setCountry(profile.postalAddress.country ? profile.postalAddress.country : '');
    }

    if (profile.invoiceAddress) {
      setInvoiceStreet(profile.invoiceAddress.address ? profile.invoiceAddress.address : '');
      setInvoiceExtendedStreet(profile.invoiceAddress.extendedStreet ? profile.invoiceAddress.extendedStreet : '');
      setInvoicePostalCode(profile.invoiceAddress.postalCode ? profile.invoiceAddress.postalCode : '');
      setInvoiceCity(profile.invoiceAddress.city ? profile.invoiceAddress.city : '');
      setInvoiceCountry(profile.invoiceAddress.country ? profile.invoiceAddress.country : '');
    }
  }, [profile]);

  const profileData = {
    companyDetails: {
      companyName: companyName || '',
      companyRegistrationNumber: regNumber || '',
      vatNumber: vatNumber || '',
      invoiceEmail: invoiceEmail || '',
      invoiceContactPerson: invoiceContactPerson || '',
    },
    postalAddress: {
      address: street || '',
      extendedStreet: extendedStreet || '',
      postalCode: postalCode || '',
      city: city || '',
      country: country || '',
    },
    invoiceAddress: {
      address: invoiceStreet || '',
      extendedStreet: invoiceExtendedStreet || '',
      postalCode: invoicePostalCode || '',
      city: invoiceCity || '',
      country: invoiceCountry || '',
    },
    companyContactPerson: {
      name: contactPersonName || '',
      email: contactPersonEmail || '',
      title: contactPersonTitle || '',
      phoneNumber: contactPersonPhone || '',
    },
  };
  const onSubmit = async () => {
    // TODO: check if fields are dirty before submitting anything - if not, just close the modal.
    try {
      const response = await updateIssuerContactProfileResource(
        issuerID,
        profileData
      );
      const statusGroup = response.status - (response.status % 100);
      if (statusGroup === 200) {
        showSuccess(
          `Your change request has been sent. It will take up to two business days to see the change.
        Please don't request another change during that timeframe.`,
        );
        onSubmitted();
      } else
        showWarning(`There may be a problem with your input data. Please review and try again.`);
    } catch (err: any) {
      showWarning(`There was a problem with updating the Company Details, please try again.`);
      console.error(JSON.stringify(err?.response ?? err));
    }
  };
  return (
    editedProfile ? (
      <Grid container>
        <Grid item xs={12} md={6}>
          <EditBasicInformation
            companyName={companyName}
            setCompanyName={setCompanyName}
            regNumber={regNumber}
            setRegNumber={setRegNumber}
            vatNumber={vatNumber}
            setVatNumber={setVatNumber}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EditContactPerson
            contactPersonName={contactPersonName}
            setContactPersonName={setContactPersonName}
            contactPersonTitle={contactPersonTitle}
            setContactPersonTitle={setContactPersonTitle}
            contactPersonEmail={contactPersonEmail}
            setContactPersonEmail={setContactPersonEmail}
            contactPersonPhone={contactPersonPhone}
            setContactPersonPhone={setContactPersonPhone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EditPostalAddress
            street={street}
            setStreet={setStreet}
            extendedStreet={extendedStreet}
            setExtendedStreet={setExtendedStreet}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            city={city}
            setCity={setCity}
            country={country}
            setCountry={setCountry}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EditInvoiceAddress
            invoiceEmail={invoiceEmail}
            setInvoiceEmail={setInvoiceEmail}
            invoiceContactPerson={invoiceContactPerson}
            setInvoiceContactPerson={setInvoiceContactPerson}
            street={invoiceStreet}
            setStreet={setInvoiceStreet}
            extendedStreet={invoiceExtendedStreet}
            setExtendedStreet={setInvoiceExtendedStreet}
            postalCode={invoicePostalCode}
            setPostalCode={setInvoicePostalCode}
            city={invoiceCity}
            setCity={setInvoiceCity}
            country={invoiceCountry}
            setCountry={setInvoiceCountry}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSubmit}
        >
          Request Update
        </Button>
      </Grid>
    ) : null
  );
};


export const EditContactDetailsButton: FC<EditCompanyContactDetailsProps> = (props ) => {
  const [open, setOpen] = useState(false);
  return (
    <Box displayPrint="none">
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        size="small"
        color="primary"
      >
        <EditIcon />
        Edit
      </Button>
      {
        open && <SimpleModal
          title="Edit Contact Details"
          content={<EditCompanyContactDetails {...props} />}
          onClose={()=> setOpen(false)}
        />
      }
    </Box>
  );
};
