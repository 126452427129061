import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SimplePaper } from '../SimplePaper';
import classes from './Disclaimer.module.css';

export const Disclaimer = () => (
  <SimplePaper>
    <Typography variant="body1" color="textSecondary">
      The information set out below is provided for information purposes only, and does not purport to be complete,
      accurate or up-to-date. For a complete overview of all security interest provided in relation to you loans,
      we refer to the bond terms and all the related documents and agreements as amended from time to time. Please see our
      <Link href="/terms-of-use" className={classes.link}> Terms of Use </Link>
      for further information. Please note that you will not receive any notifications when changes are made to this page,
      and that historic or future security interests are not shown.
    </Typography>
  </SimplePaper>
);

