import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CaseLogDTO, DocumentDTO, EmployeeDTO, LoanDTO } from 'api/api.types';
import { getDocuments } from 'modules/Documents/DocumentsApi';
import { getUpcomingEventsForIssue } from 'modules/Events';
import { EventDTO } from 'modules/Events/types';
import {
  getLoanCaseHandler,
  getLoanCaseLogs,
  getLoanRemarks,
} from '../../LoansApi';
import { LoanDetails } from './LoanDetails';
import { LoanSecondaryDetails } from './LoanSecondaryDetails';
import classes from './Overview.module.css';


interface ComponentProps {
  loan: LoanDTO | undefined;
  loanID: number;
  issuerID: number;
  loadingLoanDetails: boolean;
}

export const Overview = ({
  loan, issuerID, loadingLoanDetails, loanID,
}: ComponentProps) => {
  const [events, setEvents] = useState<EventDTO[]>([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [messages, setMessages] = useState<CaseLogDTO[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [contactPerson, setContactPerson] = useState<EmployeeDTO | undefined>(undefined);

  useEffect(() => {
    setLoadingEvents(true);
    setLoadingDocuments(true);
    setLoadingMessages(true);

    getUpcomingEventsForIssue(loanID).then((fetchedItems) => {
      setEvents(fetchedItems);
      setLoadingEvents(false);
    });
    getDocuments(issuerID, loanID).then((fetchedItems) => {
      setDocuments(fetchedItems);
      setLoadingDocuments(false);
    });

    async function getAndSetMessages() {
      const messagesFromNT = await getLoanCaseLogs(loanID);
      const messagesInternal = await getLoanRemarks(loanID);
      setMessages(messagesFromNT.concat(messagesInternal).sort((a, b) => {
        if (a.date < b.date) { return 1; }
        if (a.date > b.date) { return -1; }
        return 0;
      }));
      setLoadingMessages(false);
    }

    getAndSetMessages();

    getLoanCaseHandler(loanID).then((cp) => {
      setContactPerson(cp);
    });
  }, [issuerID, loanID]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={8} xs={12}>
          <LoanDetails
            loan={loan}
            contact={contactPerson}
            events={events}
            loading={loadingLoanDetails || loadingEvents}
          />
        </Grid>
        <Grid item lg={4} xs={12}>
          <LoanSecondaryDetails
            loanID={loanID}
            events={events}
            loadingEvents={loadingEvents}
            documents={documents}
            loadingDocuments={loadingDocuments}
            messages={messages}
            loadingMessages={loadingMessages}
          />
        </Grid>
      </Grid>
    </div>
  );
};

