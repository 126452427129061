import globalAxios from 'axios';
import { getAccessToken, setUnAuthorized } from 'modules/Account/accountLocalStorage';

const { CancelToken } = globalAxios;

let cancelTokenSource = CancelToken.source();

export const getCancelToken = () => cancelTokenSource.token;

const refreshTokenSource = () => { cancelTokenSource = CancelToken.source(); };

export const cancelRequests = (): void => {
  cancelTokenSource.cancel('Auto canceling request.');
  refreshTokenSource();
};

const UNAUTHORIZED = 401;
const BAD_REQUEST = 400;

export function configureGlobalAxios(): void {
  globalAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : 0;
      if (status === UNAUTHORIZED) {
        cancelRequests();
        setUnAuthorized();
        return Promise.resolve(error);
      }

      if (status === BAD_REQUEST && error.response.data === '') {
        error.response.data = { message: 'Bad Request' };
      }

      return Promise.reject(error);
    },
  );

  globalAxios.interceptors.request.use((request) => {
    const token = getAccessToken();

    if (!request.headers.Authorization && !!token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  });
}
