import { useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button } from '@mui/material';
import { SimpleModal } from 'components/SimpleModal';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import { DocumentsUploadForm } from './DocumentsUploadForm';
import classes from './DocumentUploadButton.module.css';

interface ComponentProps {
  documentType: HubDocumentTypeUniverseEnum;
  issuerID: number;
  isin: string;
  loanID: number;
  defaultOpen?: boolean;
  covenantReportingID?: string;
  title: string;
  onUploadSuccess?: () => void;
  reportingDate?: string;
}

export const DocumentUploadButton = ({
  documentType,
  issuerID,
  loanID,
  defaultOpen,
  covenantReportingID,
  onUploadSuccess,
  isin,
  title,
  reportingDate,
}: ComponentProps) => {
  const [open, setOpen] = useState(!!defaultOpen);

  const onUploadSuccessAndClose = ()=> {
    if(onUploadSuccess) onUploadSuccess();
    setOpen(false);
  }
  return (
    <Box display={{ xs: 'none', sm: 'block' }}>
      <Button
        variant="text"
        size="small"
        color="primary"
        className={classes.cardAction}
        onClick={() => setOpen(true)}
      >
        <CloudUploadIcon className={classes.icon} />
        {title}
      </Button>
      {
        open && <SimpleModal
          title={title}
          onClose={()=> setOpen(false)}
          content={
            <DocumentsUploadForm
              onUploadSuccess={onUploadSuccessAndClose}
              documentType={documentType}
              issuerID={issuerID}
              loanID={loanID}
              isin={isin}
              covenantReportingID={Number(covenantReportingID)}
              reportingDate={reportingDate}
            />
          }
        />
      }
    </Box>
  );
};

