import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FinancialOverviewKeyFiguresDTO } from 'api/api.types';
import { Money } from '../atoms';
import classes from './KeyFigures.module.css';

interface ComponentProps{
  keyFigures: FinancialOverviewKeyFiguresDTO[] | undefined;
}

export const KeyFigures = ({ keyFigures }: ComponentProps) => (
  <TableContainer>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align="right">
            Currency
          </TableCell>
          <TableCell align="right">
            Outst. Amt
          </TableCell>
          <TableCell align="right">
            W Avg Coupon
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keyFigures?.map((k, i) => (
          <TableRow key={k.issueType} className={(i === keyFigures.length - 1) ? classes.borderTop : ''}>
            <TableCell>
              <Box fontWeight="fontWeightBold">
                {k.issueType}
              </Box>
            </TableCell>
            <TableCell align="right">
              {k.currency}
            </TableCell>
            <TableCell align="right">
              <Money value={k.outstanding} />
            </TableCell>
            <TableCell align="right">
              {`${k.weightedAverageCoupon}%`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

