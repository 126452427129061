import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export async function uploadReportingDocuments(issueID: number, reportingID: number, scheduleID: number, issuerID: number, documents: File[], uploadToStamdata: boolean[]): Promise<AxiosResponse<any>> {
  const form = new FormData();

  documents.forEach((file: File) => form.append('documents', file));
  uploadToStamdata.forEach((flag: boolean) => form.append('uploadToStamdata', `${flag}`));
  form.append('bondID', String(issueID));
  form.append('complianceScheduleID', String(scheduleID));
  form.append('issuerID', String(issuerID));

  const requestConfig: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    baseURL: `${window._env_.REACT_APP_API_BASE}`,
  };
  return axios.post(`/api/loans/${issueID}/reportings/${reportingID}/files`, form, requestConfig);
}
