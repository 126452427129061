import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import React from 'react';

interface PageHeaderDrawerProps {
  logOut: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const PageHeaderDrawer = ({
  logOut,
  open,
  setOpen,
}: PageHeaderDrawerProps) => (
  <Drawer open={open} onClose={() => setOpen(false)}>
    <div
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        <ListItem>
          <ListItemText>
            <Link component={RouterLink} to="/dashboard">
              Dashboard
            </Link>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText>
            <Link component={RouterLink} to="/loans">
              Loans
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link component={RouterLink} to="/events">
              Events
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link component={RouterLink} to="/documents">
              Documents
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link component={RouterLink} to="/account/profile">
              Account
            </Link>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText>
            <Link
              component={RouterLink}
              to="/"
              onClick={() => {
                logOut();
              }}
            >
              Sign out
            </Link>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  </Drawer>
);
