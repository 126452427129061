import { useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { InterestPaymentItemDTO } from 'api/api.types';
import { useSearchParams } from 'utils/hooks';
import getISOWithoutTime from 'utils/strings/getISOWithoutTime';
import { PaymentRowDetailsDialog } from './PaymentRowDetailsDialog';

type PaymentDetailsProps = {
  interestPayment: InterestPaymentItemDTO;
  loading: boolean;
}

export const PaymentDetails = ({ interestPayment, loading }: PaymentDetailsProps) => {
  const [{ interestPaymentDate, isin }, setSearchParams] = useSearchParams<{
    interestPaymentDate?: string;
    isin?: string;
  }>();
  const handleDetailsClick = () => {
    setSearchParams((previous) => ({
      ...previous,
      interestPaymentDate: getISOWithoutTime(interestPayment.paymentDate),
      isin: interestPayment.isin,
    }));
  };
  const handleCloseDetails = () => {
    setSearchParams((previous) => {
      delete previous.interestPaymentDate;
      delete previous.isin;
      return previous;
    });
  };
  const openDetails = useMemo(
    () =>
      isin === interestPayment.isin &&
      interestPaymentDate === getISOWithoutTime(interestPayment.paymentDate),
    [isin, interestPayment.isin, interestPayment.paymentDate, interestPaymentDate],
  );

  return (
    <Box displayPrint="none">
      <Typography variant="body1">
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={handleDetailsClick}
        >
          Details
        </Button>
        {openDetails && (
          <PaymentRowDetailsDialog
            interestPayment={interestPayment}
            loading={loading}
            onClose={handleCloseDetails}
          />
        )}
      </Typography>
    </Box>
  );
};