import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import { LoadingContainer } from 'components/Loaders';
import { EventSearchFilterOption } from '../../types';
import { EventsSearchFilterIDs, EventsSearchFilterSelection } from '../../EventsApi';
import classes from './EventsFilterList.module.css';

interface ComponentProps {
  filterID: EventsSearchFilterIDs;
  filterOptions: EventSearchFilterOption[];
  handleToggle: (
    filterID: EventsSearchFilterIDs,
    filterOption: EventSearchFilterOption,
    selection: EventsSearchFilterSelection
  ) => void;
  selection: EventsSearchFilterSelection;
  loading: boolean;
}

export const EventsFilterList = ({
  filterID, filterOptions, handleToggle, selection, loading,
}: ComponentProps) => (
  <List disablePadding className={classes.list}>
    <LoadingContainer loading={loading} type="list" rows={10} cols={1}>
      {filterOptions.map((filterOption, i) => (
        <ListItem
          key={filterOption.id || i}
          dense
          button
          className={classes.item}
          divider={filterOption.id === 'all'}
          role={undefined}
          onClick={() => handleToggle(filterID, filterOption, selection)}
        >
          <ListItemIcon className={classes.listIcon}>
            <>
              {selection === 'single' && (
                <Radio
                  edge="start"
                  checked={filterOption.selected === true}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
              )}
              {selection === 'multiple' && (
                <Checkbox
                  edge="start"
                  checked={filterOption.selected === true}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
              )}
            </>
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'body1' }}
            primary={filterOption.text}
          />
        </ListItem>
      ))}
    </LoadingContainer>
  </List>
);
