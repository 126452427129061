import { useEffect, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import { CardContent, Typography } from '@mui/material';
import { EmployeeDTO } from 'api/api.types';
import { SubCard } from 'components/Card';
import { LoadingContainer } from 'components/Loaders';
import { getCaseHandlerLogoUrl } from '../../../LoansApi';
import { ContactDetails } from './ContactDetails';
import classes from './Contact.module.css';


interface ComponentProps {
  loanID: number;
  contact: EmployeeDTO;
  loading: boolean;
}

export const Contact = ({
  loanID,
  loading,
  contact: { firstName, lastName, jobTitle, email, phoneNumber },
}: ComponentProps) => {
  const [image, setImage] = useState<string>();
  useEffect(() => {
    let unmounted = false;
    (async () => {
      const img = await getCaseHandlerLogoUrl(loanID);
      if (!unmounted && img !== '') setImage(img);
    })();
    return () => {
      unmounted = true;
    };
  }, [loanID]);
  const fullName = `${firstName} ${lastName}`;
  return (
    <SubCard title="Contact">
      <CardContent className={classes.content}>
        <LoadingContainer
          loading={loading}
          type="contactCard"
          rows={0}
          cols={0}
        >
          {image ? (
            <img src={image} alt={fullName} className={classes.photo} />
          ) : (
            <PersonIcon
              className={`${classes.photo} ${classes.avatarIcon}`}
              aria-describedby={fullName}
            />
          )}
          <Box component="article" className={classes.textWrapper}>
            <Typography variant="h6">{fullName}</Typography>
            <Typography variant="body2">{jobTitle}</Typography>
            <ContactDetails phoneNumber={phoneNumber} email={email} />
          </Box>
        </LoadingContainer>
      </CardContent>
    </SubCard>
  );
};
