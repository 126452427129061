import React from 'react';
import Box from '@mui/material/Box';
import { ExtendedMainMenu } from './ExtendedMainMenu';
import { PageHeaderMobileMenu } from './PageHeaderMobileMenu';
import { CompanyDTO } from '../../types';
import { BalanceSheetOpener } from './BalanceSheetOpener';
import {
  Dashboard,
  MyDocuments,
  MyEvents,
  MyFinancials,
} from './MainMenuButtons';
import { useGlobal } from '../../Providers';

type MainMenuPros = {
  logOut: ()=> void;
  companies: CompanyDTO[];
};

export const MainMenu: React.FC<MainMenuPros> = ({ logOut, companies }) => {
  const openRequestAuditorConfirmationModal = () => {
    const element: HTMLElement = document.getElementById(
      'openModal',
    ) as HTMLElement;
    element.click();
  };
  const { activeCompany } = useGlobal();
  return (
    <>
      <Box component={'nav'} display={{ xs: 'none', sm: 'block' }}>
        <Dashboard />
        <MyFinancials />
        <MyEvents />
        <MyDocuments />
        <ExtendedMainMenu
          logOut={logOut}
          openModal={openRequestAuditorConfirmationModal}
        />
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <PageHeaderMobileMenu logOut={logOut} />
      </Box>
      <BalanceSheetOpener company={activeCompany} companies={companies} />
    </>
  );
};
