import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { StyledCardHeader } from 'components/Card';
import classes from './EventsListSettings.module.css';

export const EventsListSettings = () => (
  <Card className={classes.card}>
    <StyledCardHeader
      avatar={<SettingsIcon />}
      title="Settings"
    />
    <CardContent className={classes.columnContent}>
      <Link component={RouterLink} to="/account/notification-settings" variant="body1">
        Change notification settings
      </Link>
    </CardContent>
  </Card>
);