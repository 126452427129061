import { ReactElement } from 'react';
import Table, { TableProps } from '@mui/material/Table';
import classes from './StickyTable.module.css';

interface StickyTableProps {
  children?: ReactElement<TableProps>[] | ReactElement<TableProps>;
}

export const StickyTable = ({
  children,
}: StickyTableProps) => (
  <div className={classes.stickyTable}>
    <Table size="small">
      {children}
    </Table>
  </div>
);