import {
Container,   Grid, Link,
} from '@mui/material';
import { HubUserWithCompaniesDTO } from '../../AccountApi/api.types';
import { NotificationSettings } from './NotificationSettings';
import { OtherUsers } from './OtherUsers';
import { UserProfile } from './UserProfile';
import classes from './ViewProfile.module.css';

interface ComponentProps {
  user: HubUserWithCompaniesDTO;
}

export const Profile = ({ user }: ComponentProps) => {
  return (<div className={classes.root}>
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <UserProfile user={user} />
            </Grid>
            <Grid item sm={12}>
              <NotificationSettings username={user.username} />
            </Grid>
            <Link
              href="https://www.hotjar.com/legal/compliance/opt-out"
              target="_blank"
              className={classes.hotJarLink}
            >
              Opt-Out with HotJar
            </Link>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <OtherUsers hubUserID={user.hubUserID} />
        </Grid>
      </Grid>
    </Container>
  </div>);
};

