import { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Fingerprint } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { CreateMaterialUiChangeHandler } from 'legacy/inputHandlers';
import { SubmitButton } from 'components/form';
import { validatePassword } from 'modules/Global/validationHelpers/validationHelpers';
import { PaperHeader, PaperPage } from '../../atoms/index';
import classes from './ConfigurePassword.module.css';

interface ComponentState {
  currentPassword?: string;
  password?: string;
  confirmPassword?: string;
  isLoading: boolean;
  eulaAgreementAccepted: boolean;
}

interface FormInput {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

interface OwnProps {
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  title?: string;
  submitTitle: string;
  text: string;
  boxless?: boolean;
  enterExistingPassword?: boolean;
  showEulaConfirmation?: boolean;
  onSubmit: (password: string, existingPassword?: string) => Promise<void>;
}

type ComponentProps = OwnProps;

export class ConfigurePassword extends Component<
  ComponentProps,
  ComponentState
> {
  private changeHandler = CreateMaterialUiChangeHandler<FormInput>(
    (key, value) => {
      this.setState({ [key]: value.trim() } as any);
    },
  );

  public constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isLoading: false,
      eulaAgreementAccepted: false,
    };

    ValidatorForm.addValidationRule(
      'passwordMeetRequirements',
      validatePassword,
    );

    ValidatorForm.addValidationRule('isPasswordMatch', (value: any) => {
      const { password } = this.state;
      return value === password;
    });

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private async handleSubmit() {
    const { onSubmit } = this.props;
    const { password, currentPassword } = this.state;

    this.setState({ isLoading: true });
    await onSubmit(password as string, currentPassword);

    this.setState({
      isLoading: false,
    });
  }

  private onFocusHandler = (value: string, propertyName: string) => {
    if (!value) {
      this.setState({ [propertyName]: '' } as any);
    }
  };

  private Container = (props: any) => {
    const { boxless } = this.props;
    if (boxless) {
      return <>{props.children}</>;
    }

    return <PaperPage>{props.children}</PaperPage>;
  };

  public render() {
    const {
      email,
      firstName,
      lastName,
      title,
      text,
      submitTitle,
      showEulaConfirmation,
      enterExistingPassword,
      userName,
    } = this.props;

    const {
      currentPassword,
      password,
      confirmPassword,
      isLoading,
      eulaAgreementAccepted,
    } = this.state;

    return (
      // eslint-disable-next-line no-undef
      <this.Container>
        <ValidatorForm onSubmit={this.handleSubmit}>
          {title && <PaperHeader>{title}</PaperHeader>}
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            className={classes.topMargin}
          >
            <Grid item>
              <Typography variant="body1">{text}</Typography>
            </Grid>
          </Grid>
          {(firstName || lastName || email) && (
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              className={classes.topMargin}
            >
              <Grid item md sm xs>
                {(firstName || lastName) && (
                  <Typography className={classes.capitalize}>
                    {firstName} {lastName}{' '}
                  </Typography>
                )}
                {email && <Typography>{email}</Typography>}
              </Grid>
            </Grid>
          )}
          {enterExistingPassword && (
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={1}>
                <Fingerprint className={classes.icon} />
              </Grid>
              <Grid item md sm xs>
                <TextValidator
                  fullWidth
                  label="Current password"
                  onChange={this.changeHandler(
                    'currentPassword',
                    (e) => e.target.value,
                  )}
                  name="currentPassword"
                  value={currentPassword ?? ''}
                  type="password"
                  validators={['required']}
                  errorMessages={['this field is required']}
                  withRequiredValidator
                  onFocus={(e: any) =>
                    this.onFocusHandler(e.target.value, 'currentPassword')
                  }
                />
              </Grid>
            </Grid>
          )}
          {/* this is here only to help with password autocomplete */}
          <TextValidator
            name=""
            value={userName}
            autoComplete="username"
            className={classes.hide}
          />
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={1}>
              <Fingerprint className={classes.icon} />
            </Grid>
            <Grid item md sm xs>
              <TextValidator
                fullWidth
                label="Password"
                onChange={this.changeHandler('password', (e) => e.target.value)}
                name="password"
                value={password ?? ''}
                type="password"
                validators={['required', 'passwordMeetRequirements']}
                errorMessages={[
                  'this field is required',
                  "password doesn't meet requirements. Minimum 1 UPPER, 1 lower, 1 number with a total minimum length of 8 chars",
                ]}
                withRequiredValidator
                onFocus={(e: any) =>
                  this.onFocusHandler(e.target.value, 'password')
                }
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={1}>
              <Fingerprint className={classes.icon} />
            </Grid>
            <Grid item md sm xs>
              <TextValidator
                fullWidth
                label="Repeat password"
                onChange={this.changeHandler(
                  'confirmPassword',
                  (e) => e.target.value,
                )}
                name="confirmPassword"
                value={confirmPassword ?? ''}
                type="password"
                validators={[
                  'required',
                  'isPasswordMatch',
                  'passwordMeetRequirements',
                ]}
                errorMessages={[
                  'this field is required',
                  "passwords doesn't match.",
                  "password doesn't meet requirements",
                ]}
                withRequiredValidator
                onFocus={(e: any) =>
                  this.onFocusHandler(e.target.value, 'confirmPassword')
                }
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          {showEulaConfirmation && (
            <Grid className={classes.topPadding}>
              <Box ml={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={eulaAgreementAccepted}
                      onChange={() =>
                        this.setState({
                          eulaAgreementAccepted: !eulaAgreementAccepted,
                        })
                      }
                      color="primary"
                    />
                  }
                  label={
                    <>
                      By clicking &quot;Verify Account&quot;, you agree to our
                      <Link
                        href="/terms-of-use"
                        className={classes.eulaTextLink}
                      >
                        {' '}
                        Terms of Use{' '}
                      </Link>
                      and that you have read our
                      <Link
                        href="https://nordictrustee.com/about/governance/"
                        className={classes.eulaTextLink}
                      >
                        {' '}
                        Privacy Statement{' '}
                      </Link>
                      and our
                      <Link
                        href="https://nordictrustee.com/privacy/"
                        className={classes.eulaTextLink}
                      >
                        {' '}
                        Cookie Policy
                      </Link>
                      .
                    </>
                  }
                />
              </Box>
            </Grid>
          )}
          <Grid container justifyContent="center" className={classes.topMargin}>
            <SubmitButton
              text={submitTitle}
              loading={isLoading}
              disabled={showEulaConfirmation && !eulaAgreementAccepted}
            />
          </Grid>
        </ValidatorForm>
      </this.Container>
    );
  }
}

